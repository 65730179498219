import { AbstractGraphData } from "../abstract-graph-data";
import { AppType } from "../../app.type";
import { Impact } from "../../../../../shared/models/impact";

export class FleetGraphData extends AbstractGraphData {
  private monthEnd: Date;
  fv = 0;
  rv = 0;
  others = 0;

  constructor(private monthBegin: Date, app: AppType) {
    super(app);
    this.monthEnd = new Date(
      monthBegin.getFullYear(),
      monthBegin.getMonth() + 1
    );
  }

  getTime() {
    return this.monthBegin.getTime();
  }

  add(impact: Impact) {
    const thread = this.app.graphs.allThreads.find(
      (t) => t.omfNumber === impact.omfNumber
    );
    if (thread == null) {
      return;
    }
    const threadCreateDate: number = thread.create_time;
    let fleet = "";
    if (impact.omfVehicleFleet != null) {
      fleet = impact.omfVehicleFleet;
    }

    if (!(threadCreateDate && fleet)) {
      return;
    }
    if (threadCreateDate > this.monthEnd.getTime()) {
      return;
    }

    switch (fleet.toUpperCase()) {
      case "RV":
        this.rv++;
        break;
      case "FV":
        this.fv++;
        break;
      default:
        this.others++;
        break;
    }
  }

  get data() {
    return {
      name: this.getDateLabel(this.monthBegin),
      series: [
        { name: "RV", value: this.rv },
        { name: "FV", value: this.fv },
        { name: this.app.text.graphs.others, value: this.others },
      ],
    };
  }
}
