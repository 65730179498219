<!-- <app-modal
  #discardChangesModal
  [modalMessage]="app.text.impact.discard"
  (ok)="discardChanges(); app.impact.impactNameExist = false"
>
</app-modal> -->

<ng-template #content let-modal>
  <div class="modal-header">
    <div class="col-md-12">
      <span class="modal-title">{{ modalTitle }}</span>
      <button class="btn pull-right" (click)="modal.dismiss('CANCEL')">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="field !== 'impact.actions'">
      <app-field
        *ngFor="let field of fields; index as index"
        [model]="app.train.currentModel"
        [field]="field"
        [index]="index"
      ></app-field>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-ok text-center m-1"
      (click)="app.train.save(); closeModal()"
      [disabled]="!showSaveButton()"
    >
      {{ app.text.app.save }}
    </button>
  </div>
</ng-template>
