import { DocModel } from "../state/state";
import { ImportXmlServiceType } from "./import-xml-service.type";
import { parseXML } from "./pcn-generator-import.util";
import { Injectable } from "@angular/core";

@Injectable()
export class ImportXmlService implements ImportXmlServiceType {
  att: any;
  result: DocModel = {};

  uploadFile(file: any) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      this.readFile(event, file.name);
    };
  }

  readFile(event: any, fileName: string) {
    const target: any = event.target;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(target.result, "text/xml");
    this.parse(xmlDoc, fileName);
  }

  parse(xml: Document, fileName: string) {
    /** !! IMPORTANT!!! this is GENERAL import service please create separate services/files for type specific logic ! */
    /** handle pcn's */
    if (fileName === "PCNbody.xml") {
      this.result = parseXML(xml);
    }
    /** add logic for parsing other types docs if needed */
  }
}
