import { Inject, Injectable } from "@angular/core";
import { CreateManufacturerServiceType } from "./create-manufacturer-modal.service-type";
import { DocModel } from "../state/state";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { APP_TYPE, AppType } from "../app.type";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CreateManufacturerService
  implements CreateManufacturerServiceType {
  model: DocModel = new Manufacturer();

  constructor(@Inject(APP_TYPE) private app: AppType, private router: Router) {}

  setModel() {
    if (this.app.view === "external-data-filter") {
      this.model = this.app.externalDataFilter.setFields();
    }
    if (this.app.view === "manufacturer") {
      this.model = this.app.manufacturerUtils.setFieldsFromSelected();
      this.model["manufacturer.partNumber"] =
        this.app.manufacturer.currentManufacturer["manufacturer.partNumber"];
      this.app.typeAheadBasic.model["manufacturer.partNumber"] =
        this.app.manufacturer.currentManufacturer["manufacturer.partNumber"];
      // await this.app.part.getPartsWithoutCpns();
    }
    if (this.app.view === "part") {
      this.model["manufacturer.partNumber"] =
        this.app.part.part["part.partNumber"];
      this.app.typeAheadBasic.model["manufacturer.partNumber"] =
        this.app.part.part["part.partNumber"];
    }
  }
}
