import { AppType, APP_TYPE } from "../app.type";
import { DinCodeResponsibleServiceType } from "./din-code-responsible.service.type";
import { Inject, Injectable } from "@angular/core";
import { getDinCodeDocuments } from "../api.service";
import { DinCodeResponsible } from "../../../../shared/models/dinCodeResponsible";

@Injectable()
export class DinCodeResponsibleService
  implements DinCodeResponsibleServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  dinCodeDocuments: DinCodeResponsible[] = [];
  dinCodeRespName: string[] = [];
  dinCodeRespDep: string[] = [];
  dinCodeRespEmail: string[] = [];

  async getDinCodeDocuments() {
    const customer = this.app.customers.expectCurrent;
    this.dinCodeDocuments = await getDinCodeDocuments(customer);
    this.extractDinCodeData();

    return this.dinCodeDocuments;
  }

  set(doc: DinCodeResponsible) {
    this.app.thread.thread["thread.dinCodeRespName"] =
      doc.responsibleFirstName + " " + doc.responsibleLastName;
    this.app.thread.thread["thread.dinCodeRespDep"] = doc.responsibleDepartment;
    this.app.thread.thread["thread.dinCodeRespEmail"] = doc.responsibleEmail;
  }

  generateResponsibles(docs: any[]) {
    const responsibles: DinCodeResponsible[] = [];
    docs.forEach((doc) => {
      const dinCodeRespDoc = {} as DinCodeResponsible;
      this.app.import.props.forEach((field) => {
        dinCodeRespDoc[field.key] = doc[field.key];
      });
      dinCodeRespDoc.type = "dinCodeResponsible";
      delete dinCodeRespDoc._id;
      responsibles.push(dinCodeRespDoc);
    });
    docs = responsibles;
    this.app.import.docs = docs;
    (docs as []).forEach((doc: DinCodeResponsible) => {
      this.app.import.selected.add(doc.dinCode);
    });

    this.app.import.stepper = "reviewList";
    this.app.import.step = "third";
  }

  private extractDinCodeData() {
    const names = this.dinCodeDocuments.map(
      (doc) => `${doc.responsibleFirstName} ${doc.responsibleLastName}`
    );
    this.dinCodeRespName = [...new Set(names)].filter((name) => name != " ");

    const departments = this.dinCodeDocuments.map(
      (doc) => doc.responsibleDepartment
    );
    this.dinCodeRespDep = [...new Set(departments)].filter((dep) => dep != "");

    const emails = this.dinCodeDocuments.map((doc) => doc.responsibleEmail);
    this.dinCodeRespEmail = [...new Set(emails)].filter((email) => email != "");
  }
}
