import { Component, Input } from "@angular/core";
import { getApp } from "../../app";
import { ImportType } from "../pcn-generator.service";

@Component({
  selector: "app-pcn-generator-step-bar",
  templateUrl: "./pcn-generator-step-bar.component.html",
  styleUrls: ["./pcn-generator-step-bar.component.scss"],
})
export class PcnGeneratorStepBarComponent {
  app = getApp((app) => {
    this.app = app;
  });
  @Input() listBoxes: string[] = [];
  @Input() hideBoxes: string[] = [];

  constructor() {}
  ngOnInit() {}

  // check if the box should be displayed
  showBox(box: string) {
    for (const hideBox of this.hideBoxes) {
      if (hideBox === box) {
        return false;
      }
    }

    return true;
  }

  displayBox(box: string) {
    if (this.app.pcnGenerator.isPcnSent) {
      this.app.pcnGenerator.changePcnType(ImportType.Manually);
    } else {
      this.app.leftNav.selectedBox = box;
    }
  }
}
