import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { Part } from "../../../../shared/models/part";
import { Subscription } from "rxjs";
import { doc2Model, getManufacturersByPartNumber } from "../api.service";

@Component({
  selector: "app-part",
  templateUrl: "./part.component.html",
  styleUrls: ["./part.component.scss"],
})
export class PartComponent implements OnInit, OnDestroy {
  previousPartNumber: string | null = "";
  isNewTab: boolean = history.length > 1 ? false : true;
  boxesLeft: string[] = [];
  boxesLeftEditMode: string[] = [];

  canExport = true;

  deleteModalTitle = "";
  deleteModalText = "";
  docType = "";

  @ViewChild("fileInput", { static: false })
  fileInput: any;
  @ViewChild("deleteItemsModal", { static: false })
  deleteItemsModal: any;

  app = getApp(async (app) => {
    this.app = app;
  });

  private _routeSubscription: Subscription = new Subscription();

  constructor() {}

  async ngOnInit() {
    if (this.app.part.id !== null) {
      this.app.routing.setTabTitle(this.app.part.id + " - LCM Client");
      await this.getCurrentPart();

      // Reset view to Item Details to avoid not loading of BOM/Manufacturers
      this.app.leftNav.selectedBox = "part.itemDetailSection";

      this.setPreviousPartNumber();

      // Initialize boxes
      this.initializeBoxes();

      this.getSelectedBox();
      this.app.message.getMessages(this.app.part.id);
    }
    // if the part does not exist anymore in the database redirect to not found page
    if (Object.keys(this.app.part.part).length == 0) {
      this.app.routing.navigateNotFound();
    }

    this.app.part.getImageExternal();
  }

  async getCurrentPart() {
    if (this.app.part.id == null) {
      return;
    }

    const part: Part = await this.app.part.getPartByPartNumberWithoutBuffer(
      this.app.part.id
    );

    this.app.part.cleanPart = doc2Model("part", part) as Part;
    this.app.part.part = doc2Model("part", part) as Part;
  }

  // getImageSource(): string {
  //   return this.app.url.part.defaultImage;
  // }

  setPreviousPartNumber() {
    this.previousPartNumber = this.app.part.id;

    if (this.previousPartNumber === this.app.part.id) {
      this.previousPartNumber = "";
    }
  }

  async navigateBack() {
    await this.app.routing.navigatePart(this.previousPartNumber as string);
  }

  async discardChanges() {
    this.app.part.id = this.app.part.id;
    await this.getCurrentPart();
    this.app.unlockedId = null;
  }

  private getSelectedBox(): void {
    const selectedBox: string =
      this.app.leftNav.selectedBox != null ? this.app.leftNav.selectedBox : "";

    this.app.leftNav.selectedBox =
      this.app.list.part.boxesLeft.indexOf(selectedBox) === -1
        ? "part.itemDetailSection"
        : this.app.leftNav.selectedBox;
  }

  private async initializeBoxes(): Promise<void> {
    // If the part have bill of materials hide Manufacturers button, else hide Bill Of Materials button
    // Keep both sections in case an assembly has parts and also manufacturers
    if (this.app.part.id !== null) {
      this.boxesLeft = this.app.list.part.boxesLeft;
      this.boxesLeftEditMode = this.app.list.part.boxesLeftEditMode;
      let boxIndex: number | null = null;
      let bom: string[] = [];
      const manufacturers = await getManufacturersByPartNumber(
        this.app.customers.expectCurrent,
        this.app.part.id,
        true
      );

      if (this.app.part.cleanPart[this.app.fieldId.part.parts] != null) {
        bom = Object.keys(this.app.part.cleanPart[this.app.fieldId.part.parts]);
      }

      if (bom.length > 0 && manufacturers.length === 0) {
        boxIndex = this.boxesLeft.findIndex(
          (i) => i === "part.manufacturerSection"
        );

        this.boxesLeft.splice(boxIndex, 1);
      }

      if (manufacturers.length > 0 && bom.length === 0) {
        boxIndex = this.boxesLeft.findIndex(
          (i) => i === "part.billOfMaterialSection"
        );

        this.boxesLeft.splice(boxIndex, 1);
        this.canExport = false;
      }
    }
  }

  async onUpload() {
    const file: File = this.fileInput.nativeElement.files[0];
    if (file.type.indexOf("image/") !== 0 && file != null) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.thread.uploadImageTypeError;
    } else {
      this.app.hasError = false;
      this.app.errorText = "";
      const fileLink = this.app.part.getImage();
      await this.app.file.upload(file, fileLink);
      this.app.part.getImageExternal();
      // this.app.thread.hasDeleteImagePermission();
    }
  }

  isExportAvailable() {
    if (
      this.app.permission.RM.canExport &&
      this.canExport &&
      this.app.leftNav.selectedBox !== "part.manufacturerSection"
    ) {
      return true;
    }
    return false;
  }

  deleteItems(docType: string) {
    this.docType = docType;
    this.deleteItemsModal.open();
    if (
      docType === "manufacturer" &&
      this.app.permission.manufacturer.deleteManufacturer
    ) {
      this.deleteModalTitle = this.app.text.manufacturer.deleteManufacturer;
      this.deleteModalText = this.app.text.manufacturer.removeModal;
    }
    if (docType === "part") {
      this.deleteModalTitle = this.app.text.part.deleteModal;
      this.deleteModalText = this.app.text.part.removePart;
    }
  }

  ngOnDestroy(): void {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }
}
