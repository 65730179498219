import { ActivatedRoute } from "@angular/router";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { Subscription } from "rxjs";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput", { static: false }) fileInput: any;
  profileName = "";

  private _routeSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  personProfileFields: string[] = [];

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.personProfileFields = this.app.list.personProfile.personProfile;
    // this.app.profile.user = this.selectedProfile;
    this._routeSubscription = this.route.params.subscribe(async (params) => {
      this.profileName = params.user;
      this.app.profile.userProfilePicture =
        await this.app.profile.getProfilePicture(this.profileName);
      await this.app.profile.getProfile(this.profileName);
      this.app.profile.loadProfileModelWithSections();

      if (this.app.customers.expectCurrent === Customer.OC) {
        this.app.OCHome.setBadgeSystem();
        if (!this.app.auth.isAdmin) {
          this.personProfileFields.splice(
            this.personProfileFields.indexOf("personProfile.Management", 1)
          );
        }
      }
    });
  }

  get name(): string {
    const profileUser = this.app.profile.user;
    if (profileUser != null) {
      return profileUser;
    }
    return this.app.expectUser;
  }

  get own() {
    return this.name === this.app.user;
  }

  checkPermissions(name: string) {
    switch (this.app.customers.expectCurrent) {
      case Customer.OC:
        return this.app.permission.user.manageProfile;
      default:
        if (name !== this.app.user) {
          return false;
        } else {
          return true;
        }
    }
  }

  async onUpload() {
    const file: File = this.fileInput.nativeElement.files[0];
    this.app.profile.uploadProfilePicture(file);
  }

  async onDelete() {
    await this.app.profile.deleteProfilePicture();
    this.fileInput.nativeElement.value = "";
  }

  discardDetails() {
    this.app.unlockedId = null;
    this.app.profile.profileWithSections = Object.assign(
      {},
      this.app.profile.cleanProfileWithSections
    );

    this.app.userSettings.currentUserEmailPreferences = [
      ...this.app.userSettings.cleanUserEmailPreferences,
    ];
  }

  showEmailsBox() {
    // Read mode
    if (this.app.unlockedId == null) {
      if (
        (this.app.auth.isAdmin &&
          this.app.customers.expectCurrent === Customer.OC) ||
        this.app.users.currentUser.name === this.profileName
      ) {
        return true;
      }
    }

    // Edit mode
    if (this.app.unlockedId != null) {
      if (
        (this.app.auth.isAdmin &&
          this.app.customers.expectCurrent === Customer.OC) ||
        (this.app.users.currentUser.name === this.profileName &&
          this.app.permission.userSettings.editOwn)
      ) {
        return true;
      }
    }

    return false;
  }

  ngOnDestroy(): void {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }
}
