import { AlertData } from "../../../shared/models/alert";
import { AllDocs, AllDocsCompressed } from "../../../shared/index";
import { ApplicationArea } from "../../../shared/models/application-area";
import { AttachmentsInfo } from "../../../shared/models/attachment";
import { Change } from "../../../shared/models/change";
import { CommodityGroupResponsible } from "../../../shared/models/commodityGroupResponsible";
import { DinCodeResponsible } from "../../../shared/models/dinCodeResponsible";
import { DocModel } from "./state/state";
import { EmailDetails } from "../../../shared/models/email-details";
import { FileData } from "../../../shared/models/pcn";
import { getFileContentAsArrayBuffer } from "./import/file.utils";
import { getToken } from "./auth/token";
import { HistoryChartResponse, HistoryDoc } from "./history/history";
import { HttpClient } from "@angular/common/http";
import { Impact } from "../../../shared/models/impact";
import { InternalItem } from "../../../shared/models/internalItem";
import { lastValueFrom } from "rxjs";
import { LoginDetails } from "../../../shared/models/login-details";
import { LoginResult } from "../../../shared/models/login-result";
import { Manufacturer, SeMatch } from "../../../shared/models/manufacturer";
import { ManufacturerCode } from "../../../shared/models/manufacturerCode";
import { ManufacturerNames } from "../../../shared/models/manufacturerName";
import { Message } from "../../../shared/models/message";
import { MFADetails } from "../../../shared/models/MFA-details";
import { OCComment } from "../../../shared/models/obsolescence-community/OC-Comment";
import { OCPost } from "../../../shared/models/obsolescence-community/OC-Post";
import { OmfFormat } from "../../../shared/models/omf-number";
import { Parameter } from "../../../shared/models/parameter";
import { Part } from "../../../shared/models/part";
import { Pcn } from "../../../shared/models/pcn";
import { Person } from "../../../shared/models/person";
import { Post } from "../../../shared/models/post";
import { PredefinedTask } from "../../../shared/models/predefinedTasks";
import { Profile } from "../../../shared/models/profile";
import { ResetPasswordEmailDetails } from "../../../shared/models/reset-password-email-details";
import { ResetPasswordObject } from "../../../shared/models/reset-password-object";
import { RMDoc } from "../../../shared/models/rm-search";
import { ServiceHelper } from "./injector";
import { StockDoc } from "../../../shared/models/stock";
import { SyncDoc } from "../../../shared/models/sync";
import { Task } from "../../../shared/models/task";
import { Thread } from "../../../shared/models/thread";
import { TotalCostDoc } from "../../../shared/models/totalCostLTB";
import { Train } from "../../../shared/models/train";
import { User, UserCompressed, UserOptions } from "../../../shared/models/user";
import { UserSettings } from "../../../shared/models/user-settings";
import { VehicleResponsible } from "../../../shared/models/vehicleResponsible";
import { WorkHistory } from "../../../shared/models/workHistory";
import {
  API,
  API_RESET,
  API_ALERTS,
  API_ALL_OMFNUMBERS,
  API_ASSEMBLIES,
  API_FILTER_ASSEMBLIES,
  API_FILTER_TREE,
  API_RM_SEARCH,
  API_BULK_MANUFACTURERS,
  API_BULK_PARTS,
  API_CHECK,
  API_IDS_BY_TYPE,
  API_DOCS_BY_TYPE,
  API_FILES,
  API_HISTORY,
  API_IMPACTS,
  API_LOGIN,
  API_MFA,
  API_MANUFACTURERS,
  API_MATCH,
  API_OMF_NUMBERS,
  API_PARTS,
  API_PROFILE,
  API_SETTINGS,
  API_STATUS_CHART_HISTORY,
  API_SUBSTITUTES,
  API_SYNC,
  API_TRAINS,
  API_UPDATE_MANUFACTURER,
  API_TASKS,
  API_VERIFY_PASSWORD,
  API_PCNS,
  API_THREAD,
  API_POST,
  API_CHANGES,
  API_APPLICATION_AREA,
  API_GET_FILTERSERVICE,
  API_USERS,
  API_WORK_HISTORY,
  API_WORK,
  API_GRAPHS,
  API_DINCODE_RESP,
  API_COMMODITY_RESP,
  API_VEHICLE_RESP,
  API_PCN_GENERATOR,
  API_MESSAGE,
  API_SAVE_PASS,
  API_CHECK_TOKEN,
  API_WORKFLOW,
  API_INTERNALITEMS,
  API_FILTER_PARTS,
  API_USER_SETTINGS,
  API_PERSON,
  API_MANUFACTURER_NAMES,
  API_OCPOST,
  API_OCCOMMENT,
} from "../../../shared/api";
import {
  defaultSettings,
  Docs,
  Settings,
  Type,
} from "../../../shared/components";
import {
  SeMatchingResult,
  SeSearchPart,
} from "../../../shared/models/manufacturer";
import {
  WorkCountResponse,
  WorkRequest,
} from "./operations/operations.service";

function getApiUrl(...segments: string[]) {
  return [API, ...segments].join("/");
}

interface Result {
  id: string;
  rev: string;
  ok: string;
}

export async function login(details: LoginDetails): Promise<LoginResult> {
  const url = getApiUrl(API_LOGIN);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<LoginResult>(url, details)
  );
}

export async function completeMFA(details: MFADetails): Promise<LoginResult> {
  const url = getApiUrl(API_MFA);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<LoginResult>(url, details)
  );
}

export async function checkAuth(): Promise<LoginResult> {
  const url = getApiUrl(API_CHECK);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<LoginResult>(url)
  );
}

export async function checkIfTokenValid(token: string): Promise<boolean> {
  const url = getApiUrl(API_CHECK_TOKEN);
  const body = { token };

  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<boolean>(url, body)
  );
}

export async function verifyUsernameAndPassword(
  details: LoginDetails
): Promise<boolean> {
  const url = getApiUrl(API_VERIFY_PASSWORD);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<boolean>(url, details)
  );
}

export async function resetPassword(
  details: ResetPasswordEmailDetails
): Promise<void> {
  const url = getApiUrl(API_RESET);
  await lastValueFrom(ServiceHelper.get(HttpClient).post<void>(url, details));
}
export async function saveNewPassword(
  details: ResetPasswordObject
): Promise<boolean> {
  const url = getApiUrl(API_SAVE_PASS);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<boolean>(url, details)
  );
}

async function markAsSeen(
  alerts: AlertData[],
  customer: string,
  user: string
): Promise<AlertData> {
  const url = getApiUrl(API_ALERTS, customer, "mark-as-seen");
  const body = { alerts, user };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<AlertData>(url, body)
  );
}

export async function markDocSeen(
  customer: string,
  docs: any[],
  type: string
): Promise<any[]> {
  const url = getApiUrl(API_IDS_BY_TYPE, customer, "mark");
  const body = { docs, customer, type };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, body)
  );
}

async function checkPassword(details: LoginDetails): Promise<void> {
  const url = getApiUrl(API_CHECK);
  await lastValueFrom(
    ServiceHelper.get(HttpClient).post<boolean>(url, details)
  );
}

export async function getHistory(
  id: string,
  customer: string
): Promise<HistoryDoc[]> {
  const url = getApiUrl(API_HISTORY, customer);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<HistoryDoc[]>(url, { key: id })
  );
}

async function deleteHistory(
  customer: string,
  docs: HistoryDoc[]
): Promise<HistoryDoc[]> {
  const url = getApiUrl(API_HISTORY, customer, "delete-history");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).put<HistoryDoc[]>(url, { keys: docs })
  );
}

export async function deleteHistoryByDocType(
  customer: string,
  docType: string
): Promise<any> {
  const url = getApiUrl(API_HISTORY, customer, "delete-history-by-type");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, { type: docType })
  );
}

export async function getStatusHistory(
  customer: string
): Promise<HistoryChartResponse[]> {
  const url = getApiUrl(API_STATUS_CHART_HISTORY, customer);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<HistoryChartResponse[]>(url)
  );
}

export async function getIdsByType(
  customer: string,
  type: Type
): Promise<{ id: string; value: string }[]> {
  const url = getApiUrl(API_IDS_BY_TYPE, customer);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ id: string; value: string }[]>(url, {
      type,
    })
  );
}

async function getIdsByOmfNumber(
  customer: string,
  type: Type,
  omfNumber: string
): Promise<{ id: string; value: string }[]> {
  const url = getApiUrl(API_IDS_BY_TYPE, customer, "omfNumber");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ id: string; value: string }[]>(url, {
      type,
      omfNumber,
    })
  );
}

export async function getDocsByType(
  customer: string,
  type: Type,
  docId?: string
): Promise<Docs[Type][]> {
  const url = getApiUrl(API_DOCS_BY_TYPE, customer);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Docs[Type][]>(url, { type, id: docId })
  );
}

export async function getDocsByOmfNumber(
  customer: string,
  type: Type,
  omfNumber: string
): Promise<{ doc: Type }[]> {
  const url = getApiUrl(API_DOCS_BY_TYPE, customer, "omfNumber");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ doc: Type }[]>(url, {
      type,
      omfNumber,
    })
  );
}

export async function getDocsByPartNumber(
  customer: string,
  type: Type,
  partNumber: string
) {
  const url = getApiUrl(API_DOCS_BY_TYPE, customer, "partNumber");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ doc: Type }[]>(url, {
      type,
      partNumber,
    })
  );
}

export async function importDocsByType(
  customer: string,
  type: Type,
  createHistory: boolean = false,
  docs: any[]
) {
  let url = getApiUrl(API_IDS_BY_TYPE, customer, "bulk");
  if (createHistory) {
    url = getApiUrl(API_IDS_BY_TYPE, customer, "import-bulk");
  }
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ id: string; value: string }[]>(url, {
      type,
      keys: docs,
    })
  );
}

export async function bulkDocsByType(
  customer: string,
  type: Type,
  docs: any[]
) {
  const url = getApiUrl(API_IDS_BY_TYPE, customer, "bulk");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ id: string; value: string }[]>(url, {
      type,
      keys: docs,
    })
  );
}

export async function deleteBulkByType(
  customer: string,
  type: Type,
  docs: any[]
) {
  const url = getApiUrl(API_IDS_BY_TYPE, customer, "bulk-delete");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ id: string; value: string }[]>(url, {
      type,
      keys: docs,
    })
  );
}

async function bulkDocsByTypeWithChunks(
  customer: string,
  type: Type,
  docs: any[]
): Promise<{ id: string; value: string }[]> {
  const url = getApiUrl(API_IDS_BY_TYPE, customer, "bulk-chunks");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ id: string; value: string }[]>(url, {
      type,
      keys: docs,
    })
  );
}

export async function getCustomerSettings(customer: string): Promise<Settings> {
  const url = getApiUrl(API_SETTINGS, customer);
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Settings>(url));
}

export async function getCustomerSettingsWithoutUser(customer: string) {
  const url = getApiUrl(API_SETTINGS, customer, "pcn");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Settings>(url));
}

export async function getManufacturerNames() {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "get-names");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<ManufacturerNames>(url, {})
  );
}
export async function setManufacturerNames(names: ManufacturerNames) {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "set-names");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<ManufacturerNames>(url, names)
  );
}

export async function getManufacturerNameByCode(manufacturerCode: string) {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "manufacturerNameByCode");
  const body = {
    manufacturerCode,
  };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, body)
  );
}

export async function getCageCodeList() {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "cageCodeList");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{
      cageCodes: string[];
    }>(url, {})
  );
}

export async function getManufacturerCodeByName(manufacturerName: string) {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "manufacturerCodeByName");
  const body = { manufacturerName };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<ManufacturerCode>(url, body)
  );
}

async function getManufacturerNamesList() {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "listOfNames");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<string[]>(url, {})
  );
}

export async function getAllManufacturersCodesDocs() {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "allManufacturersCodesDocs");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<ManufacturerCode[]>(url, {})
  );
}

export async function getManufacturers(
  customer: string,
  manufacturerIds?: string[]
) {
  const url = getApiUrl(API_MANUFACTURERS, customer);
  let body: {} | null;
  if (manufacturerIds != null) {
    body = { ids: manufacturerIds };
  } else {
    body = null;
  }
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Manufacturer[]>(url, body)
  );
}

export async function getMatches(
  customer: string,
  manufacturerPartNumber: string,
  dataProvider: string
) {
  const url = getApiUrl(API_MANUFACTURERS, customer, "matches");
  const body = {
    dataProvider,
    partNumber: manufacturerPartNumber,
  };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<SeMatch[]>(url, body)
  );
}

export async function createMatchDoc(
  customer: string,
  dataProvider: string,
  manufacturerPartNumber: string,
  manufacturerName: string
) {
  const url = getApiUrl(API_MANUFACTURERS, customer, "create-match");
  const body = {
    dataProvider,
    manufacturerPartNumber,
    manufacturerName,
  };

  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, body)
  );
}

export async function updateManufacturer(
  customer: string,
  type: string,
  newDoc: any,
  oldDoc?: any
) {
  if (newDoc == null || Object.keys(newDoc).length < 1) {
    return {};
  }
  const url = getApiUrl(API_UPDATE_MANUFACTURER, customer, type);
  const body = { updatedManufacturer: newDoc, oldManufacturer: oldDoc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Manufacturer>(url, body)
  );
}

export async function deleteManufacturer(customer: string, doc: Manufacturer) {
  const url = getApiUrl(API_MANUFACTURERS, customer, "delete");
  const body = { manufacturer: doc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Manufacturer>(url, body)
  );
}

async function getManufacturersCompressed(customer: string) {
  const url = getApiUrl(
    API_MANUFACTURERS,
    customer,
    "manufacturers-compressed"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<Manufacturer[]>(url)
  );
}

export async function getManufacturerByMPN(
  customer: string,
  mpn: string
): Promise<Manufacturer[]> {
  const body = { mpn };
  const url = getApiUrl(API_MANUFACTURERS, customer, "byMPN");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Manufacturer[]>(url, body)
  );
}
export async function getManufacturerByMPNAndCPN(
  customer: string,
  mpn: string,
  cpn: string
) {
  const body = { mpn, cpn };
  const url = getApiUrl(API_MANUFACTURERS, customer, "byMPNAndCPN");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Manufacturer[]>(url, body)
  );
}
export async function getManufacturerIdByMPN(customer: string, mpn: string) {
  const body = { mpn };
  const url = getApiUrl(API_MANUFACTURERS, customer, "idByMpn");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Manufacturer>(url, body)
  );
}

export async function getManufacturersByPartNumber(
  customer: string,
  partNumber?: string,
  detail?: boolean
): Promise<Manufacturer[]> {
  let body: {} | null;
  if (partNumber != null) {
    body = { partNumber, detail };
  } else {
    body = null;
  }
  const url = getApiUrl(API_MANUFACTURERS, customer, "byPartNumber");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Manufacturer[]>(url, body)
  );
}
export async function getManufacturerIdsByMode(
  customer: string,
  mode: string
): Promise<string[]> {
  // this api does not bring all the properties from a manufacturer doc
  const url = getApiUrl(API_MANUFACTURERS, customer, "idsByFilterType");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<string[]>(url, { filterType: mode })
  );
}
export async function getAllMByPartNumberPaginated(
  customer: string,
  partNumber: string | null,
  page: string,
  pageSize: string
) {
  let body: {} | null;
  if (partNumber != null) {
    body = { partNumber };
  } else {
    body = null;
  }
  const url = getApiUrl(
    API_MANUFACTURERS,
    customer,
    "byPartNumber",
    page,
    pageSize,
    "manufacturersByPage"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{
      manufacturers: Manufacturer[];
      size: number;
    }>(url, body)
  );
}
export async function getMpnsListByPartNumber(
  customer: string,
  partNumber?: string
) {
  let body: {} | null;
  if (partNumber != null) {
    body = { partNumber };
  } else {
    body = null;
  }
  const url = getApiUrl(API_MANUFACTURERS, customer, "mpnsByPartNumber");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<string[]>(url, body)
  );
}

async function getTreeManufacturers(customer: string): Promise<Manufacturer[]> {
  const url = getApiUrl(API_MANUFACTURERS, customer, "tree");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<Manufacturer[]>(url)
  );
}

/** filtered list of manufacturers */
/** matched - unmatched - edited - manual override - manual assignment required */
export async function filterManufacturers(
  customer: string,
  filterType: string,
  page: string,
  pageSize: string
): Promise<{ manufacturers: Manufacturer[]; size: number }> {
  const url = getApiUrl(
    API_MANUFACTURERS,
    customer,
    "filter",
    filterType,
    page,
    pageSize,
    "manufacturers"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<{
      manufacturers: Manufacturer[];
      size: number;
    }>(url)
  );
}

export async function filterManufacturersForExport(
  customer: string,
  filterType: string,
  partNumbers: string[]
): Promise<Manufacturer[]> {
  const url = getApiUrl(
    API_MANUFACTURERS,
    customer,
    "filterManufacturersForExport"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Manufacturer[]>(url, {
      filterType,
      partNumbers,
    })
  );
}

export async function getManufacturersByCPNs(
  customer: string,
  partNumbers: string[]
) {
  const url = getApiUrl(API_MANUFACTURERS, customer, "manufacturersByCPNs");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Manufacturer[]>(url, {
      partNumbers,
    })
  );
}

/** filter in the database based on a specific query */
export async function filterManufacturersByCPNs(
  customer: string,
  filterType: string,
  partNumbers: string[],
  page?: string,
  pageSize?: string
) {
  const url = getApiUrl(
    API_MANUFACTURERS,
    customer,
    "filterManufacturersByCPNs"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{
      manufacturers: Manufacturer[];
      size: number;
    }>(url, {
      filterType,
      partNumbers,
      page,
      pageSize,
    })
  );
}

/** filter in the database based on a specific query */
export async function filterAllManufacturers(
  customer: string,
  fieldNames: string[],
  values: any,
  filterType: string,
  page?: string,
  pageSize?: string,
  mpnsFiltered?: string[]
) {
  const url = getApiUrl(
    API_MANUFACTURERS,
    customer,
    "filter-all-manufacturers"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, {
      fieldNames,
      values,
      filterType,
      page,
      pageSize,
      mpnsFiltered,
    })
  );
}

export async function getTypeaheadMPNsandCPNs(customer: string, mode?: string) {
  const url = getApiUrl(API_MANUFACTURERS, customer, "typeaheadMPNsandCPNs");
  const body = { mode };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{
      cpns: string[];
      mpns: string[];
      names: string[];
    }>(url, body)
  );
}

export async function getIPC(
  customer: string,
  comId?: string
): Promise<string> {
  const url = getApiUrl(API_MANUFACTURERS, customer, "ipcExport");
  const body = { customer, comId };
  const response = await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, body, {
      responseType: "text" as "json",
    })
  );
  if (response instanceof ArrayBuffer) {
    const decoder = new TextDecoder("utf-8");
    return decoder.decode(response); // Convert ArrayBuffer to string (XML)
  }
  return response as string;
}

async function bulkManufacturers(docs: any, customer: string) {
  const url = getApiUrl(API_BULK_MANUFACTURERS, customer);
  const body = { keys: docs };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, body)
  );
}

export async function searchSeManufacturer(
  params: SeSearchPart[],
  customer: string
) {
  const url = getApiUrl(API_MATCH, customer, "seSearch");
  const body = { seParams: params };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<SeMatchingResult[]>(url, body)
  );
}

export async function searchZ2DataManufacturer(customer: string, mpn: string) {
  const url = getApiUrl(API_MATCH, customer, "z2DataSearch");
  const body = { mpn };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, body)
  );
}

export async function getMPNByDataProviderId(
  dataProvider: string,
  dataProviderId: string,
  customer: string
) {
  const url = getApiUrl(
    API_MANUFACTURERS,
    customer,
    "manufacturersByDataProviderId"
  );
  const body = { dataProvider, dataProviderId };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, body)
  );
}

export async function getDataByProviderId(
  customer: string,
  dataProvider: string,
  dataProviderId: string
) {
  const url = getApiUrl(API_MANUFACTURERS, customer, "dataProviderDetails");
  const body = { dataProvider, dataProviderId };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, body)
  );
}

export async function getInventoryDataById(
  customer: string,
  dataProvider: string,
  dataProviderId: string
) {
  const url = getApiUrl(
    API_MANUFACTURERS,
    customer,
    dataProvider,
    dataProviderId,
    "inventoryData"
  );
  return await ServiceHelper.get(HttpClient).get<any>(url).toPromise();
}

export async function getParts(
  customer: string,
  partNumbers?: string[]
): Promise<Part[]> {
  const url = getApiUrl(API_PARTS, customer);
  let body: {} | null;
  if (partNumbers != null) {
    body = { ids: partNumbers };
  } else {
    body = null;
  }
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Part[]>(url, body)
  );
}

export async function getPartsWithoutManufacturers(
  customer: string,
  page: string,
  pageSize: string
) {
  const url = getApiUrl(
    API_PARTS,
    customer,
    "without-manufacturers",
    page,
    pageSize
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{
      parts: Partial<Part>[];
      size: number;
    }>(url, {})
  );
}

export async function getPartsWithoutManufacturersAll(customer: string) {
  const url = getApiUrl(API_PARTS, customer, "without-manufacturers-all");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Partial<Part>[]>(url, {})
  );
}

export async function getPartsNotUsedInAssemblies(
  customer: string,
  page: string,
  pageSize: string
) {
  const url = getApiUrl(
    API_PARTS,
    customer,
    "not-used-in-assemblies",
    page,
    pageSize
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<{ parts: Partial<Part>[]; size: number }>(
      url
    )
  );
}

export async function getPartsNotUsedInAssembliesAll(customer: string) {
  const url = getApiUrl(API_PARTS, customer, "not-used-in-assemblies-all");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<Partial<Part>[]>(url)
  );
}

export async function getPartsToBeDeleted(
  customer: string,
  page: string,
  pageSize: string
) {
  const url = getApiUrl(
    API_PARTS,
    customer,
    "parts-to-be-deleted",
    page,
    pageSize
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<{ parts: Partial<Part>[]; size: number }>(
      url
    )
  );
}

export async function getPartsToBeDeletedAll(customer: string) {
  const url = getApiUrl(API_PARTS, customer, "parts-to-be-deleted-all");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<Partial<Part>[]>(url)
  );
}

export async function getPartByMPN(customer: string, mpn: string) {
  const url = getApiUrl(API_PARTS, customer, "partByMPN");
  const body = { id: mpn };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Part[]>(url, body)
  );
}

async function getTreeParts(customer: string): Promise<Part[]> {
  const url = getApiUrl(API_PARTS, customer, "tree");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Part[]>(url));
}

export async function getPartsCompressedForTree(
  customer: string,
  partNumbers: string[]
): Promise<Part[]> {
  const url = getApiUrl(API_PARTS, customer, "tree-parts");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Part[]>(url, { keys: partNumbers })
  );
}

async function getPartsIds(customer: string) {
  const url = getApiUrl(API_PARTS, customer, "bulk-get");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<string[]>(url, null)
  );
}

export async function getPartsCompressed(
  customer: string,
  page?: string,
  pageSize?: string
) {
  const url = getApiUrl(API_PARTS, customer, "parts-compressed");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{
      parts: Partial<Part>[];
      size: number;
    }>(url, { page, pageSize })
  );
}

export async function getPartsByStatus(
  customer: string,
  page?: string,
  pageSize?: string
) {
  const url = getApiUrl(API_PARTS, customer, "parts-by-status");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{
      parts: Partial<Part>[];
      size: number;
    }>(url, {
      page,
      pageSize,
    })
  );
}

// used for export
export async function getAllPartsByStatus(customer: string) {
  const url = getApiUrl(API_PARTS, customer, "parts-by-status-all");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<Partial<Part>[]>(url)
  );
}

export async function getPartsWithoutCPNs(customer: string) {
  const url = getApiUrl(API_PARTS, customer, "partsWithoutCPN");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<string[]>(url, null)
  );
}

export async function getBomParts(customer: string, partNumbers: string[]) {
  const url = getApiUrl(API_PARTS, customer, "bom");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Part[]>(url, { keys: partNumbers })
  );
}

export async function updatePart(customer: string, partDoc: Part) {
  if (partDoc == null || Object.keys(partDoc).length < 1) {
    return {};
  }
  const url = getApiUrl(API_PARTS, customer, "update");
  const body = { part: partDoc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Part>(url, body)
  );
}

async function bulkParts(docs: any, customer: string) {
  const url = getApiUrl(API_BULK_PARTS, customer);
  const body = { keys: docs };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, body)
  );
}

export async function createPart(customer: string, partDoc: Part) {
  const url = getApiUrl(API_PARTS, customer, "new-part");
  const body = { part: partDoc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Part>(url, body)
  );
}

export async function getPartNumbers(customer: string, viewType?: string) {
  const url = getApiUrl(API_PARTS, customer, "partNumbers");
  const body = { viewType };
  return await lastValueFrom(ServiceHelper.get(HttpClient).post(url, body));
}

export async function getAssemblies(customer: string, keys: string[]) {
  const url = getApiUrl(API_PARTS, customer, API_ASSEMBLIES);
  const body = { keys };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, body)
  );
}

async function getAssemblyList(customer: string) {
  const url = getApiUrl(API_PARTS, customer, "assemblyList");
  const body = {};
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, body)
  );
}

export async function getTopLevelItems(
  customer: string,
  page: string,
  pageSize: string
): Promise<{ assemblies: string[]; items: any[]; size: number }> {
  const url = getApiUrl(
    API_PARTS,
    customer,
    "topLevel",
    page,
    pageSize,
    "assemblies"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<{
      assemblies: string[];
      items: any[];
      size: number;
    }>(url)
  );
}

export async function getTopLevelLength(customer: string) {
  const url = getApiUrl(API_PARTS, customer, "topLevelCount");
  return await lastValueFrom(ServiceHelper.get(HttpClient).post<{}>(url, {}));
}

export async function getAssembliesCompressed(
  customer: string
): Promise<Partial<Part>[]> {
  // Partial<Part[]> contains in this case the following properties: partNumber, description and parts
  const url = getApiUrl(API_PARTS, customer, API_FILTER_ASSEMBLIES);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Partial<Part>[]>(url, {})
  );
}

export async function getRMDocsByType(
  customer: string,
  id: string,
  docType: string,
  filterType: string
): Promise<RMDoc[]> {
  const url = getApiUrl(API_RM_SEARCH, customer, docType);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<RMDoc[]>(url, {
      filterType,
      searchedText: id,
    })
  );
}

export async function getTreeFilterResult(
  customer: string,
  fieldNames: string[],
  filterValues: any,
  page: string,
  pageSize: string
) {
  const url = getApiUrl(API_PARTS, customer, API_FILTER_TREE, page, pageSize);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, {
      fieldNames,
      filters: filterValues,
    })
  );
}

export async function filterPartsForExport(
  customer: string,
  fieldNames: string[],
  filterValues: any,
  viewType: string
) {
  const url = getApiUrl(API_PARTS, customer, "filterPartsForExport");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, {
      fieldNames,
      filters: filterValues,
      viewType,
    })
  );
}

export async function getPartFilterResult(
  customer: string,
  fieldNames: string[],
  filterValues: any,
  viewType: string,
  page: string,
  pageSize: string
) {
  const url = getApiUrl(API_PARTS, customer, API_FILTER_PARTS);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, {
      fieldNames,
      filters: filterValues,
      viewType,
      page,
      pageSize,
    })
  );
}

async function getFilter(
  customer: string,
  fieldNames: string[],
  filterValues: { [key: string]: any }
) {
  const url = getApiUrl(API_GET_FILTERSERVICE, customer);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, {
      fieldNames,
      filters: filterValues,
    })
  );
}

export async function getTypeaheadForFilters(customer: string) {
  const url = getApiUrl(
    API_GET_FILTERSERVICE,
    customer,
    "typeaheadOptionsForFilters"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{
      // mpns: string[];
      // cpns: string[];
      omfNumbers: string[];
      // pcnIds: string[];
    }>(url, {})
  );
}

export async function Search(
  customer: string,
  type: string,
  field: any,
  searchText: string
) {
  const url = getApiUrl(API_GET_FILTERSERVICE, customer, "search");
  const body = {
    type,
    field,
    query: searchText,
  };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<string[]>(url, body)
  );
}

export async function getAlerts(customer: string, alertType: string) {
  const url = getApiUrl(API_ALERTS, customer, "all-alerts");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<AlertData[]>(url, {
      key: customer,
      alertType,
    })
  );
}

export async function getChanges(customer: string, user: string) {
  const url = getApiUrl(API_CHANGES, customer, "all-changes");
  const body = { customer, endkey: [user], startkey: [user, {}] };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Change[]>(url, body)
  );
}

export async function getUnreadChanges(customer: string, user: string) {
  const url = getApiUrl(API_CHANGES, customer, "unread-changes");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ numberOfChanges: number }>(url, {
      key: user,
    })
  );
}

async function deleteAlerts(customer: string, docs: AlertData[]) {
  const url = getApiUrl(API_ALERTS, customer, "delete-alert");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).put<AlertData[]>(url, { keys: docs })
  );
}

export async function getUnreadAlerts(customer: string) {
  const url = getApiUrl(API_ALERTS, customer, "unread-alerts");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{
      numberOfAlerts: number;
      numberOfChangeAlerts: number;
      numberOfLeadTimeAlerts: number;
      numberOfInvMonAlerts: number;
    }>(url, null)
  );
}

export async function getPcns(customer: string) {
  const url = getApiUrl(API_PCNS, customer, "all-pcns");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Pcn[]>(url, { key: customer })
  );
}

export async function getPcn(customer: string, pcnId: string) {
  const url = getApiUrl(API_PCNS, customer, "pcn", pcnId);
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Pcn>(url));
}

export async function getUnreadPcns(customer: string) {
  const url = getApiUrl(API_PCNS, customer, "unread-pcns");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<{ numberOfPcns: number }>(url, null)
  );
}

export async function savePcnGen(
  customer: string,
  pcnDoc: any,
  emailDetails: EmailDetails,
  fileData: FileData
) {
  if (pcnDoc == null || Object.keys(pcnDoc).length < 1) {
    return {};
  }
  const url = getApiUrl(API_PCN_GENERATOR, customer, "save");
  const body = {
    pcn: pcnDoc,
    pcnEmailDetails: emailDetails,
    fileData,
  };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Result>(url, body)
  );
}
export async function updatePcn(customer: string, pcnDoc: Pcn) {
  if (pcnDoc == null || Object.keys(pcnDoc).length < 1) {
    return {};
  }
  const url = getApiUrl(API_PCNS, customer, "update");
  const body = { pcn: pcnDoc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Pcn>(url, body)
  );
}

export async function getSubstitutes(
  customer: string,
  dataProvider: string,
  dataProviderId: string
) {
  const url = getApiUrl(API_SUBSTITUTES, customer);
  const body = { dataProvider, dataProviderId };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, body)
  );
}

export async function getTrains(customer: string): Promise<Train[]> {
  const url = getApiUrl(API_TRAINS, customer, "all-trains");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Train[]>(url));
}

export async function getTrainsCompressed(customer: string) {
  const url = getApiUrl(API_TRAINS, customer, "trains-compressed");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Train[]>(url));
}

export async function getTrainDetails(
  customer: string,
  trainId: string
): Promise<Train> {
  const url = getApiUrl(API_TRAINS, customer, "train");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Train>(url, { key: trainId })
  );
}

export async function saveTrain(customer: string, doc: Train) {
  const url = getApiUrl(API_TRAINS, customer, "new-train");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Train>(url, { train: doc })
  );
}

export async function deleteTrain(customer: string, doc: Train) {
  const url = getApiUrl(API_TRAINS, customer, "delete-train");
  doc._deleted = true;
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Train>(url, { train: doc })
  );
}

export async function saveImpact(
  customer: string,
  doc: Impact | Partial<Impact>
) {
  const url = getApiUrl(API_IMPACTS, customer, "save-impact");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Impact>(url, { impact: doc })
  );
}
export async function getImpactById(customer: string, impactId: string) {
  const url = getApiUrl(API_IMPACTS, customer, "impact");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Impact>(url, { key: impactId })
  );
}

export async function getImpacts(
  customer: string,
  omfNumber?: string
): Promise<Impact[]> {
  const url = getApiUrl(API_IMPACTS, customer, "all-impacts");
  let body: {} | null;
  if (omfNumber != null) {
    body = { omfNumber };
  } else {
    body = null;
  }
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Impact[]>(url, body)
  );
}

export async function deleteAllImpacts(customer: string, threadId: string) {
  const url = getApiUrl(API_IMPACTS, customer, "delete-all-impacts");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).put<Result[]>(url, { threadId })
  );
}

export async function getImpactsCompressed(customer: string) {
  const url = getApiUrl(API_IMPACTS, customer, "impacts-compressed");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Impact[]>(url));
}

export async function getItem(customer: string, itemId: string) {
  const url = getApiUrl(API_INTERNALITEMS, customer, itemId);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<InternalItem>(url)
  );
}

export async function getItemsByOmfNumber(customer: string, omfNumber: string) {
  const url = getApiUrl(API_INTERNALITEMS, customer, "itemsByOmfNumber");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<InternalItem>(url, {
      omfNumber,
    })
  );
}
export async function saveItem(customer: string, item: InternalItem) {
  const url = getApiUrl(API_INTERNALITEMS, customer, "item");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<InternalItem>(url, { item })
  );
}

export async function lastOmfNumbers(
  customer: string,
  key?: string
): Promise<OmfFormat[]> {
  const url = getApiUrl(API_OMF_NUMBERS, customer);
  let body: {} | null;
  if (key != null) {
    body = { endkey: [key], startkey: [key, {}] };
  } else {
    body = null;
  }
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<OmfFormat[]>(url, body)
  );
}
export async function allOmfNumbers(
  customer: string,
  keys?: string[]
): Promise<any[]> {
  const url = getApiUrl(API_ALL_OMFNUMBERS, customer);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any[]>(url, { keys })
  );
}

export async function updateSyncDoc(doc: any) {
  const url = getApiUrl(API_SYNC, "create-sync");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<SyncDoc>(url, { key: doc })
  );
}

export async function getSyncDoc(omfNumber: string) {
  const url = getApiUrl(API_SYNC, "get-sync", omfNumber);
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<SyncDoc[]>(url));
}

export async function getSyncDocClient(customer: string, omfNumber: string) {
  const url = getApiUrl(API_SYNC, "get-sync-client", customer, omfNumber);
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<SyncDoc[]>(url));
}
export async function getUsersCompressed(
  customer: string
): Promise<UserCompressed[]> {
  const url = getApiUrl(API_USERS, customer, "compressed");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<UserCompressed[]>(url)
  );
}
export async function getComponentResponsibles(
  customer: string
): Promise<string[]> {
  const url = getApiUrl(API_USERS, customer, "componentResponsibles");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<string[]>(url));
}
export async function getUsersWithRoles(
  customer: string
): Promise<UserOptions[]> {
  const url = getApiUrl(API_USERS, customer, "getUserOptions");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<UserOptions[]>(url)
  );
}
export async function getUserById(
  customer: string,
  user: string
): Promise<User> {
  const url = getApiUrl(API_USERS, customer, "user", user);
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<User>(url));
}
export async function setUserLanguage(user: string, language: string) {
  const url = getApiUrl(API_USERS, "user", "language");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<User>(url, { userName: user, language })
  );
}
export async function saveNewUser(customer: string, user: User) {
  const url = getApiUrl(API_USERS, customer, "new-user");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Result>(url, { user })
  );
}
export async function updateUser(customer: string, user: User) {
  const url = getApiUrl(API_USERS, customer, "update-user");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).put<User>(url, { user })
  );
}
export async function deleteUser(customer: string, name: string) {
  const url = getApiUrl(API_USERS, customer, "delete");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).put<boolean>(url, { userName: name })
  );
}

export async function getProfile(profileId: string): Promise<Profile> {
  const url = getApiUrl(API_PROFILE, profileId);
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Profile>(url));
}

export async function getProfiles(customer: string): Promise<Profile[]> {
  const url = getApiUrl(API_PROFILE, customer, "all");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Profile[]>(url));
}

export async function updateProfile(profile: Profile): Promise<Profile> {
  const url = getApiUrl(API_PROFILE, "update");
  const body = { profile };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Profile>(url, body)
  );
}

export async function updateProfileFavorite(
  profileIds: string[],
  customer: string,
  threadId: string,
  toggle: boolean
) {
  const url = getApiUrl(API_PROFILE, customer, "favorite");
  const body = { profileIds, threadId, toggle };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Profile>(url, body)
  );
}

export async function getUserSettings(userId: string) {
  const url = getApiUrl(API_USER_SETTINGS, userId);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<UserSettings>(url)
  );
}

export async function updateUserSettings(userSettingsDoc: UserSettings) {
  const url = getApiUrl(API_USER_SETTINGS, "update");
  const body = { userSettingsDoc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<UserSettings>(url, body)
  );
}

export async function getPersonDocs(customer: string) {
  const url = getApiUrl(API_PERSON, customer);
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Person[]>(url));
}

export async function getTaskByTab(
  customer: string,
  user: string,
  view: string
): Promise<Task[]> {
  const url = getApiUrl(API_TASKS, customer, "taskTab", view);
  const body = { endkey: [user], startkey: [user, {}] };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Task[]>(url, body)
  );
}

export async function getAllTasks(customer: string, view: string) {
  const url = getApiUrl(API_TASKS, customer, view);
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Task[]>(url));
}

export async function uncompletedTasks(customer: string, responsible: string) {
  const url = getApiUrl(API_TASKS, customer, "uncompleted-tasks", responsible);
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<any>(url));
}

export async function getUncompletedTasksByDeputy(
  customer: string,
  deputy: string
) {
  const url = getApiUrl(
    API_TASKS,
    customer,
    "uncompleted-tasks-deputy",
    deputy
  );
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Task[]>(url));
}

export async function getThread(
  customer: string,
  omfNumber: string
): Promise<Thread> {
  const url = getApiUrl(API_THREAD, customer, "thread");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Thread>(url, { id: omfNumber })
  );
}

export async function updateResponsibles(customer: string, threadId: string) {
  const url = getApiUrl(
    API_WORKFLOW,
    customer,
    "update-tasks-responsibles-single-thread"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Thread>(url, {
      id: threadId,
    })
  );
}

export async function updateTasksForEditedResponsibles(customer: string) {
  const url = getApiUrl(
    API_WORKFLOW,
    customer,
    "update-tasks-responsibles-multiple-threads"
  );
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Thread>(url, {})
  );
}

export async function getAllThreads(customer: string) {
  const url = getApiUrl(API_THREAD, customer, "all-threads");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Thread>(url));
}

export async function getGraphsThreads(customer: string) {
  const url = getApiUrl(API_THREAD, customer, "graphs-threads");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Thread[]>(url));
}

export async function getGraphStockData(
  customer: string,
  cpn: string
): Promise<StockDoc> {
  const url = getApiUrl(API_GRAPHS, customer, cpn, "graph-stock");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<StockDoc>(url));
}
export async function getTotalCostLTB(customer: string) {
  const url = getApiUrl(API_GRAPHS, customer, "total-cost");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<TotalCostDoc>(url)
  );
}
export async function getAllSolutions(customer: string) {
  const url = getApiUrl(API_POST, customer, "all-solutions");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<any>(url));
}

export async function updateThread(
  customer: string,
  threadDoc: Thread,
  isPrevStatusChange: boolean
): Promise<Thread | null> {
  if (threadDoc == null || Object.keys(threadDoc).length < 1) {
    return null;
  }
  const url = getApiUrl(API_THREAD, customer, "update");
  const body = { thread: threadDoc, isPrevStatusChange };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Thread | null>(url, body)
  );
}

export async function deleteThread(
  customer: string,
  thread: Thread
): Promise<any> {
  const url = getApiUrl(API_THREAD, customer, "delete");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, { thread })
  );
}

export async function checkIfTitleIsDuplicated(
  customer: string,
  threadTitle: string
): Promise<boolean> {
  const url = getApiUrl(API_THREAD, customer, "duplicateTitle");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<boolean>(url, { title: threadTitle })
  );
}

async function getDocsByPage(
  customer: string,
  type: Type,
  keys: string[]
): Promise<Docs[Type][]> {
  const url = getApiUrl(API_IDS_BY_TYPE, customer, "paginated");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Docs[Type][]>(url, { type, keys })
  );
}

export async function getThreadsCompressed(
  customer: string,
  type: Type
): Promise<Docs[Type][]> {
  const url = getApiUrl(API_OMF_NUMBERS, customer, "threadsCompressed");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Docs[Type][]>(url, { type })
  );
}

export async function getThreadsbyPartNumbers(
  customer: string,
  type: Type
): Promise<Docs[Type][]> {
  const url = getApiUrl(API_OMF_NUMBERS, customer, "threadsByPartNumbers");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Docs[Type][]>(url, { type })
  );
}
export async function getExternalCases(
  customer: string,
  external: boolean,
  type: Type
): Promise<Docs[Type][]> {
  const url = getApiUrl(API_THREAD, customer, "external");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Docs[Type][]>(url, { external, type })
  );
}

export async function getOpenedCasesFromMpn(
  customer: string
): Promise<Docs[Type][]> {
  const url = getApiUrl(API_THREAD, customer, "openedCasesFromMpn");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Docs[Type][]>(url, {})
  );
}

export async function getPosts(customer: string, omfNumber: string) {
  const url = getApiUrl(API_POST, customer, omfNumber, "posts");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<Post[]>(url));
}
export async function getFinalSolutionsCompressed(customer: string) {
  const url = getApiUrl(API_POST, customer, "finalSolutionsCompressed");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<Partial<Post>[]>(url)
  );
}

export async function savePost(
  customer: string,
  postDoc: Post,
  sync?: boolean
) {
  if (postDoc == null || Object.keys(postDoc).length < 1) {
    return {};
  }
  const url = getApiUrl(API_POST, customer, "save");
  const body = { post: postDoc, fromSync: sync };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Post>(url, body)
  );
}

export async function deletePosts(
  customer: string,
  postIds: Post[],
  typeOfPosts: string,
  threadId: string
) {
  const url = getApiUrl(API_POST, customer, "delete", typeOfPosts);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).put<any[]>(url, { postIds, threadId })
  );
}

export async function saveMessage(customer: string, message: Message) {
  const url = getApiUrl(API_MESSAGE, customer, "save");
  const body = { message };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Message>(url, body)
  );
}

export async function getApplicationAreaOptions(customer: string) {
  const url = getApiUrl(API_APPLICATION_AREA, customer, "get-options");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<ApplicationArea[]>(url)
  );
}

export async function updateApplicationAreaOptions(
  customer: string,
  applicationAreaDoc: ApplicationArea
) {
  const url = getApiUrl(API_APPLICATION_AREA, customer, "update-options");
  const body = { applicationAreaDoc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<ApplicationArea>(url, body)
  );
}
export async function getDinCodeDocuments(customer: string) {
  const url = getApiUrl(API_DINCODE_RESP, customer, "dinCodeDocs");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<DinCodeResponsible[]>(url)
  );
}

export async function getDinCodeDocument(
  customer: string,
  dinCode: string
): Promise<DinCodeResponsible> {
  const url = getApiUrl(API_DINCODE_RESP, customer, dinCode);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<DinCodeResponsible>(url)
  );
}

export async function getCommodityGroupDocument(
  customer: string,
  id: string
): Promise<CommodityGroupResponsible> {
  const body = { id };
  const url = getApiUrl(API_COMMODITY_RESP, customer, "commodityDoc");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<CommodityGroupResponsible>(url, body)
  );
}

export async function getCommodityIds(customer: string) {
  const url = getApiUrl(API_COMMODITY_RESP, customer, "commodityIds");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<string[]>(url));
}

export async function getVehicleDocument(
  customer: string,
  docId: string
): Promise<VehicleResponsible[]> {
  const url = getApiUrl(API_VEHICLE_RESP, customer, "vehicle");
  const body = { id: docId };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<VehicleResponsible[]>(url, body)
  );
}

export async function getVehicleDocuments(
  customer: string
): Promise<VehicleResponsible[]> {
  const url = getApiUrl(API_VEHICLE_RESP, customer, "vehicles");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<VehicleResponsible[]>(url)
  );
}

export async function updateVehicle(
  customer: string,
  vehicleDoc: VehicleResponsible
) {
  if (vehicleDoc == null || Object.keys(vehicleDoc).length < 1) {
    return {};
  }
  const url = getApiUrl(API_VEHICLE_RESP, customer, "update");
  const body = { vehicle: vehicleDoc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<VehicleResponsible>(url, body)
  );
}

export async function deleteVehicle(
  customer: string,
  vehicleDoc: VehicleResponsible
) {
  const url = getApiUrl(API_VEHICLE_RESP, customer, "delete");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, { vehicle: vehicleDoc })
  );
}

export async function getPredefinedTasksByStatus(
  status: string
): Promise<PredefinedTask[]> {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "getPredefinedTasksByStatus");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<PredefinedTask[]>(url, { status })
  );
}

export async function getWorkHistory(customer: string): Promise<WorkHistory[]> {
  const url = getApiUrl(API_WORK_HISTORY, customer);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<WorkHistory[]>(url)
  );
}

export async function getWorkCount(
  customer: string
): Promise<WorkCountResponse> {
  const url = getApiUrl(API_WORK_HISTORY, customer, "work-count");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<Promise<WorkCountResponse>>(url)
  );
}

export function model2Doc<T extends Type>(
  type: T,
  model: DocModel,
  doc: Docs[T] = {} as any
) {
  Object.keys(model).forEach((key) => {
    const [t, k] = key.split(".");
    if (t === type) {
      (doc as any)[k] = model[key];
    }
  });
  return doc;
}

export function doc2Model(type: Type, doc: any = {}, model: DocModel = {}) {
  Object.keys(doc).forEach((key) => {
    const fieldId = type + "." + key;
    model[fieldId] = doc[key];
  });
  return model;
}

export async function uploadFile(
  file: any,
  fileId: string,
  fromPcn?: boolean
): Promise<AttachmentsInfo> {
  let url = "";
  if (fromPcn) {
    url = getApiUrl(API_FILES, "pcn", fileId);
  } else {
    url = getApiUrl(API_FILES, fileId);
    console.log("DEBUG: url api.service:", url);
  }
  let content: ArrayBuffer;

  if (file.attachment instanceof Blob) {
    content = await getFileContentAsArrayBuffer(file.attachment);
  } else {
    content = await getFileContentAsArrayBuffer(file);
  }
  let fileType = getFileTypeByExtension(file.name);
  const token = getToken();
  return new Promise<AttachmentsInfo>((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open("PUT", url, true);
    if (token != null) {
      request.setRequestHeader("Authorization", `Bearer ${token}`);
    }
    request.setRequestHeader(
      "Content-Type",
      fileType != null ? fileType : "application/json"
    );
    request.onreadystatechange = () => {
      if (request.readyState !== 4) {
        return;
      }
      switch (request.status) {
        case 200:
          resolve(JSON.parse(request.responseText));
          break;
        case 201:
          resolve(JSON.parse(request.responseText));
          break;
        default:
          reject();
      }
    };
    request.onerror = () => {
      console.log("failed to upload file");
      return;
    };
    request.send(content);
  });
}

export async function downloadFile(fileLink: string) {
  const url = getApiUrl(API_FILES, fileLink);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get(url, { responseType: "blob" })
  );
}

export async function getAttachments(filePrefix: string) {
  const url = getApiUrl(API_FILES, filePrefix);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).get<AttachmentsInfo>(url)
  );
}

export async function deleteFile(fileLink: string) {
  const url = getApiUrl(API_FILES, fileLink);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).delete<AttachmentsInfo>(url)
  );
}

export async function updateAttachmentTags(
  fileLink: string,
  tag: string,
  action: string
) {
  const url = getApiUrl(API_FILES, fileLink, "update-tags");
  const body = { tag, action };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<
      Promise<{ id: string; ok: string; rev: string }>
    >(url, body)
  );
}
export async function markAsSensitive(
  fileLink: string,
  fileName: string,
  fziAttachment: boolean
) {
  const url = getApiUrl(API_FILES, fileLink, "markAsSensitive");
  const body = { fziAttachment };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<
      Promise<{ id: string; ok: string; rev: string }>
    >(url, body)
  );
}

export async function createTasks(
  customer: string,
  threadId: string,
  tasks: Partial<Post>
) {
  const url = getApiUrl(API_POST, customer, "createTasks");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Partial<Post>[]>(url, {
      id: threadId,
      tasksInfo: tasks,
    })
  );
}

export async function changeStatusReady(customer: string, thread: Thread) {
  const url = getApiUrl(API_POST, customer, "changeStatusReady");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Promise<any>>(url, {
      threadDoc: thread,
    })
  );
}

export async function saveParameters(parametersDoc: any) {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "save-parameter");
  const body = { parametersDoc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<any>(url, body)
  );
}

export async function getParameter() {
  const url = getApiUrl(API_MANUFACTURER_NAMES, "get-parameter");
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Parameter>(url, {})
  );
}

export async function triggerWork(
  customer: string,
  dataProvider: string,
  workType: string,
  docType?: string,
  docIds?: string[]
): Promise<WorkRequest> {
  const url = getApiUrl(API_WORK, customer, dataProvider, workType);
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Promise<any>>(url, { docType, docIds })
  );
}

export async function saveOCPost(customer: string, doc: any) {
  if (doc == null || Object.keys(doc).length < 1) {
    return {};
  }
  const url = getApiUrl(API_OCPOST, customer, "saveOCPost");
  const body = { doc };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Promise<Result>>(url, body)
  );
}

export async function ocPosts(customer: string) {
  const url = getApiUrl(API_OCPOST, customer, "ocPosts");
  return await lastValueFrom(ServiceHelper.get(HttpClient).get<OCPost[]>(url));
}

export async function updateProfileLike(customer: string, postId: string) {
  const url = getApiUrl(API_PROFILE, customer, "like");
  const body = { postId };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Profile>(url, body)
  );
}

export async function getFilteredOCPosts(
  customer: string,
  filterType: string,
  filterValue: string
) {
  const url = getApiUrl(API_OCPOST, customer, "filterOCPosts");
  const body = { filterType, filterValue };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<OCPost[]>(url, body)
  );
}

export async function saveOCComment(customer: string, doc: any, docId: string) {
  if (doc == null || Object.keys(doc).length < 1) {
    return {};
  }
  const url = getApiUrl(API_OCCOMMENT, customer, "saveOCComment");
  const body = { doc, docId };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Promise<Result>>(url, body)
  );
}

export async function ocCommentsByPostId(customer: string, postId: string) {
  const url = getApiUrl(API_OCCOMMENT, customer, "ocCommentsByPostId");
  const body = { postId };
  return await lastValueFrom(
    ServiceHelper.get(HttpClient).post<Promise<OCComment[]>>(url, body)
  );
}

function decompress(compressed: AllDocsCompressed) {
  const settings = defaultSettings;
  const docs: AllDocs = {} as any;

  for (const t of Object.keys(compressed)) {
    const type = t as Type;
    docs[type] = {};
    const fields = Object.keys(settings[type].field);
    for (const id of Object.keys(compressed[type])) {
      const array = compressed[type][id];
      const doc: any = {};
      fields.forEach((field, i) => {
        doc[field] = array[i];
      });
      docs[type][id] = doc;
    }
  }
  return docs;
}

function getFileTypeByExtension(fileName: string) {
  const extenssion = fileName.split(".").pop();
  if (extenssion == null) {
    return "application/json";
  }
  const fileExtension = extenssion.toLowerCase();
  switch (fileExtension) {
    case "png":
    case "jpg":
    case "jpeg":
    case "svg":
    case "gif": {
      return `image/${fileExtension}`;
    }
    case "pdf":
    case "rtf": {
      return `application/${fileExtension}`;
    }
    case "txt": {
      return `text/plain`;
    }
    case "xsd":
    case "xml": {
      return `text/${fileExtension}`;
    }
    case "xls": {
      return "application/vnd.ms-excel";
    }
    case "xlsx":
    case "xlsm": {
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    case "doc": {
      return "application/msword";
    }
    case "docx": {
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }
    case "ppt": {
      return "application/vnd.ms-powerpoint";
    }
    case "pptx": {
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    }
    case "odp": {
      return "application/vnd.oasis.opendocument.presentation";
    }
    case "msg": {
      return "application/vnd.ms-outlook";
    }
    default:
      return "application/json";
  }
}
