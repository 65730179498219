<ng-template #content let-modal>
  <div class="modal-header" *ngIf="showDismiss">
    <div class="col-md-12">
      <span *ngIf="!imageSource" class="modal-title">{{ modalTitle }}</span>
      <button class="btn float-end" (click)="closeModal()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body text-center">
    <span *ngIf="modalMessage">{{ modalMessage }}</span>

    <!-- if the is a special logic required in the display of the modal content, it has to be injected bellow -->
    <div *ngIf="modalType !== ''">
      <div [ngSwitch]="modalType">
        <div *ngSwitchCase="'replacements'">
          <app-replacements-table [id]="modalTableId" [showPaginator]="false">
          </app-replacements-table>
        </div>
        <div *ngSwitchCase="'svhcItems'">
          <app-svhc-items [id]="modalTableId" [showPaginator]="false">
          </app-svhc-items>
        </div>
        <div *ngSwitchCase="'pcnHistory'">
          <app-pcn-history-table [id]="modalTableId" [showPaginator]="false">
          </app-pcn-history-table>
        </div>
        <div *ngSwitchCase="'showWhereUsed'">
          <app-where-used-in></app-where-used-in>
        </div>
        <div *ngSwitchCase="'showListOfCases'">
          <app-list-opened-cases></app-list-opened-cases>
        </div>
        <div *ngSwitchCase="'dueDateField'">
          <app-field
            [model]="app.thread.thread"
            [field]="app.fieldId.thread.dueDate"
          ></app-field>
        </div>
        <div *ngSwitchCase="'wf-automation'">
          <app-wf-automation></app-wf-automation>
        </div>
        <div *ngSwitchCase="'wf-automation-new'">
          <app-wf-automation></app-wf-automation>
        </div>
        <div *ngSwitchCase="'vehicle-responsible-new'">
          <app-vehicle-responsible></app-vehicle-responsible>
        </div>
        <div *ngSwitchCase="'vehicle-responsible'">
          <app-vehicle-responsible></app-vehicle-responsible>
        </div>
        <div *ngSwitchCase="'image'">
          <img *ngIf="imageUrl" src="{{ imageUrl }}" class="img-responsive" />
        </div>
        <div *ngSwitchCase="'userManuals'">
          <app-documentation></app-documentation>
        </div>
        <div *ngSwitchCase="'assignedTo'">
          <app-assigned-to></app-assigned-to>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" *ngIf="showButtons">
    <button class="btn btn-cancel text-center m-1" (click)="dismiss()">
      {{ getButtonsText("dismiss") }}
    </button>
    <button
      class="btn btn-ok text-center m-1"
      (click)="modal.close('OK')"
      [disabled]="isDisabled()"
      *ngIf="!showItemFields"
    >
      {{ getButtonsText("save") }}
    </button>
  </div>
  <div class="modal-footer" *ngIf="showOnlyOkBtn">
    <button class="btn btn-cancel text-center m-1" (click)="dismiss()">
      {{ getButtonsText("save") }}
    </button>
  </div>
</ng-template>
