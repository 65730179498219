import { TableDef } from "pdfmake/build/pdfmake";
import { getApp } from "../../app";

export class ItemData {
  [field: string]: string;
  itemMfrNumber = "";
  itemMfrTypeIdent = "";
  itemMfrName = "";
  itemCategory = "";
  itemRev = "";
  itemSOP = "";
  itemEOS = "";
  itemEOPeffDate = "";
  itemLTD = "";
  itemEOSR = "";
  itemSubData = "";
  type = "";
  itemMfrReplNumber = "";
}

export class ItemNumbers {
  [key: string]: any;
  // tslint:disable-next-line
  ItemNumbers: ItemData[] = [];
}

export const getItemNumberDef = (data: ItemData, index = 1): TableDef => {
  const app = getApp();
  return {
    table: {
      widths: [25, 20, 40, 40, 40, 40, 40, 40, "*", 30, 40, 40, 40, 40, 40, 30],
      body: [
        [
          {
            text: [
              {
                text: "Pos.\n",
                style: "fieldName",
              },
              index.toString() || "",
            ],
            rowSpan: 3,
          },
          {
            colSpan: 3,
            text: [
              {
                text: app.text.pdf.manufacturerItemNumber + "\n\n",
                style: "fieldName",
              },
              {
                text: data.itemMfrNumber || "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
          {
            colSpan: 3,
            text: [
              {
                text: app.text.pdf.typeIdent + "\n",
                style: "fieldName",
              },
              {
                text: app.text.pdf.ifApplicable + "\n",
                style: "hint",
              },
              {
                text: data.itemMfrTypeIdent || "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
          {
            colSpan: 3,
            text: [
              {
                text: app.text.pdf.itemName + "\n\n",
                style: "fieldName",
              },
              {
                text: data.itemMfrName || "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
          {
            colSpan: 3,
            text: [
              {
                text: app.text.pdf.itemProductCategory + "\n\n",
                style: "fieldName",
              },
              {
                text: app.field.getFieldValueAsText(
                  "thread.productCategory",
                  data.itemCategory
                ),
                style: "input",
              },
            ],
          },
          "",
          "",
          {
            colSpan: 3,
            text: [
              {
                text: app.text.pdf.itemRev + "\n",
                style: "fieldName",
              },
              {
                text: app.text.pdf.ifApplicable,
                style: "hint",
              },
              {
                text: "\n" + (data.itemRev || "\n"),
                style: "input",
              },
            ],
          },
          "",
          "",
        ],
        [
          "",
          {
            colSpan: 3,
            text: [
              {
                text: app.text.pdf.startOfProduction,
                style: "fieldName",
              },
              { text: "(optional)\n", style: "hint" },
              {
                text: data.itemSOP ? data.itemSOP : "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
          {
            colSpan: 3,
            text: [
              {
                text: app.text.pdf.endOfSale + "\n",
                style: "fieldName",
              },
              {
                text: data.itemEOS ? data.itemEOS : "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
          {
            colSpan: 3,
            text: [
              {
                text:
                  app.text.pdf.endOfProduction +
                  "/" +
                  app.text.pdf.effectiveDate +
                  "\n",
                style: "fieldName",
              },
              {
                text: data.itemEOPeffDate ? data.itemEOPeffDate : "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
          {
            colSpan: 3,
            text: [
              {
                text: app.text.pdf.lastTimeDelivery,
                style: "fieldName",
              },
              {
                text: app.text.pdf.ifApplicable + "\n",
                style: "hint",
              },
              {
                text: data.itemLTD ? data.itemLTD : "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
          {
            colSpan: 3,
            text: [
              {
                text: app.text.pdf.endOfService,
                style: "fieldName",
              },
              {
                text: app.text.pdf.ifApplicable + "\n",
                style: "hint",
              },
              {
                text: data.itemEOSR ? data.itemEOSR : "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
        ],
        [
          "",
          {
            colSpan: 5,
            text: [
              {
                text: app.text.pdf.itemSubData + "",
                style: "fieldName",
              },
              { text: "(optional)", style: "hint" },
              "\n",
              {
                text: data.itemSubData || "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
          "",
          "",
          {
            colSpan: 5,
            text: [
              {
                text: app.text.pdf.type,
                style: "fieldName",
              },
              "\n",
              { text: data.type || "\n", style: "input" },
            ],
          },
          "",
          "",
          "",
          "",
          {
            colSpan: 5,
            text: [
              {
                text: app.text.pdf.replmanfItemnumber,
                style: "fieldName",
              },
              "\n",
              {
                text: app.text.pdf.ifApplicable + "\n",
                style: "hint",
              },
              {
                text: data.itemMfrReplNumber || "\n",
                style: "input",
              },
            ],
          },
          "",
          "",
          "",
          "",
        ],
      ],
    },
  };
};

const itemNumbersHead = (): TableDef => {
  const app = getApp();
  return {
    table: {
      widths: ["*"],
      body: [
        [
          {
            text: app.text.pdf.affectedItems,
            style: "section",
            border: [true, true, true, false],
          },
        ],
      ],
    },
  };
};
