interface CASNumbers {
  [code: string]: {
    de?: string;
    en: string;
    nl?: string;
  };
}

/** list of CAS Numbers + SubstanceIdentification that can be provided as options to inputs */
export const casNumberOptions: CASNumbers = {
  "4149-60-4": { en: "Ammonium salts of perfluorononan-1-oic-acid" },
  "1000597-52-3": {
    en: "Phosphonium, triphenyl(phenylmethyl)-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "10043-35-3": { en: "Boric acid" },
  "100532-36-3": { en: "Phenol, 4-(5-methylhexyl)-" },
  "10099-74-8": { en: "Lead dinitrate" },
  "101-14-4": { en: "2,2'-dichloro-4,4'-methylenedianiline" },
  "101-61-1": {
    en: "N,N,N',N'-tetramethyl-4,4'-methylenedianiline (Michler’s base)",
  },
  "101-77-9": { en: "4,4'- Diaminodiphenylmethane (MDA)" },
  "101-80-4": { en: "4,4'-oxydianiline" },
  "10108-64-2": { en: "Cadmium chloride" },
  "10124-36-4, 31119-53-6": { en: "Cadmium sulphate" },
  "10124-43-3": { en: "Cobalt(II) sulphate" },
  "10141-05-6": { en: "Cobalt(II) dinitrate" },
  "102570-52-5": { en: "Phenol, 4-(3-methylhexyl)-" },
  "10325-94-7": { en: "Cadmium nitrate" },
  "104-35-8": { en: "Ethanol, 2-(4-nonylphenoxy)-" },
  "104-40-5": { en: "p-nonylphenol" },
  "10588-01-9, 7789-12-0": { en: "Sodium dichromate" },
  "106-94-5": { en: "1-bromopropane (n-propyl bromide)" },
  "106599-06-8": { en: "Phenol, p-sec-nonyl-, phosphite" },
  "107-06-2": { en: "1,2-dichloroethane" },
  "107-15-3": { en: "Ethylenediamine" },
  "1072-63-5": { en: "1-vinylimidazole" },
  "108427-54-9": {
    en: "1-Butanaminium, N,N,N-tributyl-, salt with 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonic acid",
  },
  "108427-55-0": {
    en: "Ethanaminium, N,N,N-triethyl-, salt with1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonic acid (1:1)",
  },
  "109-86-4": { en: "2-methoxyethanol" },
  "110-00-9": { en: "Furan" },
  "110-49-6": { en: "2-methoxyethyl acetate" },
  "110-71-4": {
    en: "1, 2-dimethoxyethane; ethylene glycol dimethyl ether (EGDME)",
  },
  "110-80-5": { en: "2-ethoxyethanol" },
  "11066-49-2": { en: "Isononylphenol" },
  "111-15-9": { en: "2-ethoxyethyl acetate" },
  "111-30-8": { en: "glutaral" },
  "111-96-6": { en: "Bis(2-methoxyethyl) ether" },
  "11103-86-9": { en: "Potassium hydroxyoctaoxodizincatedichromate" },
  "11113-50-1": { en: "Boric acid, crude natural" },
  "11120-22-2": { en: "Silicic acid, lead salt" },
  "11138-47-9": { en: "Perboric acid, sodium salt" },
  "1119449-37-4": { en: "2-[4-(3,6-dimethylheptan-3-yl)phenoxy]ethanol" },
  "1119449-38-5": {
    en: "2-{2-[4-(3,6-dimethylheptan-3-yl)phenoxy]ethoxy}ethanol",
  },
  "112-49-2": { en: "1,2-bis(2-methoxyethoxy)ethane (TEGDME; triglyme)" },
  "1120-71-4": { en: "1,3-propanesultone" },
  "1139800-98-8": { en: "Phenol, 4-(4-methylhexyl)-" },
  "115-96-8": { en: "Tris(2-chloroethyl) phosphate" },
  "1163-19-5": {
    en: "Bis(pentabromophenyl) ether (decabromodiphenyl ether) (DecaBDE)",
  },
  "117-81-7": { en: "Bis (2-ethylhexyl)phthalate (DEHP)" },
  "117-82-8": { en: "Bis(2-methoxyethyl) phthalate" },
  "117933-89-8": {
    en: "1,3-Dioxane, 2-(2,4-dimethyl-3-cyclohexen-1-yl)-5-methyl-5-(1-methylpropyl)-",
  },
  "1187817-57-7": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, compd. With pyrrolidine (1:1)",
  },
  "119313-12-1": { en: "2-benzyl-2-dimethylamino-4'-morpholinobutyrophenone" },
  "120-12-7": { en: "Anthracene" },
  "120-71-8": { en: "6-methoxy-m-toluidine (p-cresidine)" },
  "12008-41-2": { en: "Disodium octaborate" },
  "12036-76-9": { en: "Lead oxide sulfate" },
  "12060-00-3": { en: "Lead titanium trioxide" },
  "12065-90-6": { en: "Pentalead tetraoxide sulphate" },
  "121-14-2": { en: "2,4-dinitrotoluene" },
  "121158-58-5": { en: "Phenol, dodecyl-, branched" },
  "12141-20-7": { en: "Trilead dioxide phosphonate" },
  "12179-04-3, 1303-96-4, 1330-43-4": { en: "Disodium tetraborate, anhydrous" },
  "12202-17-4": { en: "Tetralead trioxide sulphate" },
  "12267-73-1": { en: "Tetraboron disodium heptaoxide, hydrate" },
  "123-77-3": {
    en: "Diazene-1,2-dicarboxamide (C,C'-azodi(formamide)) (ADCA)",
  },
  "123-91-1": { en: "1,4-dioxane" },
  "125022-34-6": {
    en: "Borate(2-), tetrahydroxybis[μ-(peroxy-κO1:κO2)]di-, sodium, hydrate (1:2:6)",
  },
  "12578-12-0": { en: "Dioxobis(stearato)trilead" },
  "12626-81-2": { en: "Lead titanium zirconium oxide" },
  "12656-85-8": {
    en: "Lead chromate molybdate sulphate red (C.I. Pigment Red 104)",
  },
  "127-19-5": { en: "N,N-dimethylacetamide" },
  "127087-87-0": { en: "4-Nonylphenol, branched, ethoxylated" },
  "129-00-0": { en: "Pyrene" },
  "1303-28-2": { en: "Diarsenic pentaoxide" },
  "1303-86-2": { en: "Diboron trioxide" },
  "1306-19-0": { en: "Cadmium oxide" },
  "1306-23-6": { en: "Cadmium sulphide" },
  "131-18-0": { en: "Dipentyl phthalate (DPP)" },
  "1310480-24-0": {
    en: "Ethanaminium, N-[4-[[4-(diethylamino)phenyl][4-(ethylamino)-1-naphthalenyl]methylene]-2,5-cyclohexadien-1-ylidene]-N-ethyl-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "1310480-27-3": {
    en: "Methanaminium, N-[4-[[4-(dimethylamino)phenyl][4-(ethylamino)-1-naphthalenyl]methylene]-2,5-cyclohexadien-1-ylidene]-N-methyl-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "1310480-28-4": {
    en: "Methanaminium, N-[4-[[4-(dimethylamino)phenyl][4-(phenylamino)-1-naphthalenyl]methylene]-2,5-cyclohexadien-1-ylidene]-N-methyl-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "1314-41-6": { en: "Orange lead (lead tetroxide)" },
  "13149-00-3": { en: "cis-cyclohexane-1,2-dicarboxylic anhydride" },
  "131651-65-5": { en: "lithium perfluorobutanesulfonate" },
  "1317-36-8": { en: "Lead monoxide (lead oxide)" },
  "1319-46-6": { en: "Trilead bis(carbonate) dihydroxide" },
  "13252-13-6": {
    en: "2,3,3,3-tetrafluoro-2-(heptafluoropropoxy)propionic acid",
  },
  "1327-53-3": { en: "Diarsenic trioxide" },
  "1329995-45-0": {
    en: "Beta-Cyclodextrin, compd. with 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonic acid ion(1-)(1:1)",
  },
  "1329995-69-8": {
    en: "Gamma-Cyclodextrin, compd. with 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonic acid ion(1-)(1:1)",
  },
  "1333-73-9": { en: "Boric acid, sodium salt" },
  "1333-82-0": { en: "Chromium trioxide" },
  "134237-50-6": { en: "alpha-hexabromocyclododecane" },
  "134237-51-7": { en: "beta-hexabromocyclododecane" },
  "134237-52-8": { en: "gamma-hexabromocyclododecane" },
  "13424-46-9": { en: "Lead diazide, Lead azide" },
  "1344-37-2": { en: "Lead sulfochromate yellow (C.I. Pigment Yellow 34)" },
  "13517-20-9": {
    en: "Perboric acid (H3BO2(O2)), monosodium salt, trihydrate",
  },
  "13530-68-2": { en: "Dichromic acid" },
  "13560-89-9": {
    en: "1,6,7,8,9,14,15,16,17,17,18,18-dodecachloropentacyclo[12.2.1.16,9.02,13.05,10]octadeca-7,15-diene",
  },
  "135821-03-3": {
    en: "(1S,2S,5R,6R,9S,10S,13R,14R)-1,6,7,8,9,14,15,16,17,17,18,18-Dodecachloropentacyclo[12.2.1.1⁶,⁹.0²,¹³.0⁵,¹⁰]octadeca-7,15-diene",
  },
  "135821-74-8": {
    en: "(1S,2S,5S,6S,9R,10R,13R,14R)-1,6,7,8,9,14,15,16,17,17,18,18-Dodecachloropentacyclo[12.2.1.1⁶,⁹.0²,¹³.0⁵,¹⁰]octadeca-7,15-diene",
  },
  "1372804-76-6": { en: "Alkanes, C14-16, chloro" },
  "13814-96-5": { en: "Lead bis(tetrafluoroborate)" },
  "13840-56-7": { en: "Orthoboric acid, sodium salt" },
  "140-66-9": { en: "4-(1,1,3,3-tetramethylbutyl)phenol" },
  "14166-21-3": { en: "trans-cyclohexane-1,2-dicarboxylic anhydride" },
  "142731-63-3": { en: "4-(1-Ethyl-1,4-dimethylpentyl)phenol" },
  "142844-00-6": { en: "Refractories, fibers, aluminosilicate" },
  "143-24-8": { en: "Bis(2-(2-methoxyethoxy)ethyl)ether" },
  "14312-40-4": { en: "Trisodium orthoborate" },
  "143860-04-2": { en: "3-ethyl-2-methyl-2-(3-methylbutyl)-1,3-oxazolidine" },
  "14409-72-4": {
    en: "26-(4-Nonylphenoxy)-3,6,9,12,15,18,21,24- octaoxahexacosan -1-ol",
  },
  "144116-10-9": {
    en: "Sulfonium, triphenyl-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "144317-44-2": { en: "Triphenylsulfanium perfluorobutane sulfonate" },
  "1462414-59-0": {
    en: "Quinolinium, 1-(carboxymethyl)-4-[2-[4-[4-(2,2-diphenylethenyl)phenyl]-1,2,3,3a,4,8b-hexahydrocyclopent[b]indol-7-yl]ethenyl]-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "1471311-26-8": {
    en: "Reaction product of 1,3,4-thiadiazolidine-2,5-dithione, formaldehyde and phenol, heptyl derivs.",
  },
  "14890-53-0": { en: "Boric acid (H3BO3), sodium salt (1:1)" },
  "15087-24-8": {
    en: "1,7,7-trimethyl-3-(phenylmethylene)bicyclo[2.2.1]heptan-2-one",
  },
  "15120-21-5": { en: "Sodium perborate" },
  "1522-92-5": { en: "3-bromo-2,2-bis(bromomethyl)-1-propanol (TBNPA)" },
  "15245-44-0": { en: "Lead styphnate" },
  "153443-35-7": {
    en: "Iodonium, diphenyl-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "15571-58-1": {
    en: "2-ethylhexyl 10-ethyl-4,4-dioctyl-7-oxo-8-oxa-3,5-dithia-4-stannatetradecanoate (DOTE)",
  },
  "15606-95-8": { en: "Triethyl arsenate" },
  "156609-10-8": { en: "4-t-Nonylphenol-diethoxylate" },
  "17404-66-9": { en: "p-(1-methyloctyl)phenol" },
  "17570-76-2": { en: "Lead(II) bis(methanesulfonate)" },
  "1824346-00-0": { en: "Phenol, 4-[2-methyl-1-(1-methylethyl)propyl]-" },
  "186309-28-4": {
    en: "1,3-Dioxane, 2-(2,4-dimethyl-3-cyclohexen-1-yl)-5-methyl-5-(1-methylpropyl)-",
  },
  "186825-36-5": { en: "4-(1-Ethyl-1,3-dimethylpentyl)phenol" },
  "186825-39-8": { en: "4-(3-ethylheptan-2-yl)phenol" },
  "189274-31-5": {
    en: "Methanaminium, N,N,N-trimethyl-, salt with 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonic acid (1:1)",
  },
  "191-24-2": { en: "Benzo[ghi]perylene" },
  "1937-37-7": {
    en: "Disodium 4-amino-3-[[4'-[(2,4-diaminophenyl)azo][1,1'-biphenyl]-4-yl]azo] -5-hydroxy-6-(phenylazo)naphthalene-2,7-disulphonate (C.I. Direct Black 38)",
  },
  "19438-60-9": { en: "Hexahydro-4-methylphthalic anhydride" },
  "1987-50-4": { en: "4-heptylphenol" },
  "198840-65-2": { en: "Tetradecane, chloro derivs." },
  "202189-84-2": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, compd.with 2-methyl-2-propanamine (1:1)",
  },
  "20427-84-3": { en: "2-[2-(4-nonylphenoxy)ethoxy]ethanol" },
  "2058-94-8": { en: "Henicosafluoroundecanoic acid" },
  "206-44-0": { en: "Fluoranthene" },
  "2062-98-8": {
    en: "2,3,3,3-tetrafluoro-2-(heptafluoropropoxy)propionyl fluoride",
  },
  "20636-48-0": {
    en: "3,6,9,12-Tetraoxatetradecan-1-ol, 14-(4-nonylphenoxy)-",
  },
  "207-08-9": { en: "Benzo[k]fluoranthene" },
  "20837-86-9": { en: "Lead cyanamidate" },
  "21041-95-2": { en: "Cadmium hydroxide" },
  "21049-39-8": { en: "Sodium salts of perfluorononan-1-oic-acid" },
  "210555-94-5": { en: "Phenol, 4-dodecyl, branched" },
  "213740-81-9": {
    en: "Iodonium, bis[4-(1,1-dimethylethyl)phenyl]-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "218-01-9": { en: "Chrysene" },
  "220133-51-7": { en: "dimethyl(phenyl)sulfanium perfluorobutanesulfonate" },
  "220689-12-3": { en: "tetrabutyl-phosphonium nonafluoro-butane-1-sulfonate" },
  "22454-04-2": { en: "Boric acid (H3BO3), disodium salt" },
  "22673-19-4": { en: "Dibutylbis(pentane-2,4-dionato-O,O')tin" },
  "2315-61-9": {
    en: "2-[2-[4-(1,1,3,3-tetramethylbutyl)phenoxy]ethoxy]ethanol, 2-{2-[4-(2,4,4-trimethylpentan-2-yl)phenoxy]ethoxy}ethanol",
  },
  "2315-67-5": { en: "2-[4-(1,1,3,3-tetramethylbutyl)phenoxy]ethanol" },
  "2451-62-9": {
    en: "1,3,5-Tris(oxiran-2-ylmethyl)-1,3,5-triazinane-2,4,6-trione (TGIC)",
  },
  "24613-89-6": { en: "Dichromium tris(chromate)" },
  "2497-59-8": {
    en: "20-[4-(1,1,3,3-tetramethylbutyl)phenoxy]-3,6,9,12,15,18-hexaoxaicosan-1-ol",
  },
  "25154-52-3": { en: "Nonylphenol" },
  "25155-23-1": { en: "Trixylyl phosphate" },
  "25214-70-4": {
    en: "Formaldehyde, oligomeric reaction products with aniline",
  },
  "25550-51-0": { en: "Hexahydromethylphthalic anhydride" },
  "25628-08-4": {
    en: "N,N,N-triethylethanaminium 1,1,2,2,3,3,4,4,4-nonafluorobutane-1-sulfonate",
  },
  "25637-99-4": { en: "Hexabromocyclododecane" },
  "25747-83-5": { en: "boric acid (H3BO3), sodium salt, hydrate" },
  "2580-56-5": {
    en: "[4-[[4-anilino-1-naphthyl][4-(dimethylamino)phenyl]methylene]cyclohexa-2,5-dien-1-ylidene] dimethylammonium chloride (C.I. Basic Blue 26)",
  },
  "25973-55-1": {
    en: "2-(2H-benzotriazol-2-yl)-4,6-ditertpentylphenol (UV-328)",
  },
  "26027-38-3": { en: "4-Nonylphenol, ethoxylated" },
  "26264-02-8": { en: "14-(nonylphenoxy)-3,6,9,12-tetraoxatetradecan-1-ol" },
  "26523-78-4": { en: "tris(nonylphenyl) phosphite" },
  "26543-97-5": { en: "p-isononylphenol" },
  "26571-11-9": {
    en: "26-(nonylphenoxy)-3,6,9,12,15,18,21,24-octaoxahexacosan-1-ol",
  },
  "27147-75-7": { en: "Phenol, 4-isododecyl-" },
  "27177-05-5": {
    en: "23-(nonylphenoxy)-3,6,9,12,15,18,21-heptaoxatricosan-1-ol",
  },
  "27459-10-5": { en: "4-isododecylphenol" },
  "27942-27-4": { en: "20-(4-nonylphenoxy)-3,6,9,12,15,18-hexaoxaicosan-1-ol" },
  "288864-02-8": { en: "Phenol, 4-tert-heptyl-" },
  "29420-49-3": {
    en: "Potassium 1,1,2,2,3,3,4,4,4-nonafluorobutane-1-sulphonate",
  },
  "301-04-2": { en: "Lead di(acetate)" },
  "302-01-2, 7803-57-8": { en: "Hydrazine" },
  "3050-88-2": { en: "Phenol, 4-nonyl-, phosphite (3:1)" },
  "307-55-1": { en: "Tricosafluorododecanoic acid" },
  "30784-27-1": { en: "Phenol, 4-(1-ethyl-1,2-dimethylpropyl)-" },
  "30784-30-6": { en: "p-(1,1-dimethylheptyl)phenol" },
  "30784-31-7": { en: "Phenol, 4-(1,1-dimethylpentyl)-" },
  "30784-32-8": { en: "4-(3-methylhexan-3-yl)phenol" },
  "3108-42-7": { en: "Ammonium nonadecafluorodecanoate" },
  "31631-13-7": { en: "Phenol, p-isononyl-, phosphite (3:1)" },
  "3194-55-6": { en: "1,2,5,6,9,10-hexabromocyclododecane" },
  "3296-90-0": { en: "2,2-bis(bromomethyl)propane-1,3-diol (BMP)" },
  "33104-11-9": { en: "Phenol, 4-(1,1,3-trimethylbutyl)-" },
  "335-67-1": { en: "Pentadecafluorooctanoic acid (PFOA)" },
  "335-76-2": { en: "Nonadecafluorodecanoic acid" },
  "341035-71-0": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, gallium salt (9CI)",
  },
  "341548-85-4": {
    en: "Sulfonium, bis(4-methylphenyl)phenyl-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "34166-38-6": { en: "p-Nonylphenol hexaethoxylate" },
  "343934-04-3": {
    en: "1,3-Dioxane, 2-[(1R,2R)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, cis-rel-",
  },
  "343934-05-4": {
    en: "1,3-Dioxane, 2-[(1R,2R)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, trans-rel-",
  },
  "350836-93-0": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, scandium(3+) salt (3:1)",
  },
  "355-46-4": { en: "perfluorohexane-1-sulphonic acid" },
  "36437-37-3": {
    en: "2-(2H-benzotriazol-2-yl)-4-(tert-butyl)-6-(sec-butyl)phenol (UV-350)",
  },
  "3648-18-8": { en: "Dioctyltin dilaurate" },
  "36483-57-5": { en: "2,2-dimethylpropan-1-ol, tribromo derivative (TBNPA)" },
  "3687-31-8": { en: "Trilead diarsenate" },
  "37205-87-1": { en: "Isononylphenol, ethoxylated" },
  "375-73-5": { en: "1,1,2,2,3,3,4,4,4-nonafluorobutane-1-sulphonic acid" },
  "375-95-1": { en: "Perfluorononan-1-oic-acid" },
  "376-06-7": { en: "Heptacosafluorotetradecanoic acid" },
  "37872-24-5": { en: "Phenol, 4-(1,1-diethylpropyl)-" },
  "3825-26-1": { en: "Ammonium pentadecafluorooctanoate (APFO)" },
  "3830-45-3": { en: "Decanoic acid, nonadecafluoro-, sodium salt" },
  "3846-71-7": { en: "2-benzotriazol-2-yl-4,6-di-tert-butylphenol (UV-320)" },
  "3864-99-1": {
    en: "2,4-di-tert-butyl-6-(5-chlorobenzotriazol-2-yl)phenol (UV-327)",
  },
  "3871-99-6": { en: "potassium perfluorohexane-1-sulphonate" },
  "41184-65-0": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, neodymium(3+) salt (3:1)",
  },
  "41242-12-0": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, yttrium(3+) salt (3:1)",
  },
  "421555-73-9": {
    en: "Sulfonium, (thiodi-4,1-phenylene)bis[diphenyl-, salt with 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonic acid (1:2)",
  },
  "421555-74-0": {
    en: "Iodonium, bis[4-(1,1-dimethylpropyl)phenyl]-, salt with 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonic",
  },
  "425670-70-8": {
    en: "Sulfonium, tris[4-(1,1-dimethylethyl)phenyl]-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "48122-14-1": { en: "Hexahydro-1-methylphthalic anhydride" },
  "49663-84-5": { en: "Pentazinc chromate octahydroxide" },
  "50-32-8": { en: "Benzo[def]chrysene (Benzo[a]pyrene)" },
  "503155-89-3": { en: "morpholinium perfluorobutanesulfonate" },
  "507453-86-3": { en: "magnesium perfluorobutanesulfonate" },
  "513-78-0": { en: "Cadmium carbonate" },
  "513-79-1": { en: "Cobalt(II) carbonate" },
  "51404-69-4": { en: "Acetic acid, lead salt, basic" },
  "521947-27-3": { en: "4-(1,1,5-Trimethylhexyl)phenol" },
  "52427-13-1": { en: "4-(1-ethyl-1-methylhexyl)phenol" },
  "540-97-6": { en: "Dodecamethylcyclohexasiloxane" },
  "541-02-6": { en: "Decamethylcyclopentasiloxane" },
  "548-62-9": {
    en: "[4-[4,4'-bis(dimethylamino) benzhydrylidene]cyclohexa-2,5-dien-1-ylidene]dimethylammonium chloride (C.I. Basic Violet 3)",
  },
  "55120-77-9": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, lithium salt (1:1)",
  },
  "552-30-7": { en: "Benzene-1,2,4-tricarboxylic acid 1,2 anhydride" },
  "556-67-2": { en: "Octamethylcyclotetrasiloxane" },
  "56-35-9": { en: "Bis(tributyltin) oxide (TBTO)" },
  "56-55-3": { en: "Benz[a]anthracene" },
  "561-41-1": { en: "4,4'-bis(dimethylamino)-4''-(methylamino)trityl alcohol" },
  "57110-29-9": { en: "Hexahydro-3-methylphthalic anhydride" },
  "573-58-0": {
    en: "Disodium 3,3'-[[1,1'-biphenyl]-4,4'-diylbis(azo)]bis(4-aminonaphthalene-1-sulphonate) (C.I. Direct Red 28)",
  },
  "57427-55-1": { en: "Phenol, tetrapropylene-" },
  "59653-74-6": {
    en: "1,3,5-tris[(2S and 2R)-2,3-epoxypropyl]-1,3,5-triazine-2,4,6-(1H,3H,5H)-trione (β-TGIC)",
  },
  "60-09-3": { en: "4-aminoazobenzene" },
  "605-50-5": { en: "Diisopentyl phthalate" },
  "61788-32-7": { en: "Terphenyl, hydrogenated" },
  "62037-80-3": {
    en: "ammonium 2,3,3,3-tetrafluoro-2-(heptafluoropropoxy)propanoate",
  },
  "62229-08-7": { en: "Sulfurous acid, lead salt, dibasic" },
  "625-45-6": { en: "Methoxyacetic acid" },
  "629-14-1": { en: "1,2-diethoxyethane" },
  "64-67-5": { en: "Diethyl sulphate" },
  "6465-71-0": { en: "Phenol, 4-(1-propylbutyl)-" },
  "6465-74-3": { en: "Phenol, 4-(1-ethylpentyl)-" },
  "6477-64-1": { en: "Lead dipicrate" },
  "65996-93-2": { en: "Pitch, coal tar, high-temp." },
  "67118-55-2": {
    en: "potassium 2,3,3,3-tetrafluoro-2-(heptafluoropropoxy)propionate",
  },
  "676367-02-5": {
    en: "1,3-Dioxane, 2-[(1S,2S)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, cis-",
  },
  "676367-03-6": {
    en: "1,3-Dioxane, 2-[(1S,2R)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, cis-",
  },
  "676367-04-7": {
    en: "1,3-Dioxane, 2-[(1R,2S)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, cis-",
  },
  "676367-05-8": {
    en: "1,3-Dioxane, 2-[(1R,2R)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, cis-",
  },
  "676367-06-9": {
    en: "1,3-Dioxane, 2-[(1S,2S)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, trans-",
  },
  "676367-07-0": {
    en: "1,3-Dioxane, 2-[(1S,2R)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, trans-",
  },
  "676367-08-1": {
    en: "1,3-Dioxane, 2-[(1R,2S)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, trans-",
  },
  "676367-09-2": {
    en: "1,3-Dioxane, 2-[(1R,2R)-2,4-dimethyl-3-cyclohexen-1-yl]-5-methyl-5-(1-methylpropyl)-, trans-",
  },
  "6786-83-0": {
    en: "α,α-Bis[4-(dimethylamino)phenyl]-4 (phenylamino)naphthalene-1-methanol (C.I. Solvent Blue 4)",
  },
  "68-12-2": { en: "N,N-dimethylformamide" },
  "6807-17-6": { en: "2,2-bis(4'-hydroxyphenyl)-4-methylpentane" },
  "68259-08-5": { en: "ammonium perfluorohexane-1-sulphonate" },
  "68259-10-9": {
    en: "Ammonium 1,1,2,2,3,3,4,4,4-nonafluorobutane-1-sulphonate",
  },
  "683-18-1": { en: "Dibutyltin dichloride (DBTC)" },
  "68412-54-4": { en: "Nonylphenol, branched, ethoxylated" },
  "68515-42-4": {
    en: "1,2-Benzenedicarboxylic acid, di-C7-11-branched and linear alkyl esters",
  },
  "68515-50-4": {
    en: "1,2-Benzenedicarboxylic acid, dihexyl ester, branched and linear",
  },
  "68515-51-5": { en: "1,2-Benzenedicarboxylic acid, di-C6-10-alkyl esters" },
  "6863-24-7": { en: "Phenol, 4-(1-methylhexyl)-" },
  "68648-93-1": {
    en: "1,2-Benzenedicarboxylic acid, mixed decyl and hexyl and octyl diesters",
  },
  "68784-75-8": { en: "Silicic acid (H2Si2O5), barium salt (1:1), lead-doped" },
  "69011-06-9": { en: "[Phthalato(2-)]dioxotrilead" },
  "693-98-1": { en: "2-methylimidazole" },
  "70136-72-0": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, zinc salt",
  },
  "70225-16-0": {
    en: "tridecafluorohexanesulphonic acid, compound with 2,2'-iminodiethanol (1:1)",
  },
  "71-48-7": { en: "Cobalt(II) diacetate" },
  "71850-09-4": { en: "Diisohexyl phthalate" },
  "71868-10-5": {
    en: "2-methyl-1-(4-methylthiophenyl)-2-morpholinopropan-1-one",
  },
  "71888-89-6": {
    en: "1,2-Benzenedicarboxylic acid, di-C6-8-branched alkyl esters, C7-rich",
  },
  "71945-81-8": { en: "Phenol, 4-(1,3-dimethylpentyl)-" },
  "72033-41-1": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, compd. with N,N-diethylethanamine (1:1)",
  },
  "72624-02-3": { en: "Phenol, heptyl derivs." },
  "72629-94-8": { en: "Pentacosafluorotridecanoic acid" },
  "72861-06-4": { en: "Phenol, 4-(1,1,2,2-tetramethylpropyl)-" },
  "7311-27-5": {
    en: "2-[2-[2-[2-(4-nonylphenoxy)ethoxy]ethoxy]ethoxy]ethanol",
  },
  "7439-92-1": { en: "Lead" },
  "7440-43-9": { en: "Cadmium" },
  "74499-35-7": { en: "Phenol, (tetrapropenyl) derivatives" },
  "75-12-7": { en: "Formamide" },
  "75-56-9": { en: "Methyloxirane (Propylene oxide)" },
  "75166-30-2": { en: "(2S)-3-(4-tert-butylphenyl)-2-methylpropanal" },
  "75166-31-3": { en: "(2R)-3-(4-tert-butylphenyl)-2-methylpropanal" },
  "75579-39-4": {
    en: "Propanoic acid, 2,3,3,3-tetrafluoro-2-(heptafluoropropoxy)-, (+)-",
  },
  "75579-40-7": {
    en: "Propanoic acid, 2,3,3,3-tetrafluoro-2-(heptafluoropropoxy)-, (-)-",
  },
  "7632-04-4": { en: "Sodium peroxometaborate" },
  "7646-79-9": { en: "Cobalt dichloride" },
  "77-09-8": { en: "Phenolphthalein" },
  "77-40-7": { en: "4,4'-(1-methylpropylidene)bisphenol" },
  "77-78-1": { en: "Dimethyl sulphate" },
  "7738-94-5": { en: "Chromic acid" },
  "7758-97-6": { en: "Lead chromate" },
  "776297-69-9": { en: "n-pentyl-isopentylphthalate" },
  "7775-11-3": { en: "Sodium chromate" },
  "7778-39-4": { en: "Arsenic acid" },
  "7778-44-1": { en: "Calcium arsenate" },
  "7778-50-9": { en: "Potassium dichromate" },
  "7784-40-9": { en: "Lead hydrogen arsenate" },
  "7789-00-6": { en: "Potassium chromate" },
  "7789-06-2": { en: "Strontium chromate" },
  "7789-09-5": { en: "Ammonium dichromate" },
  "7790-79-6": { en: "Cadmium fluoride" },
  "78-00-2": { en: "Tetraethyllead" },
  "79-01-6": { en: "Trichloroethylene" },
  "79-06-1": { en: "Acrylamide" },
  "79-16-3": { en: "N-methylacetamide" },
  "80-05-7": { en: "4,4'-isopropylidenediphenol" },
  "80-46-6": { en: "p-(1,1-dimethylpropyl)phenol" },
  "80-54-6": { en: "2-(4-tert-butylbenzyl)propionaldehyde" },
  "8012-00-8": { en: "Pyrochlore, antimony lead yellow" },
  "81-15-2": { en: "5-tert-butyl-2,4,6-trinitro-m-xylene (Musk xylene)" },
  "82382-12-5": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, sodium salt",
  },
  "838-88-0": { en: "4,4'-methylenedi-o-toluidine" },
  "84-61-7": { en: "Dicyclohexyl phthalate" },
  "84-69-5": { en: "Diisobutyl phthalate" },
  "84-74-2": { en: "Dibutyl phthalate (DBP)" },
  "84-75-3": { en: "Dihexyl phthalate" },
  "84777-06-0": {
    en: "1,2-Benzenedicarboxylic acid, dipentyl ester, branched and linear",
  },
  "84852-15-3": { en: "Phenol, 4-nonyl-, branched" },
  "85-01-8": { en: "Phenanthrene" },
  "85-42-7": { en: "Cyclohexane-1,2-dicarboxylic anhydride" },
  "85-68-7": { en: "Benzyl butyl phthalate (BBP)" },
  "854904-92-0": { en: "Phenol, 4-(1-ethyl-3-methylbutyl)-" },
  "854904-93-1": { en: "Phenol, 4-(1,2-dimethylpentyl)-" },
  "85535-84-8": {
    en: "Alkanes, C10-13, chloro (Short Chain Chlorinated Paraffins)",
  },
  "85535-85-9": { en: "Alkanes, C14-17, chloro" },
  "857629-71-1": { en: "Phenol, 4-(1,4-dimethylpentyl)-" },
  "861010-65-3": { en: "Phenol, 4-(1-ethyl-2,2-dimethylpropyl)-" },
  "861011-60-1": { en: "4-(2,3-dimethylpentan-2-yl)phenol" },
  "866621-50-3": {
    en: "Iodonium, bis[(1,1-dimethylethyl)phenyl]-, salt with 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonic acid (1:1) (9CI)",
  },
  "872-50-4": { en: "1-Methyl-2-pyrrolidone (NMP)" },
  "88-85-7": { en: "Dinoseb (6-sec-butyl-2,4-dinitrophenol)" },
  "90-04-0": { en: "2-Methoxyaniline, o-Anisidine" },
  "90-94-8": { en: "4,4'-bis(dimethylamino)benzophenone (Michler’s ketone)" },
  "9002-93-1": {
    en: "Polyethylene glycol p-(1,1,3,3-tetramethylbutyl)phenyl ether",
  },
  "9016-45-9": { en: "Nonylphenol, ethoxylated" },
  "9036-19-5": {
    en: "Poly(oxy-1,2-ethanediyl), α-[(1,1,3,3-tetramethylbutyl)phenyl]-ω-hydroxy-",
  },
  "90481-04-2": { en: "Phenol, nonyl-, branched" },
  "90568-23-3": {
    en: "Borate(2-), tetrahydroxybis[μ-(peroxy-κO1:κO2)]di-, sodium (1:2)",
  },
  "90640-81-6": { en: "Anthracene oil, anthracene paste" },
  "90640-82-7": { en: "Anthracene oil, anthracene-low" },
  "91031-62-8": { en: "Fatty acids, C16-18, lead salts" },
  "910606-39-2": {
    en: "Sulfonium, (4-methylphenyl)diphenyl-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "911027-68-4": {
    en: "Sulfonium, [4-[(2-methyl-1-oxo-2-propen-1-yl)oxy]phenyl]diphenyl-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "911027-69-5": {
    en: "Sulfonium, [4-[(2-methyl-1-oxo-2-propenyl)oxy]phenyl]diphenyl-, salt with 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonic acid (1:1)",
  },
  "911370-98-4": { en: "Phenol, 4-(3-ethylpentyl)-" },
  "911371-06-7": { en: "Phenol, 4-(1,2,2-trimethylbutyl)-" },
  "911371-07-8": { en: "Phenol, 4-(1,3,3-trimethylbutyl)-" },
  "91648-39-4": { en: "Stannane, dioctyl-, bis(coco acyloxy) derivs." },
  "91995-15-2": { en: "Anthracene oil, anthracene paste, anthracene fraction" },
  "91995-17-4": { en: "Anthracene oil, anthracene paste, distn. lights" },
  "92-67-1": { en: "Biphenyl-4-ylamine" },
  "92011-17-1": {
    en: "1-Hexanesulfonic acid, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-, cesium salt (1:1)",
  },
  "928049-42-7": {
    en: "Dibenzo[k,n][1,4,7,10,13]tetraoxathiacyclopentadecinium, 19-[4-(1,1-dimethylethyl)phenyl]-6,7,9,10,12,13-hexahydro-, 1,1,2,2,3,3,4,4,5,5,6,6,6-tridecafluoro-1-hexanesulfonate (1:1)",
  },
  "93925-00-9": {
    en: "Formaldehyde, reaction products with branched and linear heptylphenol, carbon disulfide and hydrazine",
  },
  "94-26-8": { en: "Butyl 4-hydroxybenzoate" },
  "95-53-4": { en: "o-toluidine" },
  "95-80-7": { en: "4-methyl-m-phenylenediamine (toluene-2,4-diamine)" },
  "96-13-9": { en: "2,3-dibromo-1-propanol (2,3-DBPA)" },
  "96-18-4": { en: "1,2,3-trichloropropane" },
  "96-45-7": { en: "Imidazolidine-2-thione (2-imidazoline-2-thiol)" },
  "97-56-3": { en: "o-aminoazotoluene" },
  "98-54-4": { en: "4-tert-butylphenol" },
  "98-95-3": { en: "Nitrobenzene" },
  "1067-53-4": { en: "tris(2-methoxyethoxy)vinylsilane" },
  "255881-94-8": {
    en: "S-(tricyclo(5.2.1.0'2,6)deca-3-en-8(or 9)-yl O-(isopropyl or isobutyl or 2-ethylhexyl) O-(isopropyl or isobutyl or 2-ethylhexyl) phosphorodithioate",
  },
  "119-47-1": { en: "6,6'-di-tert-butyl-2,2'-methylenedi-p-cresol" },
  "1782069-81-1": {
    en: "(3E)-1,7,7-trimethyl-3-(4-methylbenzylidene)bicyclo[2.2.1]heptan-2-one",
  },
  "95342-41-9": {
    en: "(1R,3E,4S)-1,7,7-trimethyl-3-(4- methylbenzylidene)bicyclo[2.2.1]heptan-2-one",
  },
  "852541-25-4": {
    en: "(1S,3Z,4R)-1,7,7-trimethyl-3-(4-methylbenzylidene)bicyclo[2.2.1]heptan-2-one",
  },
  "36861-47-9": {
    en: "(±)-1,7,7-trimethyl-3-[(4-methylphenyl)methylene]bicyclo[2.2.1]heptan-2-one",
  },
  "741687-98-9": {
    en: "(1R,4S)-1,7,7-trimethyl-3-(4-methylbenzylidene)bicyclo[2.2.1]heptan-2-one",
  },
  "852541-30-1": {
    en: "(1S,3E,4R)-1,7,7-trimethyl-3-(4- methylbenzylidene)bicyclo[2.2.1]heptan-2-one",
  },
  "852541-21-0": {
    en: "(1R,3Z,4S)-1,7,7-trimethyl-3-(4- methylbenzylidene)bicyclo[2.2.1]heptan-2-one",
  },
  "924-42-5": {
    en: "N-(hydroxymethyl)acrylamide",
  },
  "80-43-3": {
    en: "Bis(α,α-dimethylbenzyl) peroxide",
  },
  "68512-30-1": {
    en: "Phenol, methylstyrenated",
  },
  "3896-11-5": {
    en: "Bumetrizole (UV-326)",
  },
  "119344-86-4": {
    en: "2-(dimethylamino)-2-[(4-methylphenyl)methyl]-1-[4-(morpholin-4-yl)phenyl]butan-1-one",
  },
  "3147-75-9": {
    en: "2-(2H-benzotriazol-2-yl)-4-(1,1,3,3-tetramethylbutyl)phenol (UV-329)",
  },
  "732-26-3": {
    en: "2,4,6-tri-tert-butylphenol",
  },
};
