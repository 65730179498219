import { EuRoHSStatus, ObsolescenceStatus } from "../rm-common-properties";

export class Z2DataDetail {
  MPNSummary: MPNSummary = new MPNSummary();
  Lifecycle: Lifecycle = new Lifecycle();
  MPNRiskScore: MPNRiskScore = new MPNRiskScore();
  ComplianceDetails: ComplianceDetails = new ComplianceDetails();
  ParametricSummary: ParametricSummary = new ParametricSummary();
  Parametric: Parametric = new Parametric();
  Package: Package = new Package();
  Packing: Packing = new Packing();
  Manufacturing: Manufacturing = new Manufacturing();
  Qualification: Qualification = new Qualification();
  ManufacturingLocations: ProductionLocations = new ProductionLocations();
  MarketAvailabilitySummary: MarketAvailabilitySummary =
    new MarketAvailabilitySummary();
  TradeCodes: Codes = new Codes();
  ConflictMineral: ConflictMineral = new ConflictMineral();
  SupplierSummary: SupplierSummary = new SupplierSummary();
  Replacement: Z2DataReplacement[] = [];
  PCNHistory: PCN[] = [];
}

class MPNSummary {
  PartID: number = 0;
  MPN: string = "";
  SupplierID: number = 0;
  Supplier: string = "";
  ProductType: string = "";
  PackageName: string = "";
  PackingType: string = "";
  Description: string = "";
  Datasheet: string = "";
  FamilyName: string = "";
  MPNMask: string = "";
  MPNIntroductionYear: number = 0;
  FamilyIntroductionYear: number = 0;
  LifecycleStatus: ObsolescenceStatus = "";
  EstimatedYearsToEOL: number = 0;
  MPNScore: number = 0;
  MPNDetailPageURL: string = "";
  MainCategory: string = "";
  Category: string = "";
  SubCategory: string = "";
  HasReplacement: string = "";
  CrossesCount: number = 0;
  ManufacturerCrossCount: number = 0;
}

class Lifecycle {
  LifecycleStatus: ObsolescenceStatus = "";
  LifecycleSource: string = "";
  LifecycleSourceType: string = "";
  LastTimeBuyDate: string = "";
  LastShipDate: string = "";
  EstimatedYearsToEOL: number = 0;
  LCComment: string = "";
  CommodityAverageEOLs: number = 0;
  EOLProductLCs: number = 0;
  ForecastedObsolescenceYear: number = 0;
}

class MPNRiskScore {
  MPNScore: number = 0;
  OverallRisk: string = "";
  ObsolescenceScore: number = 0;
  ObsolescenceRisk: string = "";
  ComplianceScore: number = 0;
  ComplianceRisk: string = "";
  TechnologyScore: number = 0;
  TechnologyRisk: string = "";
  MultiSourcingScore: number = 0;
  MultiSourcingRisk: string = "";
  MarketScore: number = 0;
  MarketRisk: string = "";
  SupplierScore: number = 0;
  SupplierScale: string = "";
}

class ComplianceDetails {
  RoHSStatus: EuRoHSStatus = "";
  RoHSVersion: string = "";
  RoHSSource: string = "";
  REACHStatus: string = "";
  REACHVersion: string = "";
  REACHSource: string = "";
  TSCA: string = "";
  FMDSource: string = "";
  NetWeight: string = "";
}

class ParametricSummary {
  MaximumJunctionTemperature: MaximumJunctionTemperature =
    new MaximumJunctionTemperature();
  MinimumJunctionTemperature: MinimumJunctionTemperature =
    new MinimumJunctionTemperature();
  MaximumOperatingTemperature: MaximumOperatingTemperature =
    new MaximumOperatingTemperature();
  MinimumOperationTemperature: MinimumOperationTemperature =
    new MinimumOperationTemperature();
  MaximumPowerDissipation: MaximumPowerDissipation =
    new MaximumPowerDissipation();
  Capacitance: Capacitance = new Capacitance();
}

class BaseParametric {
  ParametricFeatureID: number = 0;
  ParametricFeatureName: string = "";
  ParametricFeatureValue: string = "";
  ParametricFeatureNormalized?: number = 0;
  ParametricFeatureUnit?: string = "";
}

class MaximumJunctionTemperature extends BaseParametric {}

class MinimumJunctionTemperature extends BaseParametric {}

class MaximumOperatingTemperature extends BaseParametric {}

class MinimumOperationTemperature extends BaseParametric {}

class MaximumPowerDissipation extends BaseParametric {}

class Capacitance extends BaseParametric {}

class Parametric {
  ParametricFeatures: BaseParametric[] = [];
}

class Package {
  NormalizedPackageName: string = "";
  PackageName: string = "";
  PinCount: string = "";
  Dimensions_mm: string = "";
  MaximumDiameter: string = "";
  MaximumHeight: string = "";
  Thickness: number = 0;
  LeadShape: string = "";
  MountingType: string = "";
  PinPitch_mm: string = "";
}

class Packing {
  PackingType: string = "";
  ReelDiameter: string = "";
  PackingQuantity: string = "";
}

class Manufacturing {
  MSL: string = "";
  ManufacturingReflowTemperature: string = "";
  MaximumWaveTemperature: string = "";
  NumberOfReflowCycle: string = "";
  BaseMaterial: string = "";
  LeadFinishPlating: string = "";
  ReflowSolderTime_Sec: string = "";
  WaveSolderTime: string = "";
  ShelfLife_Month: string = "";
  ShelfLifeStart: string = "";
  ShelfLifeCondition: string = "";
  NetWeight: string = "";
}

class Qualification {
  MPNGrade: string = "";
  TempRange: string = "";
  MTBF_MTTF_Hrs: string = "";
  AutomotiveQualified: string = "";
}

class ProductionLocations {
  ManufacturingLocations: ManufacturingLocation[] = [];
}

class ManufacturingLocation {
  CompanyLocationID: number = 0;
  Facilitytype: string = "";
  TrustLevel: string = "";
  CountryName: string = "";
  StateProvinceName: string = "";
  CityName: string = "";
  SiteOwner: string = "";
  DisplayName: string = "";
  Address: string = "";
}

class MarketAvailabilitySummary {
  MarketStatus: string = "";
  MarketLeadTime_weeks: number = 0;
  StandardLeadTime_weeks: number = 0;
  NumberOfSeller: number = 0;
  TotalQuantityAvailable: number = 0;
  LowestPrice: number = 0;
  MaxPrice: number = 0;
  AvgPrice: number = 0;
}

class Codes {
  TradeCodes: TradeCode[] = [];
}

class TradeCode {
  TradeCodeName: string = "";
  TradeCodeValue: string = "";
}

class ConflictMineral {
  ConflictMineralStatus: string = "";
  ConflictMineralSource: string = "";
  ConflictMineralSourceType: string = "";
}

class SupplierSummary {
  SupplierID: number = 0;
  SupplierName: string = "";
  OverallScore: number = 0;
  OverallRisk: string = "";
  SupplierOwnership: string = "";
  ParentSupplierName: string = "";
  DUNSNumber: string = "";
  HQCageCode: string = "";
  Address: string = "";
  Employees: string = "";
  Youtube: string = "";
  Twitter: string = "";
  LinkedIn: string = "";
  FaceBook: string = "";
  Website: string = "";
  PhoneNumber: string = "";
  FinancialScore: number = 0;
  FinalScoreScale: string = "";
}

export class Z2DataReplacement {
  HasReplacement: string = "";
  ReplacementPartID: number = 0;
  ReplacementPart: string = "";
  ReplacementCompany: string = "";
  ReplacementType: string = "";
}

export class PCN {
  TrackingNumber: string = "";
  TypeOfChanges: string[] = [];
  NotificationDate: string = "";
  EffectiveDate: string = "";
  ChangeDescription: string = "";
  ReasonOfChange: string = "";
  Source: string = "";
  ReplacementPart: string = "";
  ReplacementCompany: string = "";
}
export class Z2DataMatch {
  [key: string]: any;
  Manufacture: string = "";
  Datasheet: string = "";
  PartID: number = 0;
  MPN: string = "";
  Manufacturer: string = "";
  Description: string = "";
  LifecycleStatus: string = "";
  MPNMask: string = "";
  RoHSStatus: string = "";
  RoHSVersion: string = "";
  Category: string = "";
  SubCategory: string = "";
  MainCategory: string = "";
  ProductType: string = "";
  EstimatedYearsToEOL: number = 0;
}

class BaseResponse {
  statusCode: number = 0;
  status: string = "";
}

export class Z2DataCrossesResponse extends BaseResponse {
  results: CrossResult = new CrossResult();
}

class CrossResult {
  partID: number = 0;
  numFound: number = 0;
  pageNumber: number = 0;
  partNumber: string = "";
  companyName: string = "";
  partLifecycle: string = "";
  roHsFlag: string = "";
  dataSheet: string = "";
  crossAggregation: CrossAggregation = new CrossAggregation();
  crossesDetails: CrossesDetails = new CrossesDetails();
}

class CrossAggregation {
  "DROP-IN B"?: number = 0;
  "DROP-IN C"?: number = 0;
}

class CrossesDetails {
  currentPage: number = 0;
  pageSize: number = 0;
  Total_Crosses_Found: number = 0;
  crosses: Z2DataCross[] = [];
}

export class Z2DataCross {
  partNumber: string = "";
  companyName: string = "";
  dataSheet: string = "";
  crossType: string = "";
  crossComment: string = "";
  partDescription: string = "";
  partLifecycle: ObsolescenceStatus = "";
  roHsFlag: EuRoHSStatus = "";
  package: string = "";
}

export class Z2DataPartDetailResponse extends BaseResponse {
  results: Z2DataDetail[] = [];
}
