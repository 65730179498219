import { Docs, Type } from "../../../../shared/components";

export function hasChanges(a: any, b: any) {
  if (a === b) {
    return false;
  }
  try {
    return JSON.stringify(a) !== JSON.stringify(b);
  } catch {
    return true;
  }
}

function isValue(value: any) {
  return value != null && value !== "";
}

export function userEditedFields(cleanModel: any, currentModel: any) {
  const keys = new Set<string>();
  Object.keys(cleanModel).forEach((key) => keys.add(key));
  Object.keys(currentModel).forEach((key) => keys.add(key));
  const fields: string[] = [];
  Array.from(keys)
    .sort()
    .map((key) => key)
    .forEach((key) => {
      const oldValue = cleanModel[key];
      const newValue = currentModel[key];
      const hasOld = isValue(oldValue);
      const hasNew = isValue(newValue);
      if (
        hasOld !== hasNew ||
        (hasNew && JSON.stringify(oldValue) !== JSON.stringify(newValue))
      ) {
        fields.push(key.split(".")[1]);
      }
    });
  return fields;
}

// // Needed when comparing a class to a model to remove the prefixes from the model property keys
// export function hasChangesAsDocs(a: any, b: any) {
//   if (a === b) {
//     return false;
//   }
//   try {
//     let bObject: object = {};

//     Object.keys(b).forEach(key => {
//       let noPrefix = key.split('.')[1];

//       bObject[noPrefix] = b[key];
//     })
//     // console.log('aaaa', a)
//     // console.log('bbbbb', bObject)
//     return JSON.stringify(a) !== JSON.stringify(bObject);
//   } catch {
//     return true;
//   }
// }

export function touch<T extends {}>(
  get: () => T,
  update: (obj: T) => void = () => null
): T {
  let obj: T | null = null;
  try {
    obj = get();
  } catch (err) {
    obj = null;
  }
  if (obj == null) {
    obj = {} as any;
  } else {
    obj = { ...obj };
  }
  if (obj == null) {
    throw new Error("obj is null");
  }
  update(obj);
  return obj;
}

export function loadModel<T extends Type>(
  type: T,
  doc: Docs[T],
  model: { [fieldId: string]: any } = {}
) {
  if (doc != null) {
    Object.keys(doc).forEach((fieldName) => {
      const fieldId = type + "." + fieldName;
      (model as any)[fieldId] = (doc as any)[fieldName];
    });
  }
  return model;
}

/**
 * Find the array element next to an element
 */
export function getNeighbour<T>(current: T, array: T[], distance = 1) {
  const index = array.indexOf(current);
  if (index < 0) {
    return null;
  }
  const nextIndex = index + distance;
  if (nextIndex >= 0 && nextIndex < array.length) {
    const next = array[nextIndex];
    return next;
  }
  return null;
}
