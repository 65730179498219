import { Post } from "../../../../shared/models/post";
import { BehaviorSubject } from "rxjs";

export type PostType = "comment" | "solution" | "task";
export type SectionType = "comment" | "solution" | "task" | "stream";

export const STREAM: SectionType = "stream";
export const COMMENT: any = "comment";
export const SOLUTION: any = "solution";
export const TASK: any = "task";

export interface PostServiceType {
  /** The current model of a post */
  model: Post;

  /** All posts */
  posts: Post[];

  /** All posts as stored in the database */
  cleanPosts: Post[];

  /** All post ids */
  ids: string[];

  /** Comments array */
  comments: Post[];

  /** Number of all comments */
  numberOfComments: number;

  /** Solutions array */
  solutions: Post[];

  /** Number of all solutions */
  numberOfSolutions: number;

  /** Tasks array */
  tasks: Post[];

  /** Number of all tasks */
  numberOfTasks: number;

  /** Number of all open tasks */
  numberOfOpenTasks: number;

  /** Uncompleted tasks in the current status */
  uncompletedTasksByStatus: Post[];

  /** Completed tasks in the current status */
  completedTasksByStatus: Post[];

  /** List of accepted solution with less properties: post.resolveClass, post.content, post.resolveCost */
  acceptedSCompressed: Partial<Post>[];

  /** Contains the filters applied based on the postType selected */
  postFilters: string[];

  /** Is the currently selected post one that has been created locally and is not saved? */
  isNew: boolean;

  /** Returns if the postType selected is a solution */
  isSolution: boolean;

  /** Returns if the postType selected is in the read mode */
  isReadMode: boolean;

  /** Display save comment/solution/task button */
  showSaveButton: boolean;

  /** Returns the type of a post */
  postType: PostType;

  /** Returns the currently selected section */
  sectionType: SectionType;

  /** Returns the filter type selected (All Comments/Created by Me/ My tasks...) */
  filterMode: string;

  /** Returns the sorting order based on the postType selected */
  sortingOrder: string;

  /** Subscribe to changes to get the docs based on a filter set */
  groupingByFilterSubject: BehaviorSubject<Post[]>;

  /** Subscribe to display the clean post when discard button is clicked */
  discardPostSubject: BehaviorSubject<boolean>;

  /** List of currect project names */
  projectNames: string[];

  /** Was a filter applied on posts? */
  isFilterSet: boolean;

  /** Was a sort applied on posts? */
  isSortingOrderSet: boolean;

  /** List of current selected filters */
  projectFilters: string[];

  /** Get all docs of type post of a thread */
  getThreadPosts(customer: string, threadId: string): Promise<void>;

  /** Save the currently edited post */
  save(): void;

  /** Mark a post as final solution */
  setFinalSolution(post: Post): void;

  /** Get the list of accepted solutions with the following properties: post.resolveClass, post.content, post.resolveCost
   * reduce load of data on home page & export
   */
  getAcceptedSCompressed(): Promise<void>;

  /** Export posts as csv */
  exportData(): Promise<void>;

  /** Delete the post */
  delete(post: Post): Promise<void>;

  /** Set the filters option based on the postType selected */
  setFilters(postType: string): string[];

  /** Returns the text for the filters */
  getPostFilterText(post: string): string;

  /** Get the post docs based on the filters selected */
  getPostsByFilter(filter: string): void;

  /** Sort the post docs based on the sectionType selected */
  sortPosts(posts: Post[], order: string, sectionType: SectionType): void;

  /** Check to see if the post is a solution or not */
  showResolveClassField(id: string | null): boolean;

  /** Prepare data for each postType when clicking on the tabs */
  changePostSection(type: any, sortingOrder: string, filterMode: string): void;

  /** Set the fields that are required for each postType */
  setRequiredFields(type: PostType, isEditMode?: boolean): void;

  /** Does the user model have changes?  */
  modelHasChanges(postId: string | null): boolean;

  /** Get the comments, solutions and tasks for a thread */
  filterPosts(): void;

  /** Returns true if the solutionType needs an evaluation check (only for NS) */
  needEvaluationCheck(solutionType: string): boolean;

  /** Does the current user have the permission to edit the post document with the given id? */
  hasEditPermission(post: Post): boolean;

  /** Get the text for the Add butoons based on the sectionType selected */
  getLabelBySectionType(sectionType: SectionType): void;

  /** Discard the current changes of a post model */
  discardChanges(): void;

  /** Get the list of fields to be displayed for a certain type of solution */
  getSolutionFieldsList(solutionName: string | undefined): string[];

  /** Get the list of fields to be displayed for a certain type of solution when creating a new solution */
  getNewSolutionFieldsList(solutionName: string | undefined): string[];

  /** Reset post arrays */
  resetPosts(): void;

  /** Check if user have permission to add a comment/solution/task */
  hasPermissionToAddPost(postType: PostType): boolean;

  /** Get the comments, solutions and tasks for a thread based on selected project */
  filterPostsByProject(isRemoved?: boolean): void;

  /** Is a option selected for project filtering (KNDS)? */
  isOptionSelected(option: string): boolean;

  /** Toggle filter options */
  toggleOption(option: string): void;

  /** Mark open tasks as completed if the corresponding project has been deleted */
  markTaskCompleted(deletedProject: string): void;

  /** Used to set the default sorting value for each post section */
  setDefaultSorting(type: string): void;

  /** Used to set the default filter value for each post section */
  setDefaultFilter(type: SectionType): void;

  /** Send email to person who created the case when the status can to move to the next one (3 to 4) */
  triggerOMEmail(): void;
}
