import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { PcnData } from "../../../../../shared/models/manufacturer";
import { getApp } from "../../app";

@Component({
  selector: "app-supply-chain-table",
  templateUrl: "./supply-chain-table.component.html",
  styleUrls: ["./supply-chain-table.component.scss"],
})
export class SupplyChainComponent {
  selectedFilters: { columnName: string; value: string }[] = [];
  resetFilterInputs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  resetFilterInputsObservable: Observable<boolean> =
    this.resetFilterInputs.asObservable();

  app = getApp((app) => {
    this.app = app;
  });
  columns: string[] = [];
  cleanColumns: string[] = [];
  loading = true;

  async ngOnInit() {
    this.columns = this.app.list.manufacturer.supplyChainColumns;
    this.getCleanColumns();
    await this.app.manufacturerUtils.getInventoryDetails();
    this.loading = false;
  }

  getCleanColumns() {
    this.cleanColumns = this.app.list.manufacturer.supplyChainColumns.map(
      (field: string) => field.split(".")[1]
    );
    return this.columns;
  }
}
