import { Component, OnInit } from "@angular/core";
import { Customer } from "../../../../shared/types/customers";
import { getApp } from "../app";
import { FieldTypes, Options } from "../../../../shared/settings/settings";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  columns: string[] = [];
  columnList: string[] = [];
  app = getApp((app) => {
    this.app = app;
  });
  filterOpen = false;
  showCounter = false;

  selectedFilters: { columnName: string; value: string }[] = [];

  constructor() {}

  async ngOnInit() {
    this.app.type = "user";
    await this.app.users.getListOfUsers();
    if (
      this.app.auth.isTeam &&
      this.app.customers.expectCurrent !== Customer.DB
    ) {
      this.columns = this.app.list.user.readonlyView;
    } else {
      this.columns = this.app.list.user.columns;
    }
    this.columnList = this.columns.map((col) => col.split(".")[1]);

    this.addOptionActiveField();
  }

  getUserRole(role: string) {
    if (role.includes(this.app.customers.expectCurrent)) {
      return role.split("-")[1];
    } else {
      return "";
    }
  }

  checkPermission(userName: string) {
    if (
      this.app.auth.isTeam &&
      this.app.customers.expectCurrent === Customer.NS
    ) {
      return;
    } else {
      this.app.users.getUserById(userName);
      this.app.routing.navigateUser(userName);
    }
  }

  addOptionActiveField() {
    this.app.field.getFieldSettings(this.app.fieldId.user.active).type =
      FieldTypes.options;

    const options: any = {} as Options;
    options["active"] = {
      text: {
        de: "Active",
        en: "Active",
        nl: "Active",
      },
    };
    options["offline"] = {
      text: {
        de: "Offline",
        en: "Offline",
        nl: "Offline",
      },
    };

    this.app.field.getFieldSettings(this.app.fieldId.user.active).options =
      options;
  }
}
