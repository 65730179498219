import { FilterTreeServiceType } from "./filter-tree.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Part } from "../../../../shared/models/part";
import { getTreeFilterResult } from "../api.service";
import { FilterPage } from "../filter-list/filter-list.state";
import { SearchWords } from "../filter/filter.service.type";

export const WITHOUT_DATA = "withoutData";
export const OBSOLETE = "obsolete";
export const ACTIVE = "active";
export const NRND = "nrnd";
export const PDN = "PDN issued";
export const PARTNUMBER = "partNumber";
const INPUT = "input";
export const SPECIAL_STATUS = "specialStatus";
const OPTIONS = "options";
@Injectable()
export class FilterTreeService implements FilterTreeServiceType {
  searchPartResult: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  highestLevel: Partial<Part> = {};
  assembliesCopy: { cpn: string; isChecked: boolean }[] = [];
  filters: Partial<FilterPage>[] = [];
  filterValues: SearchWords = {};
  partsOfCurrentAssembly: string[] = [];
  assemblySelected = "";
  result: any = {};

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async filter(field: string, value: string) {
    this.app.spinner.showSpinner();
    this.filterValues = this.createFilterObject(field, value);

    await this.getTreeSearchResult();
    this.app.tree.openParts.clear();
    this.app.state.next({ filterTree: this.filterValues });
    this.app.tree.loading = false;
  }

  async getTreeSearchResult(page?: string) {
    this.app.spinner.showSpinner();
    const p = page != null ? page : "1";
    if (Object.keys(this.filterValues).length === 0) {
      await this.app.tree.getItems();
      this.searchPartResult.next(false);
      return;
    }
    this.result = await getTreeFilterResult(
      this.app.customers.expectCurrent,
      Object.keys(this.filterValues),
      this.filterValues,
      p,
      this.app.paginator.pageSize.toString()
    );
    this.app.tree.items = this.result.rows;

    if (
      Object.keys(this.filterValues).includes("partNumber") &&
      Object.keys(this.filterValues).length === 1
    ) {
      this.app.tree.partsOfSearchedAssembly = this.result.parts.map(
        (part: Part) => part.partNumber
      );
    }

    this.app.tree.partsOfCurrentAssembly = this.result.parts.map(
      (part: Part) => part.partNumber
    );

    this.app.spinner.hideSpinner();

    this.highestLevel = {};
    if (this.result && this.result.rows.length > 0) {
      this.highestLevel = this.result.rows[0].node.part;
    } else {
      this.app.tree.loading = true;
    }

    /** once the row has been opened, it has to be reopened when toggle-ing on another row */
    if (this.app.tree.openParts.has(this.highestLevel.partNumber as string)) {
      this.app.spinner.showSpinner();
      this.app.tree.openParts.clear();
      this.app.tree.toggleTreeRow(this.result.rows[0]);
      this.app.spinner.hideSpinner();
    }
    this.searchPartResult.next(true);
    this.app.tree.pages = this.result.size.toString();
    return this.result;
  }

  onChangeAssembly(event: any) {
    const { value } = event.target;

    // tslint:disable-next-line
    for (let i = 0; i < this.assembliesCopy.length; i++) {
      if (value === this.assembliesCopy[i].cpn) {
        this.assembliesCopy[i].isChecked = true;
      } else {
        this.assembliesCopy[i].isChecked = false;
      }
    }
  }

  createFilterObject(fieldName: string, value: string) {
    const filter: Partial<FilterPage> = {
      values: value,
      filterLabelField: fieldName,
    };

    const index = this.filters.findIndex(
      (e) => e.filterLabelField === fieldName && e.values === value
    );

    // For fieldName partNumber only one can be selected at a time in the filtration of the tree
    // If partNumber is unchecked(index != -1) remove the filter only at the end to avoid to push the same filter again
    if (fieldName === this.app.fieldId.part.partNumber && index === -1) {
      this.removeExistingFilter(fieldName);
    }

    if (index === -1) {
      this.filters.push(filter);
    } else {
      this.filters.splice(index, 1);
    }

    return this.app.filter.setFilterValues(this.filters);
  }

  getFieldType(field: string) {
    switch (field) {
      case this.app.fieldId.part.partNumber:
        return PARTNUMBER;
      case this.app.fieldId.part.obsolescenceStatus:
      case this.app.fieldId.part.obsolescenceStatus2years:
      case this.app.fieldId.part.obsolescenceStatus4years:
      case this.app.fieldId.part.obsolescenceStatus6years:
      case this.app.fieldId.part.obsolescenceStatus8years:
      case this.app.fieldId.manufacturer.obsolescenceStatus:
      case this.app.fieldId.manufacturer.obsolescenceStatus2years:
      case this.app.fieldId.manufacturer.obsolescenceStatus4years:
      case this.app.fieldId.manufacturer.obsolescenceStatus6years:
      case this.app.fieldId.manufacturer.obsolescenceStatus8years:
        return SPECIAL_STATUS;
      default:
        return OPTIONS;
    }
  }

  resetFilterTree() {
    this.filterValues = {};

    if (this.app.RM.fromRMSearch && this.assemblySelected !== "") {
      this.filterValues.partNumber = this.assemblySelected;
    }

    if (!this.app.RM.fromRMSearch) {
      this.filters = [];
    } else {
      this.filters = this.filters.filter(
        (item: any) => item.filterLabelField == "part.partNumber"
      );
    }
  }

  async resetFiltersByPage(): Promise<void> {
    this.resetFilterTree();

    if (this.app.RM.fromRMSearch && this.assemblySelected !== "") {
      // Reset filter-tree from RMSerach only when Reset filter button is used
      this.filters = [];
      this.app.filterTree.filter("part.partNumber", this.assemblySelected);
    } else {
      await this.app.tree.getItems();
    }
  }

  getSelectedOptions(field: string): string {
    const filters = this.filters
      .filter((f) => f.filterLabelField === field)
      .map((v) => v.values);

    const labels: string[] = [];
    filters.forEach((f: string) => {
      if (f === WITHOUT_DATA) {
        labels.push(this.app.text.RM.withoutData);
      }
      labels.push(this.app.field.getFieldValueAsText(field, f));
    });
    return labels.join(",");
  }

  getOptionText(field: string, status: string) {
    switch (status) {
      case WITHOUT_DATA:
        return this.app.text.RM.withoutData;
      default:
        return this.app.field.getOptionText(field, status);
    }
  }

  private removeExistingFilter(fieldName: string) {
    const index = this.filters.findIndex(
      (e) => e.filterLabelField === fieldName
    );

    if (index !== -1) {
      this.filters.splice(index, 1);
    }
  }
}
