import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewRef,
} from "@angular/core";
import { getApp } from "../app";
import { Subscription } from "rxjs";
import { filterInitialState } from "../filter-list/filter-list.state";
import { Customer } from "../../../../shared/types/customers";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild("searchField", { static: false }) searchField: any;
  @ViewChild("dropdownItem", { static: false }) dropdownItem: any;
  public unreadCount = 0;
  url = "";
  segments: string[] = [];
  customers: string[] = [];
  cleanCustomers: string[] = [];
  searchedClient = "";
  showClientsList = false;

  app = getApp((app) => {
    this.app = app;
  });

  private _unreadSubscription: Subscription = new Subscription();
  private _profilePictureSubscription: Subscription = new Subscription();
  private _modeSubscription: Subscription = new Subscription();

  constructor(private cdRef: ChangeDetectorRef, private router: Router) {}

  public async ngOnInit() {
    // get unread count if users have access to the alerts (all user roles except guest)
    this._unreadSubscription = this.app.mail.updateUnreadCount.subscribe(
      async (value) => {
        if (value) {
          await this.app.change.getUnreadChanges();
          await this.app.pcns.getUnreadPcns();
          await this.app.alerts.getUnreadAlerts();
          this.unreadCount = this.app.mail.unread;
          if (!(this.cdRef as ViewRef).destroyed) {
            this.cdRef.detectChanges();
          }
        }
      }
    );
    this.app.auth.getCurrentUserRole();

    const roleIndex = this.app.auth.rolesArray.findIndex(
      (role: string) => role === `${this.app.customers.expectCurrent}-team`
    );
    if (roleIndex !== -1) {
      this.app.auth.isTeam = true;
    }

    this._profilePictureSubscription =
      this.app.profile.ownProfilePictureChanges.subscribe((value) => {
        if (value !== "") {
          this.app.profile.ownProfilePicture = value;
        } else {
          this.app.profile.ownProfilePicture = null;
        }
        this.cdRef.detectChanges();
      });

    await this.app.profile.getProfilePicture(this.app.user);

    this._modeSubscription = this.app.header.newHeaderSubject.subscribe(
      async (value) => {
        this.app.header.showSidebar = value;
        this.cdRef.detectChanges();
      }
    );

    this.cleanCustomers = this.app.customers.customers;
    this.customers = this.app.customers.customers;

    if (this.customers.length > 1) {
      this.showClientsList = true;
    } else {
      this.showClientsList = false;
    }
  }

  routerLinkUrl(route: string) {
    // special case for analyst and RMAdmin role - navigate to alerts table
    if (
      route === "mail/change" &&
      (this.app.state.isAnalyst || this.app.state.isRMAdmin)
    ) {
      return `/${this.app.customers.expectCurrent}/` + "mail/alert";
    }
    if (route === "profile") {
      return (
        `/${this.app.customers.expectCurrent}/` + "profile/" + this.app.user
      );
    }
    return `/${this.app.customers.expectCurrent}/` + route;
  }

  navigateRM() {
    /** created only to take out several functions call in the html */
    this.app.routing.navigateRM();
    this.app.paginator.focus = 0;
    this.app.tree.partsOfCurrentAssembly = [];
    this.app.tree.partsOfSearchedAssembly = [];
    this.app.filterTree.assemblySelected = "";
    this.app.filterTree.resetFilterTree();
  }

  navigateRMSearch() {
    /** created only to take out several functions call in the html */
    this.app.routing.navigateRMSearch();
    this.app.paginator.focus = 0;
    this.app.RM.filterOpen = false;
  }

  resetFilters() {
    this.app.filterList.open = false;
    this.app.state.filters = {};
    const initialState = filterInitialState;
    initialState.filters = {};
    this.app.state.next({ ...initialState });
    this.app.filterTree.resetFilterTree();
    this.app.filterTree.resetFiltersByPage();
  }

  isCollapsedSidebar() {
    if (
      (this.app.customers.expectCurrent === Customer.MTU ||
        this.app.customers.expectCurrent === Customer.KNDS ||
        this.app.customers.expectCurrent === Customer.ROSEN ||
        this.app.customers.expectCurrent === Customer.LRE ||
        this.app.customers.expectCurrent === Customer.HERRMANNULTRASCHALL ||
        this.app.customers.expectCurrent === Customer.PLASTICOMNIUM ||
        this.app.customers.expectCurrent === Customer.RHEINMETALLAIRDEFENCE ||
        this.app.customers.expectCurrent === Customer.RHEINMETALL) &&
      this.app.header.showSidebar
    ) {
      return true;
    }
    return false;
  }

  navigateUserProfile() {
    if (this.app.user == null) {
      return;
    }
    this.app.routing.navigateProfile(this.app.user);
  }

  showPortalOMButton() {
    if (
      (this.app.customer === Customer.OMP &&
        !this.app.permission.RM.isAnalyst &&
        !this.app.auth.isRMAdmin) ||
      this.app.customer === Customer.COOP
    ) {
      return true;
    }
    return false;
  }

  showOMButton() {
    if (
      this.app.customers.expectCurrent !== Customer.OMP &&
      this.app.customers.expectCurrent !== Customer.COOP &&
      this.app.customers.expectCurrent !== Customer.OC
    ) {
      if (!this.app.permission.RM.isAnalyst && !this.app.auth.isRMAdmin) {
        return true;
      }
    }
    return false;
  }

  filterCustomers(searchedClient: string) {
    this.customers = this.app.customers.customers;
    const customersNames = this.customers.map((customer) =>
      this.app.customers.getText(customer)
    );

    const result = customersNames.filter(
      (element) =>
        String(element).toLowerCase().indexOf(searchedClient.toLowerCase()) !==
        -1
    );

    this.customers = [];
    result.forEach((customer: string) => {
      // Used to convert the client name to the value from the database (navigateCustomer(dbValue))
      const dbValue = this.app.customers.getLowerCaseText(customer);
      this.customers.push(dbValue);
    });
  }

  checkKey(event: KeyboardEvent) {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      this.dropdownItem.nativeElement.focus();
    } else if (event.key !== "Enter") {
      this.searchField.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    // Set Subject value to false to prevent retrigger as soon as the new Route is loaded
    this.app.mail.updateUnreadCount.next(false);

    if (this._unreadSubscription) {
      this._unreadSubscription.unsubscribe();
    }
    if (this._profilePictureSubscription) {
      this._profilePictureSubscription.unsubscribe();
    }
    if (this._modeSubscription) {
      this._modeSubscription.unsubscribe();
    }
  }
}
