import { Component, Input, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-frame",
  templateUrl: "./frame.component.html",
  styleUrls: ["./frame.component.scss"],
})
export class FrameComponent {
  @Input() title = "";
  @Input() showContent = true;
  @Output() showsContent = new Subject();
  @Input() showHeader = true;
  @Input() changeHeader = false;
  @Input() filterBox = false;
  @Input() noteBox = false;
  @Input() fieldTableBox = false;

  constructor() {}

  // toggleContent() {
  //   if (this.showContent) {
  //     this.showContent = false;
  //   } else {
  //     this.showContent = true;
  //   }
  //   this.showsContent.next(this.showContent);
  // }
}
