import {
  Component,
  PipeTransform,
  Pipe,
  ViewChild,
  OnInit,
  Input,
} from "@angular/core";
import { getApp } from "../../app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OCPost } from "../../../../../shared/models/obsolescence-community/OC-Post";
import { StringUtils } from "../../../../../shared/utils/string.utils";

@Pipe({
  name: "bold",
})
class BoldPipe implements PipeTransform {
  transform(textValue: string, subTextValue: string): string {
    return textValue.replace(
      new RegExp(`(${subTextValue})`, "gi"),
      "<b>$1</b>"
    );
  }
}

@Component({
  selector: "app-oc-post-modal",
  templateUrl: "./OC-post-modal.component.html",
  styleUrls: ["./OC-post-modal.component.scss"],
})
export class OCPostModalComponent implements OnInit {
  app = getApp(async (app) => {
    this.app = app;
  });
  @ViewChild("content", { static: false }) content: any;
  @Input() title = "";

  constructor(private modalService: NgbModal) {}

  async ngOnInit() {}

  async open() {
    try {
      this.modalService.open(this.content, {
        windowClass: "mediumModal",
      });
    } catch (err) {
      // NOP
    }
  }

  isDisabled() {
    const title = this.app.OCHome.postDoc.title;
    const message = this.app.OCHome.postDoc.message;

    if (
      StringUtils.isNullOrEmpty(title) ||
      StringUtils.isNullOrEmpty(message)
    ) {
      return true;
    }
    return false;
  }

  closeModal() {
    this.modalService.dismissAll();
    this.app.OCHome.postDoc = new OCPost();
  }
}
