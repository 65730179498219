import { APP_TYPE, AppType } from "../app.type";
import { AuthServiceType } from "./auth.service.type";
import { Customer } from "../../../../shared/types/customers";
import { CustomerName } from "../../../../shared/config/customers";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";
import { Inject, Injectable } from "@angular/core";
import { LoginDetails } from "../../../../shared/models/login-details";
import { LoginResult } from "../../../../shared/models/login-result";
import { NAVIGATION_HISTORY, REDIRECT_TO } from "../routing/routing.service";
import { PORTAL } from "../../../../shared/constants/portal";
import { ResetPasswordEmailDetails } from "../../../../shared/models/reset-password-email-details";
import { ResetPasswordObject } from "../../../../shared/models/reset-password-object";
import { TOKEN } from "./token";
import {
  checkAuth,
  login,
  resetPassword,
  saveNewPassword,
  verifyUsernameAndPassword,
} from "../api.service";

function getDefaultCustomer(customerNames: CustomerName[]): CustomerName {
  if (customerNames.length > 0) {
    return customerNames[0];
  }

  return PORTAL;
}

@Injectable()
export class AuthService implements AuthServiceType {
  rolesArray: string[] = [];
  hasMTUPermission = false;
  isTeam = false;
  isAdmin = false;
  isUser = false;
  isRMAdmin = false;
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async loginToHomePage(details: LoginDetails) {
    this.app.run(async () => {
      await this.login(details);
    });
  }

  async login(details: LoginDetails) {
    const loginResult = await login(details);

    if (loginResult.authenticated) {
      if (loginResult.mfaRequired) {
        // Set username and mfaCodeLength to localStorage to be able to access them on mfa-complete page
        localStorage.setItem("username", loginResult.username);
        localStorage.setItem(
          "mfaCodeLength",
          loginResult.mfaCodeLength.toString()
        );

        await this.app.routing.navigate("mfa-complete");
      } else {
        await this.handleAuthentication(loginResult);
      }
    } else {
      throw new Error("not logged in");
    }
  }

  async handleAuthentication(loginResult: LoginResult) {
    const { username, customerNames, token, passwordChangeRequired, roles } =
      loginResult;

    if (typeof Storage !== "undefined") {
      localStorage.setItem(TOKEN, EncodingUtils.encodeBase64(token));
    }

    const defaultCustomer = getDefaultCustomer(customerNames);
    if (defaultCustomer != null) {
      this.app.state.next({
        user: username,
        customers: customerNames,
        customer: defaultCustomer,
        isAnalyst: (roles as any)[defaultCustomer].analyst,
        isRMAdmin: (roles as any)[defaultCustomer].RMAdmin,
        passwordChangeRequired,
      });
    }

    if (passwordChangeRequired) {
      await this.app.routing.navigateProfilePassword();
      return;
    }

    if (typeof Storage !== "undefined") {
      const segments = sessionStorage.getItem(REDIRECT_TO);
      if (segments !== null) {
        await this.app.routing.navigate(...JSON.parse(segments));
        return;
      }
    }

    if (defaultCustomer !== null) {
      if (
        (roles as any)[defaultCustomer].analyst ||
        (roles as any)[defaultCustomer].RMAdmin
      ) {
        await this.app.routing.navigateRM();
      } else {
        await this.app.routing.navigateCustomer(
          defaultCustomer as CustomerName
        );
      }
    }

    return;
  }

  async checkAuth(): Promise<boolean> {
    const {
      authenticated,
      customerNames,
      token,
      username,
      passwordChangeRequired,
      roles,
      rolesArray,
    } = await checkAuth();

    if (authenticated) {
      if (typeof Storage !== "undefined") {
        localStorage.setItem(TOKEN, EncodingUtils.encodeBase64(token));
      }

      const defaultCustomer = getDefaultCustomer(customerNames);
      if (defaultCustomer != null) {
        this.app.state.next({
          user: username,
          customers: customerNames,
          customer: defaultCustomer,
          isAnalyst: (roles as any)[defaultCustomer].analyst,
          isRMAdmin: (roles as any)[defaultCustomer].RMAdmin,
          passwordChangeRequired,
        });
        this.rolesArray = rolesArray;
        if (
          this.rolesArray.findIndex((role: string) =>
            role.includes(Customer.MTU)
          ) !== -1
        ) {
          /** used for displaying a warning when trying to upload an attachment to posts */
          this.hasMTUPermission = true;
        }
      }
    } else {
      this.app.state.next({ user: null });
    }
    return authenticated;
  }

  async resetPassword(details: ResetPasswordEmailDetails) {
    await resetPassword(details);
  }

  async savePassword(details: ResetPasswordObject) {
    return await saveNewPassword(details);
  }

  async verifyOldPassword(user: string, password: string) {
    return await verifyUsernameAndPassword({ user, password });
  }

  async logout() {
    if (typeof Storage !== "undefined") {
      if (localStorage.getItem(TOKEN) !== null) {
        localStorage.removeItem(TOKEN);
      }

      if (sessionStorage.getItem(REDIRECT_TO) !== null) {
        sessionStorage.removeItem(REDIRECT_TO);
      }

      if (sessionStorage.getItem(NAVIGATION_HISTORY) !== null) {
        sessionStorage.removeItem(NAVIGATION_HISTORY);
      }
      this.app.state.filterByPage = [];
      this.app.filterList.field = null;
      this.app.filterFields.quickFilterSelected = "";
      this.app.filterTree.resetFilterTree();
    }

    this.app.state.next({ model: {}, numberOfUnreadAlerts: undefined });
    await this.app.routing.navigate("login");
  }

  get landing() {
    return this.app.state.landing;
  }

  /** Get role of the logged in user */
  getCurrentUserRole() {
    if (
      this.app.auth.rolesArray.includes(
        `${this.app.customers.expectCurrent}-team`
      )
    ) {
      this.app.auth.isTeam = true;
    } else {
      this.app.auth.isTeam = false;
    }
    if (
      this.app.auth.rolesArray.includes(
        `${this.app.customers.expectCurrent}-admin`
      )
    ) {
      this.app.auth.isAdmin = true;
    } else {
      this.app.auth.isAdmin = false;
    }
    if (
      this.app.auth.rolesArray.includes(
        `${this.app.customers.expectCurrent}-user`
      )
    ) {
      this.app.auth.isUser = true;
    } else {
      this.app.auth.isUser = false;
    }
    if (
      this.app.auth.rolesArray.includes(
        `${this.app.customers.expectCurrent}-RMAdmin`
      )
    ) {
      this.app.auth.isRMAdmin = true;
    } else {
      this.app.auth.isRMAdmin = false;
    }
  }
}
