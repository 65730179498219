import { Language } from "../../../../shared/settings/settings";
import { Thread } from "../../../../shared/models/thread";
import { field } from "../../../../shared/components/thread.settings";
import { getApp } from "../app";

export const dataXmlTemplate = (data: Thread) => {
  const pcnType = data.creator_class
    ? `<pcnType>${data.creator_class}</pcnType>`
    : "";
  const pcnCompany = data.creator
    ? `<pcnCompany>${data.creator}</pcnCompany>`
    : "";
  const pcnMfrName = data.mfrhighlevel
    ? `<pcnMfrName>${data.mfrhighlevel}</pcnMfrName>`
    : "";
  const pcnEmail = data.crtContact
    ? `<pcnEmail>${data.crtContact}</pcnEmail>`
    : "";
  const pcnNumber = data.pcnID
    ? `<pcnNumber>${data.pcnID}</pcnNumber>`
    : data.pcnID === ""
    ? `<pcnNumber>${data.omfNumber}</pcnNumber>`
    : "";
  const pcnIssueDate = data.oDate
    ? `<pcnIssueDate>${data.oDate}</pcnIssueDate>`
    : "";
  const pcnTitle = data.title ? `<pcnTitle>${data.title}</pcnTitle>` : "";
  const pcnRevision = data.pcnRevision
    ? `<pcnRevision>${data.pcnRevision}</pcnRevision>`
    : "";

  return `<masterData pcnFormatVersion="VDMA24903_1.0">
	${pcnNumber}
	${pcnTitle}
	${pcnType}
	${pcnMfrName}
	${pcnIssueDate}
	${pcnRevision}
	<contacts>
		<contact pcnContactType="Manufacturer">
		${pcnCompany}
		${pcnEmail}
		</contact>
    </contacts>
   </masterData>`;
};

const attachmentsXmlHead = `<?xml version="1.0" encoding="utf-8"?>
<attachments xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://www.smartpcn.org/images/files/VDMA24903Schema/attachments">`;
const attachmentsXmlFoot = "</attachments>";

const attachmentXmlTemplate = (attachment: any) => {
  const fileSize = attachment.length
    ? `<filesize>${attachment.length}</filesize>`
    : "";
  return `<attachment attachmentType="FILE">
        <filename>${attachment.name}</filename>
        ${fileSize}
    </attachment>`;
};

export const attachmentsXmlTemplate = (attachments: any[]) => {
  const reduceFn = (acc: string, curr: any) =>
    acc + attachmentXmlTemplate(curr);
  return attachments.reduce(reduceFn, attachmentsXmlHead) + attachmentsXmlFoot;
};

export function itemsXmlTemplate(
  itemValues: Thread[],
  locale: Language
): string {
  const reduceFn = (accumulator: string, item: Thread) =>
    accumulator + makeItemXml(item, locale);
  return itemValues.reduce(reduceFn, "<ItemNumbers>") + "</ItemNumbers>";
}

function makeItemXml(item: Thread, locale: Language): string {
  const app = getApp();
  return `<ItemNumber>
  <itemMfrNumber>${item.crtNumber}</itemMfrNumber>
  ${
    item.drawNumber
      ? "<itemMfrTypeIdent>" + item.drawNumber + "</itemMfrTypeIdent>"
      : ""
  }
  <itemMfrName>${item.omfShortDescr}</itemMfrName>
  <itemCategory>${item.productCategory}</itemCategory>
  ${item.typeIdNum ? "<itemRev>" + item.typeIdNum + "</itemRev>" : ""}
  ${item.dinCode ? "<dinCode>" + item.dinCode + "</dinCode>" : ""}
  ${
    item.dinText
      ? "<dinText>" +
        app.field.getFieldValueAsText(
          app.fieldId.thread.dinText,
          item.dinText
        ) +
        "</dinText>"
      : ""
  }
  <itemLifeCycleData ${item.itemSOP ? "itemSOP=\"" + item.itemSOP + "\" " : ""} ${
    item.itemEOS ? "itemEOS=\"" + item.itemEOS + "\" " : ""
  } itemEOPeffDate="${item.itemEOP}" ${
    item.itemLTD ? "itemLTD=\"" + item.itemLTD + "\" " : ""
  } ${item.itemEOSR ? "itemEOSR=\"" + item.itemEOSR + "\" " : ""}/>
  ${
    item.replmanfItemnumber
      ? "<itemReplacements><itemReplacement><itemMfrReplNumber>" +
        item.replmanfItemnumber +
        "</itemMfrReplNumber></itemReplacement></itemReplacements>"
      : ""
  }
  ${
    item.itemSubData
      ? "<itemSubStructures><itemSubStructure itemSubType=\"TEXT\" itemSubRevision=\"1.0\"><itemSubData>" +
        item.itemSubData +
        "</itemSubData></itemSubStructure></itemSubStructures>"
      : ""
  }
  ${
    item.changeClasses != null
      ? itemChanges(item.changeClasses as any, locale)
      : ""
  }
  </ItemNumber>`;
}

function itemChanges(types: string[], locale: Language): string {
  const options = field.changeClasses.options;
  if (options == null) {
    throw new Error("no options");
  }
  return (
    types.reduce((accumulator, type) => {
      const optionKey = Object.keys(options).filter(
        (opt: any) => opt === type
      )[0];
      const option = (options as any)[optionKey];
      if (option == null) {
        throw new Error("no option");
      }
      const translation = option.text[locale];
      return (
        accumulator +
        `
  <itemChange itemChangeType="${type}">
  <itemChangeText>${translation}</itemChangeText>
  </itemChange>`
      );
    }, "<itemChanges>") + "</itemChanges>"
  );
}

export const changeXmlTemplate = (formData: Thread) =>
  `<difference>
		<pcnChangeDetail>${formData.descr}</pcnChangeDetail>
		<pcnChangeIdentificationMethod>${formData.identificationMethod}</pcnChangeIdentificationMethod>
	</difference>`;
