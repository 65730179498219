const CHUNK_MAX_SIZE = 200000; // KB

export function createChunk(docs: any) {
  const chunk = [];

  for (const doc of docs) {
    if (canBeAddedToChunck(CHUNK_MAX_SIZE, chunk, doc)) {
      chunk.push(doc);
    } else {
      docs.splice(0, chunk.length);
      return { chunk, newDocs: docs };
    }
  }
  if (chunk.length === docs.length) {
    docs.splice(0, chunk.length);
  }
  return { chunk, newDocs: docs };
}

function canBeAddedToChunck(size: number, chunk: any, element: any) {
  const newChunk = Array.from(chunk);
  newChunk.push(element);
  const newChunkSize = new Blob([JSON.stringify(newChunk)]).size;

  if (newChunkSize < size && element != null) {
    return true;
  }
  return false;
}

// sort the documents based on their size for faster saving
function sortDocsBySize(docs: any): any {
  const startTime = Date.now();
  console.log("sorting started");
  const sortedDocs = docs.sort((a: any, b: any) => {
    return (
      new Blob([JSON.stringify(b)]).size - new Blob([JSON.stringify(a)]).size
    );
  });
  console.log("sorting finished");
  const endTime = Date.now();
  console.log("sorting took: ", startTime - endTime, " millisenconds");
  return sortedDocs;
}
