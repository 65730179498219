import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { getApp } from "../../app";
@Component({
  selector: "app-oc-post-item",
  templateUrl: "./OC-post-item.component.html",
  styleUrls: ["./OC-post-item.component.scss"],
})
export class OCPostItemComponent implements OnInit, OnDestroy {
  @Input() post = {} as any;

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    const mandatoryBadgesOrder = [
      "amsysCustomer",
      "seminarParticipants",
      "omCertified",
      "iiom",
      "academia",
    ];

    if (this.post["userBadges"] != null) {
      const badges = this.post["userBadges"];
      if (badges.length > 0) {
        badges.sort(
          (a: any, b: any) =>
            mandatoryBadgesOrder.indexOf(a) - mandatoryBadgesOrder.indexOf(b)
        );
      }
    }
  }

  ngOnDestroy() {}
}
