import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";

@Component({
  selector: "app-pcns",
  templateUrl: "./pcns.component.html",
  styleUrls: ["./pcns.component.scss"],
})
export class PcnsComponent implements OnInit {
  modeSubscription: any;
  pcnColumns = new Set<string>();
  selectedFilters: { columnName: string; value: string }[] = [];
  loading = true;
  columns: string[] = [];
  columnsShort: string[] = [];

  constructor() {}

  app = getApp((app) => {
    this.app = app;
  });

  public async ngOnInit(): Promise<void> {
    // reset pcns array when switching between tabs to avoid displaying some old data until the actual pcns are loading
    this.app.pcns.pcns = [];
    this.app.table.clearSelection();

    this.getListOfColumns();
    await this.app.pcns.getAllPcns();
    this.app.table.clearSelection();
    this.loading = false;
  }

  async markItems() {
    this.app.spinner.showSpinner();
    await this.app.mail.markItemsAsSeen("seen");
    await this.app.pcns.getUnreadPcns();
    this.app.mail.updateUnreadCount.next(true);
    this.app.spinner.hideSpinner();
  }
  async deletePcnDocs() {
    await this.app.mail.deleteNotifications();
    this.app.table.clearSelection();
    this.app.pcns.getAllPcns();
    this.app.filterTable.resetAllFilters.next(true);
    this.app.mail.updateUnreadCount.next(true);
    this.app.routing.navigateMail("thread");
  }

  getListOfColumns() {
    this.columns = this.app.list.pcn.columns;
    this.columnsShort = this.columns.map((field) => field.split(".")[1]);
    this.columns.unshift("pcn.seen");
    this.columns.unshift("select");
    this.columnsShort.unshift("seen");
    this.columnsShort.unshift("select");
    this.columnsShort.push("createCase");
    this.columns.push("createCase");
  }
}
