import { Component, Input, OnInit } from "@angular/core";
import { getApp } from "../../app";
@Component({
  selector: "app-limit",
  templateUrl: "./limit.component.html",
  styleUrls: ["./limit.component.scss"],
})
export class LimitComponent implements OnInit {
  @Input() limit = 0;
  @Input() textInput = "";
  @Input() fieldName = "";
  displayedText = "";
  isShortened = false;
  isTooBig = false;
  private cutPosition = 0;
  private readonly numberOfWordsAllowedAfterLimit = 15;

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}
  ngOnInit() {
    this.displayedText = this.textInput;
    this.isShortened = true;
    this.checkLength();
    this.calculateCutPosition();
    this.cut();
  }

  ngOnChanges() {
    this.isShortened = true;
    this.ngOnInit();
  }

  private checkLength() {
    if (this.textInput) {
      const INPUT_IS_SMALLER_THAN_LIMIT = this.textInput.length < this.limit;
      let hasAFewWordsAfterLimit = false;
      if (this.fieldName === "vehicleNames") {
        hasAFewWordsAfterLimit =
          this.textInput
            .slice(this.limit)
            .split(",")
            .filter((substr) => substr).length <
          this.numberOfWordsAllowedAfterLimit;
      }
      hasAFewWordsAfterLimit =
        this.textInput
          .slice(this.limit)
          .split(" ")
          .filter((substr) => substr).length <
        this.numberOfWordsAllowedAfterLimit;
      if (INPUT_IS_SMALLER_THAN_LIMIT) {
        this.isTooBig = false;
        return;
      }
      if (hasAFewWordsAfterLimit) {
        this.isTooBig = true;
        return;
      }

      this.isTooBig = true;
    }
  }

  private calculateCutPosition() {
    if (!this.isTooBig) {
      return;
    }
    if (this.fieldName === "vehicleNames") {
      this.cutPosition = this.textInput.indexOf(",", this.limit);
    } else {
      this.cutPosition = this.textInput.indexOf(" ", this.limit);
    }
  }

  cut() {
    if (!this.isTooBig) {
      return;
    }
    if (!this.isShortened) {
      this.displayedText = this.textInput;
    } else {
      this.displayedText = this.textInput
        .slice(0, this.cutPosition)
        .concat("...");
    }

    this.isShortened = !this.isShortened;
  }
}
