import { getToken } from "../../auth/token";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "authImage",
})
export class AuthImagePipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  async transform(src: string): Promise<string> {
    const token = getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const imageBlob = await lastValueFrom(
      this.http.get(src, { headers, responseType: "blob" })
    );
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    });
  }
}
