import { Component, Input, Output, EventEmitter } from "@angular/core";
import { getApp } from "../app";
import { Subscription } from "rxjs";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
})
export class PaginatorComponent {
  app = getApp((app) => {
    this.app = app;
  });

  @Input() length = 0;
  @Input() index = 0;
  @Input() type = "";
  @Output() focus = new EventEmitter<number>();

  private _firstPageSubscription: Subscription = new Subscription();

  ngOnInit() {
    this._firstPageSubscription = this.app.paginator.updatePage.subscribe(
      async (value) => {
        if (value) {
          this.index = 0;
        }
      }
    );
  }

  constructor() {}

  get page() {
    return Math.floor(this.index / this.app.paginator.pageSize);
  }

  get start() {
    const { page, app, length } = this;
    return Math.min(page * app.paginator.pageSize, length) + 1;
  }

  get end() {
    const { page, app, length } = this;
    return Math.min((page + 1) * app.paginator.pageSize, length);
  }

  goLast() {
    const i =
      (Math.ceil(this.length / this.app.paginator.pageSize) - 1) *
      this.app.paginator.pageSize;
    this.next(i);
  }

  disabledNext() {
    if (this.end === Number(this.length)) {
      return true;
    }
    return false;
  }

  disabledPrev() {
    if (this.start === 1) {
      return true;
    }
    return false;
  }

  async next(index: number) {
    this.focus.next(Math.max(0, Math.min(this.length - 1, index)));
    const page = Math.round(
      Number(index) / Number(this.app.paginator.pageSize) + 1
    ).toString();

    /** request data paginated on next */
    switch (this.type) {
      case "rm-tree":
        if (Number(page) === 0) {
          return;
        }
        if (Object.keys(this.app.filterTree.filterValues).length > 0) {
          await this.app.filterTree.getTreeSearchResult(page);
        } else {
          await this.app.tree.getItems(page);
        }
        break;
      case "manufacturer-filter":
        if (Number(page) === 0 || index > this.length) {
          return;
        }
        await this.app.manufacturersFilter.getFilterResultBasedOnView(page);
        break;
      case "partsTable":
        if (
          Number(page) > this.app.partsTable.size ||
          Number(page) === 0 ||
          index > this.length
        ) {
          return;
        }
        await this.app.partsTable.getFilterResultBasedOnView(page);
        break;
    }
  }

  ngOnDestroy(): void {
    // Set Subject value to false to prevent retrigger as soon as the page is to first page
    this.app.paginator.updatePage.next(false);

    if (this._firstPageSubscription) {
      this._firstPageSubscription.unsubscribe();
    }
  }
}
