import { AppType, APP_TYPE } from "../app.type";
import { Row } from "../tree/tree.util";
import { Part } from "../../../../shared/models/part";
import { TreeCellServiceType } from "./tree-cell.service.type";
import { Inject, Injectable } from "@angular/core";
import {
  Manufacturer,
  SeUserInfo,
} from "../../../../shared/models/manufacturer";
import { AlertData } from "../../../../shared/models/alert";
import { Thread } from "../../../../shared/models/thread";
import { getAssembliesCompressed } from "../api.service";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Injectable()
export class TreeCellService implements TreeCellServiceType {
  partCount: SeUserInfo = {} as SeUserInfo;
  currentRow: Row | AlertData = {} as Row;
  currentPart: Part = {} as Part;
  currentManufacturer = new Manufacturer();
  topLevelItems: Row[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  getPartNumber(row: Row) {
    if (this.app.tree.isManufacturerRow(row)) {
      return row.manufacturer!.partNumber;
    }
    if (this.app.tree.isPartRow(row)) {
      return row.node!.part.partNumber;
    }
    return "";
  }

  getCase(row: Row) {
    let threadIndex = -1;
    if (
      this.app.thread.threads !== undefined &&
      !this.app.tree.isManufacturerRow(row)
    ) {
      threadIndex = this.app.thread.threads.findIndex(
        (thread) => thread.artNumber === this.getPartNumber(row)
      );
      if (threadIndex !== -1) {
        return true;
      }
      return false;
    }
    if (
      this.app.tree.isManufacturerRow(row) &&
      this.app.thread.threads !== undefined
    ) {
      if (
        row != undefined &&
        row.manufacturer != undefined &&
        row.manufacturer.manufacturerPartNumber != undefined
      ) {
        const mpn = row.manufacturer.manufacturerPartNumber;
        threadIndex = this.app.thread.threads.findIndex(
          (thread) => thread.crtNumber === mpn
        );
        if (
          threadIndex !== -1 &&
          row.manufacturer.manufacturerPartNumber !== ""
        ) {
          return true;
        }
        return false;
      }
    }
    return false;
  }

  getCasesByPartNumber(row: Row) {
    this.app.treeCell.currentRow = row;
    const threads = new Set<Thread>();
    const currentPartNumber = this.getPartNumber(row);
    this.app.treeCell.currentRow = row;
    // set manufactuer in localstorage
    if (this.app.thread.threads !== undefined) {
      this.app.thread.threads.forEach((thread) => {
        if (this.app.tree.isPartRow(row)) {
          if (thread.artNumber === currentPartNumber && thread.crtNumber) {
            threads.add(thread);
          }
        }
        if (this.app.tree.isManufacturerRow(row) && row.manufacturer) {
          if (thread.crtNumber === row.manufacturer.manufacturerPartNumber) {
            threads.add(thread);
          }
        }
        // if (thread.artNumber === currentPartNumber && !thread.crtNumber) {
        //   this.openCase(row);
        // }
      });
      if (Array.from(threads).length !== 0) {
        this.app.thread.threadsByPartNumber = Array.from(threads);
      }
    }
    /** Return the result sorted by omfNumber */
    const sortedData = this.app.thread.threadsByPartNumber.sort(
      (a: any, b: any) => {
        if (a["omfNumber"] < b["omfNumber"]) {
          return -1;
        } else {
          return 1;
        }
      }
    );

    return sortedData;
  }

  newCase(row: Row) {
    if (this.app.tree.isManufacturerRow(row) && row.manufacturer != null) {
      this.currentManufacturer = row.manufacturer;
    }
    sessionStorage.setItem("manufacturer", JSON.stringify(row.manufacturer));

    const partNumber = this.getPartNumber(row);
    if (partNumber != null) {
      this.generateImpactsFromParts(partNumber);
    }

    this.app.routing.navigateNewThread();
  }

  // openCase(row: Row) {
  //   if (this.app.thread.threads !== undefined) {
  //     let thread = this.app.thread.threads.find(
  //       (thread) => thread.artNumber === this.getPartNumber(row)
  //     );
  //     this.modalService.dismissAll();
  //     this.app.routing.navigateThread(thread._id);
  //   }
  // }

  async generateImpactsFromParts(partNumber: string) {
    if (this.app.part.assemblies.length === 0) {
      this.app.part.assemblies = await getAssembliesCompressed(
        this.app.customers.expectCurrent
      );
    }
    const part = await this.app.part.getPartByPartNumberWithoutBuffer(
      partNumber
    );
    this.currentPart = part;
    if (part === null || part === undefined) {
      return;
    }
    const vehicles = await this.app.part.getImpactsFlat(part);
    this.app.spinner.hideSpinner();

    sessionStorage.setItem("fromTree", "true");
    if (vehicles !== null) {
      this.app.state.next({ partsToVehicles: vehicles });
      this.app.impacts.partsToVehiclesSubject.next(true);
    }
  }

  /** open the link in a new tab */
  navigateToMatchingPage(row?: Row | null, manufacturer?: Manufacturer | null) {
    this.app.routing.openInNewTab(this.getMatchingUrl(row, manufacturer));
  }

  getMatchingUrl(row?: Row | null, manufacturer?: Manufacturer | null) {
    let partNumber = "";
    let manufacturerId = "";
    let url = "";
    if (row !== undefined && row !== null) {
      partNumber = this.app.treeCell.getPartNumber(row);
      if (row.manufacturer!._id != null) {
        manufacturerId = row.manufacturer!._id;
      }
    }
    if (manufacturer !== undefined && manufacturer !== null) {
      partNumber = manufacturer.partNumber;
      if (manufacturer._id) {
        manufacturerId = manufacturer._id;
      } else {
        manufacturerId = "";
      }
    }

    // PartNumber should be encoded since it could contain '/' sign
    url =
      "/" +
      this.app.customers.expectCurrent +
      "/matching/" +
      StringUtils.encode(partNumber) +
      "/" +
      manufacturerId;
    return url;
  }

  /** open the link in a new tab */
  navigateToDetailPage(row: Row) {
    this.app.routing.openInNewTab(this.getDetailsUrl(row));
    this.getDetailsUrl(row);
  }

  getDetailsUrl(row: Row) {
    if (this.app.tree.isPartRow(row)) {
      // partNumber should be encoded since it could contain '/' sign
      const partNumber = StringUtils.encode(row.node!.part.partNumber);
      return "/" + this.app.customers.expectCurrent + "/part/" + partNumber;
    } else if (this.app.tree.isManufacturerRow(row)) {
      return (
        "/" +
        this.app.customers.expectCurrent +
        "/manufacturer/" +
        row.manufacturer!._id
      );
    }
  }
}
