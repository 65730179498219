import { DocsServiceType } from "./docs.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { DocIndex, AllDocs } from "../../../../shared/index";
import { Type, Docs } from "../../../../shared/components";
import { DocModel } from "../state/state";
import { touch } from "../utils/app.utils";
import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CustomerName } from "../../../../shared/config/customers";

@Injectable()
export class DocsService implements DocsServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  docsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchResult: any[] = [];

  getDocs<T extends Type>(
    type = this.app.expectType,
    customer = this.app.customer,
    filterFunction: (doc: T) => boolean = this.app.filterList.getFunction(type)
  ): DocIndex<any> {
    try {
      if (type == null || customer == null) {
        return {};
      }
      const buffer = this.app.state.buffer[customer];
      if (buffer == null) {
        return {};
      }
      const docs = buffer.docs[type];
      if (docs == null) {
        return {};
      }
      const result: DocIndex<any> = {};
      Object.keys(docs).forEach((id) => {
        const doc = docs[id];
        if (filterFunction(doc)) {
          result[id] = doc;
        }
      });
      return result;
    } catch (err) {
      return {};
    }
  }

  getDocInternal<T extends Type>(
    id: string,
    type: T,
    customer: CustomerName
  ): Docs[T] | null {
    try {
      if (type == null || customer == null) {
        return null;
      }
      const buffer = this.app.state.buffer[customer];
      if (buffer == null) {
        return null;
      }
      const docs = buffer.docs[type];
      if (docs == null) {
        return null;
      }
      const doc = docs[id];
      if (doc == null) {
        return null;
      }
      // return { ...doc };
      return doc;
    } catch (err) {
      return null;
    }
  }

  getDoc<T extends Type>(
    id: string,
    type: T,
    customer = this.app.customers.current
  ): Docs[T] {
    const doc = this.getDocInternal(id, type, customer);
    if (doc == null) {
      return {} as any;
    }
    return doc;
  }

  setDocs(type: Type, customer: CustomerName, docs: DocIndex<any>) {
    const buffer = touch(
      () => this.app.state.buffer,
      (arg) => {
        arg[customer] = touch(
          () => arg[customer],
          (arg1) => {
            arg1.docs = touch(
              () => arg1.docs,
              (arg2) => {
                arg2[type] = docs;
              }
            );
          }
        );
      }
    );
    this.app.state.next({ buffer });
  }

  addDocs(customer: CustomerName, allDocs: AllDocs) {
    for (const t in allDocs) {
      if (allDocs.hasOwnProperty(t)) {
        const type = t as Type;
        const newDocs = allDocs[type];
        const docs: any = this.getDocs(type, customer);
        Object.keys(newDocs).forEach((id) => {
          delete (docs as any)[id];
        });
        Object.keys(newDocs).forEach((id) => {
          const newClientDoc = newDocs[id];
          if (newClientDoc == null) {
            return;
          }
          docs[id] = newClientDoc;
        });
        this.app.docs.setDocs(type, customer, docs);
      }
    }
  }

  resetDocModel() {
    const model: DocModel = {};
    const { type } = this.app;
    const doc = this.doc;
    Object.keys(doc).forEach((fieldName) => {
      const fieldId = type + "." + fieldName;
      model[fieldId] = doc[fieldName];
    });

    this.app.state.next({ model });
  }

  get doc() {
    try {
      const { customer } = this.app;
      if (customer == null) {
        return {};
      }
      const buffer = this.app.state.buffer[customer];
      if (buffer == null) {
        return {};
      }
      const { type, id } = this.app;
      if (type == null || id == null) {
        return {};
      }
      const docs = buffer.docs[type];
      if (docs == null) {
        return {};
      }
      const doc = docs[id];
      if (doc == null) {
        return {};
      }
      return JSON.parse(JSON.stringify(doc));
    } catch (err) {
      return {};
    }
  }

  get jsonModel() {
    return this.app.state.jsonModel;
  }

  resetJsonModel() {
    const jsonModel = JSON.stringify(this.doc, null, 4);
    this.app.state.next({ jsonModel });
  }

  getIdByDocType(docType: string) {
    switch (docType) {
      case "manufacturer":
        return this.app.manufacturer.currentManufacturer[
          this.app.fieldId.manufacturer._id
        ];
      default:
        return "";
    }
  }

  async getModelByDocType(docType: string, docId: string) {
    switch (docType) {
      case "manufacturer":
        await this.app.manufacturer.getManufacturer(docId);
        break;
      default:
        return;
    }
  }

  getAttachmentHistoryByDocType(docType: string) {
    switch (docType) {
      case "manufacturer":
        return this.app.manufacturer.currentManufacturer[
          "manufacturer.attachmentHistory"
        ];
      default:
        return "";
    }
  }
}
