import JSZip from "jszip";
import { typeCategories } from "../../../../shared/typeCategories";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { AppType } from "../app.type";
import { pcnMapping } from "../pcn-generator/pcn-mapping";
import { typesMap } from "../pcn-generator/smart-pcn-mapping";
import { DocModel } from "../state/state";

class PCNBody {
  [key: string]: any;
  masterData: MasterData = new MasterData();
  difference: Difference = new Difference();
  itemNumbers: ItemNumbers = new ItemNumbers();
  pcnLifeCycleData: PcnLifeCycleData = new PcnLifeCycleData();
  pcnCauseOfChanges: PcnCauseOfChanges = new PcnCauseOfChanges();
  pcnSubStructures: PcnSubStructures = new PcnSubStructures();
}

class MasterData {
  pcnNumber = "";
  pcnTitle = "";
  pcnType: any = null;
  pcnMfrName: any = null;
  pcnRevision = "";
  pcnEmail = "";
  pcnCompany = "";
  pcnState = "";
  pcnStreet = "";
  pcnZipCode = "";
  pcnCity = "";
  pcnCountry = "";
  pcnIssueDate = "";
}

class Difference {
  pcnChangeTitle = "";
  pcnChangeIdentificationMethod = "";
  pcnChangeDetail = "";
}

class ItemNumber {
  [key: string]: any;
  itemMfrNumber = "";
  itemMfrTypeIdent = "";
  itemMfrName = "";
  itemRev = "";
  itemCategory = "";
  itemSubData = "";
  dinCode = "";
  dinText = "";
  artNumber = "";
  itemChangeText = "";
  itemChangeType: string[] = [];
  itemMfrReplNumber = "";
  itemSOP = "";
  itemEOPeffDate = "";
  itemEOS = "";
  itemLTD = "";
  itemEOSR = "";
}

class ItemChange {
  itemChangeText = "";
  itemChangeType = "";
}

class LifeCycle {
  itemSOP = "";
  itemEOS = "";
  itemEOPeffDate = "";
  itemLTD = "";
  itemEOSR = "";
  itemLCI = "";
}

class ItemNumbers {
  itemNumbers: ItemNumber[] = [];
}

class PcnLifeCycleData {
  pcnSOP = "";
  pcnEOS = "";
  pcnEOPeffDate = "";
  pcnLTD = "";
  pcnEOSR = "";
}

class PcnCauseOfChanges {
  pcnChangeTypes: PcnChange[] = [];
}
class PcnChange {
  pcnChangeType = "";
  pcnChangeDescription = "";
}

class PcnSubStructure {
  pcnSubRevision = "";
  pcnSubData = "";
  pcnSubType = "";
}
class PcnSubStructures {
  pcnSubStructures: PcnSubStructure[] = [];
}

class AttObject {
  [key: string]: any;
}

const fieldNames = Object.keys(pcnMapping);

export function parseXML(xml: Document): DocModel {
  const pcnBody = new PCNBody();

  let sectionName: Element = {} as Element;

  /** possible sections found in the xml file */
  const xmlSections: string[] = [];
  const childNodes = xml.documentElement.childNodes;
  childNodes.forEach((child) => xmlSections.push(child.nodeName));

  /** for each section get the xml-section name */
  xmlSections.forEach((s: string) => {
    if (pcnBody[s] != null) {
      /** get the list of fields for the current section (masterData | difference | itemNumbers) */
      const currentKeys = Object.keys(pcnBody[s]);

      /** get the name of the section from the xml file in order to be able to set the value from it */
      sectionName = getSectionName(xml, s);

      /** get the values for each property within the current section */
      /** ex: for the master data, pass through all the fields and get only the values that exist in pcnBody.masterData object */
      fieldNames.forEach((fieldName: string) => {
        if (currentKeys.includes(fieldName)) {
          const textContent: string | null = getTextContent(
            sectionName,
            fieldName
          );
          pcnBody[s][fieldName] = textContent;
        }
      });
    }
  });

  /** set the values from the pcnBody object to a new document of type docModel-> pcn */
  const pcn = createPcnFromXml(xml, pcnBody, xmlSections);
  return pcn;
}

function createPcnFromXml(
  xml: Document,
  pcnBody: PCNBody,
  xmlSections: string[]
) {
  const pcn: DocModel = {};
  xmlSections.forEach((section) => {
    if (section === "masterData") {
      Object.keys(pcnBody["masterData"]).forEach((k) => {
        pcn[k] = (pcnBody["masterData"] as any)[k];
      });
      Object.keys(pcnBody["difference"]).forEach((k) => {
        pcn[k] = (pcnBody["difference"] as any)[k];
      });
    }
    if (section === "ItemNumbers") {
      /** get item numbers as a whole with the elements contained */
      const itemNumbersElement = xml.getElementsByTagName("ItemNumbers")[0];
      /** get all elements of type ItemNumber */
      const itemsContained =
        itemNumbersElement.getElementsByTagName("ItemNumber");
      /** set the result into the pcn.itemNumbers array */
      pcn["ItemNumbers"] = getItems(Array.from(itemsContained));
    }
    if (section === "pcnLifeCycleData") {
      Object.keys(pcnBody["pcnLifeCycleData"]).forEach((k) => {
        pcn[k] = (pcnBody["pcnLifeCycleData"] as any)[k];
      });
    }
    if (section === "pcnSubstructures") {
      const pcnSubStructuresElement =
        xml.getElementsByTagName("pcnSubStructures")[0];
      const pcnSubContained =
        pcnSubStructuresElement.getElementsByTagName("pcnSubStructure");
      pcn["pcnSubStructures"] = getSubStructures(Array.from(pcnSubContained));
    }

    if (section === "pcnCauseOfChanges") {
      const pcnChangeElement = xml.getElementsByTagName("pcnCauseOfChanges")[0];
      const pcnChangeContained =
        pcnChangeElement.getElementsByTagName("pcnChange");
      pcn["pcnCauseOfChanges"] = getChanges(Array.from(pcnChangeContained));
    }
  });
  return pcn;
}

function getSectionName(xml: Document, s: string) {
  switch (s) {
    case "masterData":
      return xml.getElementsByTagName("masterData")[0];
    case "difference":
      return xml.getElementsByTagName("difference")[0];
    case "items":
      return xml.getElementsByTagName("ItemNumbers")[0];
    case "pcnLifeCycleData":
      return xml.getElementsByTagName("pcnLifeCycleData")[0];
    case "pcnSubStructures":
      return xml.getElementsByTagName("pcnSubStructures")[0];
    case "pcnCauseOfChanges":
      return xml.getElementsByTagName("pcnCauseOfChanges")[0];
    default:
      return xml.getElementsByTagName("masterData")[0];
  }
}

function getTextContent(
  xml: Element | Document,
  tagName: string
): string | null {
  try {
    const elements: HTMLCollectionOf<Element> =
      xml.getElementsByTagName(tagName);
    return elements[0].textContent;
  } catch (err) {
    return null;
  }
}

function getItems(items: Element[]) {
  /** used only for getting the keys from the class */
  const itemNumberFields = new ItemNumber();
  /** keys from the itemNumberFields class */
  const currentKeys = Object.keys(itemNumberFields);

  /** create an empty array */
  const itemsArray: ItemNumber[] = [];
  // let subItems: AttObject[] = [];
  items.forEach((item) => {
    const attributes = getNodeAttributesForItem("itemLifeCycleData", item);
    const changeAttributes = getNodeAttributesForChanges("itemChange", item);

    /** for each item in items create a new ItemNumber in order to set its property - values */
    const itemNumber = new ItemNumber();
    fieldNames.forEach((fieldName) => {
      if (currentKeys.includes(fieldName)) {
        const textContent: string | null = getTextContent(item, fieldName);
        itemNumber[fieldName] = textContent;
      }
      if (Object.keys(attributes).includes(fieldName)) {
        itemNumber[fieldName] = attributes[fieldName];
      }
      if (Object.keys(changeAttributes).includes(fieldName)) {
        itemNumber[fieldName] = changeAttributes[fieldName];
      }
    });
    /** add each item to the newly created array */
    itemsArray.push(itemNumber);
  });
  /** return the array so that it can be saved on the pcnBody.itemNumbers[] */
  return itemsArray;
}

function getSubStructures(subStructures: Element[]) {
  const subStructuresFields = new PcnSubStructure();
  const currentKeys = Object.keys(subStructuresFields);
  const subStructureArray: PcnSubStructure[] = [];

  subStructures.forEach((item) => {
    const attributesObjects = item.attributes;

    const subStructure = new PcnSubStructure();
    fieldNames.forEach((fieldName: string) => {
      if (currentKeys.includes(fieldName)) {
        const textContent: string | null = getTextContent(item, fieldName);
        (subStructure as any)[fieldName] = textContent;
      }
      if (fieldName === "pcnSubType" || fieldName === "pcnSubRevision") {
        const fieldAttribute = Array.from(attributesObjects).filter(
          (attr) => attr.name === fieldName
        );
        subStructure[fieldName] = fieldAttribute[0].value;
      }
    });
    subStructureArray.push(subStructure);
  });
  return subStructureArray;
}

function getChanges(causeOfChanges: Element[]) {
  const causeOfChangesFields = new PcnChange();
  const currentKeys = Object.keys(causeOfChangesFields);
  const changeArray: PcnChange[] = [];

  causeOfChanges.forEach((item) => {
    const attributesObjects = item.attributes;

    const change = new PcnChange();
    fieldNames.forEach((fieldName: string) => {
      if (currentKeys.includes(fieldName)) {
        const textContent: string | null = getTextContent(item, fieldName);
        (change as any)[fieldName] = textContent;
      }
      if (fieldName === "pcnChangeType") {
        const fieldAttribute = Array.from(attributesObjects).filter(
          (attr) => attr.name === fieldName
        );
        change[fieldName] = fieldAttribute[0].value;
      }
    });
    changeArray.push(change);
  });
  return changeArray;
}

function getNodeAttributesForItem(nodeName: string, item: Element) {
  const lifeCycleData = new LifeCycle();
  const itemChange = new ItemChange();
  const attObject = new AttObject();
  const attributes = item.getElementsByTagName(nodeName)[0].attributes;
  Object.keys(lifeCycleData).forEach((field) => {
    const fieldAttribute = Array.from(attributes).filter(
      (attr) => attr.name === field
    );
    if (fieldAttribute.length > 0) {
      attObject[field] = fieldAttribute[0].value;
    } else {
      attObject[field] = "";
    }
  });
  Object.keys(itemChange).forEach((field) => {
    const fieldAttribute = Array.from(attributes).filter(
      (attr) => attr.name === field
    );
    if (fieldAttribute.length > 0) {
      attObject[field] = [fieldAttribute[0].value];
    } else {
      attObject[field] = "";
    }
  });
  return attObject;
}

function getNodeAttributesForChanges(nodeName: string, item: Element) {
  const itemChange = new ItemChange();
  const attObject = new AttObject();
  const itemNodes = item.getElementsByTagName(nodeName);
  let attribute: NamedNodeMap;
  const attributes: NamedNodeMap[] = [];
  /** for each item in itemNodes create a new attribute in order to add its values to attributes array */
  Array.from(itemNodes).forEach((itemNode: any) => {
    if (itemNode != null) {
      attribute = itemNode.attributes;
    }
    attributes.push(attribute);
  });

  /** keys from the itemChange class */
  Object.keys(itemChange).forEach((field) => {
    if (attribute != null) {
      const fieldAttribute = Array.from(attribute).filter(
        (attr) => attr.name === field
      );
      /** create a new array in order to set all values from the attribute to the itemChangeType property */
      const fieldValues: string[] = [];
      if (fieldAttribute.length > 0) {
        attributes.forEach((attr) => {
          const value = attr[0].value;
          fieldValues.push(value);
        });
        attObject[field] = fieldValues;
      } else {
        attObject[field] = "";
      }
    }
    /** create a new types array in order to have the new categories names mapped to VMDA standard */
    const optionTypes: string[] = [];
    if (!StringUtils.isNullOrEmpty(attObject[field])) {
      // tslint:disable-next-line
      const VDMATypes: string[] = Object.keys(typeCategories);
      attObject[field].forEach((attr: string) => {
        if (VDMATypes.includes(attr)) {
          optionTypes.push(attr);
        } else {
          const smartType = mapSmartPcnChangeTypes(attr);
          optionTypes.push(smartType);
        }
        attObject[field] = optionTypes;
      });
    }
  });
  return attObject;
}

/** map SmartPCN Types to VDMA Types  */
function mapSmartPcnChangeTypes(val: string) {
  const smartPcnTypes: string[] = Object.keys(typesMap);
  if (smartPcnTypes.indexOf(val) === -1) {
    return "";
  }
  return typesMap[val];
}

export async function getAttachments(file: File, app: AppType): Promise<void> {
  const zipFile = new JSZip();

  await zipFile.loadAsync(file).then((zip) => {
    /** create a new attachment object in order to have the name of the attachment as a refference */
    const attachments: {
      name: string;
      attachment: Blob;
      xmlFile: true;
      data: any;
    }[] = [];

    /** create an index in order to count the number of attachments so that we know when we have to emit the subject */
    let i = 0;

    delete zip.files["Attachments/"];

    Object.values(zip.files).forEach(async (f) => {
      const currentFile = f.name;
      const fileType = currentFile.split(".").pop();
      if (fileType == null) {
        return;
      }
      const fileExtension = fileType.toLowerCase();
      const acceptedFileFormats = [
        "png",
        "jpg",
        "jpeg",
        "svg",
        "gif",
        "pdf",
        "rtf",
        "txt",
        "xml",
        "xls",
        "xlsx",
        "doc",
        "docx",
        "ppt",
        "pptx",
        "odp",
        "msg",
        "xlsm",
        "xsd",
      ];

      if ("PCNbody.xml" in zip.files) {
        app.pcnGenerator.isValidZip = true;
      } else {
        app.pcnGenerator.isValidZip = false;
      }

      let attachmentIncluded = false;
      if (currentFile.includes("Attachments")) {
        attachmentIncluded = true;
      } else {
        attachmentIncluded = false;
      }

      /** length will be -2 because we need to take out the pcnBody.xml file & attachments.xml file */
      const length = Object.keys(zip.files).length - 2;

      if (fileExtension === "xml") {
        /** read xml file */

        await zip.files[currentFile].async("text").then((data: string) => {
          data = data;
          const parser = new DOMParser();
          const doc = parser.parseFromString(data, "text/xml");
          app.importXml.parse(doc, currentFile);

          if (!attachmentIncluded) {
            app.pcnGenerator.attachments = [];
            app.pcnGenerator.createPcnDoc(app.importXml.result);
          }
        });
      }

      if (
        acceptedFileFormats.includes(fileExtension) &&
        fileExtension != "xml"
      ) {
        /** read pdf-files and store them */

        zip.files[currentFile as string]
          .async("blob")
          .then(async (data: BlobPart) => {
            data = data;
            const fileData = new Blob([data], {
              type: `application/${fileExtension}`,
            });

            attachments.push({
              name: currentFile.split("/")[1],
              attachment: fileData,
              xmlFile: true,
              data: "",
            });

            i++;

            if (i === length) {
              /** when the length of iterations through the attachments is the same as length emit an subject
               * so that we know that we have the end result of the attachments and store them in the service
               * The subscription for the subject is done in pcn-generator.component.ts and also the setting of the attachments
               */
              app.pcnGenerator.attachmentSubject.next(attachments);
            }
          });
      }
    });
  });
}
