import { AbstractGraphData } from "../abstract-graph-data";
import { AppType } from "../../app.type";

export class SolutionsCurrentYear extends AbstractGraphData {
  val = 0;

  constructor(private solution: string, app: AppType) {
    super(app);
    this.getSolutionsMap();
  }

  getSolutionsMap() {
    const { app } = this;
    const map = new Map<string, number>();
    app.field
      .getOptions(app.fieldId.post.resolveClass)
      .forEach((resolveClass) => {
        map.set(resolveClass, 0);
      });
    return map;
  }

  add(solution: any) {
    const solutionTypes = this.app.field.getOptions(
      this.app.fieldId.post.resolveClass
    );
    solutionTypes.forEach((solType) => {
      if (solType === solution.resolveClass) {
        this.val++;
      }
    });
  }

  get data() {
    return {
      name: this.app.field.getOptionText(
        this.app.fieldId.post.resolveClass,
        this.solution
      ),
      series: [
        {
          name: this.app.field.getOptionText(
            this.app.fieldId.post.resolveClass,
            this.solution
          ),
          value: this.val,
        },
      ],
    };
  }
}
