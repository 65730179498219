import { Inject, Injectable } from "@angular/core";
import { AppType, APP_TYPE } from "../app.type";
import { ApplicationAreaServiceType } from "./application-area.service.type";
import { ApplicationArea } from "../../../../shared/models/application-area";
import {
  getApplicationAreaOptions,
  updateApplicationAreaOptions,
} from "../api.service";
import { applicationAreaPredefinedOptions } from "../../../../shared/components/thread.settings";

@Injectable()
export class ApplicationAreaService implements ApplicationAreaServiceType {
  applicationAreaOptions: string[] = [];
  applicationAreaDoc: ApplicationArea[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getApplicationArea() {
    await this.getApplicationAreaFieldOptions();
    if (this.applicationAreaOptions.length === 0) {
      this.updateApplicationAreaOptions(applicationAreaPredefinedOptions);
    }
  }

  async getApplicationAreaFieldOptions() {
    this.applicationAreaDoc = await getApplicationAreaOptions(
      this.app.customers.expectCurrent
    );
    if (this.applicationAreaDoc.length > 0) {
      this.applicationAreaOptions = this.applicationAreaDoc[0].applicationArea;
    }
  }

  async updateApplicationAreaOptions(options: string[]) {
    const doc: ApplicationArea = {
      type: "applicationArea",
      applicationArea: [],
    };
    if (this.applicationAreaOptions.length === 0) {
      // create doc
      doc.applicationArea = options;
    } else {
      doc._id = this.applicationAreaDoc[0]._id;
      doc._rev = this.applicationAreaDoc[0]._rev;
      const newOptions = this.applicationAreaOptions.concat(options);
      doc.applicationArea = newOptions;
    }
    await updateApplicationAreaOptions(this.app.customers.expectCurrent, doc);
    await this.getApplicationAreaFieldOptions();
  }
}
