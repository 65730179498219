import { APP_TYPE, AppType } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { OperationsServiceType } from "./operations.service.type";
import { WorkHistory } from "../../../../shared/models/workHistory";
import { getWorkCount, getWorkHistory, triggerWork } from "../api.service";

export class WorkRequest {
  customer = "";
  workType = "";
  user = "";
}

export class WorkCountResponse {
  count: WorkCount = new WorkCount();
}

export class WorkCount {
  limitCount = "";
  usedCount = "";
  remainingCount = "";
  createDate = "";
  expirationDate = "";
}

@Injectable()
export class OperationsService implements OperationsServiceType {
  constructor(@Inject(APP_TYPE) private _app: AppType) {}

  async getWorkHistory(): Promise<WorkHistory[]> {
    this._app.spinner.showSpinner();

    const history = await getWorkHistory(this._app.customers.expectCurrent);

    history.forEach((ea) => {
      if (ea.complete_time == null) {
        ea.duration = this._app.getText(`operations.inProgressMessage`);
      } else {
        const duration = Number(ea.complete_time) - Number(ea.create_time);
        ea.duration = `${(duration / 1000 / 60).toFixed(3).toString()} min`;
      }
    });

    this._app.spinner.hideSpinner();

    return history;
  }

  async getWorkCount(): Promise<WorkCount> {
    const count = await getWorkCount(this._app.customers.expectCurrent);

    return count.count;
  }

  async triggerWork(
    workType: string,
    docType?: string,
    docIds?: string[]
  ): Promise<WorkRequest> {
    const dataProvider = this._app.customers.dataProvider;
    const result = await triggerWork(
      this._app.customers.expectCurrent,
      dataProvider,
      workType,
      docType,
      docIds
    );

    if (workType === "delete-docs") {
      this._app.state.next({
        hasSuccess: true,
        successText: this._app.text.operations.deleteDocsDescription,
      });
      setTimeout(() => {
        this._app.state.next({ hasSuccess: false });
      }, 6000);
    } else {
      this._app.state.successText = `${this.getTypeText(
        workType
      )} has been triggered successfully!`;
      this._app.state.hasSuccess = true;
      setTimeout(() => {
        this._app.state.next({ hasSuccess: false });
      }, 2000);
    }

    return result;
  }

  getOperationsColumns(): string[] {
    return this._app.list.operations.columns.map(
      (field) => field.split(".")[1]
    );
  }

  isSpecialColumn(column: string): boolean {
    if (
      column === "create_time" ||
      column === "complete_time" ||
      column === "work_type" ||
      column === "work_log"
    ) {
      return true;
    }

    return false;
  }

  getTypeText(type: string): string {
    switch (type) {
      case "all":
        type = "all";
        break;
      case "match":
        type = "matchParts";
        break;
      case "request-details":
        type = "requestDetails";
        break;
      case "update-details":
        type = "updateDetails";
        break;
      case "generate-tree":
        type = "generateTree";
        break;
      case "delete-docs":
        type = "deleteDocs";
        break;
      case "build-bom":
        type = "buildBOM";
        break;
      case "update-impacts":
        type = "updateImpacts";
        break;
      case "generate-alerts":
        type = "generateAlerts";
        break;
    }

    return this._app.getText(`operations.${type}`);
  }
}
