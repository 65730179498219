import { AlertData } from "../../../../shared/models/alert";
import { AlertsServiceType } from "./alerts.service.type";
import { APP_TYPE, AppType } from "../app.type";
import { getAlerts, getDocsByType, getUnreadAlerts } from "../api.service";
import { Inject, Injectable } from "@angular/core";
import { ManufacturerService } from "../manufacturer/manufacturer.service";
import { Customer } from "../../../../shared/types/customers";
import { writeConfigFile } from "../export/config-export";

@Injectable()
export class AlertsService implements AlertsServiceType {
  allAlerts: AlertData[] = [];
  selected = new Set<AlertData>();
  currentSelected: AlertData = {} as AlertData;
  columns: string[] = [];
  columnsShort: string[] = [];

  cleanAlerts: AlertData[] = [];
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getAlerts(alertType: string): Promise<void> {
    this.app.spinner.showSpinner();
    this.app.state.sortField = "alert.issueDate";
    this.allAlerts = await getAlerts(
      this.app.customers.expectCurrent,
      alertType
    );
    this.cleanAlerts = this.allAlerts;
    this.app.spinner.hideSpinner();
    for (const alert of this.allAlerts) {
      if (this.checkCapitalLetter(alert.statusChange)) {
        alert.statusChange = ManufacturerService.parseStatus(
          alert.statusChange
        );
      }
      if (this.checkCapitalLetter(alert.oldSeStatus)) {
        alert.oldSeStatus = ManufacturerService.parseStatus(alert.oldSeStatus);
      }
      if (alert.typeOfChange) {
        alert.criticality = this.app.mail.setCriticalityBasedOnType(alert);
      }
    }
  }

  getAlertColumns() {
    switch (this.app.mail.tab) {
      case "alert":
        this.columns = this.app.list.alert.alertColumns;
        this.columnsShort = this.app.list.alert.alertColumns.map(
          (field: string) => field.split(".")[1]
        );
        break;
      case "changeAlert":
        this.columns = this.app.list.alert.changeAlertColumns;
        this.columnsShort = this.app.list.alert.changeAlertColumns.map(
          (field: string) => field.split(".")[1]
        );
        break;
      case "leadTimeAlert":
        this.columns = this.app.list.alert.leadTimeAlertColumns;
        this.columnsShort = this.app.list.alert.leadTimeAlertColumns.map(
          (field: string) => field.split(".")[1]
        );
        break;
      case "inventoryMonitoringAlert":
        this.columns = this.app.list.alert.inventoryMonitoringColumns;
        this.columnsShort = this.app.list.alert.inventoryMonitoringColumns.map(
          (field: string) => field.split(".")[1]
        );
        break;
      default:
        this.columns = [];
    }

    if (this.app.permission.alert.canMarkAlert) {
      this.columns.unshift("select");
      this.columnsShort.unshift("select");
    }
    if (!this.app.permission.RM.isAnalyst && !this.app.auth.isRMAdmin) {
      this.columns.push("createCase");
      this.columnsShort.push("createCase");
    }
  }

  async getUnreadAlerts(): Promise<void> {
    if (
      this.app.customer !== Customer.OMP &&
      this.app.permission.alert.alertPermission
    ) {
      const unreadAlerts = await getUnreadAlerts(
        this.app.customers.expectCurrent
      );

      this.updateCounter(
        unreadAlerts.numberOfAlerts,
        unreadAlerts.numberOfChangeAlerts,
        unreadAlerts.numberOfLeadTimeAlerts,
        unreadAlerts.numberOfInvMonAlerts
      );
    }
  }

  async markAsSeeen(alert?: AlertData) {
    this.app.spinner.showSpinner();
    let alerts: AlertData[] = [];
    if (alert != null) {
      this.app.table.selected.add(alert);
    } else {
      alerts = Array.from(this.app.table.selected);
    }
    if (
      this.app.state.inboxType === "changeAlert" ||
      this.app.state.inboxType === "alert" ||
      this.app.state.inboxType === "leadTimeAlert"
    ) {
      alerts.forEach((a: AlertData) => {
        a.alertType = this.app.state.inboxType;
      });
    }
    if (this.app.user == null) {
      return;
    }
    await this.app.mail.markItemsAsSeen("checkedByOm");
    this.app.filterTable.resetAllFilters.next(true);
    await this.getAlerts(this.app.mail.tab);
    await this.getUnreadAlerts();
    this.app.mail.updateUnreadCount.next(true);
    this.app.spinner.hideSpinner();
    this.selected = new Set<AlertData>();
  }

  async deleteAlerts() {
    await this.app.mail.deleteNotifications();
    this.app.filterTable.resetAllFilters.next(true);
    await this.getAlerts(this.app.mail.tab);
    await this.getUnreadAlerts();
    this.app.mail.updateUnreadCount.next(true);
  }

  toggleSelect(alert: AlertData) {
    if (this.selected.has(alert)) {
      this.selected.delete(alert);
    } else {
      this.selected.add(alert);
    }
  }

  async generateAlertsForJsonImport(docs: any) {
    const alertDocs: any[] = [];
    const alerts = await getDocsByType(
      this.app.customers.expectCurrent,
      "alert"
    );
    docs.forEach(async (doc: any) => {
      let alertDoc = {} as AlertData;
      const existingDoc = await this.app.import.checkIfExists(alerts, doc);
      if (existingDoc !== undefined) {
        alertDoc = this.updateFieldsFromImport(doc, existingDoc);
      } else {
        alertDoc = doc;
      }
      alertDocs.push(alertDoc);
    });
    if (this.app.import.type === "all-config") {
      this.app.state.importBuffer.docs.data.push(alertDocs);
    } else {
      this.app.state.importBuffer.docs.data = alertDocs;
    }
    return alertDocs;
  }

  updateFieldsFromImport(doc: AlertData, existingDoc: AlertData) {
    Object.keys(doc).forEach((key) => {
      existingDoc[key] = doc[key];
    });
    return existingDoc;
  }

  setTypeOfChange(typeOfChange: string | string[]): string[] {
    const results = [];
    let typeOfChangeArray: string[] = [];
    if (!Array.isArray(typeOfChange)) {
      typeOfChangeArray = typeOfChange.split(",");
    } else {
      typeOfChangeArray = typeOfChange;
    }

    for (const type of typeOfChangeArray) {
      const typeOfChangeValue: string = this.parseTypeOfChange(type);
      results.push(typeOfChangeValue);
    }
    return [...new Set(results)];
  }

  private parseTypeOfChange(type: string) {
    switch (type) {
      case "Alert":
      case "Temporary Suspend":
      case "Other":
        return "ALERT";
      case "Assembly Process":
      case "Wafer Process":
      case "Process Technology":
      case "Storage and Handling":
      case "Pre Qual - Process Change":
      case "Assembly Tooling Addition":
      case "Assembly Tooling Change":
      case "New Subcontractor":
      case "Production Continuity":
        return "PPROC";
      case "Assembly Site":
      case "Wafer Site":
      case "Assembly Capacity Addition":
      case "Assembly Capacity Change":
      case "Assembly Site Addition":
      case "Assembly Site Removed":
      case "Assembly Site Transfer":
      case "Country of Origin Addition":
      case "Country of Origin Change":
      case "Factory Addition":
      case "Factory Removed":
      case "Factory Transfer":
      case "Subcontractor Addition":
      case "Subcontractor Removed":
      case "Subcontractor Transfer":
      case "Wafer Capacity Addition":
      case "Wafer Capacity Change":
      case "Wafer Site addition":
      case "Wafer Site Removed":
      case "Wafer Site Transfer":
      case "Wafer Tooling Addition":
      case "Wafer Tooling Change":
        return "PSITE";
      case "Cavity Ids":
      case "Lead Free":
      case "Materials":
      case "Lead Finish":
      case "Material Change":
      case "Material":
      case "Lead finish":
      case "Environmental Announcement":
        return "MATER";
      case "Characteristics Change":
      case "Characteristics":
      case "Design Change":
      case "Quality Improvement":
        return "CHARA";
      case "Datasheet Specifications":
      case "Certificates and Approvals":
      case "Name Change":
      case "Nomenclature Change":
      case "Nomenclature Change-Other Name":
      case "Datasheet Change":
        return "DOCUM";
      case "Errata":
        return "CORR";
      case "Fit":
        return "FIT";
      case "Form":
      case "Cosmetic change":
        return "FORM";
      case "Function":
      case "Pre Qual - Design Change":
        return "FUNCT";
      case "Labeling":
      case "Marking Molding":
      case "Marking Change":
        return "LABEL";
      case "Manufacturing Request":
      case "New Production":
      case "New Product Release":
      case "New Release":
      case "Alternative":
        return "PRODS";
      case "Not Recommended For New Design":
      case "Possible Future EOL":
        return "NRND";
      case "Obsolescence Notices":
      case "Removed from Cost Book-NC/NR":
      case "Removed from Price List-NCNR":
      case "Obsolescence":
        return "PDN";
      case "Packing":
        return "PACKA";
      case "Part Numbering":
      case "Product Code":
        return "ORCOD";
      case "PCN Cancelled":
        return "CANCN";
      case "Reactivation Notices":
      case "Removed from Cost Book-NC/NR Reversal":
      case "Reactivation":
      case "Removed from Price List-NCNR Reversal":
        return "CANDN";
      case "Recall":
      case "Product Re-call":
      case "Issues and Defect":
        return "RECA";
      case "Shipping":
      case "Agreement and Price Changes":
      case "Delivery Disruption":
      case "Supplier Replacement":
        return "SHIP";
      case "Software Change":
        return "SOFTW";
      case "Test Process":
      case "Test Tooling Addition":
      case "Test Tooling Change":
      case "Tool Transfer":
        return "TESTP";
      case "Test Site":
      case "Test Site addition":
      case "Test Site Removed":
      case "Test Site Transfer":
      case "Test Capacity Addition":
      case "Test Capacity Change":
        return "TESTS";
      case "Vendor Acquisition":
      case "Acquisition (Full)":
      case "Acquisition (Partial)":
        return "MANAQ";
      default:
        return "UNKNOWN";
    }
  }

  private checkCapitalLetter(word: string | undefined) {
    if (word === "" || word === null || word === undefined) {
      return true;
    } else if (word.slice(0, 1) === word.slice(0, 1).toUpperCase()) {
      return true;
    } else {
      return false;
    }
  }

  private updateCounter(
    countAlerts: number,
    countChangeAlerts: number,
    countLeadTimeAlerts: number,
    countInvMonAlerts: number
  ): void {
    this.app.state.next({
      numberOfUnreadAlerts: countAlerts,
      numberOfUnreadChangeAlerts: countChangeAlerts,
      numberOfUnreadLeadTimeAlerts: countLeadTimeAlerts,
      numberOfUnreadInvMonAlerts: countInvMonAlerts,
    });
  }

  async exportData(mode: string) {
    await writeConfigFile(this.app, mode);
  }
}
