import { APP_TYPE, AppType } from "../app.type";
import { BehaviorSubject } from "rxjs";
import { DepartmentGraph } from "./department/department-graph";
import { DinCodeGraph } from "./din-code/din-code-graph";
import { FleetGraph } from "./fleet/fleet-graph";
import { OMApproachesCurrentYearGraph } from "./approaches-current-year/approaches-current-year-graph";
import { OMApproachesPerYearGraph } from "./approaches-per-year/approaches-per-year-graph";
import { GraphName } from "../../../../shared/types/graphName";
import { GraphsServiceType } from "./graphs.service.type";
import { GraphType } from "./graph.type";
import { Inject, Injectable } from "@angular/core";
import { ResponsibleGraph } from "./responsible/responsible-graph";
import { StatusGraph } from "./status/status-graph";
import { VehicleGraph } from "./vehicles/vehicle-graph";
import { TotalCostLTBGraph } from "./totalCostLTB/totalCost-graph";
import {
  getAllSolutions,
  getGraphsThreads,
  getImpacts,
  getPartsCompressed,
  getStatusHistory,
  getTotalCostLTB,
} from "../api.service";
import { HistoryChartResponse } from "../history/history";
import { Impact } from "../../../../shared/models/impact";
import { TotalCostDoc } from "../../../../shared/models/totalCostLTB";
import { SolutionsCurrentYearGraph } from "./solutions-current-year/solutions-current-year-graph";
import { SolutionsGraph } from "./solutions/solutions-graph";
import { Customer } from "../../../../shared/types/customers";

@Injectable()
export class GraphsService implements GraphsServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  nameSubject: BehaviorSubject<GraphName> = new BehaviorSubject<GraphName>(
    GraphName.Vehicles
  );
  allThreads: any[] = [];
  allImpacts: Impact[] = [];
  totalCost: TotalCostDoc = new TotalCostDoc();
  statusHistory: HistoryChartResponse[] = [];
  solutions: any[] = [];

  get name() {
    return this.app.state.graphName;
  }

  set name(graphName: GraphName) {
    this.app.state.next({ graphName });
    this.nameSubject.next(graphName);
  }

  async getThreads() {
    this.allThreads = await getGraphsThreads(this.app.customers.expectCurrent);
  }
  async getImpacts() {
    this.allImpacts = await getImpacts(this.app.customers.expectCurrent);
    // show only impacts of type train for NS customer
    if (this.app.customers.expectCurrent === Customer.NS) {
      this.allImpacts = this.allImpacts.filter(
        (impact) => impact.impactType === "train"
      );
    }
    if (this.app.customers.expectCurrent === Customer.CEOTRONICS) {
      const allParts = await getPartsCompressed(Customer.CEOTRONICS);
      allParts.parts = allParts.parts.filter(
        (part) => part.obsolescenceManagement === "true"
      );
      this.allImpacts = this.allImpacts.filter((impact) =>
        allParts.parts.some((part) => impact.artNumber === part.partNumber)
      );
    }
  }
  async getTotalCostLTB() {
    this.totalCost = await getTotalCostLTB(this.app.customers.expectCurrent);
  }
  async getThreadApproaches() {
    this.allThreads = await getGraphsThreads(this.app.customers.expectCurrent);
  }
  async getSolutions() {
    this.solutions = await getAllSolutions(this.app.customers.expectCurrent);
  }

  async getChartStatusHistory() {
    const statusHistory = await getStatusHistory(
      this.app.customers.expectCurrent
    );
    this.statusHistory = statusHistory.map((h: any) => h.value);
  }

  get names(): GraphName[] {
    return this.app.list.graphs.graphs as GraphName[];
  }

  get sortBy() {
    return this.app.state.graphsSortBy;
  }

  set sortBy(graphsSortBy: string | null) {
    this.app.state.next({ graphsSortBy });
  }

  getSortOptions(name = this.name) {
    return this.getGraph(name).sortOptions;
  }

  getDimensions(name = this.name) {
    return this.getGraph(name).dimensions;
  }

  getConfig(name = this.name) {
    return this.getGraph(name).config;
  }

  async getData(name = this.name) {
    return await this.getGraph(name).getDataAsync();
  }

  private getGraph(name = this.name): GraphType {
    switch (name) {
      case GraphName.Vehicles:
        return new VehicleGraph(this.app);
      case GraphName.DinCodes:
        return new DinCodeGraph(this.app);
      case GraphName.Fleet:
        return new FleetGraph(this.app);
      case GraphName.Status:
        return new StatusGraph(this.app);
      case GraphName.Departments:
        return new DepartmentGraph(this.app);
      case GraphName.Responsibles:
        return new ResponsibleGraph(this.app);
      case GraphName.TotalCostLTB:
        return new TotalCostLTBGraph(this.app);
      case GraphName.OMApproachesCurrentYear:
        return new OMApproachesCurrentYearGraph(this.app);
      case GraphName.OMApproachesPerYear:
        return new OMApproachesPerYearGraph(this.app);
      case GraphName.SolutionsCurrentYear:
        return new SolutionsCurrentYearGraph(this.app);
      case GraphName.SolutionsPerYear:
        return new SolutionsGraph(this.app);
      default:
        throw new Error("could not find a graph matching the given route");
    }
  }
}
