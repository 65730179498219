import { Component, Input } from "@angular/core";
import { getApp } from "../../app";
import { Subscription } from "rxjs";
import { getManufacturersByPartNumber } from "../../api.service";
import { Manufacturer } from "../../../../../shared/models/manufacturer";
import { DataProvider } from "../../../../../shared/constants/dataProvider";

@Component({
  selector: "app-replacements-table",
  templateUrl: "./replacements-table.component.html",
  styleUrls: ["./replacements-table.component.scss"],
})
export class ReplacementsTableComponent {
  @Input() showPaginator = true;
  requestCompleted = false;
  selectedFilters: { columnName: string; value: string }[] = [];

  app = getApp((app) => {
    this.app = app;
  });
  cleanColumns: string[] = [];
  requestingData = new Subscription();
  showLoading = true;
  showNoResults = false;
  matchedItemColumns: string[] = [
    this.app.fieldId.manufacturer.manufacturerPartNumber,
    this.app.fieldId.manufacturer.name,
    this.app.fieldId.manufacturer.manufacturerPartDescription,
    this.app.fieldId.manufacturer.datasheet,
    this.app.fieldId.manufacturer.obsolescenceStatus,
    this.app.fieldId.manufacturer.euRoHS,
  ];
  manufacturers: Manufacturer[] = [];
  dataProviderId = "";

  async ngOnInit() {
    if (this.app.customers.dataProvider === DataProvider.SE) {
      this.dataProviderId =
        this.app.manufacturer.currentManufacturer["manufacturer.seId"];
    }
    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      this.dataProviderId =
        this.app.manufacturer.currentManufacturer["manufacturer.z2DataId"];
    }
    this.app.manufacturerUtils.type = "replacements";
    this.matchedItemColumns = this.matchedItemColumns.map(
      (field) => field.split(".")[1]
    );

    this.getReplacementsTableColumns();
    await this.app.manufacturerUtils.getSubstitutes(this.dataProviderId);
    this.requestingData = this.app.manufacturerUtils.resultExists.subscribe(
      (value: boolean) => {
        if (value) {
          this.showLoading = false;
        } else {
          this.showNoResults = true;
        }
      }
    );

    await this.checkIfExist();
  }

  // check if the mpn has been assigned to a cpn
  async checkIfExist() {
    this.manufacturers = await getManufacturersByPartNumber(
      this.app.customers.expectCurrent,
      this.app.manufacturer.currentManufacturer["manufacturer.partNumber"],
      true
    );
    const commonMPNs = this.app.manufacturerUtils.substitutes.filter((match) =>
      this.manufacturers.some(
        (mpn) => mpn.manufacturerPartNumber === match.manufacturerPartNumber
      )
    );
    commonMPNs.forEach((mpn: any) => (mpn.isAdded = true));
  }

  getReplacementsTableColumns() {
    let list: string[] = [];
    if (this.app.customers.dataProvider === DataProvider.SE) {
      list = this.app.list.manufacturer.loadReplacements;
    }
    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      list = this.app.list.manufacturer.loadZ2DataReplacements;
    }
    this.cleanColumns = list.map((field) => field.split(".")[1]);
    this.cleanColumns.unshift("createManufacturer");
  }

  showValue(column: string, value: string) {
    if (column === "estimatedYearsToEOL" && value === "0.0") {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    if (this.requestingData) {
      this.requestingData.unsubscribe();
    }
  }
}
