import { AbstractGraphData } from "../abstract-graph-data";
import { AppType } from "../../app.type";
import { StringUtils } from "../../../../../shared/utils/string.utils";

export class OMApproachesCurrentYear extends AbstractGraphData {
  reactive = 0;
  proactive = 0;
  unknown = 0;

  constructor(private month: string, app: AppType) {
    super(app);
    this.getApproachMap();
  }

  getApproachMap() {
    const { app } = this;
    const map = new Map<string, number>();
    app.field.getOptions(app.fieldId.thread.omApproach).forEach((approach) => {
      map.set(approach, 0);
    });
    // add a new options which is not defined for this field
    map.set("unknown", 0);
    console.log("map in get approcah map: ", map);
    return map;
  }

  add(thread: any) {
    if (thread.omApproach === "proactive") {
      this.proactive++;
    }
    if (thread.omApproach === "reactive") {
      this.reactive++;
    }
    if (StringUtils.isNullOrEmpty(thread.omApproach)) {
      this.unknown++;
    }
    console.log("a");
  }

  get data() {
    return {
      name: this.month,
      series: [
        {
          name: this.app.field.getOptionText(
            this.app.fieldId.thread.omApproach,
            "reactive"
          ),
          value: this.reactive,
        },
        {
          name: this.app.field.getOptionText(
            this.app.fieldId.thread.omApproach,
            "proactive"
          ),
          value: this.proactive,
        },
        { name: this.app.text.graphs.unknown, value: this.unknown },
      ],
    };
  }
}
