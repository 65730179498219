<ng-template #content let-modal>
  <div class="modal-header">
    <div class="col-md-12">
      <span class="modal-title">{{ modalTitle }}</span>
      <button class="btn pull-right" (click)="modal.dismiss('CANCEL')">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <div class="tableFixHead">
        <table class="table table-striped spacing-table">
          <thead>
            <tr>
              <th class="p-1" *ngFor="let column of columns">
                <div class="d-inline-flex">
                  <div
                    [ngClass]="{
                      'resize-table': app.table.canColumnBeResized(column)
                    }"
                  >
                    <span>
                      {{ column }}
                    </span>
                    <div *ngIf="column === 'Select'">
                      <i
                        class="far fa-square"
                        *ngIf="app.table.selected.size === 0"
                        [ngClass]="{ disabled: allTasksCreated }"
                        (click)="selectAll(predefinedTasks)"
                      ></i>
                      <i
                        class="far fa-check-square"
                        *ngIf="app.table.selected.size > 0"
                        (click)="app.table.clearSelection()"
                      ></i>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let task of predefinedTasks"
              class="pointer"
              [ngClass]="{ isCreated: task.isCreated }"
            >
              <td class="p-1">
                <button
                  class="btn btn-hidden pt-0 ps-0"
                  [disabled]="task.isCreated"
                  (click)="
                    app.table.toggleSelect(task); $event.stopPropagation()
                  "
                >
                  <i
                    class="far fa-check-square"
                    *ngIf="app.table.selected.has(task)"
                  ></i>
                  <i
                    class="far fa-square"
                    *ngIf="!app.table.selected.has(task)"
                  ></i>
                </button>
              </td>
              <td class="p-1">{{ task.titleDE }}</td>
              <td class="p-1">{{ task.descriptionDE }}</td>
              <td class="p-1">
                {{
                  app.field.getOptionText(
                    app.fieldId.impact.projectNumber,
                    task.projectNumber
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-ok text-center m-1"
      [disabled]="app.table.selected.size === 0"
      (click)="save()"
    >
      {{ app.text.post.saveTasks }}
    </button>
  </div>
</ng-template>
