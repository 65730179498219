import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Customer } from "../../../../shared/types/customers";
import { JOBTITLE_OM } from "../users/users.service.type";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
})
export class UserSettingsComponent implements OnInit {
  customerEmailsList: number[] = [];
  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    this.getCustomerEmailsList();
    const userName =
      this.app.profile.user != null
        ? this.app.profile.user
        : this.app.users.currentUserName;
    if (userName != null) {
      await this.app.userSettings.getCurrentUserSettings(userName);
    }
  }

  getCustomerEmailsList() {
    this.app.list.userSettings.emails.forEach((email) => {
      this.customerEmailsList.push(Number(email));
    });
    if (
      this.app.customers.expectCurrent === Customer.KNDS &&
      this.app.users.currentUser.jobTitle === JOBTITLE_OM
    ) {
      this.customerEmailsList.push(Number(21));
    }
  }

  getText(emailNumber: string) {
    const email = Number(emailNumber);
    switch (email) {
      case 1:
        return this.app.text.userSettings[1];
      case 2:
        return this.app.text.userSettings[2];
      case 3:
        return this.app.text.userSettings[3];
      case 4:
        return this.app.text.userSettings[4];
      case 5:
        return this.app.text.userSettings[5];
      case 6:
        return this.app.text.userSettings[6];
      case 7:
        return this.app.text.userSettings[7];
      case 8:
        return this.app.text.userSettings[8];
      case 9:
        return this.app.text.userSettings[9];
      case 10:
        return this.app.text.userSettings[10];
      case 11:
        return this.app.text.userSettings[11];
      case 12:
        return this.app.text.userSettings[12];
      case 13:
        return this.app.text.userSettings[13];
      case 14:
        return this.app.text.userSettings[14];
      case 15:
        return this.app.text.userSettings[15];
      case 16:
        return this.app.text.userSettings[16];
      case 17:
        return this.app.text.userSettings[17];
      case 18:
        return this.app.text.userSettings[18];
      case 21:
        return this.app.text.userSettings[21];
      case 22:
        return this.app.text.userSettings[22];
      case 23:
        return this.app.text.userSettings[23];
      case 24:
        return this.app.text.userSettings[24];
      case 25:
        return this.app.text.userSettings[25];
      case 27:
        return this.app.text.userSettings[27];
    }
  }
}
