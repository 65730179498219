import { APP_TYPE, AppType } from "../app.type";
import { BehaviorSubject } from "rxjs";
import { InboxType } from "../state/state";
import { Inject, Injectable } from "@angular/core";
import { MailServiceType } from "./mail.service.type";
import { bulkDocsByType, markDocSeen } from "../api.service";
import { Type } from "../../../../shared/components";
import { typeCategories } from "../../../../shared/typeCategories";

const LOW = "low";
const MEDIUM = "medium";
const HIGH = "high";
const VERY_HIGH = "very high";

@Injectable()
export class MailService implements MailServiceType {
  updateUnreadCount: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  set tab(inboxType: InboxType) {
    this.app.state.next({ inboxType });
  }

  get tab() {
    return this.app.state.inboxType;
  }

  get unread() {
    const numberOfPcns =
      this.app.state.numberOfUnreadPcns !== undefined &&
      !this.app.permission.RM.isAnalyst
        ? this.app.state.numberOfUnreadPcns
        : 0;

    const numberOfUnreadMails =
      this.app.state.numberOfChanges !== undefined &&
      !this.app.permission.RM.isAnalyst
        ? this.app.state.numberOfChanges
        : 0;

    const numberOfUnreadAlerts =
      this.app.state.numberOfUnreadAlerts !== undefined
        ? this.app.state.numberOfUnreadAlerts
        : 0;

    const numberOfUnreadChangeAlerts =
      this.app.state.numberOfUnreadChangeAlerts !== undefined
        ? this.app.state.numberOfUnreadChangeAlerts
        : 0;

    const numberOfUnreadLeadTimeAlerts =
      this.app.state.numberOfUnreadLeadTimeAlerts !== undefined
        ? this.app.state.numberOfUnreadLeadTimeAlerts
        : 0;

    const numberOfUnreadInvMonAlerts =
      this.app.state.numberOfUnreadInvMonAlerts !== undefined
        ? this.app.state.numberOfUnreadInvMonAlerts
        : 0;

    let counter = numberOfUnreadMails;

    if (this.app.customers.hasRM()) {
      if (this.app.permission.alert.alertPermission) {
        counter += numberOfUnreadAlerts;
      }

      if (this.app.permission.alert.changeAlertPermission) {
        counter += numberOfUnreadChangeAlerts;
      }

      if (this.app.permission.alert.leadTimeAlertsPermission) {
        counter += numberOfUnreadLeadTimeAlerts;
      }

      if (this.app.permission.alert.invMonAlertsPermission) {
        counter += numberOfUnreadInvMonAlerts;
      }
    }

    if (this.app.permission.mail.showNewCasesNotifications) {
      counter += numberOfPcns;
    }

    return counter;
  }

  async markItemsAsSeen(property: string, doc?: any) {
    this.app.spinner.showSpinner();
    let docs: any[] = [];
    let docType = "";
    if (this.app.user == null) {
      return;
    }
    if (doc != null) {
      docs.push(doc);
    } else {
      docs = Array.from(this.app.table.selected);
    }
    for (let i = 0; i < docs.length; i++) {
      docType = docs[i].type;
      docs[i][property] = !docs[i][property];
      docs[i].update_user = this.app.user;
      docs[i].update_time = new Date().getTime();
      /** once a pcn/change has been marked as seen cannot be unseen */
      if (docType === "thread" || docType === "change") {
        if (!docs[i][property]) {
          docs.splice(i, 1);
        }
      }
      if (docType === "alert") {
        docs[i].issueDate = new Date(docs[i].issueDate).getTime();
      }
    }
    await markDocSeen(this.app.customers.expectCurrent, docs, docType);
    this.app.spinner.hideSpinner();
    this.app.table.selected = new Set<any>();
  }

  async deleteNotifications() {
    this.app.spinner.showSpinner();
    let docType: Type = "alert";
    const docs = Array.from(this.app.table.selected);
    docs.forEach((doc: any) => {
      doc._deleted = true;
      docType = doc.type;
      if (docType === "thread") {
        docType = "pcn";
      }
    });
    await bulkDocsByType(this.app.customers.expectCurrent, docType, docs);
    this.app.spinner.hideSpinner();
    this.app.table.selected = new Set();
  }

  getColumnShort(column: string) {
    return column.split(".")[1];
  }

  getHeaderTextBasedOnType(tab: string) {
    switch (tab) {
      case "alert":
        return this.app.text.mail.alert;
      case "change":
        return this.app.text.mail.notification;
      case "change-alert":
      case "changeAlert":
        return this.app.text.mail.change;
      case "lead-alert":
      case "leadTimeAlert":
        return this.app.text.alert.leadTimeAlert;
      case "inventory-monitoring":
      case "inventoryMonitoringAlert":
        return this.app.text.part.inventoryMonitoringAlerts;
      case "thread":
        if (this.app.pcns.pcnId) {
          return this.app.text.mail.pcnItem;
        }
        return this.app.text.mail.pcnInbox;
    }
    return "";
  }

  setCriticalityBasedOnType(doc: any): string {
    let criticality = "";
    let field: string[] = [];
    if (doc.type === "alert") {
      field = doc.typeOfChange;
    } else {
      field = doc.changeClasses;
    }
    const fieldValue = field == null ? [] : field;
    if (fieldValue.length > 1) {
      const veryHighIndex = fieldValue.findIndex(
        (i: string) => (typeCategories as any)[i].impact === VERY_HIGH
      );
      const highIndex = fieldValue.findIndex(
        (i: string) => (typeCategories as any)[i].impact === HIGH
      );
      const mediumIndex = fieldValue.findIndex(
        (i: string) => (typeCategories as any)[i].impact === MEDIUM
      );
      const lowIndex = fieldValue.findIndex(
        (i: string) => (typeCategories as any)[i].impact === LOW
      );
      if (veryHighIndex !== -1) {
        return VERY_HIGH;
      }
      if (highIndex !== -1) {
        return HIGH;
      }
      if (mediumIndex !== -1) {
        return MEDIUM;
      }
      if (lowIndex !== -1) {
        return LOW;
      }
    } else {
      const category = (typeCategories as any)[fieldValue[0]];
      if (category != null && fieldValue[0]) {
        criticality = category.impact;
      }
    }
    return criticality;
  }
}
