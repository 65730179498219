import { Inject, Injectable } from "@angular/core";
import { AppType, APP_TYPE } from "../../app.type";
import { OCPostItemServiceType } from "./OC-post-item.service.type";

@Injectable()
export class OCPostItemService implements OCPostItemServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  getTagColor(fieldId: string, tag: string) {
    const options = this.app.field.getFieldSettings(
      this.app.fieldId.ocPost.tagCategory
    ).options as any;

    return {
      backgroundColor: options[tag]["backgroundColor"],
      color: this.app.field.getOptionColor(fieldId, tag),
      borderColor: this.app.field.getOptionColor(fieldId, tag),
    };
  }
}
