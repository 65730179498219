import { APP_TYPE, AppType } from "../app.type";
import {
  saveImpact,
  doc2Model,
  getImpactById,
  model2Doc,
  updateResponsibles,
  updateThread,
} from "../api.service";
import { DocModel } from "../state/state";
import { hasChanges } from "../utils/app.utils";
import { ImpactServiceType } from "./impact.service.type";
import { Inject, Injectable } from "@angular/core";
import { Impact } from "../../../../shared/models/impact";
import { BehaviorSubject } from "rxjs";
import { VehicleNameModel } from "../impacts/impacts.component";
import { Responsibles } from "../../../../shared/models/responsibles";
import { getVehicleResponsibleRoles } from "../../../../shared/workflow/workflow-db";
import { Customer } from "../../../../shared/types/customers";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { GREEN, RED } from "./wf-automation/wf-automation.component";
import { Thread } from "../../../../shared/models/thread";
import { JOBTITLE_KM } from "../users/users.service.type";
import { UserOptions } from "../../../../shared/models/user";

@Injectable()
export class ImpactService implements ImpactServiceType {
  currentImpact: any = {} as Impact;
  cleanModel: any = {} as Impact;
  responsibles = new Responsibles();
  invalidFields = new Set<string>();
  newInvalidField = new BehaviorSubject<number>(0);

  constructor(@Inject(APP_TYPE) private app: AppType) {}
  newImpactSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  get impactNameExist() {
    return this.app.state.impactNameExist;
  }

  set impactNameExist(impactNameExist: boolean) {
    this.app.state.next({ impactNameExist });
  }

  get id() {
    return this.app.state.impactId;
  }

  set id(impactId: string | null) {
    this.app.state.next({ impactId });
  }

  get expectId() {
    return this.app.expect(this.id);
  }
  get newId() {
    const threadId = this.app.thread.id;
    return threadId + "/" + this.app.createId();
  }

  get isNew() {
    const impactId = this.app.state.impactId;
    if (impactId === null || impactId === undefined) {
      return true;
    }
    if (impactId.includes("NEW")) {
      return true;
    }
    return false;
  }

  get modelHasChanges() {
    return hasChanges(this.cleanModel, this.currentImpact);
  }

  hasEditPermission(id: string | null) {
    const { permission, fieldId, user } = this.app;
    if (permission.impact.edit) {
      return true;
    }
    if (permission.impact.editOwn) {
      const createUser = this.app.field.getValue(fieldId.impact.user_id, id);
      if (createUser === user) {
        return true;
      }
    }
    return false;
  }

  create() {
    const threadId = this.app.thread.id;
    const impactId = threadId + "/" + this.app.createId();
    this.app.state.next({
      impactId,
      view: "impact",
      model: {},
    });
    return impactId;
  }

  async save() {
    let impact: any = {} as Impact;
    const customer = this.app.customers.expectCurrent;
    const omfNumber =
      this.currentImpact["impact.omfNumber"] != null &&
      this.currentImpact["impact.omfNumber"] !== ""
        ? this.currentImpact["impact.omfNumber"]
        : this.app.thread.id;

    impact = model2Doc("impact", this.currentImpact);
    impact.omfNumber = omfNumber;

    if (customer === Customer.DB) {
      impact.responsibles = model2Doc(
        "responsibles",
        this.currentImpact["impact.responsibles"]
      );
    }

    if (customer === Customer.KNDS) {
      if (impact.projectNumber !== undefined) {
        const userFound: any = this.app.users.userRoles.find(
          (user: UserOptions) =>
            user.projectNumber === impact.projectNumber &&
            user.jobTitle === JOBTITLE_KM
        );

        if (userFound != null) {
          impact.projectResponsible = userFound.name;
        }
      }
    }

    this.app.state.omfNumber = omfNumber;
    let impactExists = false;
    if (impact._id == null) {
      impactExists = await this.app.impacts.checkIfImpactExists();
    }
    if (impactExists) {
      this.app.state.next({
        hasError: true,
        errorText: this.app.text.impact.impactNameExist,
      });
      setTimeout(() => {
        this.app.state.next({ hasError: false });
      }, 3000);
    } else {
      this.app.spinner.showSpinner();
      // display success message
      this.app.state.next({
        hasSuccess: true,
        successText: this.app.text.impact.savedSuccesfully,
      });
      setTimeout(() => {
        this.app.state.next({ hasSuccess: false });
      }, 3000);
      // save impact
      const result = await saveImpact(customer, impact);
      // get all impacts and update responsibles if db client
      await this.app.impacts.getImpacts(omfNumber);
      if (this.app.customers.expectCurrent === Customer.KNDS) {
        this.app.post.projectNames = this.app.impacts.impacts
          .filter(
            (o: any) => o.projectNumber != undefined && this.app.impacts.impacts
          )
          .map((x: any) => x.projectNumber);
        this.app.post.projectNames = [...new Set(this.app.post.projectNames)];
        this.app.stepper.isStepDisabled(
          this.app.thread.thread["thread.omfStatus"]
        );
      }
      this.newImpactSubject.next(true);

      if (result !== null) {
        this.app.unlockedId = null;
        const threadId = this.app.thread.cleanModel["thread._id"];

        if (customer === Customer.DB) {
          await updateResponsibles(customer, threadId);
          this.app.thread.getThread(threadId);
          this.app.post.getThreadPosts(customer, omfNumber);
        }
        if (
          customer === Customer.KNDS &&
          this.app.thread.thread[this.app.fieldId.thread.omfStatus] ===
            "In Bearbeitung"
        ) {
          this.changeToDraftStatus();
        }

        this.newImpactSubject.next(true);
        this.app.routing.navigateThread(threadId);
      }
      this.app.spinner.hideSpinner();
    }
  }

  async changeToDraftStatus() {
    this.app.spinner.showSpinner();

    // model2Doc function will be removed once model won't have "thread."
    this.app.thread.thread[this.app.fieldId.thread.omfStatus] = "vorerfasst";
    this.app.thread.threadDoc = model2Doc("thread", this.app.thread.thread);

    // Update thread with the new status
    const result = (await updateThread(
      this.app.customers.expectCurrent,
      this.app.thread.threadDoc,
      true
    )) as Thread;

    this.app.thread.threadSubject.next(result);
    this.app.spinner.hideSpinner();
  }

  async addImpact(vehicleNameModel: VehicleNameModel) {
    this.id = this.newId;
    const threadId = this.app.thread.id;
    if (threadId == null) {
      throw new Error("id is null");
    }
    const name = vehicleNameModel["impact.omfVehicleName"];
    this.app.vehicleResponsible.setImpactDetails(name);

    await this.save();
    await this.app.impacts.getImpacts(threadId);
    await this.app.thread.getThread(this.app.thread.cleanModel["thread._id"]);
    this.newImpactSubject.next(true);
    vehicleNameModel["impact.omfVehicleName"] = "";
  }

  async delete(impact: Impact | DocModel) {
    if (impact === null) {
      return;
    }
    const customer = this.app.customers.expectCurrent;

    // check if impact comes as a model with "impact."
    const keys = Object.keys(impact);
    if (keys[0].split(".")[0] === "impact") {
      const impactDoc = model2Doc("impact", impact);
      impact = impactDoc;
    }

    const impactToDelete: Partial<Impact> = {
      _id: impact._id,
      _rev: impact._rev,
      omfVehicleName: impact.omfVehicleName,
      omfNumber: impact.omfNumber,
      _deleted: true,
    };
    const omfNumber = impact.omfNumber;
    const result = await saveImpact(customer, impactToDelete);

    if (result !== null) {
      this.app.clearModel();

      if (customer === Customer.DB) {
        const threadId = this.app.thread.cleanModel["thread._id"];

        await updateResponsibles(customer, threadId);
        this.app.thread.getThread(threadId);
        this.app.post.getThreadPosts(customer, omfNumber);
      }

      this.app.unlockedId = null;
      this.app.thread.id = omfNumber;
      this.app.impacts.getImpacts(omfNumber);
    }

    const index = this.app.impacts.impacts.findIndex(
      (i) => i._id === impact._id
    );
    const impactsList = this.app.impacts.impacts;
    if (index > -1) {
      impactsList.splice(index, 1);
    }
    this.newImpactSubject.next(true);
  }

  async generateImpactsFromParts(omfNumber: string) {
    this.app.state.partsToVehicles.forEach(async (doc) => {
      // TODO: Remove 'any' after figuring out way to assign all fields at once
      const impact: Impact | any = {
        omfNumber,
        omfVehicleName: doc.omfVehicleName,
        omfVehicleCnt: doc.omfVehicleCnt,
        impacts: doc.impacts,
        artNumber: doc.artNumber,
        designType: doc.designType,
        sapStatus: doc.sapStatus,
        stockRange: doc.stockRange,
        verwa: doc.verwa,
      };
      await saveImpact(this.app.customers.expectCurrent, impact);
    });
    // clean then partsToVehicles data to avoid the generation of the last created impacts on another cases
    this.app.impacts.partsToVehicles = [];
    this.app.state.partsToVehicles = [];
  }

  async getImpactById(impactId: string) {
    const customer = this.app.customers.expectCurrent;
    const impact = await getImpactById(customer, impactId);
    if (impact !== undefined) {
      this.cleanModel = doc2Model("impact", impact);
      this.currentImpact = doc2Model("impact", impact);
      this.responsibles =
        impact.responsibles != null
          ? impact.responsibles
          : ({} as Responsibles);

      if (customer === Customer.DB) {
        this.cleanModel["impact.responsibles"] = doc2Model(
          "responsibles",
          impact.responsibles
        );
        this.currentImpact["impact.responsibles"] = doc2Model(
          "responsibles",
          impact.responsibles
        );
      }
    }
    return impact;
  }

  displayBullet(
    fieldName: string,
    color: string,
    responsiblesModel: Responsibles,
    vehicleName: string
  ) {
    const thread = this.app.thread.thread;
    const statusResponsibles = getVehicleResponsibleRoles(
      thread["thread.omfStatus"]
    );
    let statusResponsiblesKeys = [...statusResponsibles];

    // Check if dinCodeRespName is responsible in the current status
    if (
      statusResponsiblesKeys.includes("bearbeiter") &&
      fieldName === "thread.dinCodeRespName"
    ) {
      return true;
    }

    // Check if componentResponsible is responsible in the current status
    if (
      statusResponsiblesKeys.includes("komponentenManager") &&
      fieldName === "thread.componentResponsible"
    ) {
      return true;
    }

    // Check if responsibles assigned on the impacts are responsible in the current status
    // If fieldName have "responsibles.bavFv" format responsibleKeys should have the same format
    if (fieldName.split(".")[1] != null) {
      statusResponsiblesKeys = [...statusResponsibles].map(
        (r) => "responsibles." + r
      );
    }

    if (
      statusResponsiblesKeys.includes(fieldName) &&
      responsiblesModel != null &&
      !StringUtils.isNullOrEmpty(responsiblesModel[fieldName])
    ) {
      if (
        (fieldName === "bavCargoVertreter" &&
          thread["thread.omfStatus"] !== "90") ||
        (fieldName === "bavCargo" && thread["thread.omfStatus"] === "90")
      ) {
        return false;
      }

      return this.checkIfResponsiblesHasSigned(
        responsiblesModel[fieldName],
        vehicleName,
        color
      );
    }
    return false;
  }

  private checkIfResponsiblesHasSigned(
    responsible: string,
    vehicleName: string,
    color: string
  ) {
    const uncompletedTask = this.app.post.uncompletedTasksByStatus.find(
      (task) => task.taskResponsible === responsible
    );

    if (color === RED) {
      if (
        uncompletedTask != null &&
        uncompletedTask.vehicleNames != null &&
        uncompletedTask.vehicleNames.includes(vehicleName)
      ) {
        return true;
      }
    }

    if (color === GREEN) {
      let signedVehicles: string[] = [];
      const signedTasks = this.app.post.completedTasksByStatus.filter(
        (task) => task.taskResponsible === responsible
      );

      if (signedTasks == null) {
        return false;
      }

      signedTasks.forEach((task: any) => {
        if (task.vehicleNames != null) {
          signedVehicles = [...signedVehicles, ...task.vehicleNames];
        }
      });

      // This is the case where responsible has signed for the current vehicle and he has no other uncompleted tasks
      if (signedVehicles.includes(vehicleName) && uncompletedTask == null) {
        return true;
      } else if (
        signedVehicles.includes(vehicleName) &&
        uncompletedTask != null &&
        uncompletedTask.vehicleNames != null &&
        !uncompletedTask.vehicleNames.includes(vehicleName)
      ) {
        return true;
      }
    }

    return false;
  }
}
