<div
  *ngIf="app.hasError"
  class="error-notification top-bar"
  style="cursor: pointer"
>
  <div class="container">
    <div class="p-1">
      <i class="fas fa-exclamation-triangle"></i>
      {{ app.errorText }}
      <div class="close-btn pull-right" (click)="app.hasError = false">
        <i class="fa fa-times"></i>
      </div>
    </div>
  </div>
</div>
<ng-template
  #content
  let-modal
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="m-1 p-1" *ngIf="!routeParams.token">
    <button
      class="btn pull-right"
      (click)="app.routing.navigateLogin(); modal.dismiss('CANCEL')"
      [ngbTooltip]="app.text.user.discard"
    >
      <i class="fa fa-times"></i>
    </button>
    <div class="m-2 p-2">
      <h2 class="text-center">{{ app.text.resetPassword.resetPassword }}</h2>
    </div>
    <div class="controls">
      <button
        *ngIf="requiredFields.length < 1"
        class="btn"
        [title]="app.text.user.save"
      >
        <i class="fa fa-check"></i>
      </button>
    </div>
    <div class="card m-2 p-2">
      <app-field [model]="model" field="user.name"></app-field>
      <app-field [model]="model" field="user.email"></app-field>
    </div>
    <div class="controls">
      <button
        id="login-button"
        class="btn"
        (click)="requestChangePass()"
        [disabled]="!app.field.isValidRegex('user.email', model['user.email'])"
      >
        <i class="fa fa-check"></i>
        {{ app.text.resetPassword.requestPassword }}
      </button>
    </div>
  </div>
</ng-template>
<div
  class="app-container offset-md-3 col-md-6"
  *ngIf="routeParams.token && tokenValid"
>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center p-lg-5">
        <img
          *ngIf="app.auth.landing == null"
          class="p-3 m-3 login-image"
          [src]="app.url.auth.logo"
        />
        <img
          *ngIf="app.auth.landing != null"
          class="p-3 m-3 login-image-omp"
          [src]="app.url.auth.landingLogo"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="m-1 p-1">
        <div class="m-2 p-2">
          <h2 class="text-center">
            {{ app.text.resetPassword.resetPassword }}
          </h2>
        </div>
        <div class="card m-2 p-2">
          <app-field [model]="model" field="user.password"></app-field>
          <app-field [model]="model" field="user.retypePassword"></app-field>
        </div>
        <div class="controls">
          <button id="login-button" class="btn" (click)="changePassword()">
            <i class="fa fa-check"></i>
            {{ app.text.resetPassword.save }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="app-container offset-md-3 col-md-6"
  *ngIf="routeParams.token && !tokenValid"
>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center p-lg-5">
        <img
          *ngIf="app.auth.landing == null"
          class="p-3 m-3 login-image"
          [src]="app.url.auth.logo"
        />
        <img
          *ngIf="app.auth.landing != null"
          class="p-3 m-3 login-image"
          [src]="app.url.auth.landingLogo"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="top-bar error-notification" style="cursor: pointer">
        <div class="container">
          <div class="p-1">
            <i class="fas fa-exclamation-triangle"></i>
            <span *ngIf="!tokenValid">
              {{ app.text.resetPassword.linkExpired }}
            </span>
            <div class="close-btn pull-right" (click)="app.error = false">
              <i class="fa fa-times"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
