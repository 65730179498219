import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Post } from "../../../../shared/models/post";
import { getApp } from "../app";

@Component({
  selector: "app-task-completed-note",
  templateUrl: "./task-completed-note.component.html",
  styleUrls: ["./task-completed-note.component.scss"],
})
export class TaskCompletedNoteComponent implements OnInit {
  @Input() currentTask = "";
  @ViewChild("taskCompletedNoteModal", { static: false })
  taskCompletedNoteModal: any;
  taskNote = "";

  app = getApp((app) => {
    this.app = app;
  });
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  openModal() {
    this.modalService.open(this.taskCompletedNoteModal, {
      windowClass: "mediumModal",
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  async addTaskNote(post: Post) {
    // Add note to the task
    this.app.spinner.showSpinner();
    post.taskNote = this.taskNote;
    this.closeModal();

    // Mark task as complete/incomplete
    await this.app.taskCompleted.changeTaskCompletion(
      this.app.customers.expectCurrent,
      post
    );
    this.taskNote = "";

    // Check if all task are completed and change the status
    await this.app.tasks.checkTasksStatus();
    this.app.spinner.hideSpinner();
  }
}
