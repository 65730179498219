import { Component, Input } from "@angular/core";
import { getApp } from "../app";
import { Type } from "../../../../shared/components";
import { CustomerName } from "../../../../shared/config/customers";
import { PORTAL } from "../../../../shared/constants/portal";

@Component({
  selector: "app-sync",
  templateUrl: "./sync.component.html",
  styleUrls: ["./sync.component.scss"],
})
export class SyncComponent {
  app = getApp((app) => {
    this.app = app;
  });

  @Input() type: Type = "thread";
  @Input() id = "";
  @Input() displayPostSync = false;
  @Input() hasText = true;
  customer: CustomerName = PORTAL;

  constructor() {}
}
