import { Inject, Injectable } from "@angular/core";
import { AppType, APP_TYPE } from "../../app.type";
import { OCPostServiceType } from "./OC-post.service.type";
import { OCPost } from "../../../../../shared/models/obsolescence-community/OC-Post";
import { saveOCPost, updateProfileLike } from "../../../app/api.service";
import { hasChanges } from "../../utils/app.utils";
import { BehaviorSubject } from "rxjs";
import { StringUtils } from "../../../../../shared/utils/string.utils";
import { BILD_IMAGE } from "../../file/file.service";
import { EncodingUtils } from "../../../../../shared/utils/encoding.utils";

@Injectable()
export class OCPostService implements OCPostServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  updatePostSubject: BehaviorSubject<{}> = new BehaviorSubject<any>({});

  selectedPost: OCPost = new OCPost();
  cleanPost: OCPost = new OCPost();

  image = "";
  externalImage = "";

  async savePost() {
    this.app.spinner.showSpinner();
    await saveOCPost(this.app.customers.expectCurrent, this.app.OCHome.postDoc);
    // Reset Model
    this.app.OCHome.postDoc = new OCPost();
    await this.app.OCHome.ocPosts();
    this.app.OCPost.updatePostSubject.next(true);
    this.app.spinner.hideSpinner();
  }

  async updatePost() {
    this.app.spinner.showSpinner();
    await saveOCPost(this.app.customers.expectCurrent, this.cleanPost);
    this.app.OCHome.editedId = "";
    if (this.app.OCHome.isFilterSelected) {
      await this.app.OCHome.filterOCPosts(
        this.app.OCHome.selectedType,
        this.app.OCHome.searchWords[this.app.OCHome.selectedType]
      );
    } else {
      // await this.app.OCHome.ocPosts();
      this.app.OCHome.updatePostsSubject.next(true);
    }
    this.app.spinner.hideSpinner();
  }

  async deletePost() {
    this.app.spinner.showSpinner();

    // Delete selected post
    this.selectedPost._deleted = true;
    this.app.OCHome.postDoc = this.selectedPost;
    await this.savePost();

    // Get the new posts
    await this.app.OCHome.ocPosts();
    this.app.spinner.hideSpinner();
  }

  async openPost(post: OCPost) {
    this.selectedPost = post;
    this.app.OCPost.updatePostSubject.next(true);
    // Reset comment
    this.app.OCComment.commentDoc["message"] = "";
  }

  getImage(customer = this.app.customers.expectCurrent) {
    if (this.app.OCPost.selectedPost == null) {
      return;
    }
    const id = this.app.OCPost.selectedPost._id;
    let encodedId = "";
    if (id != null) {
      encodedId = EncodingUtils.encodeBase64(id);
    }
    this.image = [customer, "ocPost", encodedId, BILD_IMAGE].join("/");
    return this.image;
  }

  getImageExternal(): void {
    const { app } = this;
    const postId = this.app.OCPost.selectedPost._id;

    if (postId !== null) {
      const fileLink = this.getImage();
      const imageExists = app.file.exists(this.app.OCPost.selectedPost);
      const hasBildImage = this.hasBildImage();

      if (imageExists || hasBildImage) {
        if (fileLink != null) {
          this.externalImage = app.file.getUrl(fileLink);
        }
      } else {
        this.externalImage = !StringUtils.isNullOrEmpty(
          this.app.OCPost.selectedPost.image
        )
          ? this.app.OCPost.selectedPost.image
          : "";
      }
    }
  }

  private hasBildImage() {
    if (
      this.app.OCPost.selectedPost._attachments != null &&
      Object.keys(this.app.OCPost.selectedPost._attachments > 0)
    ) {
      const hasBildImage =
        this.app.OCPost.selectedPost._attachments[BILD_IMAGE];
      if (hasBildImage) {
        return true;
      } else {
        return false;
      }
    }
  }

  // getRouterURL(postId: string) {
  //   // this.router.navigate(["/oc-home", postId]);
  //   return `/${this.app.customers.expectCurrent}/oc-home/${postId}`;
  // }

  async likePost(postId: any) {
    // isLiked property was added to be used in the express OC-post service to check whether user has hit like button
    this.selectedPost.isLiked = this.selectedPost.isLikedByLoggedInUser;

    this.selectedPost.isLikedByLoggedInUser =
      !this.selectedPost.isLikedByLoggedInUser;
    this.cleanPost = this.selectedPost;

    await updateProfileLike(this.app.customers.expectCurrent, postId);
    await this.updatePost();

    if (this.app.OCHome.isFilterSelected) {
      await this.app.OCHome.filterOCPosts(
        this.app.OCHome.selectedType,
        this.app.OCHome.searchWords[this.app.OCHome.selectedType]
      );
    } else {
      this.app.OCHome.updatePostsSubject.next(true);
    }
  }

  modelHasChanges() {
    const modelHasChanges = hasChanges(this.cleanPost, this.selectedPost);
    if (
      this.selectedPost["message"] != "" &&
      this.selectedPost["title"] != "" &&
      modelHasChanges
    ) {
      return false;
    } else {
      return true;
    }
  }

  postHasValidUserName(post: OCPost) {
    if (post.user_id == null) {
      return false;
    }

    if (
      StringUtils.isNullOrEmpty(post.userFirstName) ||
      StringUtils.isNullOrEmpty(post.userLastName)
    ) {
      return false;
    } else {
      return true;
    }
  }

  discardChanges() {
    this.app.OCHome.editedId = "";
  }
}
