import { TableDef } from "pdfmake/build/pdfmake";
import { NON_TRANSPORT_CLIENT } from "../../customers/customers.service";
import { getApp } from "../../app";

export class ChangeData {
  [key: string]: any;
  pcnChangeDetail = "";
  pcnChangeIdentificationMethod = "";
  dinCode = "";
  dinText = "";
}
export const description = (
  data: ChangeData,
  appType: "omc" | "pcngen"
): TableDef => {
  const app = getApp();
  if (app.customers.getCustomerType() === NON_TRANSPORT_CLIENT) {
    return {
      table: {
        widths: ["*"],

        body: [
          [
            {
              text: app.text.pdf.description,
              style: "section",
            },
          ],

          [
            {
              text: [
                {
                  text: app.text.pdf.alertImpact + "\n",
                  style: "fieldName",
                },
                {
                  text: data.pcnChangeDetail || "\n",
                  style: "input",
                },
              ],
            },
          ],

          [
            {
              text: [
                {
                  text: app.text.pdf.changeIdentificationMethod,
                  style: "fieldName",
                },
                {
                  text: app.text.pdf.ifApplicable + "\n",
                  style: "hint",
                },
                {
                  text: data.pcnChangeIdentificationMethod || "\n",
                  style: "input",
                },
              ],
            },
          ],
        ],
      },
    };
  } else if (appType === "pcngen") {
    return {
      table: {
        widths: ["*", "*", "*"],

        body: [
          [
            {
              colSpan: 3,
              text: app.text.pdf.description,
              style: "section"
            },
            "",
            ""
          ],

          [
            {
              colSpan: 3,
              text: [
                {
                  text: app.text.pdf.alertImpact + "\n",
                  style: "fieldName"
                },
                {
                  text: data.pcnChangeDetail || "\n",
                  style: "input"
                }
              ]
            },
            "",
            ""
          ],

          [
            {
              colSpan: 3,
              text: [
                {
                  text: app.text.pdf.changeIdentificationMethod,
                  style: "fieldName"
                },
                {
                  text: app.text.pdf.ifApplicable + "\n",
                  style: "hint"
                },
                {
                  text: data.pcnChangeIdentificationMethod || "\n",
                  style: "input"
                }
              ]
            },
            "",
            ""
          ]
        ]
      }
    };
  } else {
    return {
      table: {
        widths: ["*", "*", "*"],

        body: [
          [
            {
              colSpan: 3,
              text: app.text.pdf.description,
              style: "section",
            },
            "",
            "",
          ],

          [
            {
              colSpan: 3,
              text: [
                {
                  text: app.text.pdf.alertImpact + "\n",
                  style: "fieldName",
                },
                {
                  text: data.pcnChangeDetail || "\n",
                  style: "input",
                },
              ],
            },
            "",
            "",
          ],

          [
            {
              text: [
                {
                  text: app.text.pdf.changeIdentificationMethod,
                  style: "fieldName",
                },
                {
                  text: app.text.pdf.ifApplicable + "\n",
                  style: "hint",
                },
                {
                  text: data.pcnChangeIdentificationMethod || "\n",
                  style: "input",
                },
              ],
            },
            {
              text: [
                {
                  text: app.text.pdf.dinCode + "\n",
                  style: "fieldName",
                },
                {
                  text: data.dinCode,
                  style: "input",
                },
              ],
            },
            {
              text: [
                {
                  text: app.text.pdf.dinText + "\n",
                  style: "fieldName",
                },
                {
                  text: data.dinText,
                  style: "input",
                },
              ],
            },
          ],
        ],
      },
    };
  }
};
