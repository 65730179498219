import { Inject, Injectable } from "@angular/core";
import { APP_TYPE, AppType } from "../app.type";
import { PanelServiceType } from "./panel.service.type";
import { Task } from "../../../../shared/models/task";
import { BehaviorSubject } from "rxjs";
import { hasChanges } from "../utils/app.utils";
import { model2Doc } from "../api.service";
import { Customer } from "../../../../shared/types/customers";
import { Post } from "../../../../shared/models/post";
@Injectable()
export class PanelService implements PanelServiceType {
  isOpen = false;
  selectedItem: Task = {} as Task;
  selectedItemModel: Task = {} as Task;
  cleanItem: Task = {} as Task;
  userProfile = "";
  hasEditPermissionForDescription = false;
  triggerPanel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  creditsSubject: BehaviorSubject<Task> = new BehaviorSubject<Task>({} as Task);
  openDiscardModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  openPanel() {
    this.isOpen = true;
  }

  closePanel() {
    const validFields = this.checkIfFieldsAreValid();

    if (validFields) {
      this.savePanelItem();
      this.isOpen = false;
    } else {
      this.openDiscardModal.next(true);
      this.userProfile = "";
    }
  }

  async savePanelItem() {
    const currentPostDoc = model2Doc("post", this.selectedItemModel);
    const modelHasChanges = hasChanges(this.cleanItem, currentPostDoc);

    if (modelHasChanges) {
      this.app.post.model = this.selectedItemModel as Post;
      this.app.post.save();
    }
  }

  checkIfFieldsAreValid() {
    // Task Description is not using the app-field component therefore the ngModel is different and has to be added to the currentPostDoc
    this.selectedItemModel[this.app.fieldId.post.taskDescription] =
      this.selectedItem.taskDescription;

    return (
      this.app.field.getInvalidFields("post", this.selectedItemModel).length < 1
    );
  }

  hasEditPermission(selectedItem: Task) {
    const customer = this.app.customers.expectCurrent;

    switch (customer) {
      case Customer.DB:
        this.hasEditPermissionForDescription = this.app.auth.isTeam;
        break;
      default:
        this.hasEditPermissionForDescription = this.app.post.hasEditPermission(
          selectedItem as Post
        );
    }
  }

  async navigateProfileFromPanel(user: string) {
    const validFields = this.checkIfFieldsAreValid();

    if (validFields) {
      this.savePanelItem();
      this.isOpen = false;
      await this.app.routing.navigate(
        this.app.customers.expectCurrent,
        "profile",
        user
      );
    } else {
      this.openDiscardModal.next(true);
      this.userProfile = user;
    }
  }
}
