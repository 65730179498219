import * as XLSX from "xlsx";
import {
  getFileContentAsArrayBuffer,
  getFileContentAsString,
} from "./file.utils";
import { TypeIndex } from "../../../../shared/index";
import { StringUtils } from "../../../../shared/utils/string.utils";

export async function importXlsx(file: File) {
  const buffer: TypeIndex<any> = {};

  const book = XLSX.read(await getFileContentAsArrayBuffer(file), {
    type: "array",
  });
  let currentLanguage = "";
  /** read data from different sheet names */
  // book.SheetNames.map((name) => {
  //   const sheet = book.Sheets[name];
  //   buffer[name] = importDocs(sheet);
  //   console.log(name);
  // });

  /** read data only from the first page */
  const name = book.SheetNames[0];
  if (name !== undefined && name.includes("_")) {
    currentLanguage = name.split("_")[1].toLowerCase();
  } else {
    // default import language
    currentLanguage = "en";
  }
  const sheet = book.Sheets[name];
  buffer[name] = importDocs(sheet);

  sessionStorage.setItem("language", currentLanguage);
  return buffer;
}

function parseJson(arg: string): any[] {
  return JSON.parse(arg);
}

export async function importJson(file: File) {
  const buffer: TypeIndex<any> = {};
  await getFileContentAsString(file);
  buffer["data"] = parseJson(await getFileContentAsString(file));
  return buffer;
}

function importDocs(sheet: XLSX.WorkSheet) {
  const rows: string[][] = XLSX.utils.sheet_to_json(sheet, {
    raw: true,
    header: 1,
  });
  return rows;
}

export function setCreateTimeAndUpdateTime(doc: any) {
  if (StringUtils.isNullOrEmpty(doc["create_time"])) {
    doc.create_time = new Date().getTime();
    doc.update_time = new Date().getTime();
  } else {
    doc.create_time = Number(doc["create_time"]);
    doc.update_time = doc.create_time;
  }

  return doc;
}
