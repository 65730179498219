import { AppType, APP_TYPE } from "../app.type";
import {
  deleteVehicle,
  model2Doc,
  updateVehicle,
  getVehicleDocument,
  getVehicleDocuments,
  doc2Model,
} from "../api.service";
import { VehicleResponsibleServiceType } from "./vehicle-responsible.service.type";
import { Inject, Injectable } from "@angular/core";
import { VehicleResponsible } from "../../../../shared/models/vehicleResponsible";
import { Impact } from "../../../../shared/models/impact";
import { hasChanges } from "../utils/app.utils";
import { Responsibles } from "../../../../shared/models/responsibles";
import { Customer } from "../../../../shared/types/customers";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class VehicleResponsibleService
  implements VehicleResponsibleServiceType
{
  isNew = false;
  ids: string[] = [];
  model: any = {} as VehicleResponsible;
  cleanModel: any = {} as VehicleResponsible;
  vehicles: VehicleResponsible[] = [];
  vehicleNames: string[] = [];
  docsToImport: Partial<VehicleResponsible>[] = [];
  existingVNames: string[] = [];
  invalidFields = new Set<string>();
  newInvalidField = new BehaviorSubject<number>(0);
  vResponsibleSubject = new BehaviorSubject<boolean>(false);
  vResponsibleDeleted = new BehaviorSubject<boolean>(false);
  cleanVehicles: Responsibles[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  get id() {
    return this.app.state.vehicleId;
  }

  set id(vehicleId: string | null) {
    this.app.state.next({ vehicleId });
  }

  async getVehicleById(vehicleId: string) {
    const customer = this.app.customers.expectCurrent;
    const vehicle = await getVehicleDocument(customer, vehicleId);

    this.model = doc2Model("vehicleResponsible", vehicle[0]);

    this.cleanModel = doc2Model("vehicleResponsible", vehicle[0]);

    if (customer === Customer.DB) {
      this.model["vehicleResponsible.responsibles"] = doc2Model(
        "responsibles",
        vehicle[0].responsibles
      );
      this.cleanModel["vehicleResponsible.responsibles"] = doc2Model(
        "responsibles",
        vehicle[0].responsibles
      );
    }
  }

  get modelHasChanges() {
    return hasChanges(this.cleanModel, this.model);
  }

  async getVehicleDocuments() {
    const customer = this.app.customers.expectCurrent;
    this.vehicles = await getVehicleDocuments(customer);
    this.cleanVehicles = [];
    if (this.app.customers.expectCurrent === Customer.DB) {
      /** created a new object with all the properties at the same level so that the filtering works on all of them
       */
      this.vehicles.forEach((vehicle: VehicleResponsible) => {
        if (vehicle.responsibles != null) {
          const vResponsiblesObject = vehicle.responsibles;
          vResponsiblesObject._id = vehicle._id;
          vResponsiblesObject.vehicleName = vehicle.vehicleName;
          vResponsiblesObject.vehicleFleet = vehicle.vehicleFleet;
          this.cleanVehicles.push(vResponsiblesObject);
        }
      });
    }
    const uniqueIds = new Set<string>();
    this.vehicles.forEach((v) => {
      if (v._id != null) {
        uniqueIds.add(v._id);
      }
    });
    this.ids = Array.from(uniqueIds);
    this.extractVehicleData();
  }

  async save() {
    const customer = this.app.customers.expectCurrent;

    if (this.isNew) {
      delete this.model["vehicleResponsible._id"];
    }

    // //TODO: model2Doc function will be removed once model won't have "vehicleResponsible."
    const vehicleDoc: any = model2Doc("vehicleResponsible", this.model);
    if (customer === Customer.DB) {
      vehicleDoc.responsibles = model2Doc(
        "responsibles",
        this.model["vehicleResponsible.responsibles"]
      );
    }

    // save vehicle
    const result: any = await updateVehicle(customer, vehicleDoc);
    this.app.unlockedId = null;

    // TODO: check if is still nedeed for vehicle-modal (new-design implementation)
    // if (newVehicle) {
    //   this.app.routing.navigateVehicleResponsibles();
    // }
    // if (newVehicle && this.app.customer !== "db") {
    //   await this.app.vehicleResponsible.getVehicleDocuments();
    // }
    // if (newVehicle) {
    //   this.app.routing.navigateVehicleResponsible(result._id);
    // } else {
    //   this.getVehicleDocument();
    // }
    this.app.vehicleResponsible.id = result.id;
    this.app.state.vehicleId = result.id;
    this.app.model = {};
    this.isNew = false;
    this.app.typeAheadBasic.model = {};
    this.vResponsibleSubject.next(true);
  }

  async delete() {
    const customer = this.app.customers.expectCurrent;
    // TODO: model2Doc function will be removed once model won't have "vehicleResponsible."
    const vehicleDoc: any = model2Doc("vehicleResponsible", this.model);

    vehicleDoc._deleted = true;
    await deleteVehicle(customer, vehicleDoc);
    this.vResponsibleDeleted.next(true);
  }

  private extractVehicleData() {
    const names = this.vehicles.map((doc) => doc.vehicleName);
    this.vehicleNames = [...new Set(names)];
  }

  setImpactDetails(name: string) {
    const impact = new Impact();
    const vehicle = this.vehicles.filter((x) => x.vehicleName === name);
    const doc = vehicle[0];

    if (doc != null) {
      impact.omfNumber = this.app.thread.id != null ? this.app.thread.id : "";
      impact.omfVehicleName = doc.vehicleName;
      impact.omfVehicleFleet = doc.vehicleFleet;
      impact.omfVehicleClass = doc.vehicleClass;
      impact.omfCommodityRespName = `${doc.responsibleFirstName} ${doc.responsibleLastName}`;
      impact.omfVehicleRespDep = doc.responsibleDepartment;
      impact.omfVehicleRespEmail = doc.responsibleEmail;
      impact.omfVehicleCnt = 1;
      impact.vehicleGroup = doc.vehicleGroup;
      impact.level = "product";
      if (
        doc.responsibles != null &&
        this.app.customers.expectCurrent === Customer.DB
      ) {
        impact.responsibles = Object.assign(doc.responsibles);
      }
    }

    delete impact._id;
    delete impact._rev;
    this.app.impact.currentImpact = doc2Model("impact", impact) as Impact;
    if (this.app.customers.expectCurrent === Customer.DB) {
      this.app.impact.currentImpact["impact.responsibles"] = doc2Model(
        "responsibles",
        impact.responsibles
      );
      this.app.model = this.app.impact.currentImpact["impact.responsibles"];
    }
  }

  async generateVehicles(docs: any[]) {
    this.docsToImport = [];
    await this.getVehicleDocuments();
    docs.forEach((doc: any) => {
      let vResponsible = this.createVResponsible(doc);
      const existingDoc = this.checkIfExists(doc.vehicleName);
      if (existingDoc != null) {
        vResponsible = this.updateFieldsFromImport(vResponsible, existingDoc);
        this.existingVNames.push(existingDoc.vehicleName);
        vResponsible._id = existingDoc._id;
      } else {
        delete vResponsible._id;
        delete vResponsible._rev;
      }
      vResponsible.type = "vehicleResponsible";
      doc.type = "vehicleResponsible";

      this.docsToImport.push(vResponsible);
      this.app.import.selected.add(vResponsible.vehicleName);
    });

    this.app.import.docs = docs;
  }

  createVResponsible(doc: any) {
    const vehicleResponsible = new VehicleResponsible();
    const responsibles = new Responsibles();
    Object.keys(doc).forEach((key) => {
      if (
        Object.keys(vehicleResponsible).findIndex((field) => field === key) !==
        -1
      ) {
        vehicleResponsible[key] = doc[key];
      }
      if (Object.keys(responsibles).findIndex((k) => k === key) !== -1) {
        responsibles[key] = doc[key];
      }
      /** create a copy of the "responsibles" object and assign only the not empty values */
      const responsiblesCopy: Partial<Responsibles> = {};
      Object.keys(responsibles).forEach((field) => {
        if (responsibles[field] !== "") {
          responsiblesCopy[field] = responsibles[field];
        }
      });
      /** set on the vehicleResponsible object the result filtered by the non empty values */
      vehicleResponsible["responsibles"] = responsiblesCopy;
    });
    const vResponsibleCopy: Partial<VehicleResponsible> = {};
    Object.keys(vehicleResponsible).forEach((field) => {
      if (vehicleResponsible[field] !== "") {
        vResponsibleCopy[field] = vehicleResponsible[field];
        delete vResponsibleCopy._id;
      }
    });

    /** the vehicle responsible object compressed with only the needed fields */
    return vResponsibleCopy;
  }

  private checkIfExists(vehicleName: any) {
    if (this.vehicles) {
      const vehicle = this.vehicles.find(
        (v: VehicleResponsible) => v.vehicleName === vehicleName
      );
      return vehicle;
    }
  }

  private updateFieldsFromImport(
    doc: Partial<VehicleResponsible>,
    existingDoc: VehicleResponsible
  ) {
    Object.keys(doc).forEach((key) => {
      if (doc[key] !== "") {
        if (
          doc.responsibles != null &&
          existingDoc.responsibles != null &&
          key === "responsibles"
        ) {
          Object.keys(doc.responsibles).forEach((k: string) => {
            if (
              (doc as any).responsibles[k] !==
              (existingDoc as any).responsibles[k]
            ) {
              (existingDoc as any).responsibles[k] = (doc as any).responsibles[
                k
              ];
            }
          });
        } else {
          existingDoc[key] = (doc as any)[key];
        }
      }
    });
    return existingDoc;
  }
}
