import { Component, ViewChild, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { getApp } from "../app";
import { createTasks, getPredefinedTasksByStatus } from "../api.service";
import { PredefinedTask } from "../../../../shared/models/predefinedTasks";
import { Post } from "../../../../shared/models/post";
import { JOBTITLE_OM } from "../users/users.service.type";
import { UserOptions } from "../../../../shared/models/user";

@Component({
  selector: "app-predefined-tasks",
  templateUrl: "./predefined-tasks.component.html",
  styleUrls: ["./predefined-tasks.component.scss"],
})
export class PredefinedTasksComponent {
  @Input() modalTitle = "";

  @ViewChild("content", { static: false }) content: any;
  @Output() ok = new Subject<any>();

  app = getApp((app) => {
    this.app = app;
  });

  columns: string[] = [
    this.app.text.table.select,
    this.app.text.post.tasks,
    this.app.text.part.descriptionLabel,
    this.app.text.impact.projectNumber,
  ];
  predefinedTasks: PredefinedTask[] = [];
  allTasksCreated = false;

  constructor(private modalService: NgbModal) {}

  async open() {
    this.predefinedTasks = await getPredefinedTasksByStatus(
      this.app.thread.thread[this.app.fieldId.thread.omfStatus]
    );
    if (this.app.users.currentUser["jobTitle"] !== JOBTITLE_OM) {
      this.filterTasksByProject(this.predefinedTasks);
    }
    this.markTasksAsCreated(this.predefinedTasks);

    try {
      const result = await this.modalService.open(this.content, {
        windowClass: "xlModal",
      }).result;
      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  async save() {
    if (this.app.state.omfNumber == null) {
      return;
    }
    const tasks: Partial<Post>[] = [];
    const selectedTasks = [...this.app.table.selected];

    this.app.spinner.showSpinner();
    selectedTasks.forEach(async (task: any) => {
      const taskInfo = {} as Partial<Post>;
      taskInfo.taskDescription = task.descriptionDE;
      taskInfo.taskResponsible = this.app.users.userRoles
        .filter((user: UserOptions) => user.businessArea === task.department)
        .map((x: any) => x.name)
        .join("");
      taskInfo.projectNumber = task.projectNumber;
      taskInfo.loggedInUser = this.app.users.currentUser.name;
      tasks.push(taskInfo);
    });
    const threadId = this.app.thread.cleanModel["thread._id"];
    await createTasks(this.app.customers.expectCurrent, threadId, tasks);

    this.app.thread.getThread(threadId);
    this.app.post.getThreadPosts(
      this.app.customers.expectCurrent,
      this.app.state.omfNumber
    );
    this.app.table.selected.clear();
    this.app.spinner.hideSpinner();
    this.modalService.dismissAll();
  }

  closeModal() {
    this.app.table.selected.clear();
    this.modalService.dismissAll();
  }

  markTasksAsCreated(predefinedTasks: PredefinedTask[]) {
    let countCreatedTasks = 0;
    predefinedTasks.forEach((task: any) => {
      const taskDescription = task.descriptionDE;
      const taskResponsible = this.app.users.userRoles
        .filter((user: UserOptions) => user.businessArea === task.department)
        .map((x: any) => x.name)
        .join("");

      const createdTask = this.app.post.cleanPosts.find(
        (post: Post) =>
          post.taskResponsible === taskResponsible &&
          post.taskDescription === taskDescription &&
          !post.taskCompleted &&
          post.typeOfPost === "task"
      );

      // used to disable the Predefined Tasks that are used in status 1 "Creation"
      if (task["projectNumber"] === undefined && createdTask == undefined) {
        return task.isCreated;
      } else if (
        task["projectNumber"] === undefined &&
        createdTask != undefined
      ) {
        task.isCreated = true;
        return;
      }

      // used to disable the Predefined Tasks that are not associated with any Project from the Where-Used section
      if (
        task["projectNumber"] !== undefined &&
        !this.app.post.projectNames.includes(task["projectNumber"])
      ) {
        task.isCreated = true;
      }

      if (createdTask !== undefined) {
        task.isCreated = true;
        countCreatedTasks++;
      }
    });

    if (countCreatedTasks === predefinedTasks.length) {
      this.allTasksCreated = true;
    } else {
      this.allTasksCreated = false;
    }
  }

  selectAll(docs: any[]) {
    docs.forEach((doc) => {
      if (!doc.isCreated) {
        this.app.table.selected.add(doc);
      }
    });

    return this.app.table.selected;
  }

  filterTasksByProject(predefinedTasks: PredefinedTask[]) {
    this.predefinedTasks = predefinedTasks.filter(
      (task: PredefinedTask) =>
        task.projectNumber === this.app.users.currentUser["projectNumber"]
    );
  }
}
