import { AppType } from "../app.type";
import { getApp } from "../app";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { getMPNByDataProviderId } from "../api.service";
import { DataProvider } from "../../../../shared/constants/dataProvider";

@Component({
  selector: "app-assigned-to",
  templateUrl: "./assigned-to.component.html",
  styleUrls: ["./assigned-to.component.scss"],
})
export class AssignedToComponent implements OnInit, OnDestroy {
  app = getApp((app: AppType) => {
    this.app = app;
  });
  assignedCPNs: string[] = [];
  dataProviderMPN = "PartNumber";

  constructor() {}

  ngOnInit() {
    this.setAssignedCPNs();
  }

  async setAssignedCPNs() {
    let manufacturersByDataProviderId: any[] = [];
    let dataProviderId = "";

    if (this.app.customers.dataProvider === DataProvider.SE) {
      dataProviderId = this.app.manufacturer.selectedManufacturer.ComID;
    }

    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      dataProviderId =
        this.app.manufacturer.selectedManufacturer.PartID != null
          ? this.app.manufacturer.selectedManufacturer.PartID
          : "";
      this.dataProviderMPN = "MPN";
    }
    if (dataProviderId != "") {
      manufacturersByDataProviderId = await getMPNByDataProviderId(
        this.app.customers.dataProvider,
        dataProviderId,
        this.app.customers.expectCurrent
      );
    }

    manufacturersByDataProviderId.forEach((mpn: any) => {
      if (
        mpn.manufacturerPartNumber ==
          this.app.manufacturer.selectedManufacturer[this.dataProviderMPN] &&
        mpn.name ==
          this.app.manufacturer.selectedManufacturer["Manufacturer"] &&
        !this.assignedCPNs.includes(mpn.partNumber)
      ) {
        this.assignedCPNs.push(mpn.partNumber);
      }
    });
  }

  ngOnDestroy(): void {}
}
