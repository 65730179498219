import { Component, OnDestroy, OnInit } from "@angular/core";
import { dinCodesCategories } from "./din-code/din-code-graph";
import { getApp } from "../app";
import { GraphConfig, GraphDimensions } from "./graph.type";
import { GraphDataItem } from "./graphs.service.type";
import { dinCodes } from "../../../../shared/models/dinCodes";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { TotalCost } from "../../../../shared/models/totalCostLTB";
import { GraphName } from "../../../../shared/types/graphName";

@Component({
  selector: "app-graphs",
  templateUrl: "./graphs.component.html",
  styleUrls: ["./graphs.component.scss"],
})
export class GraphsComponent implements OnInit, OnDestroy {
  results: GraphDataItem[] | TotalCost[] = [];
  graphName: GraphName | null = null;
  graphConfig: GraphConfig | null = null;
  graphDimensions: GraphDimensions | null = null;
  dinCodeCategories: string[] = [];
  dinCodesWithText: { dinCode: string; dinCodeText: string }[] = [];
  graphNameSubscription: Subscription = new Subscription();

  app = getApp(async (app) => {
    this.app = app;
  });

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.graphNameSubscription = this.route.params.subscribe(async (params) => {
      if (params.graph != null) {
        this.graphName = params.graph;
        this.app.graphs.name = params.graph;
        await this.loadData();
        this.graphConfig = this.app.graphs.getConfig();
        this.graphDimensions = this.app.graphs.getDimensions();
        this.getdinCodes();
        this.getDinCodesWithText();
        await this.loadResults();
      }
    });
  }

  async loadData() {
    switch (this.graphName) {
      case GraphName.Vehicles:
        await this.app.graphs.getImpacts();
        break;
      case GraphName.Fleet:
        await this.app.graphs.getThreads();
        await this.app.graphs.getImpacts();
        break;
      case GraphName.DinCodes:
        await this.app.graphs.getThreads();
        await this.app.graphs.getImpacts();
        break;
      case GraphName.Status:
        await this.app.graphs.getThreads();
        await this.app.graphs.getChartStatusHistory();
        break;
      case GraphName.Departments:
        await this.app.graphs.getThreads();
        break;
      case GraphName.Responsibles:
        await this.app.graphs.getThreads();
        break;
      case GraphName.TotalCostLTB:
        await this.app.graphs.getTotalCostLTB();
        break;
      case GraphName.OMApproachesCurrentYear:
      case GraphName.OMApproachesPerYear:
        await this.app.graphs.getThreadApproaches();
        break;
      case GraphName.SolutionsCurrentYear:
      case GraphName.SolutionsPerYear:
        await this.app.graphs.getSolutions();
        break;
    }
  }

  async loadResults() {
    if (this.graphName === GraphName.TotalCostLTB) {
      this.results = this.app.graphs.totalCost.totals;
    } else {
      this.results = await this.app.graphs.getData();
    }
  }

  getdinCodes() {
    this.dinCodeCategories = dinCodesCategories;
  }

  protected init() {
    getApp().filterList.clear();
  }

  /*getDinText(dinCode: string) {
    return dinCodes[dinCode.slice(2)].de;
  }*/

  getDinCodesWithText() {
    this.dinCodesWithText = [];
    this.dinCodeCategories.forEach((dinCode) => {
      this.dinCodesWithText.push({
        dinCode,
        dinCodeText: dinCodes[dinCode.slice(2)].de,
      });
    });
  }

  onSelectColumn(event: any, graph: string) {
    console.log("select column", event, graph);
  }
  ngOnDestroy() {
    if (this.graphNameSubscription) {
      this.graphNameSubscription.unsubscribe();
    }
  }
}
