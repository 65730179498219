import { HeaderServiceType } from "./header.service.type";
import { BehaviorSubject } from "rxjs";
import { AppType, APP_TYPE } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { Customer } from "../../../../shared/types/customers";
@Injectable()
export class HeaderService implements HeaderServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  newHeaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  showSidebar = false;
  isRM = false;
  headerTitle = "";

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
    this.newHeaderSubject.next(this.showSidebar);
    const sidebar = document.getElementById("sidebar-wrapper");
    if (sidebar != null) {
      sidebar.style.marginLeft = "0";
    }
  }

  closeNav() {
    const sidebar = document.getElementById("sidebar-wrapper");
    if (sidebar != null) {
      sidebar.style.marginLeft = "-20rem";
    }
    this.showSidebar = false;
  }

  getHeaderTitle(tab?: string) {
    switch (this.app.view) {
      case "home":
        this.app.header.headerTitle = this.app.text.sidebar.OM;
        break;
      case "thread":
        if (this.app.thread.isInCreateMode) {
          this.app.header.headerTitle = this.app.text.thread.create;
          break;
        }
        this.app.header.headerTitle = this.app.text.thread.caseDetails;
        break;
      case "RM":
      case "RMSearch":
        this.app.header.headerTitle = this.app.text.sidebar.RM;
        break;
      case "mail":
        if (tab == null) {
          return;
        }
        this.app.header.headerTitle =
          this.app.mail.getHeaderTextBasedOnType(tab);
        break;
      case "debug":
        this.app.header.headerTitle = this.app.text.debug.title;
        break;
      case "import":
        this.app.header.headerTitle = this.app.text.import.import;
        break;
      case "profile":
        this.app.header.headerTitle = this.app.text.profile.title;
        break;
      case "users":
        this.app.header.headerTitle = this.app.text.user.users;
        break;
      case "user":
        if (this.app.users.currentUser.name === "") {
          this.app.header.headerTitle = this.app.text.user.add;
          break;
        }
        this.app.header.headerTitle = this.app.text.user.main;
        break;
      case "graphs":
        this.app.header.headerTitle = this.app.text.sidebar.charts;
        break;
      case "external-data-filter":
        this.app.header.headerTitle = this.app.text.sidebar.searchInComponent;
        break;
      case "user-profile-list":
        this.app.header.headerTitle = this.app.text.user.userList;
        break;
      case "tasks":
        this.app.header.headerTitle = this.app.text.app.myTasks;
        break;
      case "vehicleResponsible":
        this.app.header.headerTitle =
          this.app.text.vehicleResponsible.vehicleResponsible;
        break;
      case "train":
        this.app.header.headerTitle = this.app.text.train.trainHeader;
        break;
      case "part":
        this.app.header.headerTitle = this.app.text.part.cpnDetails;
        break;
      case "manufacturer":
        this.app.header.headerTitle = this.app.text.manufacturer.mpnDetails;
        break;
      case "profile-password":
        this.app.header.headerTitle = this.app.text.resetPassword.resetPassword;
        break;
      case "operations":
        this.app.header.headerTitle = this.app.text.operations.operations;
        break;
      case "matching":
        this.app.header.headerTitle =
          this.app.text.matchingManufacturer.matchingItem;
        break;
      // HEADER OC
      case "oc-home":
        this.app.header.headerTitle = this.app.text.ocPost.OC;
        break;
      default:
        this.app.header.headerTitle = this.app.text.thread.threads;
        break;
    }
  }

  removePadding() {
    switch (this.app.view) {
      case "import":
      case "vehicleResponsibleList":
      case "vehicleResponsible":
      case "part":
      case "manufacturer":
      case "user":
      case "matching":
      case "train":
        return "p-0";
    }
  }

  isWideLogo() {
    if (
      this.app.customers.expectCurrent === Customer.MTU ||
      this.app.customers.expectCurrent === Customer.KNDS ||
      this.app.customers.expectCurrent === Customer.ROSEN ||
      this.app.customers.expectCurrent === Customer.LRE ||
      this.app.customers.expectCurrent === Customer.HERRMANNULTRASCHALL ||
      this.app.customers.expectCurrent === Customer.PLASTICOMNIUM ||
      this.app.customers.expectCurrent === Customer.RHEINMETALLAIRDEFENCE ||
      this.app.customers.expectCurrent === Customer.RHEINMETALL
    ) {
      return true;
    }
    return false;
  }
}
