import { TypeIndex, DocIndex } from "../../../../shared/index";
import { AppType } from "../app.type";

function deleteAllDocs(app: AppType): TypeIndex<any> {
  const buffer: TypeIndex<any> = {};
  app.types.forEach((type) => {
    if (type === "user") { return; }
    if (type === "profile") { return; }
    const docs = app.docs.getDocs(type);
    const deletedDocs: DocIndex<any> = {};
    Object.keys(docs).forEach((id) => {
      deletedDocs[id] = { _deleted: true };
    });
    buffer[type] = deletedDocs;
  });
  return buffer;
}

export function generateRandomDocs(
  app: AppType,
  iterations = 100,
  buffer: TypeIndex<any> = {}
): TypeIndex<any> {
  const name = app.user;
  if (name == null) {
    throw new Error("not logged in");
  }

  const time = new Date().getTime();

  buffer.thread = {};
  buffer.layer = {};
  buffer.part = {};
  buffer.manufacturer = {};
  buffer.post = {};

  for (let index = 0; index < iterations; index++) {
    const n = Math.floor(Math.random() * 1000000);
    const omfNumber = "OMF-GEN-" + n;
    buffer.thread[omfNumber] = {
      title: "TEST",
      create_time: time,
    };
    buffer.layer[omfNumber] = {
      omfNumber,
      dinCodeRespName: "GEN-" + n,
    };
    const partNumber = "PART-" + n;
    buffer.part[partNumber] = {
      partNumber,
    };
    const manufacturerPartNumber = "MPN-" + n;
    buffer.manufacturer[manufacturerPartNumber] = {
      manufacturerPartNumber,
      partNumber,
      purchasePartNumber: `${manufacturerPartNumber}-P`,
    };
    const post = "POST-" + n;
    buffer.post[post] = {
      omfNumber,
    };
  }

  return buffer;
}
