<div class="tableFixHead mx-2 pb-2">
  <table class="table table-striped">
    <thead>
      <tr>
        <th
          [ngClass]="{
            'col-md-6': column === 'manufacturer.distributor',
            'pl-18': column === 'manufacturer.quantity'
          }"
          class="p-1"
          *ngFor="let column of columns"
        >
          <div class="d-inline-flex">
            <div
              [ngClass]="{
                'resize-table': app.table.canColumnBeResized(column)
              }"
            >
              <span
                (click)="
                  app.table.toggleSortDirection(column);
                  app.table.sort(app.manufacturerUtils.currentInvDocs)
                "
              >
                {{ app.field.getLabel(column) }}
              </span>
              &nbsp;
              <span *ngIf="column === app.table.sortField">
                <i
                  *ngIf="app.table.sortDirection === 1"
                  class="fa fa-chevron-up"
                ></i>
                <i
                  *ngIf="app.table.sortDirection === -1"
                  class="fa fa-chevron-down"
                ></i>
              </span>
            </div>
          </div>
          <div *ngIf="app.filterTable.getFilterTableColumns(column)">
            <app-filter-table
              [column]="app.mail.getColumnShort(column)"
              [columns]="cleanColumns"
              [docType]="'manufacturer'"
              [cleanDocs]="app.manufacturerUtils.cleanInvDocs"
              [selectedFilters]="selectedFilters"
              (resultedDocs)="
                app.filterTable.getFilterResults($event, 'manufacturer')
              "
              [resetComponent]="resetFilterInputsObservable"
            >
            </app-filter-table>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let doc of app.manufacturerUtils.currentInvDocs">
        <td class="p-1" *ngFor="let column of cleanColumns">
          <div
            [ngClass]="{
              'pl-18': column === 'quantity'
            }"
            [ngSwitch]="app.table.getColumnTypeDisplay(column)"
          >
            <div *ngSwitchCase="'normal'">
              {{ doc[column] }}
            </div>
            <div *ngSwitchCase="'link'">
              <div>
                <a
                  *ngIf="doc['buyNowLink']"
                  [href]="doc['buyNowLink']"
                  target="_blank"
                >
                  <span>{{ doc[column] }}</span>
                </a>
                <span *ngIf="!doc['buyNowLink']">{{ doc[column] }}</span>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td *ngIf="loading" colspan="99" class="text-center">
          {{ this.app.text.core.loading }}
        </td>
        <td
          *ngIf="!loading && app.manufacturerUtils.currentInvDocs.length === 0"
          colspan="99"
          class="text-center"
        >
          {{ app.text.app.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
