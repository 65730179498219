import { Component, Input } from "@angular/core";
import { getApp } from "../app";
import { Post } from "../../../../shared/models/post";
import { Customer } from "../../../../shared/types/customers";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { doc2Model, model2Doc } from "../api.service";
import { COMMENT, SOLUTION, STREAM, TASK } from "../post/post.service.type";
import { Subscription } from "rxjs";

@Component({
  selector: "app-post-item",
  templateUrl: "./post-item.component.html",
  styleUrls: ["./post-item.component.scss"],
})
export class PostItemComponent {
  @Input() post = {} as Post;
  @Input() showContent = true;
  threadStatus = "";
  postModel: any = {} as Post;
  showFinalSolutionCheckbox = false;
  completedAt = "";
  completedBy = "";
  deleteQuestion = "";
  currentSolutionNeedsEvaluation = false;
  canEditCurrentPost = true;
  showReopenIcon = false;
  showCompleteIcon = false;
  showGraphicsButton = false;
  incompleteTask = false;
  finalSolution = false;
  comment: string = COMMENT;
  solution: string = SOLUTION;
  task: string = TASK;
  stream: string = STREAM;

  private _postDiscardSubscription: Subscription = new Subscription();

  constructor() {}

  app = getApp((app) => {
    this.app = app;
  });

  ngOnInit() {
    this.postModel = doc2Model("post", this.post);
    this.threadStatus = this.getThreadStatus(this.post);
    this.displaySolutionCheckbox();
    const completed = this.app.taskCompleted.getCompletedTaskDetails(this.post);
    this.completedAt = completed.completedAt;
    this.completedBy = completed.completedBy;
    this.incompleteTask =
      this.post.taskCompleted === false && this.post.typeOfPost === TASK;
    this.finalSolution =
      this.post.acceptedSolution && this.post.typeOfPost === SOLUTION;

    // Subscribe to display the clean post when discard button is clicked
    this._postDiscardSubscription = this.app.post.discardPostSubject.subscribe(
      (value) => {
        if (value) {
          this.postModel = doc2Model("post", this.post);
        }
      }
    );

    // Show the graphic button only for komax and if a certain solution type was chosen
    if (
      this.app.customers.expectCurrent === Customer.KOMAX &&
      this.post.resolveClass === "LTB-Last Time Buy"
    ) {
      this.showGraphicsButton = true;
    }
    // Check if the current solution needs evaluation check and display a message
    if (this.post.resolveClass != null) {
      this.currentSolutionNeedsEvaluation = this.app.post.needEvaluationCheck(
        this.post.resolveClass
      );
    }
    this.canEditCurrentPost = this.app.post.hasEditPermission(this.post);

    // Choose which icon to be displayed fo tasks
    if (this.post.taskResponsible != null && this.post.taskResponsible !== "") {
      this.showCompleteIcon = this.app.taskCompleted.showCompleteTaskIcon(
        this.post
      );
      this.showReopenIcon = this.app.taskCompleted.showReopenTaskIcon(
        this.post
      );
    }

    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.app.taskCompleted.checkOpenTasks(this.post);
    }
  }

  preparePostForEditMode() {
    // Recreate postModel from inint to make sure the _rev & _attachments are updated
    this.postModel = doc2Model("post", this.post);
    this.app.post.model = this.postModel;

    this.app.post.postType =
      this.app.post.model[this.app.fieldId.post.typeOfPost];

    if (this.app.post.postType === TASK) {
      this.app.unlockedId = null;
      this.app.panel.openPanel();
      this.app.panel.selectedItem = model2Doc("post", this.app.post.model);
      this.app.panel.triggerPanel.next(true);
      this.app.post.setRequiredFields(this.app.post.postType, true);
      this.app.post.isReadMode = true;
    } else {
      this.app.post.showSaveButton = true;
      this.app.post.setRequiredFields(this.app.post.postType, true);
    }
  }

  getDeleteQuestionText(post: Post) {
    if (post.resolveClass != null && post.resolveClass !== "") {
      this.deleteQuestion = this.app.text.post.deleteSolutionQuestion;
    } else if (post.taskResponsible) {
      this.deleteQuestion = this.app.text.post.deleteTaskQuestion;
    } else {
      this.deleteQuestion = this.app.text.post.deleteCommentQuestion;
    }
  }

  displaySolutionCheckbox() {
    if (
      this.app.permission.post.showFinalSolution &&
      this.post.typeOfPost === SOLUTION
    ) {
      this.showFinalSolutionCheckbox = true;
    }
  }

  isFinalSolution() {
    if (this.post.acceptedSolution && this.post.acceptedSolution == true) {
      return true;
    }
  }

  getThreadStatus(post: Post): string {
    if (post == null) {
      return "";
    }
    const snapshot = post.snapshot;
    if (
      snapshot == null ||
      snapshot.thread == null ||
      snapshot.thread.omfStatus == null
    ) {
      return "";
    }
    return this.app.field.getOptionText(
      this.app.fieldId.thread.omfStatus,
      snapshot.thread.omfStatus
    );
  }

  hasPermissionToAddAttachment() {
    if (
      this.app.post.sectionType === STREAM &&
      this.post.typeOfPost === TASK &&
      this.app.customers.expectCurrent === Customer.DB
    ) {
      return false;
    }

    if (this.app.permission.post.addAttachment) {
      return true;
    }
    if (this.post.user_id === this.app.user) {
      return true;
    } else {
      return false;
    }
  }

  // Check if in the current post the postField has any value inside
  hasValue(postField: string) {
    if (postField.split(".")[1] == null) {
      return;
    }

    const fieldName = postField.split(".")[1];
    const fieldType = this.app.field.getType(postField);

    switch (fieldType) {
      case "value":
      case "text":
      case "date":
      case "number":
        if (StringUtils.isNullOrEmpty(this.post[fieldName])) {
          return false;
        } else {
          return true;
        }
      case "options":
        if (this.post[fieldName] == null || this.post[fieldName].length === 0) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  getPostFieldValue(postField: string) {
    if (postField.split(".")[1] == null) {
      return;
    } else {
      return this.post[postField.split(".")[1]];
    }
  }

  ngOnDestroy(): void {
    if (this._postDiscardSubscription) {
      this._postDiscardSubscription.unsubscribe();
    }
  }
}
