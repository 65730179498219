import { Component } from "@angular/core";
import { getApp } from "../app";
import { getDocsByType } from "../api.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Docs, Type } from "../../../../shared/components";
import { formatDate } from "../utils/date.util";

@Component({
  selector: "app-export",
  templateUrl: "./debug.component.html",
  styleUrls: ["./debug.component.scss"],
})
export class DebugComponent {
  app = getApp((app) => {
    this.app = app;
  });
  manufacturers: Docs[Type][] = [];
  parts: Docs[Type][] = [];
  alerts: Docs[Type][] = [];
  messages: Docs[Type][] = [];
  all: any[] = [];
  docs: any[] = [];
  downloadJsonHref: SafeUrl = "";
  data = "part";

  constructor(private sanitizer: DomSanitizer) {}

  async ngOnInit() {
    await this.showData("part");
  }

  async showData(data: string) {
    this.downloadJsonHref = "";
    this.data = data;
    switch (data) {
      case "part":
        if (this.parts.length === 0) {
          this.parts = await getDocsByType(
            this.app.customers.expectCurrent,
            data
          );
        }
        this.docs = this.parts;
        break;
      case "manufacturer":
        if (this.manufacturers.length === 0) {
          this.manufacturers = await getDocsByType(
            this.app.customers.expectCurrent,
            data
          );
        }
        this.docs = this.manufacturers;
        break;
      case "alert":
        if (this.alerts.length === 0) {
          this.alerts = await getDocsByType(
            this.app.customers.expectCurrent,
            data
          );
        }
        this.docs = this.alerts;
        break;
      case "message":
        if (this.messages.length === 0) {
          this.messages = await getDocsByType(
            this.app.customers.expectCurrent,
            data
          );
        }
        this.docs = this.messages;
        break;
      case "all":
        if (this.parts.length === 0) {
          this.parts = await getDocsByType(
            this.app.customers.expectCurrent,
            "part"
          );
        }
        if (this.manufacturers.length === 0) {
          this.manufacturers = await getDocsByType(
            this.app.customers.expectCurrent,
            "manufacturer"
          );
        }
        if (this.alerts.length === 0) {
          this.alerts = await getDocsByType(
            this.app.customers.expectCurrent,
            "alert"
          );
        }
        if (this.messages.length === 0) {
          this.messages = await getDocsByType(
            this.app.customers.expectCurrent,
            "message"
          );
        }
        this.docs = this.parts.concat(
          this.manufacturers,
          this.messages,
          this.alerts
        );
        this.all = this.docs;
        this.docs.forEach((element: any) => {
          delete element._rev;
        });
        break;
    }
    this.docs.forEach((element: any) => {
      delete element._rev;
    });
    const jsonDocs = JSON.stringify(this.docs);
    this.downloadJsonHref = this.sanitizer.bypassSecurityTrustUrl(
      "data:application/json;charset=UTF-8," + encodeURIComponent(jsonDocs)
    );
  }

  createFileName() {
    const currentDate = formatDate(new Date(), "YYYYMMDD");
    return (
      currentDate +
      "-" +
      this.app.customers.expectCurrent +
      "-" +
      this.data +
      ".json"
    );
  }
}
