export const countryCodes = {
  AF: {
    text: {
      de: "AF - Afghanistan",
      en: "AF - Afghanistan",
      nl: "AF - Afghanistan",
    },
  },
  AL: {
    text: {
      de: "AL - Albanien",
      en: "AL - Albania",
      nl: "AL - Albanië",
    },
  },
  DZ: {
    text: {
      de: "DZ - Algerien",
      en: "DZ - Algeria",
      nl: "DZ - Algerije",
    },
  },
  AS: {
    text: {
      de: "AS - Amerikanisch-Samoa",
      en: "AS - American Samoa",
      nl: "AS - Amerikaans-Samoa",
    },
  },
  AD: {
    text: {
      de: "AD - Andorra",
      en: "AD - Andorra",
      nl: "AD - Andorra",
    },
  },
  AO: {
    text: {
      de: "AO - Angola",
      en: "AO - Angola",
      nl: "AO - Angola",
    },
  },
  AI: {
    text: {
      de: "AI - Anguilla",
      en: "AI - Anguilla",
      nl: "AI - Anguilla",
    },
  },
  AQ: {
    text: {
      de: "AQ - Antarktis",
      en: "AQ - Antarctica",
      nl: "AQ - Antarctica",
    },
  },
  AG: {
    text: {
      de: "AG - Antigua und Barbuda",
      en: "AG - Antigua and Barbuda",
      nl: "AG - Antigua en Barbuda",
    },
  },
  AR: {
    text: {
      de: "AR - Argentinien",
      en: "AR - Argentina",
      nl: "AR - Argentinië",
    },
  },
  AM: {
    text: {
      de: "AM - Armenien",
      en: "AM - Armenia",
      nl: "AM - Armenië",
    },
  },
  AW: {
    text: {
      de: "AW - Aruba",
      en: "AW - Aruba",
      nl: "AW - Aruba",
    },
  },
  AU: {
    text: {
      de: "AU - Australien",
      en: "AU - Australia",
      nl: "AU - Australië",
    },
  },
  AT: {
    text: {
      de: "AT - Österreich",
      en: "AT - Austria",
      nl: "AT - Oostenrijk",
    },
  },
  AZ: {
    text: {
      de: "AZ - Aserbaidschan",
      en: "AZ - Azerbaijan",
      nl: "AZ - Azerbeidzjan",
    },
  },
  BS: {
    text: {
      de: "BS - Bahamas",
      en: "BS - Bahamas",
      nl: "BS - Bahama's",
    },
  },
  BH: {
    text: {
      de: "BH - Bahrain",
      en: "BH - Bahrain",
      nl: "BH - Bahrein",
    },
  },
  BD: {
    text: {
      de: "BD - Bangladesch",
      en: "BD - Bangladesh",
      nl: "BD - Bangladesh",
    },
  },
  BB: {
    text: {
      de: "BB - Barbados",
      en: "BB - Barbados",
      nl: "BB - Barbados",
    },
  },
  BY: {
    text: {
      de: "BY - Belarus",
      en: "BY - Belarus",
      nl: "BY - Wit-Rusland",
    },
  },
  BE: {
    text: {
      de: "BE - Belgien",
      en: "BE - Belgium",
      nl: "BE - België",
    },
  },
  BZ: {
    text: {
      de: "BZ - Belize",
      en: "BZ - Belize",
      nl: "BZ - Belize",
    },
  },
  BJ: {
    text: {
      de: "BJ - Benin",
      en: "BJ - Benin",
      nl: "BJ - Benin",
    },
  },
  BM: {
    text: {
      de: "BM - Bermuda",
      en: "BM - Bermuda",
      nl: "BM - Bermuda",
    },
  },
  BT: {
    text: {
      de: "BT - Bhutan",
      en: "BT - Bhutan",
      nl: "BT - Bhutan",
    },
  },
  BO: {
    text: {
      de: "BO - Bolivien",
      en: "BO - Bolivia",
      nl: "BO - Bolivië",
    },
  },
  BA: {
    text: {
      de: "BA - Bosnien und Herzegowina",
      en: "BA - Bosnia and Herzegovina",
      nl: "BA - Bosnië-Herzegovina",
    },
  },
  BW: {
    text: {
      de: "BW - Botswana",
      en: "BW - Botswana",
      nl: "BW - Botswana",
    },
  },
  BV: {
    text: {
      de: "BV - Bouvetinsel",
      en: "BV - Bouvet Island",
      nl: "BV - Bouvet Eiland",
    },
  },
  BR: {
    text: {
      de: "BR - Brasilien",
      en: "BR - Brazil",
      nl: "BR - Brazilië",
    },
  },
  IO: {
    text: {
      de: "IO - Britisches Territorium im Indischen Ozean",
      en: "IO - British Indian Ocean Territory",
      nl: "IO - Brits Indische oceaan",
    },
  },
  BN: {
    text: {
      de: "BN - Brunei Darussalam",
      en: "BN - Brunei Darussalam",
      nl: "BN - Brunei Darussalam",
    },
  },
  BG: {
    text: {
      de: "BG - Bulgarien",
      en: "BG - Bulgaria",
      nl: "BG - Bulgarije",
    },
  },
  BF: {
    text: {
      de: "BF - Burkina Faso",
      en: "BF - Burkina Faso",
      nl: "BF - Burkina Faso",
    },
  },
  BI: {
    text: {
      de: "BI - Burundi",
      en: "BI - Burundi",
      nl: "BI - Burundi",
    },
  },
  KH: {
    text: {
      de: "KH - Kambodscha",
      en: "KH - Cambodia",
      nl: "KH - Cambodja",
    },
  },
  CM: {
    text: {
      de: "CM - Kamerun",
      en: "CM - Cameroon",
      nl: "CM - Kameroen",
    },
  },
  CA: {
    text: {
      de: "CA - Kanada",
      en: "CA - Canada",
      nl: "CA - Canada",
    },
  },
  CV: {
    text: {
      de: "CV - Kap Verde",
      en: "CV - Cape Verde",
      nl: "CV - Kaapverdië",
    },
  },
  KY: {
    text: {
      de: "KY - Kaimaninseln",
      en: "KY - Cayman Islands",
      nl: "KY - Kaaimaneilanden",
    },
  },
  CF: {
    text: {
      de: "CF - Zentralafrikanische Republik",
      en: "CF - Central African Republic",
      nl: "CF - Centraal-Afrikaanse Republiek",
    },
  },
  TD: {
    text: {
      de: "TD - Tschad",
      en: "TD - Chad",
      nl: "TD - Tsjaad",
    },
  },
  CL: {
    text: {
      de: "CL - Chile",
      en: "CL - Chile",
      nl: "CL - Chili",
    },
  },
  CN: {
    text: {
      de: "CN - China",
      en: "CN - People's Republic of China",
      nl: "CN - China",
    },
  },
  CX: {
    text: {
      de: "CX - Weihnachtsinsel",
      en: "CX - Christmas Island",
      nl: "CX - Christmaseiland",
    },
  },
  CC: {
    text: {
      de: "CC - Kokosinseln",
      en: "CC - Cocos (Keeling) Islands",
      nl: "CC - Cocoseilanden",
    },
  },
  CO: {
    text: {
      de: "CO - Kolumbien",
      en: "CO - Colombia",
      nl: "CO - Colombia",
    },
  },
  KM: {
    text: {
      de: "KM - Komoren",
      en: "KM - Comoros",
      nl: "KM - Comoren",
    },
  },
  CG: {
    text: {
      de: "CG - Republik Kongo",
      en: "CG - Republic of the Congo",
      nl: "CG - Congo, Volksrepubliek",
    },
  },
  CD: {
    text: {
      de: "CD - Kongo",
      en: "CD - Democratic Republic of the Congo",
      nl: "CD - Congo, Democratische Republiek",
    },
  },
  CK: {
    text: {
      de: "CK - Cookinseln",
      en: "CK - Cook Islands",
      nl: "CK - Cookeilanden",
    },
  },
  CR: {
    text: {
      de: "CR - Costa Rica",
      en: "CR - Costa Rica",
      nl: "CR - Costa Rica",
    },
  },
  CI: {
    text: {
      de: "CI - Elfenbeinküste",
      en: "CI - Cote D'Ivoire",
      nl: "CI - Ivoorkust",
    },
  },
  HR: {
    text: {
      de: "HR - Kroatien",
      en: "HR - Croatia",
      nl: "HR - Kroatië",
    },
  },
  CU: {
    text: {
      de: "CU - Kuba",
      en: "CU - Cuba",
      nl: "CU - Cuba",
    },
  },
  CY: {
    text: {
      de: "CY - Zypern",
      en: "CY - Cyprus",
      nl: "CY - Cyprus",
    },
  },
  CZ: {
    text: {
      de: "CZ - Tschechische Republik",
      en: "CZ - Czech Republic",
      nl: "CZ - Tsjechië",
    },
  },
  DK: {
    text: {
      de: "DK - Dänemark",
      en: "DK - Denmark",
      nl: "DK - Denemarken",
    },
  },
  DJ: {
    text: {
      de: "DJ - Dschibuti",
      en: "DJ - Djibouti",
      nl: "DJ - Djibouti",
    },
  },
  DM: {
    text: {
      de: "DM - Dominica",
      en: "DM - Dominica",
      nl: "DM - Dominica",
    },
  },
  DO: {
    text: {
      de: "DO - Dominikanische Republik",
      en: "DO - Dominican Republic",
      nl: "DO - Dominicaanse Republiek",
    },
  },
  EC: {
    text: {
      de: "EC - Ecuador",
      en: "EC - Ecuador",
      nl: "EC - Ecuador",
    },
  },
  EG: {
    text: {
      de: "EG - Ägypten",
      en: "EG - Egypt",
      nl: "EG - Egypte",
    },
  },
  SV: {
    text: {
      de: "SV - El Salvador",
      en: "SV - El Salvador",
      nl: "SV - El Salvador",
    },
  },
  GQ: {
    text: {
      de: "GQ - Äquatorialguinea",
      en: "GQ - Equatorial Guinea",
      nl: "GQ - Equatoriaal-Guinea",
    },
  },
  ER: {
    text: {
      de: "ER - Eritrea",
      en: "ER - Eritrea",
      nl: "ER - Eritrea",
    },
  },
  EE: {
    text: {
      de: "EE - Estland",
      en: "EE - Estonia",
      nl: "EE - Estland",
    },
  },
  ET: {
    text: {
      de: "ET - Äthiopien",
      en: "ET - Ethiopia",
      nl: "ET - Ethiopië",
    },
  },
  FK: {
    text: {
      de: "FK - Falklandinseln",
      en: "FK - Falkland Islands (Malvinas)",
      nl: "FK - Falklandeilanden",
    },
  },
  FO: {
    text: {
      de: "FO - Färöer",
      en: "FO - Faroe Islands",
      nl: "FO - Faeröer",
    },
  },
  FJ: {
    text: {
      de: "FJ - Fidschi",
      en: "FJ - Fiji",
      nl: "FJ - Fiji",
    },
  },
  FI: {
    text: {
      de: "FI - Finnland",
      en: "FI - Finland",
      nl: "FI - Finland",
    },
  },
  FR: {
    text: {
      de: "FR - Frankreich",
      en: "FR - France",
      nl: "FR - Frankrijk",
    },
  },
  GF: {
    text: {
      de: "GF - Französisch-Guayana",
      en: "GF - French Guiana",
      nl: "GF - Frans-Guyana",
    },
  },
  PF: {
    text: {
      de: "PF - Französisch-Polynesien",
      en: "PF - French Polynesia",
      nl: "PF - Frans-Polynesië",
    },
  },
  TF: {
    text: {
      de: "TF - Französische Süd- und Antarktisgebiete",
      en: "TF - French Southern Territories",
      nl: "TF - Franse Zuidelijke Gebieden",
    },
  },
  GA: {
    text: {
      de: "GA - Gabun",
      en: "GA - Gabon",
      nl: "GA - Gabon",
    },
  },
  GM: {
    text: {
      de: "GM - Gambia",
      en: "GM - Republic of The Gambia",
      nl: "GM - Gambia",
    },
  },
  GE: {
    text: {
      de: "GE - Georgien",
      en: "GE - Georgia",
      nl: "GE - Georgië",
    },
  },
  DE: {
    text: {
      de: "DE - Deutschland",
      en: "DE - Germany",
      nl: "DE - Duitsland",
    },
  },
  GH: {
    text: {
      de: "GH - Ghana",
      en: "GH - Ghana",
      nl: "GH - Ghana",
    },
  },
  GI: {
    text: {
      de: "GI - Gibraltar",
      en: "GI - Gibraltar",
      nl: "GI - Gibraltar",
    },
  },
  GR: {
    text: {
      de: "GR - Griechenland",
      en: "GR - Greece",
      nl: "GR - Griekenland",
    },
  },
  GL: {
    text: {
      de: "GL - Grönland",
      en: "GL - Greenland",
      nl: "GL - Groenland",
    },
  },
  GD: {
    text: {
      de: "GD - Grenada",
      en: "GD - Grenada",
      nl: "GD - Grenada",
    },
  },
  GP: {
    text: {
      de: "GP - Guadeloupe",
      en: "GP - Guadeloupe",
      nl: "GP - Guadeloupe",
    },
  },
  GU: {
    text: {
      de: "GU - Guam",
      en: "GU - Guam",
      nl: "GU - Guam",
    },
  },
  GT: {
    text: {
      de: "GT - Guatemala",
      en: "GT - Guatemala",
      nl: "GT - Guatemala",
    },
  },
  GN: {
    text: {
      de: "GN - Guinea",
      en: "GN - Guinea",
      nl: "GN - Guinea",
    },
  },
  GW: {
    text: {
      de: "GW - Guinea-Bissau",
      en: "GW - Guinea-Bissau",
      nl: "GW - Guinee-Bissau",
    },
  },
  GY: {
    text: {
      de: "GY - Guyana",
      en: "GY - Guyana",
      nl: "GY - Guyana",
    },
  },
  HT: {
    text: {
      de: "HT - Haiti",
      en: "HT - Haiti",
      nl: "HT - Haïti",
    },
  },
  HM: {
    text: {
      de: "HM - Heard und McDonaldinseln",
      en: "HM - Heard Island and McDonald Islands",
      nl: "HM - Heard en McDonaldeilanden",
    },
  },
  VA: {
    text: {
      de: "VA - Vatikanstadt",
      en: "VA - Holy See (Vatican City State)",
      nl: "VA - Heilige Stoel",
    },
  },
  HN: {
    text: {
      de: "HN - Honduras",
      en: "HN - Honduras",
      nl: "HN - Honduras",
    },
  },
  HK: {
    text: {
      de: "HK - Hongkong",
      en: "HK - Hong Kong",
      nl: "HK - Hong Kong",
    },
  },
  HU: {
    text: {
      de: "HU - Ungarn",
      en: "HU - Hungary",
      nl: "HU - Hongarije",
    },
  },
  IS: {
    text: {
      de: "IS - Island",
      en: "IS - Iceland",
      nl: "IS - IJsland",
    },
  },
  IN: {
    text: {
      de: "IN - Indien",
      en: "IN - India",
      nl: "IN - India",
    },
  },
  ID: {
    text: {
      de: "ID - Indonesien",
      en: "ID - Indonesia",
      nl: "ID - Indonesië",
    },
  },
  IR: {
    text: {
      de: "IR - Iran",
      en: "IR - Islamic Republic of Iran",
      nl: "IR - Iran",
    },
  },
  IQ: {
    text: {
      de: "IQ - Irak",
      en: "IQ - Iraq",
      nl: "IQ - Irak",
    },
  },
  IE: {
    text: {
      de: "IE - Irland",
      en: "IE - Ireland",
      nl: "IE - Ierland",
    },
  },
  IL: {
    text: {
      de: "IL - Israel",
      en: "IL - Israel",
      nl: "IL - Israël",
    },
  },
  IT: {
    text: {
      de: "IT - Italien",
      en: "IT - Italy",
      nl: "IT - Italië",
    },
  },
  JM: {
    text: {
      de: "JM - Jamaika",
      en: "JM - Jamaica",
      nl: "JM - Jamaica",
    },
  },
  JP: {
    text: {
      de: "JP - Japan",
      en: "JP - Japan",
      nl: "JP - Japan",
    },
  },
  JO: {
    text: {
      de: "JO - Jordanien",
      en: "JO - Jordan",
      nl: "JO - Jordanië",
    },
  },
  KZ: {
    text: {
      de: "KZ - Kasachstan",
      en: "KZ - Kazakhstan",
      nl: "KZ - Kazachstan",
    },
  },
  KE: {
    text: {
      de: "KE - Kenia",
      en: "KE - Kenya",
      nl: "KE - Kenia",
    },
  },
  KI: {
    text: {
      de: "KI - Kiribati",
      en: "KI - Kiribati",
      nl: "KI - Kiribati",
    },
  },
  KP: {
    text: {
      de: "KP - Nordkorea",
      en: "KP - North Korea",
      nl: "KP - Noord-Korea",
    },
  },
  KR: {
    text: {
      de: "KR - Südkorea",
      en: "KR - South Korea",
      nl: "KR - Zuid-Korea",
    },
  },
  KW: {
    text: {
      de: "KW - Kuwait",
      en: "KW - Kuwait",
      nl: "KW - Koeweit",
    },
  },
  KG: {
    text: {
      de: "KG - Kirgisistan",
      en: "KG - Kyrgyzstan",
      nl: "KG - Kirgizstan",
    },
  },
  LA: {
    text: {
      de: "LA - Laos",
      en: "LA - Lao People's Democratic Republic",
      nl: "LA - Laos",
    },
  },
  LV: {
    text: {
      de: "LV - Lettland",
      en: "LV - Latvia",
      nl: "LV - Letland",
    },
  },
  LB: {
    text: {
      de: "LB - Libanon",
      en: "LB - Lebanon",
      nl: "LB - Libanon",
    },
  },
  LS: {
    text: {
      de: "LS - Lesotho",
      en: "LS - Lesotho",
      nl: "LS - Lesotho",
    },
  },
  LR: {
    text: {
      de: "LR - Liberia",
      en: "LR - Liberia",
      nl: "LR - Liberia",
    },
  },
  LY: {
    text: {
      de: "LY - Libyen",
      en: "LY - Libya",
      nl: "LY - Libië",
    },
  },
  LI: {
    text: {
      de: "LI - Liechtenstein",
      en: "LI - Liechtenstein",
      nl: "LI - Liechtenstein",
    },
  },
  LT: {
    text: {
      de: "LT - Litauen",
      en: "LT - Lithuania",
      nl: "LT - Litouwen",
    },
  },
  LU: {
    text: {
      de: "LU - Luxemburg",
      en: "LU - Luxembourg",
      nl: "LU - Luxemburg",
    },
  },
  MO: {
    text: {
      de: "MO - Macao",
      en: "MO - Macao",
      nl: "MO - Macao",
    },
  },
  MG: {
    text: {
      de: "MG - Madagaskar",
      en: "MG - Madagascar",
      nl: "MG - Madagaskar",
    },
  },
  MW: {
    text: {
      de: "MW - Malawi",
      en: "MW - Malawi",
      nl: "MW - Malawi",
    },
  },
  MY: {
    text: {
      de: "MY - Malaysia",
      en: "MY - Malaysia",
      nl: "MY - Maleisië",
    },
  },
  MV: {
    text: {
      de: "MV - Malediven",
      en: "MV - Maldives",
      nl: "MV - Maldiven",
    },
  },
  ML: {
    text: {
      de: "ML - Mali",
      en: "ML - Mali",
      nl: "ML - Mali",
    },
  },
  MT: {
    text: {
      de: "MT - Malta",
      en: "MT - Malta",
      nl: "MT - Malta",
    },
  },
  MH: {
    text: {
      de: "MH - Marshallinseln",
      en: "MH - Marshall Islands",
      nl: "MH - Marshalleilanden",
    },
  },
  MQ: {
    text: {
      de: "MQ - Martinique",
      en: "MQ - Martinique",
      nl: "MQ - Martinique",
    },
  },
  MR: {
    text: {
      de: "MR - Mauretanien",
      en: "MR - Mauritania",
      nl: "MR - Mauritanië",
    },
  },
  MU: {
    text: {
      de: "MU - Mauritius",
      en: "MU - Mauritius",
      nl: "MU - Mauritius",
    },
  },
  YT: {
    text: {
      de: "YT - Mayotte",
      en: "YT - Mayotte",
      nl: "YT - Mayotte",
    },
  },
  MX: {
    text: {
      de: "MX - Mexiko",
      en: "MX - Mexico",
      nl: "MX - Mexico",
    },
  },
  FM: {
    text: {
      de: "FM - Mikronesien",
      en: "FM - Micronesia, Federated States of",
      nl: "FM - Micronesië, Federale Staten",
    },
  },
  MD: {
    text: {
      de: "MD - Moldawien",
      en: "MD - Moldova, Republic of",
      nl: "MD - Moldavië",
    },
  },
  MC: {
    text: {
      de: "MC - Monaco",
      en: "MC - Monaco",
      nl: "MC - Monaco",
    },
  },
  MN: {
    text: {
      de: "MN - Mongolei",
      en: "MN - Mongolia",
      nl: "MN - Mongolië",
    },
  },
  MS: {
    text: {
      de: "MS - Montserrat",
      en: "MS - Montserrat",
      nl: "MS - Montserrat",
    },
  },
  MA: {
    text: {
      de: "MA - Marokko",
      en: "MA - Morocco",
      nl: "MA - Marokko",
    },
  },
  MZ: {
    text: {
      de: "MZ - Mosambik",
      en: "MZ - Mozambique",
      nl: "MZ - Mozambique",
    },
  },
  MM: {
    text: {
      de: "MM - Myanmar",
      en: "MM - Myanmar",
      nl: "MM - Myanmar",
    },
  },
  NA: {
    text: {
      de: "NA - Namibia",
      en: "NA - Namibia",
      nl: "NA - Namibië",
    },
  },
  NR: {
    text: {
      de: "NR - Nauru",
      en: "NR - Nauru",
      nl: "NR - Nauru",
    },
  },
  NP: {
    text: {
      de: "NP - Nepal",
      en: "NP - Nepal",
      nl: "NP - Nepal",
    },
  },
  NL: {
    text: {
      de: "NL - Niederlande",
      en: "NL - Netherlands",
      nl: "NL - Nederland",
    },
  },
  NC: {
    text: {
      de: "NC - Neukaledonien",
      en: "NC - New Caledonia",
      nl: "NC - Nieuw-Caledonië",
    },
  },
  NZ: {
    text: {
      de: "NZ - Neuseeland",
      en: "NZ - New Zealand",
      nl: "NZ - Nieuw-Zeeland",
    },
  },
  NI: {
    text: {
      de: "NI - Nicaragua",
      en: "NI - Nicaragua",
      nl: "NI - Nicaragua",
    },
  },
  NE: {
    text: {
      de: "NE - Niger",
      en: "NE - Niger",
      nl: "NE - Niger",
    },
  },
  NG: {
    text: {
      de: "NG - Nigeria",
      en: "NG - Nigeria",
      nl: "NG - Nigeria",
    },
  },
  NU: {
    text: {
      de: "NU - Niue",
      en: "NU - Niue",
      nl: "NU - Niue",
    },
  },
  NF: {
    text: {
      de: "NF - Norfolkinsel",
      en: "NF - Norfolk Island",
      nl: "NF - Norfolk",
    },
  },
  MP: {
    text: {
      de: "MP - Nördliche Marianen",
      en: "MP - Northern Mariana Islands",
      nl: "MP - Noordelijke Marianen",
    },
  },
  MK: {
    text: {
      de: "MK - Nordmazedonien",
      en: "MK - The Republic of North Macedonia",
      nl: "MK - Noord-Macedonië",
    },
  },
  NO: {
    text: {
      de: "NO - Norwegen",
      en: "NO - Norway",
      nl: "NO - Noorwegen",
    },
  },
  OM: {
    text: {
      de: "OM - Oman",
      en: "OM - Oman",
      nl: "OM - Oman",
    },
  },
  PK: {
    text: {
      de: "PK - Pakistan",
      en: "PK - Pakistan",
      nl: "PK - Pakistan",
    },
  },
  PW: {
    text: {
      de: "PW - Palau",
      en: "PW - Palau",
      nl: "PW - Palau",
    },
  },
  PS: {
    text: {
      de: "PS - Staat Palästina",
      en: "PS - State of Palestine",
      nl: "PS - Palestina",
    },
  },
  PA: {
    text: {
      de: "PA - Panama",
      en: "PA - Panama",
      nl: "PA - Panama",
    },
  },
  PG: {
    text: {
      de: "PG - Papua-Neuguinea",
      en: "PG - Papua New Guinea",
      nl: "PG - Papoea-Nieuw-Guinea",
    },
  },
  PY: {
    text: {
      de: "PY - Paraguay",
      en: "PY - Paraguay",
      nl: "PY - Paraguay",
    },
  },
  PE: {
    text: {
      de: "PE - Peru",
      en: "PE - Peru",
      nl: "PE - Peru",
    },
  },
  PH: {
    text: {
      de: "PH - Philippinen",
      en: "PH - Philippines",
      nl: "PH - Filipijnen",
    },
  },
  PN: {
    text: {
      de: "PN - Pitcairninseln",
      en: "PN - Pitcairn",
      nl: "PN - Pitcairn",
    },
  },
  PL: {
    text: {
      de: "PL - Polen",
      en: "PL - Poland",
      nl: "PL - Polen",
    },
  },
  PT: {
    text: {
      de: "PT - Portugal",
      en: "PT - Portugal",
      nl: "PT - Portugal",
    },
  },
  PR: {
    text: {
      de: "PR - Puerto Rico",
      en: "PR - Puerto Rico",
      nl: "PR - Puerto Rico",
    },
  },
  QA: {
    text: {
      de: "QA - Katar",
      en: "QA - Qatar",
      nl: "QA - Qatar",
    },
  },
  RE: {
    text: {
      de: "RE - Réunion",
      en: "RE - Reunion",
      nl: "RE - Réunion",
    },
  },
  RO: {
    text: {
      de: "RO - Rumänien",
      en: "RO - Romania",
      nl: "RO - Roemenië",
    },
  },
  RU: {
    text: {
      de: "RU - Russische Föderation",
      en: "RU - Russian Federation",
      nl: "RU - Rusland",
    },
  },
  RW: {
    text: {
      de: "RW - Ruanda",
      en: "RW - Rwanda",
      nl: "RW - Rwanda",
    },
  },
  SH: {
    text: {
      de: "SH - St. Helena",
      en: "SH - Saint Helena",
      nl: "SH - Sint-Helena",
    },
  },
  KN: {
    text: {
      de: "KN - St. Kitts und Nevis",
      en: "KN - Saint Kitts and Nevis",
      nl: "KN - Saint Kitts en Nevis",
    },
  },
  LC: {
    text: {
      de: "LC - St. Lucia",
      en: "LC - Saint Lucia",
      nl: "LC - Saint Lucia",
    },
  },
  PM: {
    text: {
      de: "PM - Saint-Pierre und Miquelon",
      en: "PM - Saint Pierre and Miquelon",
      nl: "PM - Saint-Pierre en Miquelon",
    },
  },
  VC: {
    text: {
      de: "VC - St. Vincent und die Grenadinen",
      en: "VC - Saint Vincent and the Grenadines",
      nl: "VC - Saint Vincent en de Grenadines",
    },
  },
  WS: {
    text: {
      de: "WS - Samoa",
      en: "WS - Samoa",
      nl: "WS - Samoa",
    },
  },
  SM: {
    text: {
      de: "SM - San Marino",
      en: "SM - San Marino",
      nl: "SM - San Marino",
    },
  },
  ST: {
    text: {
      de: "ST - São Tomé und Príncipe",
      en: "ST - Sao Tome and Principe",
      nl: "ST - São Tomé en Principe",
    },
  },
  SA: {
    text: {
      de: "SA - Saudi-Arabien",
      en: "SA - Saudi Arabia",
      nl: "SA - Saudi-Arabië",
    },
  },
  SN: {
    text: {
      de: "SN - Senegal",
      en: "SN - Senegal",
      nl: "SN - Senegal",
    },
  },
  SC: {
    text: {
      de: "SC - Seychellen",
      en: "SC - Seychelles",
      nl: "SC - Seychellen",
    },
  },
  SL: {
    text: {
      de: "SL - Sierra Leone",
      en: "SL - Sierra Leone",
      nl: "SL - Sierra Leone",
    },
  },
  SG: {
    text: {
      de: "SG - Singapur",
      en: "SG - Singapore",
      nl: "SG - Singapore",
    },
  },
  SK: {
    text: {
      de: "SK - Slowakei",
      en: "SK - Slovakia",
      nl: "SK - Slowakije",
    },
  },
  SI: {
    text: {
      de: "SI - Slowenien",
      en: "SI - Slovenia",
      nl: "SI - Slovenië",
    },
  },
  SB: {
    text: {
      de: "SB - Salomonen",
      en: "SB - Solomon Islands",
      nl: "SB - Salomonseilanden",
    },
  },
  SO: {
    text: {
      de: "SO - Somalia",
      en: "SO - Somalia",
      nl: "SO - Somalië",
    },
  },
  ZA: {
    text: {
      de: "ZA - Südafrika",
      en: "ZA - South Africa",
      nl: "ZA - Zuid-Afrika",
    },
  },
  GS: {
    text: {
      de: "GS - Südgeorgien und die Südlichen Sandwichinseln",
      en: "GS - South Georgia and the South Sandwich Islands",
      nl: "GS - Zuid-Georgia en de Zuidelijke Sandwicheilanden",
    },
  },
  ES: {
    text: {
      de: "ES - Spanien",
      en: "ES - Spain",
      nl: "ES - Spanje",
    },
  },
  LK: {
    text: {
      de: "LK - Sri Lanka",
      en: "LK - Sri Lanka",
      nl: "LK - Sri Lanka",
    },
  },
  SD: {
    text: {
      de: "SD - Sudan",
      en: "SD - Sudan",
      nl: "SD - Soedan",
    },
  },
  SR: {
    text: {
      de: "SR - Suriname",
      en: "SR - Suriname",
      nl: "SR - Suriname",
    },
  },
  SJ: {
    text: {
      de: "SJ - Svalbard und Jan Mayen",
      en: "SJ - Svalbard and Jan Mayen",
      nl: "SJ - Spitsbergen en Jan Mayen",
    },
  },
  SZ: {
    text: {
      de: "SZ - Eswatini",
      en: "SZ - Eswatini",
      nl: "SZ - Ngwane, Koninkrijk Eswatini",
    },
  },
  SE: {
    text: {
      de: "SE - Schweden",
      en: "SE - Sweden",
      nl: "SE - Zweden",
    },
  },
  CH: {
    text: {
      de: "CH - Schweiz",
      en: "CH - Switzerland",
      nl: "CH - Zwitserland",
    },
  },
  SY: {
    text: {
      de: "SY - Syrien, Arabische Republik",
      en: "SY - Syrian Arab Republic",
      nl: "SY - Syrië",
    },
  },
  TW: {
    text: {
      de: "TW - Taiwan",
      en: "TW - Taiwan, Province of China",
      nl: "TW - Taiwan",
    },
  },
  TJ: {
    text: {
      de: "TJ - Tadschikistan",
      en: "TJ - Tajikistan",
      nl: "TJ - Tadzjikistan",
    },
  },
  TZ: {
    text: {
      de: "TZ - Tansania, Vereinigte Republik",
      en: "TZ - United Republic of Tanzania",
      nl: "TZ - Tanzania, Verenigde Republiek",
    },
  },
  TH: {
    text: {
      de: "TH - Thailand",
      en: "TH - Thailand",
      nl: "TH - Thailand",
    },
  },
  TL: {
    text: {
      de: "TL - Osttimor",
      en: "TL - Timor-Leste",
      nl: "TL - Timor Leste",
    },
  },
  TG: {
    text: {
      de: "TG - Togo",
      en: "TG - Togo",
      nl: "TG - Togo",
    },
  },
  TK: {
    text: {
      de: "TK - Tokelau",
      en: "TK - Tokelau",
      nl: "TK - Tokelau",
    },
  },
  TO: {
    text: {
      de: "TO - Tonga",
      en: "TO - Tonga",
      nl: "TO - Tonga",
    },
  },
  TT: {
    text: {
      de: "TT - Trinidad und Tobago",
      en: "TT - Trinidad and Tobago",
      nl: "TT - Trinidad en Tobago",
    },
  },
  TN: {
    text: {
      de: "TN - Tunesien",
      en: "TN - Tunisia",
      nl: "TN - Tunesië",
    },
  },
  TR: {
    text: {
      de: "TR - Türkei",
      en: "TR - Turkey",
      nl: "TR - Turkije",
    },
  },
  TM: {
    text: {
      de: "TM - Turkmenistan",
      en: "TM - Turkmenistan",
      nl: "TM - Turkmenistan",
    },
  },
  TC: {
    text: {
      de: "TC - Turks- und Caicosinseln",
      en: "TC - Turks and Caicos Islands",
      nl: "TC - Turks- en Caicoseilanden",
    },
  },
  TV: {
    text: {
      de: "TV - Tuvalu",
      en: "TV - Tuvalu",
      nl: "TV - Tuvalu",
    },
  },
  UG: {
    text: {
      de: "UG - Uganda",
      en: "UG - Uganda",
      nl: "UG - Oeganda",
    },
  },
  UA: {
    text: {
      de: "UA - Ukraine",
      en: "UA - Ukraine",
      nl: "UA - Oekraïne",
    },
  },
  AE: {
    text: {
      de: "AE - Vereinigte Arabische Emirate",
      en: "AE - United Arab Emirates",
      nl: "AE - Verenigde Arabische Emiraten",
    },
  },
  GB: {
    text: {
      de: "GB - Vereinigtes Königreich",
      en: "GB - United Kingdom",
      nl: "GB - Groot-Brittannië",
    },
  },
  US: {
    text: {
      de: "US - Vereinigte Staaten von Amerika",
      en: "US - United States of America",
      nl: "US - Verenigde Staten van Amerika",
    },
  },
  UM: {
    text: {
      de: "UM - United States Minor Outlying Islands",
      en: "UM - United States Minor Outlying Islands",
      nl: "UM - Ver afgelegen eilandjes van de Verenigde Staten",
    },
  },
  UY: {
    text: {
      de: "UY - Uruguay",
      en: "UY - Uruguay",
      nl: "UY - Uruguay",
    },
  },
  UZ: {
    text: {
      de: "UZ - Usbekistan",
      en: "UZ - Uzbekistan",
      nl: "UZ - Oezbekistan",
    },
  },
  VU: {
    text: {
      de: "VU - Vanuatu",
      en: "VU - Vanuatu",
      nl: "VU - Vanuatu",
    },
  },
  VE: {
    text: {
      de: "VE - Venezuela",
      en: "VE - Venezuela",
      nl: "VE - Venezuela",
    },
  },
  VN: {
    text: {
      de: "VN - Vietnam",
      en: "VN - Vietnam",
      nl: "VN - Vietnam",
    },
  },
  VG: {
    text: {
      de: "VG - Britische Jungferninseln",
      en: "VG - Virgin Islands, British",
      nl: "VG - Maagdeneilanden, Britse",
    },
  },
  VI: {
    text: {
      de: "VI - Amerikanische Jungferninseln",
      en: "VI - Virgin Islands, U.S.",
      nl: "VI - Maagdeneilanden, Amerikaanse",
    },
  },
  WF: {
    text: {
      de: "WF - Wallis und Futuna",
      en: "WF - Wallis and Futuna",
      nl: "WF - Wallis en Futuna",
    },
  },
  EH: {
    text: {
      de: "EH - Westsahara",
      en: "EH - Western Sahara",
      nl: "EH - Westelijke Sahara",
    },
  },
  YE: {
    text: {
      de: "YE - Jemen",
      en: "YE - Yemen",
      nl: "YE - Jemen",
    },
  },
  ZM: {
    text: {
      de: "ZM - Sambia",
      en: "ZM - Zambia",
      nl: "ZM - Zambia",
    },
  },
  ZW: {
    text: {
      de: "ZW - Simbabwe",
      en: "ZW - Zimbabwe",
      nl: "ZW - Zimbabwe",
    },
  },
  AX: {
    text: {
      de: "AX - Åland",
      en: "AX - Åland Islands",
      nl: "AX - Åland",
    },
  },
  BQ: {
    text: {
      de: "BQ - Bonaire",
      en: "BQ - Bonaire, Sint Eustatius and Saba",
      nl: "BQ - Bonaire, Sint Eustatius en Saba",
    },
  },
  CW: {
    text: {
      de: "CW - Curaçao",
      en: "CW - Curaçao",
      nl: "CW - Curaçao",
    },
  },
  GG: {
    text: {
      de: "GG - Guernsey",
      en: "GG - Guernsey",
      nl: "GG - Guernsey",
    },
  },
  IM: {
    text: {
      de: "IM - Insel Man",
      en: "IM - Isle of Man",
      nl: "IM - Man Eiland",
    },
  },
  JE: {
    text: {
      de: "JE - Jersey",
      en: "JE - Jersey",
      nl: "JE - Jersey",
    },
  },
  ME: {
    text: {
      de: "ME - Montenegro",
      en: "ME - Montenegro",
      nl: "ME - Montenegro",
    },
  },
  BL: {
    text: {
      de: "BL - Saint-Barthélemy",
      en: "BL - Saint Barthélemy",
      nl: "BL - Saint Barthélemy",
    },
  },
  MF: {
    text: {
      de: "MF - Saint-Martin",
      en: "MF - Saint Martin (French part)",
      nl: "MF - Sint-Maarten (Frans deel)",
    },
  },
  RS: {
    text: {
      de: "RS - Serbien",
      en: "RS - Serbia",
      nl: "RS - Servië",
    },
  },
  SX: {
    text: {
      de: "SX - Sint Maarten",
      en: "SX - Sint Maarten (Dutch part)",
      nl: "SX - Sint Maarten",
    },
  },
  SS: {
    text: {
      de: "SS - Südsudan",
      en: "SS - South Sudan",
      nl: "SS - Zuid-Soedan",
    },
  },
  XK: {
    text: {
      de: "XK - Kosovo",
      en: "XK - Kosovo",
      nl: "XK - Kosovo",
    },
  },
};
