import { Component, Input, Output, EventEmitter } from "@angular/core";
import { getApp } from "../app";
import { Observable } from "rxjs";
import { Manufacturer, SeMatch } from "../../../../shared/models/manufacturer";
import { AlertData } from "../../../../shared/models/alert";
import { Part } from "../../../../shared/models/part";
import { Subscription } from "rxjs";
import { StringUtils } from "../../../../shared/utils/string.utils";

class SearchWords {
  [key: string]: string;
}

@Component({
  selector: "app-filter-table",
  templateUrl: "./filter-table.component.html",
  styleUrls: ["./filter-table.component.scss"],
})
export class FilterTableComponent {
  /**
   * @argument selectedFilters is used to store each filter selected, and it will always be
   * reset on the init of a new component
   * @argument resetComponent is currently used only for manufactures and alerts table were
   * different docs can be retrieved depending on the tab selected
   * @argument resultedDocs would always retrieve the current documents filtrated based on
   * selectedFilters
   */

  @Input() column = "";
  @Input() columns: string[] = [];
  @Input() cleanDocs: string[] = [];
  @Input() docType = "";
  @Input() selectedFilters: { columnName: string; value: string }[] = [];
  @Input() resetComponent: Observable<boolean> = new Observable<boolean>();
  @Output() resultedDocs = new EventEmitter<any[]>();
  searchWord = "";
  searchWords: SearchWords = {};
  result: Manufacturer[] | Part[] | AlertData[] | SeMatch[] = [];
  fieldId = "";

  app = getApp((app) => {
    this.app = app;
  });

  private _resetSubscription: Subscription = new Subscription();

  ngOnInit() {
    this.fieldId = this.app.filterTable.getFieldId(this.docType, this.column);
    this.app.filterTable.getOptionsAsValue(this.column);

    // TODO remmove resetComponent input if _resetSubscription workes as espected
    this.resetComponent.subscribe((response) => {
      if (response) {
        this.columns.forEach((column: string) => {
          this.searchWords[column] = "";
        });
      }
    });

    this._resetSubscription = this.app.filterTable.resetAllFilters.subscribe(
      (value) => {
        if (value) {
          this.searchWords[this.column] = "";
          this.selectedFilters.splice(0, this.selectedFilters.length);
          // this.selectedFilters = [];
        }
      }
    );
  }

  onChange(column: string) {
    let docs: any[] = this.cleanDocs;
    this.app.paginator.resetPagination();

    // filter docs based on filters stored into selectedFilters
    if (this.selectedFilters.length > 0) {
      // make a copy of all cleanDocs into partialResult
      let partialResult: any[] = docs;

      for (const selectedFilter of this.selectedFilters) {
        const filterColumnName = selectedFilter.columnName;
        const filterValue = selectedFilter.value;

        if (selectedFilter.columnName !== column) {
          partialResult = this.app.filterTable.filterDocsByFieldType(
            this.docType,
            partialResult,
            filterValue,
            filterColumnName
          );
        }
      }
      docs = partialResult;
    }

    // filter docs based on the current typing filter
    this.result = this.app.filterTable.filterDocsByFieldType(
      this.docType,
      docs,
      this.searchWords[column],
      column
    );
    this.app.filterTable.saveFilterResult(this.docType, this.result);
    return this.resultedDocs.emit(this.result);
  }

  saveFilterInput(column: string) {
    // at least one filter was already saved into selectedFilters array and user is focusing out the second(third, forth ..) input
    if (
      !StringUtils.isNullOrEmpty(this.searchWords[column]) &&
      this.selectedFilters.length > 0
    ) {
      if (!this.columnNameAlreadySaved(column)) {
        this.selectedFilters.push({
          columnName: column,
          value: this.searchWords[column],
        });
      }

      for (const selectedFilter of this.selectedFilters) {
        if (
          selectedFilter.columnName === column &&
          this.searchWords[column] != null
        ) {
          selectedFilter.value = this.searchWords[column];
        }
      }
    } else if (
      this.searchWords[column] == "" &&
      this.selectedFilters.length > 0
    ) {
      this.clearFilter(column);
    } else if (
      !StringUtils.isNullOrEmpty(this.searchWords[column]) &&
      this.selectedFilters.length === 0
    ) {
      this.selectedFilters.push({
        columnName: column,
        value: this.searchWords[column],
      });
    }
  }

  clearFilter(column: string) {
    // remove a filter from the selectedFilters array
    for (let i = 0; i < this.selectedFilters.length; i++) {
      if (this.selectedFilters[i].columnName === column) {
        this.selectedFilters.splice(i, 1);
        this.searchWords[column] = "";
      }
    }
    this.app.filterTable.isDropdownOpen = false;
    // redo the filtration only using the remaining filters if an input was cleared
    this.onChange(column);
  }

  private columnNameAlreadySaved(column: string): boolean {
    const columnNames: string[] = [];

    for (const selectedFilter of this.selectedFilters) {
      columnNames.push(selectedFilter.columnName);
    }

    return columnNames.includes(column);
  }

  ngOnDestroy(): void {
    if (this._resetSubscription) {
      this._resetSubscription.unsubscribe();
    }
  }
}
