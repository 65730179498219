import { AppType, APP_TYPE } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { AttachmentsServiceType } from "./attachments.service.type";
import { Post } from "../../../../shared/models/post";
import { needEvaluation } from "../post/post.service";
import { BehaviorSubject } from "rxjs";
import {
  AttachmentsByTag,
  AttachmentTime,
  AttachmentTimeDocs,
  TagsObject,
} from "./attachments.utils";
import { markAsSensitive, uploadFile, downloadFile } from "../api.service";
import { Customer } from "../../../../shared/types/customers";
import { Type } from "../../../../shared/components";
import { Thread } from "../../../../shared/models/thread";
import { AttachmentHistoryInfo } from "../../../../shared/models/attachment";
import { CustomerName } from "../../../../shared/config/customers";
import { OCComment } from "../../../../shared/models/obsolescence-community/OC-Comment";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";

export class FileInfo {
  uploadedFile: File[] = [];
  uploadedAttachmentsInfo: any[] = [];
}
@Injectable()
export class AttachmentsService implements AttachmentsServiceType {
  caseAttachments: { name: string; id: string; attachmentHistory: any }[] = [];
  commentAttachments: {
    name: string;
    id: string;
    attachmentHistory: any;
  }[] = [];
  solutionAttachments: {
    name: string;
    id: string;
    attachmentHistory: any;
  }[] = [];
  taskAttachments: {
    name: string;
    id: string;
    attachmentHistory: any;
  }[] = [];
  ocPostAttachments: {
    name: string;
    id: string;
    attachmentHistory: any;
  }[] = [];
  ocCommentAttachments: {
    name: string;
    id: string;
    attachmentHistory: any;
  }[] = [];

  tagsSet = new Set<string>();
  tags: string[] = [];
  caseTags: string[] = [];

  caseTagsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  groupingByTagSubject: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);

  updateOCPostAttachments: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);

  tagsObject = {} as TagsObject;

  viewMode = "all";
  sortingOrder = "descending";

  sortDocs = {} as AttachmentTimeDocs;
  allAttachments: { name: string; id: string; attachmentHistory: any }[] = [];
  sortingMode = false;

  uploadedFileInfo: FileInfo = {
    uploadedFile: [],
    uploadedAttachmentsInfo: [],
  };

  ocAttachmentsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  updateBildImage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  getAllAttachments() {
    this.commentAttachments = [];
    this.solutionAttachments = [];
    this.taskAttachments = [];
    this.caseAttachments = [];
    let attachments: any;
    let attachmentsName: string[] = [];

    const comments: Post[] = this.app.post.comments;
    const solutions: Post[] = this.app.post.solutions;
    const tasks: Post[] = this.app.post.tasks;

    if (comments.length > 0) {
      comments.forEach((comment) => {
        if (comment._attachments != null && comment._attachments.length !== 0) {
          attachments = comment._attachments;
          attachmentsName = Object.keys(attachments);
          attachmentsName.forEach((name) => {
            const attHistory = this.app.post.comments
              .filter(
                (post) => post.attachmentHistory && post.attachmentHistory[name]
              )
              .map((post) => post.attachmentHistory[name]);

            this.commentAttachments.push({
              name,
              id: comment._id,
              attachmentHistory: attHistory,
            });
          });
        }
      });
    }
    if (solutions.length > 0) {
      solutions.forEach((solution) => {
        if (
          solution._attachments != null &&
          solution._attachments.length !== 0
        ) {
          attachments = solution._attachments;
          attachmentsName = Object.keys(attachments);
          attachmentsName.forEach((name) => {
            const attHistory = this.app.post.solutions
              .filter(
                (post) => post.attachmentHistory && post.attachmentHistory[name]
              )
              .map((post) => post.attachmentHistory[name]);

            this.solutionAttachments.push({
              name,
              id: solution._id,
              attachmentHistory: attHistory,
            });
          });
        }
      });
    }
    if (tasks.length > 0) {
      tasks.forEach((task) => {
        if (task._attachments != null && task._attachments.length !== 0) {
          attachments = task._attachments;
          attachmentsName = Object.keys(attachments);
          attachmentsName.forEach((name) => {
            const attHistory = this.app.post.tasks
              .filter(
                (post) => post.attachmentHistory && post.attachmentHistory[name]
              )
              .map((post) => post.attachmentHistory[name]);

            this.taskAttachments.push({
              name,
              id: task._id,
              attachmentHistory: attHistory,
            });
          });
        }
      });
    }

    if (this.app.thread.thread["thread._attachments"]) {
      attachments = this.app.thread.thread["thread._attachments"];
      attachmentsName = Object.keys(attachments);
      attachmentsName.forEach((name) => {
        const attHistory =
          this.app.thread.thread["thread.attachmentHistory"][name];
        this.caseAttachments.push({
          name,
          id: this.app.thread.thread["thread._id"],
          attachmentHistory: [attHistory],
        });
      });
    }

    this.allAttachments = [
      ...this.caseAttachments,
      ...this.commentAttachments,
      ...this.solutionAttachments,
      ...this.taskAttachments,
    ];
    this.setTags();
  }

  getAllOCAttachments() {
    this.ocPostAttachments = [];
    this.ocCommentAttachments = [];
    let attachments: any;
    let attachmentsName: string[] = [];
    if (this.app.OCPost.selectedPost._attachments) {
      attachments = this.app.OCPost.selectedPost._attachments;
      attachmentsName = Object.keys(attachments);
      attachmentsName.forEach((name) => {
        const attHistory = this.app.OCPost.selectedPost.attachmentHistory[name];
        this.ocPostAttachments.push({
          name,
          id: this.app.OCPost.selectedPost._id,
          attachmentHistory: [attHistory],
        });
      });
    }

    if (this.app.OCComment.comments.length > 0) {
      this.app.OCComment.comments.forEach((comment: any) => {
        if (comment._attachments != null && comment._attachments.length !== 0) {
          attachments = comment._attachments;
          attachmentsName = Object.keys(attachments);
          attachmentsName.forEach((name) => {
            const attHistory = this.app.OCComment.comments
              .filter(
                (post: any) =>
                  post.attachmentHistory && post.attachmentHistory[name]
              )
              .map((post: any) => post.attachmentHistory[name]);

            this.ocCommentAttachments.push({
              name,
              id: comment._id,
              attachmentHistory: attHistory,
            });
          });
        }
      });
    }

    this.allAttachments = [
      ...this.ocPostAttachments,
      ...this.ocCommentAttachments,
    ];
    const filesNames = this.app.attachments.allAttachments.map(
      (att) => att.name
    );
    this.updateOCPostAttachments.next(filesNames);
  }

  // check if solution attachment it is from a solution that needs a safety evaluation
  markSafetyEvaluationFile(solutionId: string) {
    let result = false;
    if (this.app.customers.expectCurrent === Customer.NS) {
      if (!this.app.post.solutions) {
        return result;
      }

      // get the value of resolve class for each element to see if it is contained in th needEvaluation array
      this.app.post.solutions.forEach((element) => {
        if (
          element.resolveClass != null &&
          solutionId === element._id &&
          needEvaluation.indexOf(element.resolveClass) !== -1
        ) {
          result = true;
        }
      });
      return result;
    }
    return result;
  }

  setTags() {
    const tags: string[] = [];
    let allCaseTags: string[] = [];
    const tagsObject = new TagsObject();

    /** get the list of of tags from each attachment */
    if (this.app.thread.thread["thread._attachments"]) {
      this.caseAttachments.forEach((att) => {
        if (
          this.app.thread.thread["thread.attachmentHistory"][att.name] != null
        ) {
          const attachmentObject = new AttachmentsByTag();
          if (
            this.app.thread.thread["thread.attachmentHistory"][att.name].tags !=
            null
          ) {
            tags.push(
              ...this.app.thread.thread["thread.attachmentHistory"][att.name]
                .tags
            );
            attachmentObject.tagNames.push(
              ...this.app.thread.thread["thread.attachmentHistory"][att.name]
                .tags
            );
          }
          attachmentObject.name = att.name;
          tagsObject.attachmentsByTag.push(attachmentObject);
        }
      });
    }
    this.solutionAttachments.forEach((attachment) => {
      attachment.attachmentHistory[0].tags.forEach((tag: string) => {
        tags.push(tag);
      });
    });
    this.commentAttachments.forEach((attachment) => {
      attachment.attachmentHistory[0].tags.forEach((tag: string) => {
        tags.push(tag);
      });
    });
    this.taskAttachments.forEach((attachment) => {
      attachment.attachmentHistory[0].tags.forEach((tag: string) => {
        tags.push(tag);
      });
    });
    /** create an aray from the array of tags [[tag1], [tag2]] */
    if (tags !== undefined) {
      allCaseTags = tags;
    }
    const uniqueTags = new Set<string>();

    allCaseTags.forEach((tag) => {
      uniqueTags.add(tag);
    });

    /** return the list of unique tags */
    this.caseTags = Array.from(uniqueTags);
    tagsObject.tags = this.caseTags;

    /** add some standard values to the array of options */
    if (this.app.customers.expectCurrent === Customer.DB) {
      this.caseTags.push("sensitive");
    }
    this.caseTags.unshift("noTag");
    this.caseTags.unshift("all");
    this.caseTags.unshift("lastAdded");
    this.caseTags.push("allAttachments");

    this.tagsObject = tagsObject;
    this.caseTagsSubject.next(true);

    return this.caseTags;
  }

  getTagText(tag: string) {
    switch (tag) {
      case "noTag":
        return this.app.text.attachments.noTag;
      case "all":
        return this.app.text.attachments.allTags;
      case "sensitive":
        return this.app.text.attachments.sensitive;
      case "lastAdded":
        return this.app.text.attachments.lastAdded;
      case "allAttachments":
        return this.app.text.attachments.all;
      default:
        return tag;
    }
  }

  getAttachmentsByTag(tag: string) {
    let result: string[] = [];
    this.viewMode = tag;
    switch (tag) {
      case "noTag":
        result = this.allAttachments
          .filter((att) => att.attachmentHistory[0].tags.length === 0)
          .map((att) => att.name);
        break;
      case "all":
        result = this.allAttachments
          .filter((att) => att.attachmentHistory[0].tags.length > 0)
          .map((att) => att.name);
        break;
      case "sensitive":
        result = this.allAttachments
          .filter((att) => att.attachmentHistory[0].fziAttachment === true)
          .map((att) => att.name);
        break;
      case "lastAdded":
        const nonExistingIndex = this.allAttachments.findIndex(
          (att: any) =>
            att.attachmentHistory && att.attachmentHistory[0] === undefined
        );
        const nonExisting = this.allAttachments.find(
          (att: any) =>
            att.attachmentHistory && att.attachmentHistory[0] === undefined
        );

        /** if attachment's is missing it history info then create a new instance and set the default of case create time */
        if (nonExistingIndex !== -1 && nonExisting != null) {
          const attHistory = new AttachmentHistoryInfo();
          nonExisting.attachmentHistory = [attHistory];
          nonExisting.attachmentHistory[0].uploadTime =
            this.app.thread.thread["thread.create_time"];
          nonExisting.attachmentHistory[0].uploadUser =
            this.app.thread.thread["thread.user_id"];
          this.allAttachments.splice(nonExistingIndex, 1);
          this.allAttachments.push(nonExisting);
        }

        const date = new Date();
        date.setMonth(date.getMonth() - 3);
        result = this.allAttachments
          .filter((att) => att.attachmentHistory[0].uploadTime > date)
          .map((att) => att.name);

        break;
      case "allAttachments":
        result = this.allAttachments.map((att) => att.name);
        break;
      default:
        result = this.allAttachments
          .filter((att) => att.attachmentHistory[0].tags.includes(tag))
          .map((att) => att.name);
        break;
    }
    const docs = this.prepareDocsForSorting(result);
    this.groupingByTagSubject.next(docs);
    this.sortingMode = true;
    return result;
  }

  prepareDocsForSorting(docs: string[]) {
    const sortDocs = new AttachmentTimeDocs();
    docs.forEach((attachment) => {
      const attachmentResult = this.allAttachments.find(
        (att) => att.name === attachment
      );
      if (attachmentResult != null) {
        const attachmentDate = new AttachmentTime();
        attachmentDate.uploadTime =
          attachmentResult.attachmentHistory[0].uploadTime;
        attachmentDate.name = attachment;
        sortDocs.docs.push(attachmentDate);
      }
    });
    this.sortDocs = sortDocs;
    const result = this.sortAttachments(this.sortingOrder);

    return result;
  }

  sortAttachments(order: string) {
    const data = this.sortDocs.docs;
    data.sort((a: any, b: any) => {
      const aValue = a["uploadTime"];
      const bValue = b["uploadTime"];
      if (order === "ascending") {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    });
    const result = data.map((att) => att.name);

    if (this.app.customers.expectCurrent === Customer.DB) {
      /** if the customer is DB, the list of the attachments should bring only the attachments
       * that are not marked as sensitive, if the current logged in user has its business area
       * set to FZ / "FZI"
       */
      const docs = [...result];
      docs.forEach((file) => {
        const index = result.findIndex((fileName) => fileName === file);
        if (
          this.app.attachments.getAttachmentHistory(file).fziAttachment ===
            true &&
          this.app.users.currentUser.businessAreaUser === "FZ"
        ) {
          result.splice(index, 1);
        }
      });
    }
    this.groupingByTagSubject.next(result);
    return result;
  }

  getAttachmentHistory(attachment: string) {
    const result = this.allAttachments.find((att) => att.name === attachment);
    if (result != null) {
      return result.attachmentHistory[0];
    }
  }

  async markAttachment(
    fileName: string,
    fileDetails: AttachmentHistoryInfo,
    fileLink: string,
    mark: boolean
  ) {
    fileDetails.fziAttachment = mark;
    await markAsSensitive(fileLink, fileName, mark);
  }

  async getUploadedFileInfo(
    id: string,
    type: Type,
    currentCustomer: CustomerName
  ) {
    const attachments = await this.app.file.getAttachmentsByDocType(
      id,
      type,
      currentCustomer
    );

    const attachmentsFile: File[] = [];
    const attachmentsInfo: any[] = [];
    if (attachments.length !== 0) {
      const entries = Object.entries(attachments);

      for (const entry of entries) {
        const fileName = entry[0];
        const fileLink = [
          currentCustomer,
          type,
          EncodingUtils.encodeBase64(id),
          fileName,
        ].join("/");

        const data = await downloadFile(fileLink);
        const file = new File([data], fileName, {
          type: entry[1].content_type || "",
        });
        attachmentsFile.push(file);
        attachmentsInfo.push(entry[0]);
      }
    }

    this.uploadedFileInfo = {
      uploadedFile: attachmentsFile,
      uploadedAttachmentsInfo: attachmentsInfo,
    };
  }

  async sendFile(
    type: Type,
    id: string,
    currentCustomer = this.app.state.customer
  ) {
    this.app.spinner.showSpinner();
    const currentThread: Thread = this.app.thread.thread;

    if (currentCustomer == null) {
      return;
    }

    // prepare sended attachments to be upload
    const { uploadedFile, uploadedAttachmentsInfo } = this.uploadedFileInfo;
    for (let i = 0; i < uploadedAttachmentsInfo.length; i++) {
      const fileLink = [
        currentCustomer,
        type,
        type == "thread"
          ? EncodingUtils.encodeBase64(currentThread["thread._id"])
          : EncodingUtils.encodeBase64(id),
        uploadedAttachmentsInfo[i],
      ].join("/");
      await uploadFile(uploadedFile[i], fileLink);
    }

    // empty the uploadedFileInfo
    this.clearTheFileInfo();
  }

  clearTheFileInfo() {
    this.uploadedFileInfo = {
      uploadedFile: [],
      uploadedAttachmentsInfo: [],
    };
  }
}
