import { Component, Input, Output, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { Subject, Subscription } from "rxjs";
import { Customer } from "../../../../shared/types/customers";
import { JUMPTOFIRST, NEXT, PREVIOUS } from "./stepper.service.type";
@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent {
  app = getApp((app) => {
    this.app = app;
  });

  @ViewChild("previousStep", { static: false }) previousStep: any;
  @ViewChild("nextStep", { static: false }) nextStep: any;
  @ViewChild("dueDateMaquetCardio", { static: false }) dueDateMaquetCardio: any;
  @ViewChild("uncompletedTasks", { static: false }) uncompletedTasks: any;
  @Input() field = "";
  @Output() select: Subject<string> = new Subject();
  next = NEXT;
  previous = PREVIOUS;
  jumpToFirstStep = JUMPTOFIRST;

  private _threadSubscription: Subscription = new Subscription();
  private _taskSubscription: Subscription = new Subscription();

  constructor() {}
  async ngOnInit() {
    this.app.stepper.getSteps();
    this._threadSubscription = this.app.thread.threadSubject.subscribe(
      async (thread) => {
        this.app.stepper.getStepIndex(thread);
        if (this.app.customers.expectCurrent === Customer.DB) {
          this.app.stepper.getStatusDescription(thread);
          this.app.stepper.getStatusResponsible(thread);
        }
      }
    );
    this._taskSubscription = this.app.tasks.taskSubject.subscribe(
      async (value) => {
        if (value && this.app.customers.expectCurrent === Customer.DB) {
          this.app.stepper.getNumberOfResponsibleLeft();
        }
      }
    );
  }

  getModal(direction: string) {
    const status = this.app.thread.thread["thread.omfStatus"];
    const customer = this.app.customers.expectCurrent;
    switch (direction) {
      case NEXT:
        if (customer === Customer.MAQUETCARDIO && status === "In Bearbeitung") {
          this.app.stepper.direction = NEXT;
          this.dueDateMaquetCardio.open();
        } else if (
          this.app.stepper.steps[this.app.stepper.stepIndex + 1] ===
            "Geschlossen" &&
          this.app.tasks.getUncompletedTasks() > 0
        ) {
          this.uncompletedTasks.open();
        } else {
          this.nextStep.open();
        }
        break;
      case PREVIOUS:
        if (customer === Customer.MAQUETCARDIO && status === "Geschlossen") {
          this.app.stepper.direction = PREVIOUS;
          this.dueDateMaquetCardio.open();
        } else {
          this.previousStep.open();
        }
        break;
    }
  }

  /** added here because of the this.select output */
  setStatus() {
    this.app.stepper.setDueDate();

    if (this.app.stepper.direction === NEXT) {
      this.app.stepper.isStepDisabled(
        this.app.stepper.steps[this.app.stepper.stepIndex + 1],
        this.app.post.solutions
      );
      this.select.next(this.app.stepper.steps[this.app.stepper.stepIndex + 1]);
    } else {
      this.app.stepper.isStepDisabled(
        this.app.stepper.steps[this.app.stepper.stepIndex - 1],
        this.app.post.solutions
      );
      this.select.next(this.app.stepper.steps[this.app.stepper.stepIndex - 1]);
    }
  }

  ngOnDestroy(): void {
    if (this._threadSubscription) {
      this._threadSubscription.unsubscribe();
    }
    if (this._taskSubscription) {
      this._taskSubscription.unsubscribe();
    }
  }
}
