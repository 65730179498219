export class AttachmentTimeDocs {
  docs: AttachmentTime[] = [];
}
export class AttachmentTime {
  name = "";
  uploadTime = 0;
}

export class TagsObject {
  tags: string[] = [];
  attachmentsByTag: AttachmentsByTag[] = [];
}

export class AttachmentsByTag {
  name = "";
  tagNames: string[] = [];
}

class AllAttachments {
  caseAttachments: any[] = [];
  commentsAttachments: any[] = [];
  solutionAttachments: any[] = [];
}

class Attachment {
  // tslint:disable-next-line
  content_type = "";
  revpos = 0;
  digest = "";
  length = 0;
  stub = false;
}
