import { Component, OnDestroy, OnInit } from "@angular/core";
import { getApp } from "../app";
import { getOpenedCasesFromMpn, getTopLevelLength } from "../api.service";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { Part } from "../../../../shared/models/part";
import { Row } from "../tree/tree.util";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { TreeMode } from "../RM/RM.service";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-tree",
  templateUrl: "./tree.component.html",
  styleUrls: ["./tree.component.scss"],
})
export class TreeComponent implements OnInit, OnDestroy {
  columns: string[] = [];
  parts: Part[] = [];
  manufacturers: Manufacturer[] = [];

  private _routeSubscription: Subscription = new Subscription();
  private _sectionSubscription: Subscription = new Subscription();
  private _routeParamsSubscription: Subscription = new Subscription();
  structureRows: Row[] = [];
  topLevelItems: Partial<Part>[] = [];
  loading = false;

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.app.type = "part";
    this.app.RM.mode = "tree";

    this._routeSubscription = this.route.data.subscribe((data) => {
      this.app.RM.displayRMHeader = data.displayRMHeader;
      this.app.routing.setTabTitle(data.title);
    });

    this._routeParamsSubscription = this.route.params.subscribe(
      async (value) => {
        if (Object.keys(value).length > 0) {
          this.loading = true;
          // Reset any remainings filters before applying the assemby filter
          this.app.filterTree.resetFilterTree();

          const assembly = decodeURIComponent(value.assembly);
          this.app.filterTree.assemblySelected = assembly;
          await this.app.filterTree.filter("part.partNumber", assembly);
          this.app.RM.fromRMSearch = true;
          this.app.routing.setTabTitle(assembly + " - LCM Client");
          this.loading = false;
        }
      }
    );
    if (StringUtils.isNullOrEmpty(this.app.filterTree.assemblySelected)) {
      await this.app.tree.getItems();
      // this.app.leftNav.resetContainer();
      this.app.paginator.pageSize = 25;
    }

    this._sectionSubscription = this.app.RMSubmenu.sectionSubject.subscribe(
      async () => {
        // if (this.app.tree.items.length > 0) {
        //   this.app.tree.loadingTreeRows = true;
        // }
        this.app.tree.currentColumns = this.app.tree.getTreeColumns();
      }
    );
    /** get the list of cases that contain at least a cpn or a mpn */
    this.app.thread.threads = await getOpenedCasesFromMpn(
      this.app.customers.expectCurrent
    );
  }

  displayMessage(row: any) {
    if (this.app.tree.isManufacturerRow(row)) {
      if (this.app.manufacturer.hasManufacturers(row)) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  switchView(view: TreeMode) {
    this.app.RM.mode = view;
    this.app.RM.filterOpen = false;
    if (this.app.RM.mode !== "tree") {
      this.app.manufacturersFilter.getFilterResultBasedOnView("1");
      this.app.filterHeaderTable.resetAllFilters.next(true);
    } else {
      this.app.filterTree.getTreeSearchResult();
      this.app.type = "part";
    }
  }

  ngOnDestroy(): void {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._sectionSubscription) {
      this._sectionSubscription.unsubscribe();
    }
    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
  }
}
