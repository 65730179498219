import { PartService } from "../part/part.service";
import {
  Manufacturer,
  SeMatch,
  SVHCItem,
} from "../../../../shared/models/manufacturer";
import {
  ManufacturerServiceType,
  MatchingStatus,
} from "./manufacturer.service.type";
import { AppType, APP_TYPE } from "../app.type";
import {
  getManufacturers,
  getManufacturersByPartNumber,
  getHistory,
  updateManufacturer,
  bulkDocsByType,
  doc2Model,
  getMatches,
  getManufacturerByMPN,
  getPartByMPN,
  getMpnsListByPartNumber,
  getManufacturerIdByMPN,
  getManufacturerByMPNAndCPN,
  model2Doc,
  getTypeaheadMPNsandCPNs,
  deleteManufacturer,
  getIPC,
} from "../api.service";
import { Part } from "../../../../shared/models/part";
import { Inject, Injectable } from "@angular/core";
import { hasChanges } from "../utils/app.utils";
import {
  convertDateToYearsLeft,
  convertYearsLeftToDate,
  formatDate,
} from "../utils/date.util";
import { BehaviorSubject } from "rxjs";
import { Message } from "../../../../shared/models/message";
import { Customer } from "../../../../shared/types/customers";
import { BILD_IMAGE } from "../file/file.service";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { Row } from "../tree/tree.util";
import {
  ObsolescenceStatus,
  ObsolescencelifeCycleRisk,
  EuRoHSStatus,
} from "../../../../shared/models/rm-common-properties";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";
import { DataProvider } from "../../../../shared/constants/dataProvider";

@Injectable()
export class ManufacturerService implements ManufacturerServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  currentManufacturer: any = {} as Manufacturer;
  cleanManufacturer: any = {} as Manufacturer;
  id = "";
  manufacturerPartNumbers: string[] = [];
  existingManufacturers: Manufacturer[] = [];
  selectedManufacturer = new Manufacturer();
  displayMpnSuggestions = false;
  displayCpnSuggestions = false;
  manufacturers: Manufacturer[] = [];
  cleanManufacturerDoc: any = {};
  manufacturerDoc: any = {};
  manufacturersById: Manufacturer[] = [];
  selectedPart = {} as Part;
  correspondingMpns: string[] = [];
  correspondingCpns: string[] = [];
  messages: Message[] = [];
  manufacturerName: string[] = [];
  image = "";
  externalImage = "";
  hasPermissionToEdit = false;
  hasPermissionToDeleteImage = false;
  checkManualOverride = false;

  manufacturerAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  subjectStatus: BehaviorSubject<string> = new BehaviorSubject<string>("");

  mutableFields: string[] = [
    ...this.app.list.manufacturer.matchingSection,
    ...this.app.list.manufacturer.environmentalCompliance,
    ...this.app.list.manufacturer.tehnicalSection,
    ...this.app.list.manufacturer.supplyChainSection,
    this.app.fieldId.manufacturer.obsolescenceStatus,
    this.app.fieldId.manufacturer.lifecycleCode,
    this.app.fieldId.manufacturer.startOfProduction,
    this.app.fieldId.manufacturer.ltbDate,
    this.app.fieldId.manufacturer.estimatedEOLDate,
    this.app.fieldId.manufacturer.estimatedYearsToEOL,
    this.app.fieldId.manufacturer.likelihood,
    this.app.fieldId.manufacturer.pcnHistory,
  ];

  /** Static functions */
  static parseStatus(seStatus: string | undefined): ObsolescenceStatus {
    switch (seStatus) {
      case "Preliminary":
      case "Active":
      case "Preview":
      case "Re-Active":
        return "active";
      case "NRND":
      case "Active (NRND)":
        return "nrnd";
      case "LTB":
      case "Last Time Buy":
      case "PDN issued":
        return "PDN issued";
      case "Obsolete":
      case "After Market":
      case "Aftermarket":
        return "obsolete";
      case "Unconfirmed":
      case "Unknown":
      case "Contact Supplier":
      case "Active (Unconfirmed)":
      case "Obsolete (Unconfirmed)":
      case "Custom":
      case "TBD":
        return "unconfirmed";
      default:
        return "unconfirmed";
    }
  }

  static parseRoHS(euRoHS: string): EuRoHSStatus {
    switch (euRoHS) {
      case "Yes":
      case "Compliant":
        return "yes";
      case "Yes with Exemption":
      case "Compliant with Exemption":
        return "yes with exemption";
      case "No":
      case "Not Compliant":
        return "no";
      case "Not Required":
      case "TBD":
        return "not required";
      case "Unknown":
      case "Contact Supplier":
      default:
        return "unknown";
    }
  }

  async getManufacturer(manufacturerId: string) {
    const data: Manufacturer[] = await getManufacturers(
      this.app.customers.expectCurrent,
      [manufacturerId]
    );
    if (!data || data[0] == null) {
      // if the manufactuer does not exist anymore in the database redirect to not found page
      this.app.routing.navigateNotFound();
      // throw Error("manufacturer not found");
    }

    // doc2Model function will be removed once model won't have "manufacturer."
    this.currentManufacturer = doc2Model("manufacturer", data[0]);
    this.cleanManufacturer = doc2Model("manufacturer", data[0]);

    this.id = this.currentManufacturer[this.app.fieldId.manufacturer._id];
  }

  async initializeManufacturer() {
    this.makeFieldsMutable(
      this.currentManufacturer[
        this.app.fieldId.manufacturer.obsolescenceStatusOverride
      ]
    );

    const currentLastUpdatedOn =
      this.currentManufacturer["manufacturer.lastUpdatedOn"];
    this.currentManufacturer["manufacturer.lastUpdatedOn"] =
      this.setLastUpdatedOn(this.currentManufacturer);

    const manufacturerDoc = model2Doc("manufacturer", this.currentManufacturer);

    if (
      currentLastUpdatedOn !==
        this.currentManufacturer["manufacturer.lastUpdatedOn"] &&
      currentLastUpdatedOn !== ""
    ) {
      if (this.app.customers.current !== null) {
        await bulkDocsByType(this.app.customers.current, "manufacturer", [
          manufacturerDoc,
        ]);
        await this.getManufacturer(this.id);
      }
    }
  }

  hasEditPermission() {
    const { permission } = this.app;
    if (permission.manufacturer.edit) {
      if (
        this.app.unlockedId !== null &&
        this.app.leftNav.selectedBox == "manufacturer.history"
      ) {
        this.app.leftNav.selectedBox = "manufacturer.clientSection";
      }
      return true;
    }
    return false;
  }

  getManufacturerByManufacturerPartNumber(manufacturerPartNumber: string) {
    if (manufacturerPartNumber !== undefined) {
      const doc = this.manufacturers.find(
        (manufacturer) =>
          manufacturer.manufacturerPartNumber === manufacturerPartNumber
      );
      return doc;
    }
    // return manufacturer
  }

  async getManufacturerIdByMpn(mpn: string) {
    if (mpn === "") {
      return;
    }
    const manufacturer = await getManufacturerIdByMPN(
      this.app.customers.expectCurrent,
      mpn
    );
    this.id = manufacturer[0];
  }

  async getManufacturersByPartNumber(
    partNumber: string,
    detail?: boolean
  ): Promise<Manufacturer[]> {
    this.manufacturersById = await getManufacturersByPartNumber(
      this.app.customers.expectCurrent,
      partNumber,
      detail
    );
    return this.manufacturersById;
  }

  navigateToManufacturerDetails(id: string) {
    const url = "/" + this.app.customers.current + "/manufacturer/" + id;
    this.app.routing.openInNewTab(url);
  }

  // async getListOfManufacturerPartNumbers(mode?: string) {
  //   const partNumbers = (await getManufacturerPartNumbers(
  //     this.app.customers.expectCurrent,
  //     mode
  //   )) as string[];
  //   this.manufacturerPartNumbers = partNumbers;
  // }

  async getTypeaheadOptions(mode?: string) {
    const { cpns, mpns } = await getTypeaheadMPNsandCPNs(
      this.app.customers.expectCurrent,
      mode
    );
    this.manufacturerPartNumbers = mpns;
    this.app.part.partNumbers = cpns;
  }

  hasManufacturers(row: Row) {
    if (row.manufacturer && row.manufacturer._id) {
      return true;
    }
    return false;
  }

  async getManufacturerRows(row: Row) {
    if (row.node == null || row.node.part == null) {
      return [];
    }
    const mRows: Row[] = [];
    const manufacturers = await getManufacturersByPartNumber(
      this.app.customers.expectCurrent,
      row.node.part.partNumber
    );

    if (manufacturers.length === 0) {
      /** if there are no manufacturers under the part, insert a manufacturer empty
       * object in the row just for being able to display the "No related manufacturers"
       * message in the tree
       */
      const manufacturerRow: Row = {} as Row;
      manufacturerRow.level = row.level != null ? row.level + 1 : 1;
      manufacturerRow.manufacturer = {} as Manufacturer;
      mRows.push(manufacturerRow);
    } else {
      manufacturers.forEach((manufacturer: Manufacturer) => {
        const manufacturerRow: Row = {} as Row;
        manufacturerRow.level = row.level != null ? row.level + 1 : 1;

        if (
          manufacturer.svhcItems != null &&
          (manufacturer as any).svhcItems !== ""
        ) {
          // return the reachSubstances for this api as a text to be displayed in the tree and manufacturers table
          const substanceIdentificationArray: string[] = [];
          manufacturer.svhcItems.forEach((element: SVHCItem) => {
            if (
              element.substanceIdentification !== "" &&
              element.substanceIdentification != null
            ) {
              substanceIdentificationArray.push(
                element.substanceIdentification
              );
            }
          });
          (manufacturer as any).reachSubstances = [
            ...new Set(substanceIdentificationArray),
          ].join(", ");
        }

        manufacturerRow.manufacturer = manufacturer;
        mRows.push(manufacturerRow);
      });
    }
    return mRows;
  }

  /**  This group of functions are called if template changes are needed */
  getImageSource(manufacturer: Manufacturer): string {
    // const { app } = this;
    const manufacturerImage = manufacturer["manufacturer.image"];

    if (manufacturerImage !== "" && manufacturerImage != null) {
      return manufacturerImage;
    } else {
      return "";
    }
  }

  getListOfFields(manufacturer: Manufacturer, box: string): string[] {
    const list = new Set(this.app.getList(box));
    const fieldName =
      this.app.customers.dataProvider === DataProvider.Z2DATA
        ? this.app.fieldId.manufacturer.seId
        : this.app.fieldId.manufacturer.z2DataId;
    if (
      manufacturer["manufacturer.obsolescenceStatusOverride"] === true ||
      !this.app.permission.manufacturer.displayDataProviderId
    ) {
      list.delete(fieldName);
    }
    if (
      !manufacturer["manufacturer.obsolescenceStatusOverride"] &&
      this.app.unlockedId === null
    ) {
      list.delete("manufacturer.obsolescenceStatusOverride");
    }

    return Array.from(list);
  }

  getInputChanges(manufacturer: Manufacturer, field: string) {
    switch (field) {
      case "manufacturer.estimatedEOLDate":
        const eolDate = manufacturer["manufacturer.estimatedEOLDate"];
        manufacturer["manufacturer.estimatedYearsToEOL"] =
          convertDateToYearsLeft(eolDate);
        return;
      case "manufacturer.estimatedYearsToEOL":
        const eolYears = manufacturer["manufacturer.estimatedYearsToEOL"];
        manufacturer["manufacturer.estimatedEOLDate"] =
          convertYearsLeftToDate(eolYears);
        return;
      case "manufacturer.productEOSR":
        const eosrDate = manufacturer["manufacturer.productEOSR"];
        manufacturer["manufacturer.productYearsToEOSR"] =
          convertDateToYearsLeft(eosrDate);
        return;
      case "manufacturer.productYearsToEOSR":
        const eosrYears = manufacturer["manufacturer.productYearsToEOSR"];
        manufacturer["manufacturer.productEOSR"] =
          convertYearsLeftToDate(eosrYears);
        return;
    }
  }

  async save(cleanManufacturer = this.cleanManufacturer) {
    // Set two decimals on fields of type number using the manufacturer model
    this.currentManufacturer = this.app.field.setTwoDecimalsOnNumberFields(
      this.currentManufacturer
    );

    // Transfor the clean and the current manufaturer from model to doc
    this.manufacturerDoc = model2Doc("manufacturer", this.currentManufacturer);
    this.cleanManufacturerDoc = model2Doc("manufacturer", cleanManufacturer);

    // Update the time, user and lastUpdatedOn properties on the manufacturer doc
    this.manufacturerDoc["update_time"] = new Date().getTime();
    this.manufacturerDoc["update_user"] = this.app.user;
    this.manufacturerDoc["lastUpdatedOn"] = formatDate(
      this.manufacturerDoc["update_time"]
    );

    // Set manufacturer fields values before saving the manufaturer taking into account several functions
    await this.app.manufacturer.setManufacturerFields(this.manufacturerDoc);

    // Save manufacturer
    await updateManufacturer(
      this.app.customers.expectCurrent,
      "manufacturer",
      this.manufacturerDoc,
      this.cleanManufacturerDoc
    );
    this.app.unlockedId = null;
    this.cleanManufacturerDoc = new Manufacturer();

    this.app.state.successText = this.app.text.manufacturer.savedSuccesfully;
    this.app.state.hasSuccess = true;
    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 2000);

    if (!StringUtils.isNullOrEmpty(this.id)) {
      // Get the manufacturer only when user is on manufacturer page
      await this.getManufacturer(this.id);
    }

    const cleanModel = model2Doc("manufacturer", cleanManufacturer);
    this.getEditedStatusOfManufacturer(cleanModel);
    this.manufacturerAction.next(true);
  }

  /**  This group of functions will return the status of a manufacturer */
  getEditedStatusOfManufacturer(cleanModel: Manufacturer) {
    const currentModel = model2Doc("manufacturer", this.currentManufacturer);
    if (cleanModel != currentModel) {
      const cleanStatus = this.getMatchingStatusOfManufacturer(cleanModel);
      const status = this.getMatchingStatusOfManufacturer(currentModel);
      if (status != cleanStatus) {
        this.subjectStatus.next(status);
      }
    }
  }

  modelHasChanges(
    cleanManufacturer: Manufacturer,
    currentManufacturer: Manufacturer
  ) {
    return hasChanges(cleanManufacturer, currentManufacturer);
  }

  makeFieldsMutable(mutable: boolean) {
    this.mutableFields.forEach((fieldName) => {
      // //if the mutable is defined in the settings file of the client, keep the mutable value
      // if (this.app.field.getFieldSettings(fieldName).mutable != null) {
      //   return;
      // }
      this.app.field.getFieldSettings(fieldName).mutable = mutable;
    });
  }

  cancelOverrideMode() {
    this.currentManufacturer[
      this.app.fieldId.manufacturer.obsolescenceStatusOverride
    ] = false;
    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      this.currentManufacturer[this.app.fieldId.manufacturer.z2DataId] =
        this.cleanManufacturer[this.app.fieldId.manufacturer.z2DataId];
    }
    if (this.app.customers.dataProvider === DataProvider.SE) {
      this.currentManufacturer[this.app.fieldId.manufacturer.seId] =
        this.cleanManufacturer[this.app.fieldId.manufacturer.seId];
    }
    this.makeFieldsMutable(false);
  }

  async getManufacturerHistory() {
    if (this.id == null) {
      return [];
    }
    return await getHistory(this.id, this.app.customers.expectCurrent);
  }

  /**  This group of functions will return the status of a manufacturer */
  getMatchingStatusOfManufacturer(manufacturer: Manufacturer): MatchingStatus {
    // returns if the manufacturer has the obsolescenceStatusOverride = true;
    if (this.isManualOverride(manufacturer)) {
      return "override";
    }
    // return if the manufacturer is exact matched
    if (this.isExactMatch(manufacturer) && !manufacturer.edited) {
      return "matched";
    }

    // return the manufacturer which wasn't matched and the user has to do it manually
    if (this.hasMatches(manufacturer) && !manufacturer.edited) {
      return "manual";
    }
    // returns the manufacturer which was edited - a match was selected
    if (manufacturer.edited) {
      return "manualAssignment";
    }

    return "unmatched";
  }

  isManualOverride(manufacturer: Manufacturer) {
    if (!manufacturer.obsolescenceStatusOverride) {
      this.checkManualOverride = false;
      return false;
    }
    this.checkManualOverride = true;
    return true;
  }

  isExactMatch(manufacturer: Manufacturer) {
    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      return manufacturer.z2DataId != null && manufacturer.z2DataId !== "";
    }
    if (this.app.customers.dataProvider === DataProvider.SE) {
      return manufacturer.seId != null && manufacturer.seId !== "";
    }
    return false;
  }

  hasMatches(manufacturer: Manufacturer) {
    const { matchesCount, numberOfMatches } = manufacturer;

    if (Number(matchesCount) > 0 || Number(numberOfMatches) > 0) {
      return true;
    }
    return false;
  }

  async getMatches(manufacturer: Manufacturer): Promise<SeMatch[]> {
    return await getMatches(
      this.app.customers.expectCurrent,
      manufacturer.manufacturerPartNumber,
      this.app.customers.dataProvider
    );
  }

  // Set manufacturer fields values taking into account several functions
  async setManufacturerFields(doc: Manufacturer) {
    this.setManufacturerFieldsAsRaw(doc);

    if (!!doc.obsolescenceStatusOverride) {
      this.setStatusesAndDatesWhenOverridden(doc);
      this.setTotalLikelihood(doc);
      this.setLeadTime(doc);
      return;
    }

    const status = doc.obsolescenceStatus != null ? doc.obsolescenceStatus : "";
    this.setStatusesAndDates(status, doc);
    // this.setEuRoHS(doc);
    this.setTotalLikelihood(doc);
  }

  // Set manufacturer values for all obsolescence statuses and dates, if manufacturer is in override mode
  setStatusesAndDatesWhenOverridden(doc: Manufacturer) {
    if (doc.obsolescenceStatus == null) {
      return;
    }

    // Update estimatedYearsToEOL based on estimatedEOLDate and vice versa in case there is only estimatedYearsToEOL
    if (
      !StringUtils.isNullOrEmpty(doc.estimatedEOLDate) &&
      doc.estimatedEOLDate != null
    ) {
      doc.estimatedYearsToEOL = convertDateToYearsLeft(doc.estimatedEOLDate);
    }
    if (
      !StringUtils.isNullOrEmpty(doc.estimatedYearsToEOL) &&
      Number(doc.estimatedYearsToEOL) !== 0
    ) {
      doc.estimatedEOLDate = convertYearsLeftToDate(doc.estimatedYearsToEOL);
    }

    const status = doc.obsolescenceStatus;
    this.setStatusesAndDates(status, doc);

    // End of production date should be overriten by LTB date if LTB is filled in and status is PDN issued
    if (doc.obsolescenceStatus === "PDN issued" && doc.ltbDate !== "") {
      doc.estimatedEOLDate = doc.ltbDate;
      if (doc.estimatedEOLDate == null) {
        return;
      }
      doc.estimatedYearsToEOL = convertDateToYearsLeft(doc.estimatedEOLDate);
    }

    if (
      doc.obsolescenceStatus === "obsolete" &&
      Number(doc.estimatedYearsToEOL) >= 0
    ) {
      doc.estimatedEOLDate = "";
      doc.estimatedYearsToEOL = convertDateToYearsLeft(doc.estimatedEOLDate);
    }
  }

  // manufacturer functions to set the obsolescenceStatuses
  setStatusAll(status: ObsolescenceStatus, doc: Manufacturer) {
    doc.obsolescenceStatus = status;
    doc.obsolescenceStatus2years = status === "obsolete" ? status : "";
    doc.obsolescenceStatus4years = status === "obsolete" ? status : "";
    doc.obsolescenceStatus6years = status === "obsolete" ? status : "";
    doc.obsolescenceStatus8years = status === "obsolete" ? status : "";
  }

  setStatusesAndDates(status: ObsolescenceStatus, doc: Manufacturer) {
    const { estimatedEOLDate, estimatedYearsToEOL } = doc;
    const yearsLeft = Number(estimatedYearsToEOL);

    if (yearsLeft <= 0 && status !== "unconfirmed" && status !== "resolved") {
      status = "obsolete";
    }

    switch (status) {
      case "active":
      case "nrnd":
      case "PDN issued":
        // If status is: active, nrnd, PDN issued we need to calculate the values for the next 2,4,6 and 8 years
        try {
          doc.obsolescenceStatus = status;
          if (yearsLeft == null || Number.isNaN(yearsLeft)) {
            throw Error();
          }
          doc.obsolescenceStatus2years = yearsLeft > 2 ? "active" : "obsolete";
          doc.obsolescenceStatus4years = yearsLeft > 4 ? "active" : "obsolete";
          doc.obsolescenceStatus6years = yearsLeft > 6 ? "active" : "obsolete";
          doc.obsolescenceStatus8years = yearsLeft > 8 ? "active" : "obsolete";
        } catch (err) {
          if (estimatedEOLDate != null && estimatedEOLDate !== "") {
            doc.obsolescenceStatus2years = PartService.getObsolescenceStatus(
              estimatedEOLDate,
              2
            );
            doc.obsolescenceStatus4years = PartService.getObsolescenceStatus(
              estimatedEOLDate,
              4
            );
            doc.obsolescenceStatus6years = PartService.getObsolescenceStatus(
              estimatedEOLDate,
              6
            );
            doc.obsolescenceStatus8years = PartService.getObsolescenceStatus(
              estimatedEOLDate,
              8
            );
          }
        }
        break;
      default:
        // If status is: obsolete, unconfirmed, ignored
        if (estimatedEOLDate === "" || estimatedEOLDate === "0") {
          doc.estimatedYearsToEOL = "";
          doc.estimatedEOLDate = ""; // field should be empty, otherwise date would be 1970-01-01
        }

        this.setStatusAll(status, doc);
    }
  }

  // TODO: To be removed if not used
  // Set manufacturer values for EuRoHS statuses
  // setEuRoHS(doc: Manufacturer) {
  //   if (doc.euRoHSStatus != null && doc.euRoHSStatus !== "") {
  //     const euRoHS = ManufacturerService.parseRoHS(doc.euRoHSStatus);
  //     doc.euRoHS = euRoHS;
  //   }
  // }

  // Set likelihoods for manufacturer
  setTotalLikelihood(doc: Manufacturer) {
    if (doc.obsolescenceStatus == null) {
      throw Error("status not found");
    }

    doc.likelihood_yteol =
      doc.estimatedYearsToEOL != null
        ? this.getYTEOLLikelihood(
            doc.estimatedYearsToEOL,
            doc.obsolescenceStatus
          )
        : "unknown";
    doc.likelihood_yteosr =
      this.app.customer === Customer.NS && doc.productYearsToEOSR != null
        ? this.getYTEOSRLikelihood(doc.productYearsToEOSR)
        : "unknown";

    // transform likelihood options into numbers
    // tslint:disable-next-line
    const likelihood_yteol =
      doc.likelihood_yteol != null
        ? this.app.part.getLifeCycleRiskValue(doc.likelihood_yteol)
        : 0;
    // tslint:disable-next-line
    const likelihood_yteosr =
      doc.likelihood_yteosr != null
        ? this.app.part.getLifeCycleRiskValue(doc.likelihood_yteosr)
        : 0;

    // the lowest value result into the best likelihood, 0 will always be ignored
    let likelihood: number;
    if (likelihood_yteol === 0 || likelihood_yteosr === 0) {
      likelihood = Math.max(likelihood_yteol, likelihood_yteosr);
    } else {
      likelihood = Math.min(likelihood_yteol, likelihood_yteosr);
    }

    // transform number into likelihood option
    doc.likelihood = this.app.part.getLifeCycleRiskName(likelihood);
  }

  getYTEOLLikelihood(
    estimatedYearsToEOL: string,
    status: ObsolescenceStatus
  ): ObsolescencelifeCycleRisk {
    const yearsLeft = Number(estimatedYearsToEOL);

    if (yearsLeft === 0 && (status === "obsolete" || status === "PDN issued")) {
      return "high";
    } else if (yearsLeft === 0 && status === "nrnd") {
      return "medium";
    } else {
      return this.calculateLikelihood(yearsLeft);
    }
  }

  getYTEOSRLikelihood(productYearsToEOSR: string): ObsolescencelifeCycleRisk {
    const yearsLeft = Number(productYearsToEOSR);
    return this.calculateLikelihood(yearsLeft);
  }

  calculateLikelihood(yearsLeft: number): ObsolescencelifeCycleRisk {
    if (yearsLeft >= 5) {
      return "low";
    } else if (yearsLeft >= 2 && yearsLeft < 5) {
      return "medium";
    } else if (yearsLeft === 0) {
      return "unknown";
    } else if (yearsLeft < 2) {
      return "high";
    }
    return "unknown";
  }

  // Set leadTime for manufacturer
  setLeadTime(doc: Manufacturer) {
    if (StringUtils.isNullOrEmpty(doc.maxWeeks)) {
      doc.leadTime = "unknown";
    } else {
      doc.leadTime =
        doc.maxWeeks == null ? "unknown" : this.calculateLeadTime(doc.maxWeeks);
    }
  }

  calculateLeadTime(maxWeeks: string): ObsolescencelifeCycleRisk {
    const weeks = Number(maxWeeks);

    if (weeks <= 12) {
      return "low";
    } else if (weeks > 12 && weeks <= 36) {
      return "medium";
    } else if (weeks > 36) {
      return "high";
    }
    return "unknown";
  }

  async generateManufacturersForJsonImport(docs: any[]) {
    const manufacturerDocs: any[] = [];
    this.manufacturers = await getManufacturers(
      this.app.customers.expectCurrent
    );
    docs.forEach(async (doc) => {
      let manufacturerDoc = new Manufacturer();
      const existingDoc = await this.checkIfExists(doc);
      if (existingDoc !== undefined) {
        manufacturerDoc = this.updateFieldsFromImport(doc, existingDoc);
      } else {
        manufacturerDoc = this.setMissingFieldsFromImport(doc, manufacturerDoc);
        manufacturerDoc.type = "manufacturer";
        // manufacturerDoc = doc;

        // Modify import value for all fields of type number to only keep two decimals
        Object.keys(manufacturerDoc).forEach((key) => {
          if (
            this.app.field.getFieldSettings("manufacturer." + key) !=
              undefined &&
            this.app.field.getFieldSettings("manufacturer." + key).type ===
              "number"
          ) {
            manufacturerDoc[key] = this.app.field.formatNumberWithDecimals(
              manufacturerDoc[key],
              2
            );
          }
        });
      }
      manufacturerDocs.push(manufacturerDoc);
    });
    if (this.app.import.type === "all-config") {
      this.app.state.importBuffer.docs.data.push(manufacturerDocs);
    } else {
      this.app.state.importBuffer.docs.data = manufacturerDocs;
    }
    return manufacturerDocs;
  }

  updateFieldsFromImport(doc: Manufacturer, existingDoc: Manufacturer) {
    Object.keys(doc).forEach((key) => {
      /** Update the existing properties only if the the new value is different
       * than null or empty and has the same type with the existing value
       */
      if (
        StringUtils.isNullOrEmpty(doc[key]) &&
        typeof existingDoc[key] !== "string"
      ) {
        return;
      } else {
        existingDoc[key] = doc[key];
      }

      // Modify import value for the active field to keep a string of true/false since there is a radio button
      if (key === "active") {
        if (StringUtils.isNullOrEmpty(doc["active"])) {
          existingDoc["active"] = "false";
        } else {
          existingDoc["active"] = doc["active"].toString();
        }
      }

      if (key === "productYearsToEOSR") {
        if (!StringUtils.isNullOrEmpty(doc["productYearsToEOSR"])) {
          const eosrYears = doc["productYearsToEOSR"];
          existingDoc["productEOSR"] = convertYearsLeftToDate(eosrYears);
        }
      }
      if (key === "productEOSR") {
        if (!StringUtils.isNullOrEmpty(doc["productEOSR"])) {
          const eosrDate = doc["productEOSR"] != null ? doc["productEOSR"] : "";
          existingDoc["productYearsToEOSR"] = convertDateToYearsLeft(eosrDate);
        }
      }

      // Modify import value for all fields of type number to only keep two decimals
      if (
        this.app.field.getFieldSettings("manufacturer." + key) != undefined &&
        this.app.field.getFieldSettings("manufacturer." + key).type === "number"
      ) {
        existingDoc[key] = this.app.field.formatNumberWithDecimals(
          existingDoc[key],
          2
        );
      }

      // Modify import value for all fields of type checkbox to make sure we always save a boolean
      // Excel values can come as: TRUE, "true"
      if (
        this.app.field.getFieldSettings("manufacturer." + key) != undefined &&
        this.app.field.getFieldSettings("manufacturer." + key).type ===
          "checkbox"
      ) {
        existingDoc[key] =
          doc[key].toString().toLowerCase() === "true" || doc[key] === true
            ? true
            : false;
      }
    });
    return existingDoc;
  }

  async generateManufacturers(docs: any[]) {
    /** get the list of manufacturer names in order to be able to compare names */
    await this.app.manufacturerName.getManufacturerNames();
    await this.app.manufacturerName.setNames();
    this.manufacturers = await getManufacturers(
      this.app.customers.expectCurrent
    );
    const manufacturerDocs: Manufacturer[] = [];
    docs.forEach(async (doc) => {
      let manufacturerDoc = new Manufacturer();
      const test: boolean = this.testDoc(doc);
      if (test) {
        /** check if exists in the database */
        const existingDoc = this.checkIfExists(doc);
        if (existingDoc != null) {
          this.existingManufacturers.push(existingDoc);
          manufacturerDoc = this.updateFieldsFromImport(doc, existingDoc);
        } else {
          manufacturerDoc = this.setMissingFieldsFromImport(
            doc,
            manufacturerDoc
          );
          manufacturerDoc.type = "manufacturer";
        }
        /** check if current doc does already exists in array of docs to be imported */
        if (
          manufacturerDocs.findIndex(
            (m: Manufacturer) =>
              m.manufacturerPartNumberRaw === doc.manufacturerPartNumberRaw &&
              m.partNumber === doc.partNumber &&
              m.nameRaw === doc.nameRaw
          ) === -1
        ) {
          manufacturerDocs.push(manufacturerDoc);
        }
      } else {
        console.log("false", doc);
        return;
      }
    });
    docs = manufacturerDocs;
    this.app.import.docs = docs;
    (docs as []).forEach((doc: Manufacturer) => {
      this.app.import.selected.add(doc.manufacturerPartNumberRaw);
    });
    console.log(
      "List of manufacturer names that were not matched",
      this.app.manufacturerName.notFound
    );
    return docs;
  }

  testDoc(doc: any) {
    /** test if one the properties is empty from the import */
    if (
      StringUtils.isNullOrEmpty(doc.manufacturerPartNumberRaw) ||
      StringUtils.isNullOrEmpty(doc.partNumber) ||
      StringUtils.isNullOrEmpty(doc.nameRaw)
    ) {
      return false;
    }
    return true;
  }

  setMissingFieldsFromImport(doc: any, existingDoc: any) {
    this.app.import.props.forEach((field) => {
      doc[field.key] = StringUtils.isNullOrEmpty(doc[field.key])
        ? existingDoc[field.key]
        : doc[field.key];

      /** if the name clean is missing from the file - > set it from the raw value */
      if (field.key === "nameClean" && doc["nameClean"] === "") {
        doc["nameClean"] = !StringUtils.isNullOrEmpty(doc["nameClean"])
          ? doc["nameClean"]
          : doc["nameRaw"];
      }
      /** if the manufacturer description clean is missing -> set it from the raw value */
      if (
        field.key === "manufacturerPartDescriptionClean" &&
        doc["manufacturerPartDescriptionClean"] === ""
      ) {
        doc["manufacturerPartDescriptionClean"] = !StringUtils.isNullOrEmpty(
          doc["manufacturerPartDescriptionClean"]
        )
          ? doc["manufacturerPartDescriptionClean"]
          : doc["manufacturerPartDescriptionRaw"];
      }
      /** if the manufacturer part number clean is missing -> set it from the raw value */
      if (
        field.key === "manufacturerPartNumberClean" &&
        doc["manufacturerPartNumberClean"] === ""
      ) {
        doc["manufacturerPartNumberClean"] = !StringUtils.isNullOrEmpty(
          doc["manufacturerPartNumberClean"]
        )
          ? doc["manufacturerPartNumberClean"]
          : doc["manufacturerPartNumberRaw"];
      }
      /** if the manufacturer partnumber is missing -> set it from the clean or from the raw */
      if (
        field.key === "manufacturerPartNumber" &&
        doc["manufacturerPartNumber"] === ""
      ) {
        doc["manufacturerPartNumber"] = !StringUtils.isNullOrEmpty(
          doc["manufacturerPartNumberClean"]
        )
          ? doc["manufacturerPartNumberClean"]
          : doc["manufacturerPartNumberRaw"];
      }
      /** if the manufacturer description is missing -> set it from the clean or from the raw */
      if (
        field.key === "manufacturerPartDescription" &&
        doc["manufacturerPartDescription"] === ""
      ) {
        doc["manufacturerPartDescription"] =
          doc["manufacturerPartDescription"] !== ""
            ? doc["manufacturerPartDescriptionClean"]
            : doc["manufacturerPartDescriptionRaw"];
      }
      /** if the name is missing -> set it from the clean or from the raw */
      if (field.key === "name" && doc["name"] === "") {
        doc["name"] = this.app.manufacturerName.setManufaturerName(doc);
      }
      // Modify import value for the active field to keep a string of true/false since there is a radio button
      if (field.key === "active") {
        if (StringUtils.isNullOrEmpty(doc["active"])) {
          doc["active"] = "false";
        } else {
          doc["active"] = doc["active"].toString();
        }
      }

      if (field.key === "productYearsToEOSR") {
        if (!StringUtils.isNullOrEmpty(doc["productYearsToEOSR"])) {
          const eosrYears = doc["productYearsToEOSR"];
          doc["productEOSR"] = convertYearsLeftToDate(eosrYears);
        }
      }
      if (field.key === "productEOSR") {
        if (!StringUtils.isNullOrEmpty(doc["productEOSR"])) {
          const eosrDate = doc["productEOSR"] != null ? doc["productEOSR"] : "";
          doc["productYearsToEOSR"] = convertDateToYearsLeft(eosrDate);
        }
      }

      // Modify import value for all fields of type number to only keep two decimals
      if (
        this.app.field.getFieldSettings("manufacturer." + field.key) !=
          undefined &&
        this.app.field.getFieldSettings("manufacturer." + field.key).type ===
          "number"
      ) {
        doc[field.key] = this.app.field.formatNumberWithDecimals(
          doc[field.key],
          2
        );
      }

      // Modify import value for all fields of type checkbox to make sure we always save a boolean
      // Excel values can come as: TRUE, "true"
      if (
        this.app.field.getFieldSettings("manufacturer." + field.key) !=
          undefined &&
        this.app.field.getFieldSettings("manufacturer." + field.key).type ===
          "checkbox"
      ) {
        doc[field.key] =
          doc[field.key].toString().toLowerCase() === "true" ||
          doc[field.key] === true
            ? true
            : false;
      }
    });

    delete doc._id;
    delete doc._rev;
    delete doc.id;
    return doc;
  }

  checkIfExists(doc: any) {
    const existingDoc = this.manufacturers.find(
      (manufacturer: Manufacturer) =>
        manufacturer.partNumber === doc.partNumber &&
        manufacturer.manufacturerPartNumberRaw ===
          doc.manufacturerPartNumberRaw &&
        manufacturer.nameRaw === doc.nameRaw
    );

    if (existingDoc != null) {
      return existingDoc;
    }
  }

  setLastUpdatedOn(doc: Manufacturer) {
    let lastCheckDate = "";
    let lastUpdatedDate = "";
    if (doc.hasOwnProperty("manufacturer.lastCheckDate")) {
      lastUpdatedDate = doc["manufacturer.lastUpdatedOn"];
      lastCheckDate = formatDate(doc["manufacturer.lastCheckDate"]);
    } else {
      lastUpdatedDate =
        doc["lastUpdatedOn"] !== undefined ? doc["lastUpdatedOn"] : "";
      lastCheckDate = formatDate(doc["lastCheckDate"]);
    }
    // if(lastCheckDate === undefined || lastCheckDate === ''){
    //   return formatDate(new Date().getTime())
    // }
    if (
      (lastUpdatedDate === null ||
        lastUpdatedDate === undefined ||
        lastUpdatedDate === "") &&
      !StringUtils.isNullOrEmpty(lastCheckDate)
    ) {
      return lastCheckDate;
    }
    if (lastUpdatedDate !== undefined) {
      if (
        new Date(lastUpdatedDate).getTime() < new Date(lastCheckDate).getTime()
      ) {
        return lastCheckDate;
      } else {
        return lastUpdatedDate;
      }
    }
  }

  async deleteManufacturer(view?: string) {
    const manufacturerDoc = this.selectedManufacturer;
    manufacturerDoc._deleted = true;
    await deleteManufacturer(this.app.customers.expectCurrent, manufacturerDoc);
    // await bulkDocsByType(this.app.customers.expectCurrent, "manufacturer", [
    //   manufacturerDoc,
    // ]);
    if (view === "detail") {
      this.manufacturerAction.next(true);
    }
    this.app.state.next({
      hasSuccess: true,
      successText: this.app.text.manufacturer.deletedSuccesfully,
    });
    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 3000);
  }

  // if the filed is cpn, get the corresponding mpns and vice versa
  async prepareData(field: string, option: string) {
    this.app.spinner.showSpinner();
    if (field === this.app.fieldId.thread.artNumber) {
      /** if the current field is artNumber, get the part containing that partNumber
       * so that we can copy the data from it
       */
      const part = await this.app.part.getPartByPartNumberWithoutBuffer(option);
      this.selectedPart = part;

      /** get the list of mpns that have the manufacturer.manufacturerPartNumber = current selected value */
      this.correspondingMpns = await getMpnsListByPartNumber(
        this.app.customers.expectCurrent,
        option
      );
      this.correspondingMpns = this.correspondingMpns.map((m: any) => m.mpn);
      this.displayMpnSuggestions = true;
    }
    if (field === this.app.fieldId.thread.crtNumber) {
      const manufacturer = await this.getManufacturerForCase(option);

      /** when creating a case from pcn or when case is synced from portal it migth happen that the client does not have that mpn in the RM
       * therefore the manufacturer variable above would be empty and have the length zero
       */
      if (manufacturer == null) {
        this.app.spinner.hideSpinner();
        return;
      }

      this.selectedManufacturer = manufacturer;
      if (this.selectedManufacturer._id != null) {
        this.id = this.selectedManufacturer._id;
      }

      /** get the list of cpns that have the part.partNumber = current selected value */
      const cpns = await getPartByMPN(this.app.customers.expectCurrent, option);

      this.correspondingCpns = cpns
        .map((part: Part) => part.partNumber)
        .filter((partNumber: string) => !StringUtils.isNullOrEmpty(partNumber));

      this.displayCpnSuggestions = true;
    }
    this.getMessages(field, option);
    this.app.spinner.hideSpinner();
  }

  /** set thread.rmNotes based on the number of messages */
  async getMessages(field: string, option: string) {
    if (
      this.app.thread.thread["thread.rmNotes"] === "no" ||
      this.app.thread.thread["thread.rmNotes"] === ""
    ) {
      if (field === this.app.fieldId.thread.artNumber) {
        this.messages = await this.app.message.getMessages(option);
      }
      if (field === this.app.fieldId.thread.crtNumber) {
        this.messages = await this.app.message.getMessages(
          this.selectedManufacturer._id
        );
      }
      this.app.thread.thread["thread.rmNotes"] =
        this.messages.length === 0 ? "no" : "yes";
      this.messages = [];
    }
  }

  checkIfCpnMpnExists(value: string, field: string): boolean {
    let result = false;
    if (field === "thread.crtNumber") {
      if (
        this.app.manufacturer.manufacturerPartNumbers.findIndex(
          (mpn: string) => mpn === value
        ) !== -1
      ) {
        result = true;
      }
    }
    if (field === "thread.artNumber") {
      if (
        this.app.part.partNumbers.findIndex((cpn: string) => cpn === value) !==
        -1
      ) {
        result = true;
      }
    }
    return result;
  }

  getListOfSuggestions(field: "thread.artNumber" | "thread.crtNumber") {
    let result: string[] = [];
    switch (field) {
      case "thread.artNumber":
        result = this.app.manufacturer.correspondingCpns;
        break;
      case "thread.crtNumber":
        result = this.app.manufacturer.correspondingMpns;
        break;
    }
    return result;
  }

  hasDeleteImagePermission(): void {
    const manufactuer = model2Doc(
      "manufacturer",
      this.app.manufacturer.currentManufacturer
    );
    const imageExists = this.app.file.exists(manufactuer);
    const hasBildImage = this.hasBildImage();

    if (
      (imageExists ||
        hasBildImage ||
        !StringUtils.isNullOrEmpty(
          this.app.manufacturer.currentManufacturer["manufacturer.image"]
        )) &&
      this.app.manufacturer.hasEditPermission()
    ) {
      this.hasPermissionToDeleteImage = true;
    } else {
      this.hasPermissionToDeleteImage = false;
    }
  }

  getImageExternal(): void {
    const { app } = this;

    if (
      this.app.manufacturer.currentManufacturer["manufacturer._id"] !== null
    ) {
      const fileLink = this.getImage();
      const manufactuer = model2Doc(
        "manufacturer",
        this.app.manufacturer.currentManufacturer
      );
      const imageExists = app.file.exists(manufactuer);
      const hasBildImage = this.hasBildImage();

      if (imageExists || hasBildImage) {
        this.externalImage = app.file.getUrl(fileLink);
      } else {
        this.externalImage = !StringUtils.isNullOrEmpty(
          this.app.manufacturer.currentManufacturer["manufacturer.image"]
        )
          ? this.app.manufacturer.currentManufacturer["manufacturer.image"]
          : "";
      }
    }
  }

  getImage(
    id = this.app.manufacturer.currentManufacturer["manufacturer._id"],
    customer = this.app.customers.expectCurrent
  ) {
    this.image = [
      customer,
      "manufacturer",
      EncodingUtils.encodeBase64(id),
      BILD_IMAGE,
    ].join("/");
    return this.image;
  }

  private hasBildImage() {
    if (
      this.app.manufacturer.currentManufacturer["manufacturer._attachments"] !=
        null &&
      Object.keys(
        this.app.manufacturer.currentManufacturer["manufacturer._attachments"] >
          0
      )
    ) {
      const hasBildImage =
        this.app.manufacturer.currentManufacturer["manufacturer._attachments"][
          BILD_IMAGE
        ];
      if (hasBildImage) {
        return true;
      } else {
        return false;
      }
    }
  }

  private setManufacturerFieldsAsRaw(doc: Manufacturer) {
    const cleanDoc = this.cleanManufacturer as Manufacturer;
    if (
      doc.manufacturerPartNumberRaw !==
        cleanDoc[this.app.fieldId.manufacturer.manufacturerPartNumberRaw] &&
      doc.manufacturerPartNumberRaw != null
    ) {
      doc.manufacturerPartNumber = doc.manufacturerPartNumberRaw;
    }
    if (
      doc.manufacturerPartDescriptionRaw !==
        cleanDoc[
          this.app.fieldId.manufacturer.manufacturerPartDescriptionRaw
        ] &&
      doc.manufacturerPartDescriptionRaw != null
    ) {
      doc.manufacturerPartDescription = doc.manufacturerPartDescriptionRaw;
    }
    if (doc.nameRaw !== cleanDoc.nameRaw && doc.nameRaw != null) {
      doc.name = doc.nameRaw;
    }
  }

  private async getManufacturerForCase(option: string) {
    let manufacturer = new Manufacturer();
    if (
      // option != this.app.thread.cleanModel["thread.crtNumber"] &&
      StringUtils.isNullOrEmpty(this.app.thread.thread["thread.artNumber"])
    ) {
      /**  if the current field is crtNumber, get the manufacturer containing the manufacturerPartNumber
       * so that we can copy the data from it
       */
      const manufacturerList = await getManufacturerByMPN(
        this.app.customers.expectCurrent,
        option
      );
      // if will return more than one manufacturer, only the first one will be considered
      manufacturer = manufacturerList[0];
    }
    if (
      !StringUtils.isNullOrEmpty(this.app.thread.thread["thread.artNumber"])
    ) {
      /**  if the current field is crtNumber, get the manufacturer containing the manufacturerPartNumber and the selected cpn
       * so that we can copy the data from it
       */
      const manufacturerList = await getManufacturerByMPNAndCPN(
        this.app.customers.expectCurrent,
        option,
        this.app.thread.thread["thread.artNumber"]
      );
      // if will return more than one manufacturer, only the first one will be considered
      manufacturer = manufacturerList[0];
    }
    return manufacturer;
  }
  async getIPC() {
    // tslint:disable-next-line
    const IPCXml = await getIPC(
      this.app.customers.expectCurrent,
      this.app.manufacturer.currentManufacturer[
        this.app.fieldId.manufacturer.seId
      ]
    );
    const fileName =
      "IPC-1752A_" +
      this.app.manufacturer.currentManufacturer[
        this.app.fieldId.manufacturer.manufacturerPartNumber
      ];
    this.app.file.downloadXmlFile(IPCXml, fileName);
  }
}
