import { Inject, Injectable } from "@angular/core";
import { Post } from "../../../../shared/models/post";
import { TaskCompletedServiceType } from "./task-completed.service.type";
import { formatDatetime } from "../utils/date.util";
import { savePost } from "../api.service";
import { AppType, APP_TYPE } from "../app.type";
import { Customer } from "../../../../shared/types/customers";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TaskCompletedService implements TaskCompletedServiceType {
  checkOpenTasksKNDS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async changeTaskCompletion(customer: string, post: Post) {
    post.taskCompleted = !post.taskCompleted;

    if (post.taskCompleted) {
      post.completedBy = this.app.state.user != null ? this.app.state.user : "";
      post.completedAt = new Date().getTime();
    } else {
      post.completedBy = "";
      delete post.completedAt;
    }
    await savePost(customer, post);

    // Update the selected task if the panel is open
    if (this.app.panel.isOpen) {
      this.app.panel.selectedItem = post;
      this.app.panel.triggerPanel.next(true);
    }

    if (this.app.thread.id == null) {
      throw new Error("id is null");
    }
    await this.app.post.getThreadPosts(
      this.app.customers.expectCurrent,
      this.app.thread.id
    );
    await this.app.thread.getThread(this.app.thread.cleanModel["thread._id"]);

    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.app.post.filterPostsByProject();
      this.app.post.triggerOMEmail();
    }

    this.app.tasks.uncompletedTasks();
  }

  showCompleteTaskIcon(task: Post) {
    // show the "mark completed" button if the field taskCompleted is false or != "" or != null
    // show the button only if the task responsible is the current user or if has permission
    if (
      (task.taskCompleted == null || task.taskCompleted === false) &&
      (task.taskResponsible === this.app.state.user ||
        task.deputy === this.app.state.user ||
        this.app.permission.post.markTaskCompleted ||
        this.app.post.hasEditPermission(task))
    ) {
      return true;
    } else {
      return false;
    }
  }

  showReopenTaskIcon(task: Post) {
    switch (this.app.customers.expectCurrent) {
      case Customer.DB:
        return task.taskCompleted && this.app.permission.post.reopenTask;
      default:
        /** If task is marked completed and the task responsible/ task creator
         * is the current user or user has permission => show reopen task icon
         */
        if (
          task.taskCompleted === true &&
          (task.taskResponsible === this.app.state.user ||
            this.app.permission.post.reopenTask ||
            task.user_id === this.app.state.user)
        ) {
          return true;
        }
        return false;
    }
  }

  isTaskCompleted(post: Post) {
    if (post.taskCompleted) {
      return true;
    } else {
      return false;
    }
  }

  isMyTask(post: Post) {
    const taskResponsible = this.app.users.currentUser.name;
    if (
      taskResponsible === post.taskResponsible &&
      !this.app.taskCompleted.isTaskCompleted(post)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getCompletedTaskDetails(task: Post): {
    completedAt: string;
    completedBy: string;
  } {
    return {
      completedAt: formatDatetime(task.completedAt),
      completedBy: task.completedBy,
    };
  }

  checkOpenTasks(post: Post) {
    if (
      post.snapshot == null ||
      post.snapshot.thread == null ||
      post.snapshot.thread.omfStatus == null
    ) {
      post.cannotCloseTask = false;
      return;
    }
    if (
      post.taskResponsible === this.app.user &&
      post.snapshot.thread.omfStatus === "vorerfasst"
    ) {
      const openByLoggedInUser = this.app.post.cleanPosts.filter(
        (task: any) =>
          task.user_id === this.app.user &&
          !task.taskCompleted &&
          task.typeOfPost === "task" &&
          task.snapshot.thread.omfStatus !== "vorerfasst"
      );

      /** if at least one task created by the logged in user (KM) is open, the logged in user cannot
       * close the task cretead in status 2 (Precheck) for which he is responsible
       */
      if (openByLoggedInUser.length > 0) {
        post.cannotCloseTask = true;
      } else {
        post.cannotCloseTask = false;
      }
    }
  }
}
