import { Component, Input } from "@angular/core";
import { getApp } from "../app";

@Component({
  selector: "app-left-nav",
  templateUrl: "./left-nav.component.html",
  styleUrls: ["./left-nav.component.scss"],
})
export class LeftNavComponent {
  app = getApp((app) => {
    this.app = app;
  });
  @Input() imageSource = "";
  @Input() listBoxes: string[] = [];
  @Input() hideBoxes: string[] = [];
  @Input() lastUpdatedOn = "";
  @Input() showImageControlBtn = false;
  @Input() showImage = true;
  @Input() showBoxes = true;
  @Input() docType = "";
  @Input() file = "";
  @Input() greyColored = false;

  invalidBoxes: string[] = [];

  listOfFields: string[] = [];

  constructor() {}
  ngOnInit() {
    // this.app.leftNav.resetContainer();
  }

  /** check if there are sections that have invalid fields */
  isSectionInvalid(selectedBox: string) {
    let fields: any = [];
    let model: any;

    if (selectedBox == null) {
      return null;
    }

    fields = this.getFieldsByBox(selectedBox);
    model = this.app.thread.thread;

    const allInvalidFields = this.app.field
      .getInvalidFields("thread", model)
      .map((field) => field);

    /** array of all required fields for each box */
    const result = fields.filter((x: string) =>
      allInvalidFields.some((y: string) => x === y)
    );

    const isBoxIncluded = this.invalidBoxes.includes(selectedBox!);

    if (result.length > 0 && !isBoxIncluded) {
      /** array of sections that have all required fields incomplete */
      this.invalidBoxes.push(selectedBox!);
    } else if (result.length === 0 && isBoxIncluded) {
      this.deleteSection();
    }

    const setIcon = this.invalidBoxes.find((element) => element === selectedBox);
    if (!setIcon) {
      return false;
    } else {
      return true;
    }
  }

  deleteSection() {
    const index = this.invalidBoxes.indexOf(this.app.leftNav.selectedBox!);
    this.invalidBoxes.splice(index, 1);
  }

  /** returns fields from each section */
  getFieldsByBox(box: any) {
    // let list = this.app.list.thread.boxesLeftEditMode;
    // const segment = box.split(".");

    switch (box) {
      case "thread.main":
        return this.app.list.thread["main"];
      case "thread.impact":
        return this.app.list.thread["impact"];
      case "thread.calendar":
        return this.app.list.thread["calendar"];
      case "thread.priority":
        return this.app.list.thread["priority"];
      case "thread.obsolescence":
        return this.app.list.thread["obsolescence"];
      case "thread.materialSection":
        return this.app.list.thread["materialSection"];
      case "thread.involved":
        return this.app.list.thread["involved"];
      case "thread.layer":
        return this.app.list.thread["layer"];
      case "thread.deadlines":
        return this.app.list.thread["deadlines"];
      case "thread.rest":
        return this.app.list.thread["rest"];
      case "thread.situation":
        return this.app.list.thread["situation"];
      case "thread.effort":
        return this.app.list.thread["effort"];
      case "thread.procurement":
        return this.app.list.thread["procurement"];
      case "thread.contactData":
        return this.app.list.thread["contactData"];
      case "thread.mainCreateCase":
        return this.app.list.thread["mainCreateCase"];
      // return this.app.list.thread[segment];
      default:
        return [];
    }
  }

  // TODO: check if this function can be removed
  // check if the box contains any required fields
  // doesBoxHaveRequiredFields(box: string) {
  //   //show the mark for th left nav boxes only if you are not editing an post doc
  //   if (!this.app.post.model["post._id"]) {
  //     return this.app
  //       .getList(box)
  //       .some((fieldId) => this.app.field.isRequired(fieldId));
  //   }
  // }

  // TODO: check if this function can be removed
  // check if the box should be displayed
  // showBox(box: string) {
  //   for (let i = 0; i < this.hideBoxes.length; i++) {
  //     if (this.hideBoxes[i] === box) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }
}
