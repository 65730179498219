import { TableDef } from "pdfmake/build/pdfmake";
import { getApp } from "../../app";
export class MasterData {
  [field: string]: string;
  pcnNumber = "";
  pcnIssueDate = "";
  pcnTitle = "";
  pcnType = "";
  pcnRevision = "";
  pcnEmail = "";
  pcnCity = "";
  pcnCompany = "";
  pcnStreet = "";
  pcnMfrName = "";
  pcnZipCode = "";
  pcnState = "";
  pcnCountry = "";
  omfStatus = "";
  artNumber = "";
  omfNumber = "";
  company = "";
}

const getClientSpecific = (data: MasterData): TableDef => {
  const app = getApp();
  return {
    table: {
      widths: ["*", "*", "*"],
      border: [true, true, true, false],
      body: [
        [
          {
            text: [
              {
                text: app.text.pdf.omfNumber + "\n",
                style: "fieldName",
              },
              {
                text: app.field.getFieldValueAsText(
                  app.fieldId.thread.omfNumber,
                  data.omfNumber
                ),
                style: "input",
              },
            ],
          },
          {
            text: [
              {
                text: app.text.pdf.artNumber + "\n",
                style: "fieldName",
              },
              {
                text: app.field.getFieldValueAsText(
                  app.fieldId.thread.artNumber,
                  data.artNumber
                ),
                style: "input",
              },
            ],
          },
          {
            text: [
              {
                text: app.text.pdf.omfStatus + "\n",
                style: "fieldName",
              },
              {
                text: app.field.getFieldValueAsText(
                  app.fieldId.thread.omfStatus,
                  data.omfStatus
                ),
                style: "input",
              },
            ],
          },
        ],
      ],
    },
  };
};

const getPcnGenSpecific = (data: MasterData): TableDef => {
  const app = getApp();
  return {
    table: {
      widths: ["*", "*", "*", "*"],
      border: [true, false, true, true],
      body: [
        [
          {
            text: app.text.pdf.mfrName,
            style: "mfr_data",
            border: [true, false, false, false],
          },
          {
            text: data.company ? data.company : data.pcnCompany || "",
            style: "input_master",
            border: [false, false, false, false],
          },
          {
            text: app.text.pdf.street,
            style: "mfr_data",
            border: [false, false, false, false],
          },
          {
            text: data.pcnStreet || "",
            style: "input_master",
            border: [false, false, true, false],
          },
        ],
        [
          // border: [true, false, true, true],
          {
            text: app.text.pdf.mfrShortName,
            style: "mfr_data",
            border: [true, false, false, false],
          },
          {
            text: data.pcnMfrName || "",
            style: "input_master",
            border: [false, false, false, false],
          },
          {
            text: app.text.pdf.zipCode,
            style: "mfr_data",
            border: [false, false, false, false],
          },
          {
            text: data.pcnZipCode || "",
            style: "input_master",
            border: [false, false, true, false],
          },
        ],
        [
          // border: [true, false, true, true],
          {
            text: "",
            border: [true, false, false, false],
          },
          {
            text: "",
            border: [false, false, false, false],
          },
          {
            text: app.text.pdf.city,
            style: "mfr_data",
            border: [false, false, false, false],
          },
          {
            text: data.pcnCity || "",
            style: "input_master",
            border: [false, false, true, false],
          },
        ],
        [
          // border: [true, false, true, true],
          {
            text: app.text.pdf.email,
            style: "mfr_data",
            border: [true, false, false, false],
          },
          {
            text: data.pcnEmail || "",
            style: "input_master",
            border: [false, false, false, false],
          },
          {
            text: app.text.pdf.state,
            style: "mfr_data",
            border: [false, false, false, false],
          },
          {
            text: data.pcnState || "",
            style: "input_master",
            border: [false, false, true, false],
          },
        ],
        [
          // border: [true, false, true, true],
          {
            text: "",
            border: [true, false, false, false],
          },
          {
            text: "",
            border: [false, false, false, false],
          },
          {
            text: app.text.pdf.country,
            style: "mfr_data",
            border: [false, false, false, false],
          },
          {
            text: data.pcnCountry || "",
            style: "input_master",
            border: [false, false, true, false],
          },
        ],
      ],
    },
  };
};

export const getMasterDataDef = (
  data: MasterData,
  appType: "omc" | "pcngen" = "pcngen"
): TableDef[] => {
  const app = getApp();
  const docDef: TableDef[] = [];
  if (appType === "omc") {
    docDef.push(getClientSpecific(data));
  }
  docDef.push({
    table: {
      widths: ["*"],
      body: [
        [
          {
            text: app.text.pdf.coreDate,
            style: "section",
          },
        ],
      ],
    },
  });
  docDef.push({
    table: {
      widths: ["auto", 80, "*", "auto"],
      body: [
        [
          app.text.pdf.pcnId,
          {
            text: data.pcnNumber || "",
            style: "input_master",
            alignment: "center",
          },
          {
            text: data.pcnTitle || "Title",
            rowSpan: 3,
            alignment: "center",
            fontSize: 13,
            bold: true,
            margin: [0, 10, 0, 0],
          },
          {
            text: app.text.pdf.pcnType,
            margin: [0, 5, 0, 5],
          },
        ],
        [
          app.text.pdf.issueDate,
          {
            text: data.pcnIssueDate || "",
            style: "input_master",
            alignment: "center",
          },
          "",
          {
            text: data.pcnType || "",
            rowSpan: 2,
            alignment: "center",
            margin: [0, 8, 0, 8],
          },
        ],
        [
          {
            text: [
              app.text.pdf.pcnRevision + "\n",
              { text: "optional", style: "hint" },
            ],
          },
          {
            text: data.pcnRevision || "",
            style: "input_master",
            alignment: "center",
          },
          "",
          "",
        ],
      ],
    },
  });

  if (appType === "pcngen") {
    docDef.push(getPcnGenSpecific(data));
  }

  if (appType !== "pcngen") {
    docDef.push({
      table: {
        widths: ["*", "*"],
        border: [true, true, true, true],
        body: [
          [
            {
              text: app.text.pdf.mfrName,
              style: "mfr_data",
              border: [true, false, false, false],
            },
            {
              text: data.company ? data.company : data.pcnCompany || "",
              style: "input_master",
              border: [false, false, true, false],
            },
          ],
          [
            // border: [true, false, true, true],
            {
              text: app.text.pdf.mfrShortName,
              style: "mfr_data",
              border: [true, false, false, false],
            },
            {
              text: data.pcnMfrName || "",
              style: "input_master",
              border: [false, false, true, false],
            },
          ],
          [
            // border: [true, false, true, true],
            {
              text: app.text.pdf.email,
              style: "mfr_data",
              border: [true, false, false, true],
            },
            {
              text: data.pcnEmail || "",
              style: "input_master",
              border: [false, false, true, true],
            },
          ],
        ],
      },
    });
  }

  return docDef;
};
