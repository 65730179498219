import { AbstractGraphData } from "./abstract-graph-data";
import { AppType } from "../app.type";
import { GraphConfig, GraphDimensions, GraphType } from "./graph.type";
import { GraphDataItem } from "./graphs.service.type";

export abstract class AbstractGraph<T extends AbstractGraphData>
  implements GraphType {
  abstract readonly config: GraphConfig;
  private map: Map<string | Date, T> = new Map();

  get data(): GraphDataItem[] {
    this.getMap(this.map);
    return Array.from(this.map.values())
      .filter(this.getFilterFunction())
      .sort(this.getSortFunction())
      .map((dataItem) => dataItem.data);
  }

  async getDataAsync(): Promise<GraphDataItem[]> {
    await this.getMap(this.map);
    return Array.from(this.map.values())
      .filter(this.getFilterFunction())
      .sort(this.getSortFunction())
      .map((dataItem) => dataItem.data);
  }

  get sortOptions(): string[] {
    return [];
  }

  get dimensions(): GraphDimensions {
    const calculatedHeight = this.data.length * 30 + 50;
    return {
      "height.px": calculatedHeight < 300 ? 300 : calculatedHeight,
    };
  }

  constructor(protected app: AppType) {}

  protected abstract getMap(map: Map<any, T>): void;

  protected getFilterFunction(): (item: T) => boolean {
    return () => true;
  }

  protected getSortFunction(): (itemA: T, itemB: T) => number {
    return () => 0;
  }

  /** Get dates of previous 12 months by default (descending order) */
  protected generateDates(months = 12, from: Date = new Date()) {
    const year = from.getFullYear();
    let month = from.getMonth();
    if (months < 1) {
      throw Error;
    }
    const dates = [];
    let i = months;
    while (i-- > 0) {
      const date = new Date(year, month);
      dates.push(date);
      month--;
    }
    return dates;
  }
}
