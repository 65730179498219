import { Component, OnDestroy, OnInit } from "@angular/core";
import { getApp } from "../../app";
import { Subscription } from "rxjs";
import { StringUtils } from "../../../../../shared/utils/string.utils";
import { FilterMode } from "./OC-home.service.type";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-oc-home",
  templateUrl: "./OC-home.component.html",
  styleUrls: ["./OC-home.component.scss"],
})
export class OCHomeComponent implements OnInit, OnDestroy {
  updatePostsSubscription: Subscription = new Subscription();

  filterModes: FilterMode[] = ["title", "tag", "message", "user_id"];
  // searchWords: any = {};

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.app.routing.setTabTitle("Obsolescence Community");
    this.app.OCHome.tags = this.app.field.getOptions(
      this.app.fieldId.ocPost.tagCategory
    );

    // populate post model with all needed information
    await this.app.OCHome.ocPosts();

    // TODO: remove this after user managerment chages are integrated
    this.updatePostsSubscription = this.app.OCHome.updatePostsSubject.subscribe(
      async (value) => {
        if (value) {
          if (!this.app.change.fromAlert) {
            await this.app.OCHome.ocPosts();
          }
        }
      }
    );

    /** when we login or navigate from another page we dont know the last postId,
     * only after we take all the posts with ocPosts we know the id, so we have to
     * add it to the URL
     */
    if (this.route.snapshot.params.postId == null) {
      this.app.routing.navigateOCPost(this.app.OCPost.selectedPost._id);
    } else {
      // when comming from the notification we have to set the selectedPost, based on the postId from the URL
      // TODO: create an api to take only the needed post based on postId
      if (this.app.OCHome.posts != null) {
        const notificationPost = this.app.OCHome.posts.find(
          (post: any) => post._id === this.route.snapshot.params.postId
        );
        if (notificationPost != null) {
          this.app.OCPost.selectedPost = notificationPost;
        }
      }
    }
    this.app.OCPost.updatePostSubject.next(true);
    this.app.change.fromAlert = false;
  }

  setType(type: string) {
    this.app.OCHome.selectedType = type;
    if (this.app.OCHome.isFilterSelected) {
      this.clearFilter();
    }
  }

  getTextDisplay(value: string) {
    switch (value) {
      case "user_id":
        return this.app.text.ocPost.own;
      case "title":
        return this.app.text.ocPost.title;
      case "message":
        return this.app.text.ocPost.description;
      case "tag":
        return "Category";
    }
  }

  isSelected(filter: string) {
    this.app.OCHome.selectedValue = this.app.OCHome.searchWords[filter];
    if (!StringUtils.isNullOrEmpty(this.app.OCHome.selectedValue)) {
      this.app.OCHome.isFilterSelected = true;
    } else {
      this.app.OCHome.isFilterSelected = false;
    }
    return this.app.OCHome.isFilterSelected;
  }

  async clearFilter() {
    this.app.OCHome.selectedValue = "";
    this.app.OCHome.isFilterSelected = false;
    this.app.OCHome.searchWords = {};
    await this.app.OCHome.ocPosts();
    this.app.OCPost.selectedPost = this.app.OCHome.posts[0];
    this.app.OCPost.updatePostSubject.next(true);
    this.app.OCHome.updatePostsSubject.next(true);
  }

  ngOnDestroy() {
    if (this.updatePostsSubscription) {
      this.updatePostsSubscription.unsubscribe();
    }
    this.app.OCHome.isFilterSelected = false;
    this.app.OCHome.searchWords[this.app.OCHome.selectedType] = "";
  }
}
