import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { Row } from "../tree/tree.util";

@Component({
  selector: "app-tree-cell",
  templateUrl: "./tree-cell.component.html",
  styleUrls: ["./tree-cell.component.scss"],
})
export class TreeCellComponent implements OnInit {
  @ViewChild("seeStructureModal", { static: false }) seeStructureModal: any;

  app = getApp((app) => {
    this.app = app;
  });

  @Input() column: string | null = null;
  @Input() row: Row | null = null;
  showStructure = false;
  isPart = false;
  isManufacturer = false;

  constructor() {}

  ngOnInit() {
    this.getRowTypes();
  }

  getRowTypes() {
    this.isPart = this.app.tree.isPartRow(this.row);
    this.isManufacturer = this.app.tree.isManufacturerRow(this.row);
  }

  openWhereUsedModal(partNumber: string) {
    this.app.spinner.showSpinner();
    this.seeStructureModal.open();
    this.app.treeCell.generateImpactsFromParts(partNumber);
  }

  counter(i: number) {
    return new Array(i);
  }
}
