import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { loadModel } from "../utils/app.utils";
import { Train } from "../../../../shared/models/train";
import { Docs } from "../../../../shared/components";
import { ActivatedRoute } from "@angular/router";
import { doc2Model } from "../api.service";

@Component({
  selector: "app-train",
  templateUrl: "./train.component.html",
  styleUrls: ["./train.component.scss"],
})
export class TrainComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });

  // model: Train;
  trainId = "";
  currentTrain = {} as Train;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.trainId = params.train;
      this.app.train.threadId = params.threadId;
    });

    // get list of users to be displayed in the train responsible dropdown
    this.app.users.getUsersWithRoles();

    if (this.trainId) {
      this.getTrain(this.trainId);
      this.app.train.getTrains();
    } else {
      this.app.unlockedId = null;
    }
  }

  async getTrain(trainId: string) {
    const train: Train = await this.app.train.getTrainDetails(trainId);
    this.currentTrain = train[0];
    this.app.train.train = this.currentTrain;
    const model = doc2Model("train", this.currentTrain);
    this.app.train.currentModel = model as Train;
    this.app.state.next({ model });
    return loadModel("train", this.convertDoc(train), this.app.model);
  }

  convertDoc(train: Train): Docs["train"] {
    const trainDoc: Docs["train"] = {
      _id: train[0]._id,
      _rev: train[0]._rev,
      trainName: train[0].trainName,
      trainResponsible: train[0].trainResponsible,
      omfNumber: train[0].omfNumber,
      delete: "",
      trainRemoved: train[0].trainRemove,
    };
    return trainDoc;
  }

  delete() {
    this.app.train.delete(this.currentTrain);
  }

  // modelHasChanges() {
  //   if (
  //     this.currentTrain.trainName !== this.app.model["train.trainName"] ||
  //     this.currentTrain.trainResponsible !==
  //       this.app.model["train.trainResponsible"]
  //   ) {
  //     return true;
  //   }
  // }

  discardChanges() {
    this.app.unlockedId = null;
    this.app.model = this.app.train.currentModel;
    this.app.field.inputSubject.next(true);
  }

  navigateToListView() {
    this.app.routing.navigateBack();
    this.app.clearModel();
    if (this.app.train.train._id !== undefined) {
      this.app.train.train._id = undefined;
    }
  }
}
