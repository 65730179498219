import { TasksServiceType } from "./tasks.service.type";
import { APP_TYPE, AppType } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { deletePosts, uncompletedTasks } from "../api.service";
import { Task } from "../../../../shared/models/task";
import { getTaskByTab, getAllTasks } from "../api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { writeConfigFile } from "../export/config-export";
import { TASK } from "../post/post.service.type";
import { Customer } from "../../../../shared/types/customers";
import { Post } from "../../../../shared/models/post";

export const MY_TASKS = "myTasks";
export const MY_OPEN_TASK = "myOpenTasks";
const CREATED_TASKS = "createdTasks";
export const CREATED_OPEN_TASKS = "createdOpenTasks";
const ALL_TASKS = "allTasks";
export const ALL_OPEN_TASKS = "allOpenTasks";
export type TasksTab =
  | "myTasks"
  | "createdTasks"
  | "myOpenTasks"
  | "createdOpenTasks"
  | "allTasks"
  | "allOpenTasks";

@Injectable()
export class TasksService implements TasksServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  numberOfTasks = 0;
  tasks: Task[] = [];
  cleanTasks: Task[] = [];
  myTaskDocs: Task[] = [];
  myCreatedTaskDocs: Task[] = [];
  allTaskDocs: Task[] = [];
  tasksTab: TasksTab = MY_TASKS;
  taskColumns: string[] = [];
  quickFilterSelected = "";
  quickFilters: string[] = [];
  selectedFilters: string[] = [];
  loading = false;

  resetFilterInputs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  taskSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resetFilterInputsObservable: Observable<boolean> =
    this.resetFilterInputs.asObservable();

  async setTasksTab(currentTab: TasksTab) {
    this.loading = true;
    this.tasksTab = currentTab;
    this.resetFilterComponent();

    switch (currentTab) {
      case MY_TASKS:
      case MY_OPEN_TASK:
        // get the columns and filters that needs to be displayed in the table for these tabs
        this.app.tasks.taskColumns = this.app.list.post.tasksTableColumns.map(
          (field) => field.split(".")[1]
        );
        this.app.tasks.taskColumns.push("finalSolution", "cpn");
        this.quickFilters = [MY_OPEN_TASK, MY_TASKS];
        this.app.tasks.quickFilterSelected = MY_OPEN_TASK;

        if (this.app.state.user != null) {
          this.myTaskDocs = await this.getTasks(this.app.state.user, MY_TASKS);
        }
        break;
      case CREATED_TASKS:
      case CREATED_OPEN_TASKS:
        // get the columns and filters that needs to be displayed in the table for these tabs
        this.app.tasks.taskColumns =
          this.app.list.post.createdTasksTableColumns.map(
            (field) => field.split(".")[1]
          );
        this.app.tasks.taskColumns.push("finalSolution", "cpn");
        this.quickFilters = [CREATED_OPEN_TASKS, CREATED_TASKS];
        this.app.tasks.quickFilterSelected = CREATED_OPEN_TASKS;

        if (this.app.user != null) {
          this.myCreatedTaskDocs = await this.getTasks(
            this.app.user,
            CREATED_TASKS
          );
        }
        break;
      case ALL_TASKS:
      case ALL_OPEN_TASKS:
        // get the columns and filters that needs to be displayed in the table for these tabs
        this.app.tasks.taskColumns =
          this.app.list.post.allTasksTableColumns.map(
            (field) => field.split(".")[1]
          );
        this.app.tasks.taskColumns.push("finalSolution", "cpn");
        this.quickFilters = [ALL_OPEN_TASKS, ALL_TASKS];
        this.app.tasks.quickFilterSelected = ALL_OPEN_TASKS;

        if (this.app.user != null) {
          this.allTaskDocs = await this.getTasks(this.app.user, ALL_TASKS);
        }
        break;
    }
    // We need to apply the quick filter because the default sortation is My Open Tasks and Created Open Tasks
    await this.addQuickFilter(currentTab);
    this.loading = false;
  }

  async getTasks(name: string, taskTab: TasksTab): Promise<Task[]> {
    if (taskTab === ALL_TASKS) {
      this.tasks = await getAllTasks(this.app.customers.expectCurrent, taskTab);
    } else {
      this.tasks = await getTaskByTab(
        this.app.customers.expectCurrent,
        name,
        taskTab
      );
    }
    this.cleanTasks = this.tasks;
    return this.tasks;
  }

  async addQuickFilter(item: TasksTab) {
    this.resetFilterComponent();

    switch (item) {
      case MY_TASKS:
        this.tasks = this.myTaskDocs;
        this.cleanTasks = this.myTaskDocs;
        break;
      case MY_OPEN_TASK:
        this.tasks = this.myTaskDocs.filter(
          (t) => t.taskCompleted === false || t.taskCompleted == null
        );
        this.cleanTasks = this.tasks;
        break;
      case CREATED_TASKS:
        this.tasks = this.myCreatedTaskDocs;
        this.cleanTasks = this.myCreatedTaskDocs;
        break;
      case CREATED_OPEN_TASKS:
        this.tasks = this.myCreatedTaskDocs.filter(
          (t) => t.taskCompleted === false || t.taskCompleted == null
        );
        this.cleanTasks = this.tasks;
        break;
      case ALL_TASKS:
        this.tasks = this.allTaskDocs;
        this.cleanTasks = this.allTaskDocs;
        break;
      case ALL_OPEN_TASKS:
        this.tasks = this.allTaskDocs.filter(
          (t) => t.taskCompleted === false || t.taskCompleted == null
        );
        this.cleanTasks = this.tasks;
        break;
    }
  }

  async uncompletedTasks() {
    if (this.app.state.user != null && this.app.permission.post.hasTasks) {
      let tasks = await uncompletedTasks(
        this.app.customers.expectCurrent,
        this.app.state.user
      );

      if (tasks.numberOfUncompletedTasks) {
        this.numberOfTasks = tasks.numberOfUncompletedTasks;
      } else {
        this.numberOfTasks = 0;
      }
    }
  }

  getUncompletedTasks() {
    const tasks = this.app.post.tasks.filter(
      (task: Post) => !task.taskCompleted
    );
    return tasks.length;
  }

  getTasksByStatus() {
    const tasksByStatus = this.app.post.tasks.filter((task: Post) => {
      if (task.snapshot != undefined && task.snapshot.thread != undefined) {
        return (
          task.snapshot.thread.omfStatus ===
          this.app.thread.thread[this.app.fieldId.thread.omfStatus]
        );
      }
    });
    this.app.post.uncompletedTasksByStatus = tasksByStatus.filter(
      (task) => task.taskCompleted === false
    );
    this.app.post.completedTasksByStatus = tasksByStatus.filter(
      (task) => task.taskCompleted === true
    );

    // update stepper responsibles using the taskSubject
    if (this.app.customers.expectCurrent === Customer.DB) {
      this.taskSubject.next(true);
    }
  }

  async deleteUncompletedTasksByStatus() {
    const uncompletedTasksByStatus = this.app.post.uncompletedTasksByStatus.map(
      (task) => task._id
    );

    if (uncompletedTasksByStatus.length > 0) {
      await deletePosts(
        this.app.customers.expectCurrent,
        uncompletedTasksByStatus,
        TASK,
        this.app.thread.thread["thread._id"]
      );
    }
  }

  async checkTasksStatus() {
    // Check if there are uncompleted tasks for the current status of thread
    if (this.app.post.uncompletedTasksByStatus.length === 0) {
      // If no uncompleted tasks were found and client is DB
      // Move the case into the next status & create tasks automatically

      if (this.app.customers.expectCurrent === Customer.DB) {
        await this.app.thread.changeToNextStatus();
        await this.app.post.getThreadPosts(
          this.app.customers.expectCurrent,
          this.app.thread.thread["thread.omfNumber"]
        );
        this.uncompletedTasks();
        await this.app.thread.getThread(this.app.thread.thread["thread._id"]);
      }
    }
  }

  getTaskDueDateColor(date: string, isCompleted: boolean) {
    const dueDate = Date.parse(date);
    const currentDate = new Date().getTime();
    const dif = currentDate - dueDate;
    const millisecInDay = 86400000;
    // show date red if due date is today or in the past
    // show date green if due date is in the future
    /** if the task is completed display the field in the same color as the label */
    if (dif >= millisecInDay || (dif < millisecInDay && dif > 0)) {
      if (!isCompleted) {
        return { color: "red" };
      }
    } else {
      if (!isCompleted) {
        return { color: "#008000" };
      }
    }
  }

  async exportData() {
    await writeConfigFile(this.app, TASK);
  }

  resetFilterComponent() {
    this.selectedFilters = [];
    this.resetFilterInputs.next(true);
  }

  openTaskPanel(task: Task) {
    this.app.panel.openPanel();
    this.app.panel.selectedItem = task;
    this.app.panel.triggerPanel.next(true);
    // TODO: check if these are still needed once create task will be done using a panel
    this.app.post.setRequiredFields(TASK);
    this.app.post.isReadMode = true;
    this.app.post.showSaveButton = false;
  }

  get numberOfUncompletedTasks() {
    return this.numberOfTasks;
  }
}
