import { ManufacturerMatchingServiceType } from "./manufacturer-matching.service.type";
import {
  getManufacturers,
  updateManufacturer,
  getParts,
  doc2Model,
  getMatches,
  createMatchDoc,
} from "../api.service";
import { Manufacturer, SeMatch } from "../../../../shared/models/manufacturer";
import { Part } from "../../../../shared/models/part";
import { ManufacturerService } from "../manufacturer/manufacturer.service";
import { Inject, Injectable } from "@angular/core";
import { AppType, APP_TYPE } from "../app.type";
import { userEditedFields } from "../utils/app.utils";
import {
  ObsolescenceStatus,
  EuRoHSStatus,
} from "../../../../shared/models/rm-common-properties";
import { DataProvider } from "../../../../shared/constants/dataProvider";
import { Z2DataMatch } from "../../../../shared/models/dataProviders/z2data";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Injectable()
export class ManufacturerMatchingService
  implements ManufacturerMatchingServiceType {
  manufacturer = new Manufacturer();

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async updateManufacturer(manufacturer: Manufacturer, oldDoc?: Manufacturer) {
    const fields = userEditedFields(
      doc2Model("manufacturer", manufacturer),
      doc2Model("manufacturer", oldDoc)
    );
    const manufacturerEditedFields = fields.filter(
      (val: any) => val != undefined && val != "manufacturerEditedFields"
    );
    manufacturer["manufacturerEditedFields"] = manufacturerEditedFields;

    await updateManufacturer(
      this.app.customers.expectCurrent,
      "manufacturer",
      manufacturer,
      oldDoc
    );
    this.app.state.next({
      hasSuccess: true,
      successText: this.app.text.matchingManufacturerModal.savedSuccesfully,
    });
    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 1000);
  }

  async getCurrentManufacturer(manufacturerId?: string): Promise<Manufacturer> {
    if (manufacturerId === null || manufacturerId === undefined) {
      return {} as Manufacturer;
    }

    const manufacturer = await getManufacturers(
      this.app.customers.expectCurrent,
      [manufacturerId]
    );

    let manufacturerDoc = manufacturer[0];

    const partNumber =
      manufacturerDoc.manufacturerPartNumber != null &&
      manufacturerDoc.manufacturerPartNumber !== ""
        ? manufacturerDoc.manufacturerPartNumber
        : (manufacturerDoc.manufacturerPartNumberRaw as string);

    manufacturerDoc.matches = await this.getManufacturerMatches(
      partNumber,
      manufacturerDoc.obsolescenceStatusOverride
    );

    if (manufacturerDoc.matches) {
      manufacturerDoc = this.parseMatchesData(manufacturerDoc);
    }
    this.manufacturer = manufacturerDoc;
    return manufacturerDoc;
  }

  async getManufacturerMatches(
    manufacturerPartNumber: string,
    isManualOverride: boolean
  ): Promise<SeMatch[]> {
    if (manufacturerPartNumber == null || isManualOverride === true) {
      return [];
    }

    const matches = await getMatches(
      this.app.customers.expectCurrent,
      manufacturerPartNumber,
      this.app.customers.dataProvider
    );

    return matches;
  }

  async getCurrentPart(partNumber: string) {
    if (partNumber == null) { return {} as Part; }

    const part = await getParts(this.app.customers.expectCurrent, [partNumber]);

    const partDoc = part[0];
    return partDoc;
  }

  async deleteMatch(manufacturer: Manufacturer, match: SeMatch | Z2DataMatch) {
    const doc = new Manufacturer();
    Object.keys(manufacturer).forEach((key) => {
      doc[key] = manufacturer[key];
    });
    manufacturer.seId = "";
    manufacturer.z2DataId = "";
    await this.updateManufacturer(doc, manufacturer);
    if (manufacturer._id == null) {
      return;
    }
    this.app.manufacturerMatching.getCurrentManufacturer(manufacturer._id);
  }

  isSpecialColumn(column: string) {
    if (
      column === "PartNumber" ||
      column === "Manufacturer" ||
      column === "Description" ||
      column === "YEOL" ||
      column === "EstimatedYearsToEOL" ||
      column === "MPN"
    ) {
      return false;
    }
    return true;
  }

  getTooltipMessage(manufacturer: Manufacturer) {
    const currentMatch =
      this.app.manufacturer.getMatchingStatusOfManufacturer(manufacturer);
    return (this.app.text.RM as any)[currentMatch];
  }

  async createMatch(manufacturer: Manufacturer, match: SeMatch | Z2DataMatch) {
    const manufacturerDoc = new Manufacturer();
    if (manufacturer == null) {
      return;
    }
    Object.keys(manufacturer).forEach((key) => {
      manufacturerDoc[key] = manufacturer[key];
    });

    manufacturerDoc["manufacturerPartDescription"] = match["Description"];
    manufacturerDoc["name"] = match["Manufacturer"];
    manufacturerDoc["edited"] = true;
    if (manufacturerDoc["searchResults"] === true) {
      manufacturerDoc["matches"] = [];
    }
    manufacturerDoc["searchResults"] = false;
    manufacturerDoc["obsolescenceStatusOverride"] = false;
    manufacturerDoc["datasheet"] = match["Datasheet"];
    manufacturerDoc["euRoHSVersion"] = match["RoHSVersion"];

    if (this.app.customers.dataProvider === DataProvider.SE) {
      manufacturerDoc["manufacturerPartNumber"] = match["PartNumber"];
      manufacturerDoc["obsolescenceStatus"] = match[
        "Lifecycle"
      ] as ObsolescenceStatus;
      manufacturerDoc["euRoHS"] = match["RoHS"] as EuRoHSStatus;
      if (match["TaxonomyPath"]) {
        const path = match["TaxonomyPath"].split(">");
        manufacturerDoc["taxonomy"] = path[0];
      }
      manufacturerDoc["estimatedYearsToEOL"] = match["YEOL"].toString();
      manufacturerDoc["seId"] = match["ComID"];
    }

    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      manufacturerDoc["manufacturerPartNumber"] = match["MPN"];
      manufacturerDoc["obsolescenceStatus"] = match[
        "LifecycleStatus"
      ] as ObsolescenceStatus;
      manufacturerDoc["euRoHS"] = match["RoHSStatus"] as EuRoHSStatus;
      manufacturerDoc["taxonomy"] = match["Category"];
      manufacturerDoc["estimatedYearsToEOL"] =
        match["EstimatedYearsToEOL"].toString();
      manufacturerDoc["z2DataId"] = match["PartID"].toString();
    }

    if (manufacturerDoc == null) {
      return;
    }
    await this.app.manufacturerMatching.updateManufacturer(
      manufacturerDoc,
      manufacturer
    );
    if (manufacturerDoc._id == null) {
      return;
    }
    await this.app.manufacturerMatching.getCurrentManufacturer(
      manufacturerDoc._id
    );

    // create match document
    const mpn =
      this.app.customers.dataProvider === DataProvider.Z2DATA
        ? match.MPN
        : match.PartNumber;
    await createMatchDoc(
      this.app.customers.expectCurrent,
      this.app.customers.dataProvider,
      mpn,
      match.Manufacturer
    );
  }

  async cleanUpData(match: SeMatch | Z2DataMatch, fields: Set<any>) {
    const manufacturerDoc = new Manufacturer();
    const oldManufacturerDoc = new Manufacturer();

    Object.keys(match).forEach((key) => {
      manufacturerDoc[key] = match[key];
      oldManufacturerDoc[key] = match[key];
    });
    fields.forEach((key) => {
      key = key.split(".")[1];
      manufacturerDoc[key] = "";
    });
    if (manufacturerDoc == null) {
      return;
    }
    if (
      manufacturerDoc.manufacturerPartNumberRaw != null &&
      manufacturerDoc.nameRaw != ""
    ) {
      manufacturerDoc.manufacturerPartNumber =
        manufacturerDoc.manufacturerPartNumberRaw;
    }

    if (manufacturerDoc.nameRaw != null && manufacturerDoc.nameRaw != "") {
      manufacturerDoc.name = manufacturerDoc.nameRaw;
    }
    manufacturerDoc.edited = false;
    manufacturerDoc.manufacturerPartDescription = "";
    manufacturerDoc.datasheet = "";
    await this.app.manufacturerMatching.updateManufacturer(
      manufacturerDoc,
      oldManufacturerDoc
    );
    if (manufacturerDoc._id == null) {
      return;
    }
    await this.app.manufacturerMatching.getCurrentManufacturer(
      manufacturerDoc._id
    );
  }

  getColumn(column: string): string {
    if (this.app.customers.dataProvider === DataProvider.SE) {
      return this.getSeColumn(column);
    }
    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      return this.getZ2DataColumn(column);
    }
    return "";
  }

  getSeColumn(column: string): string {
    switch (column) {
      case "manufacturer.manufacturerPartNumber":
        return "manufacturer.PartNumber";
      case "manufacturer.name":
        return "manufacturer.Manufacturer";
      case "manufacturer.obsolescenceStatus":
        return "manufacturer.Lifecycle";
      case "manufacturer.rohs":
        return "manufacturer.RoHS";
      case "manufacturer.manufacturerPartDescription":
        return "manufacturer.Description";
      case "manufacturer.estimatedYearsToEOL":
        return "manufacturer.YEOL";
      default:
        return "manufacturer.PartNumber";
    }
  }

  getZ2DataColumn(column: string): string {
    switch (column) {
      case "manufacturer.manufacturerPartNumber":
        return "manufacturer.PartID";
      case "manufacturer.name":
        return "manufacturer.Manufacturer";
      case "manufacturer.manufacturerPartDescription":
        return "manufacturer.Description";
      case "manufacturer.datasheet":
        return "manufacturer.Datasheet";
      default:
        return "manufacturer.MPN";
    }
  }

  updateCurrentMatchIfChangesExist(
    currentManufacturer: Manufacturer,
    matches: SeMatch[]
  ) {
    const dataProviderId =
      this.app.customers.dataProvider === DataProvider.Z2DATA
        ? currentManufacturer.z2DataId
        : currentManufacturer.seId;
    matches.forEach((match) => {
      // If current manufacturer status is different than the current match status -> update the match without saving it to the database
      if (
        match.ComID === dataProviderId &&
        currentManufacturer.obsolescenceStatus !== match.Lifecycle
      ) {
        match.Lifecycle =
          currentManufacturer.obsolescenceStatus != null
            ? currentManufacturer.obsolescenceStatus
            : "";
        match.RoHS =
          currentManufacturer.euRoHS != null ? currentManufacturer.euRoHS : "";
        match.Datasheet =
          currentManufacturer.datasheet != null
            ? currentManufacturer.datasheet
            : "";
      }
    });
  }

  private parseMatchesData(manufacturerDoc: Manufacturer) {
    if (manufacturerDoc.matches != null) {
      manufacturerDoc.matches.forEach((match: SeMatch | Z2DataMatch) => {
        switch (this.app.customers.dataProvider) {
          case DataProvider.Z2DATA:
            match.LifecycleStatus = ManufacturerService.parseStatus(
              match.LifecycleStatus
            );
            match.RoHSStatus = ManufacturerService.parseRoHS(match.RoHSStatus);
            break;
          case DataProvider.SE:
          default:
            match.Lifecycle = ManufacturerService.parseStatus(match.Lifecycle);
            match.RoHS = ManufacturerService.parseRoHS(match.RoHS);
            break;
        }
      });
    }
    return manufacturerDoc;
  }
}
