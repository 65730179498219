import { APP_TYPE, AppType } from "../app.type";
import { BehaviorSubject } from "rxjs";
import { Inject, Injectable } from "@angular/core";
import { InternalItem } from "../../../../shared/models/internalItem";
import { InternalItemsServiceType } from "./internal-items.service.type";
import { Item } from "../../../../shared/models/item";
import {
  bulkDocsByType,
  saveItem,
  getItemsByOmfNumber,
  model2Doc,
  getDocsByType,
} from "../api.service";

@Injectable()
export class InternalItemsService implements InternalItemsServiceType {
  items: Item[] = [];
  currentItem: Item = {} as Item;
  allItems: InternalItem[] = [];
  itemExists = false;
  newItemSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  cleanItems: Item[] = [];
  itemIds: string[] = [];
  hasSuccess = false;

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  get addNewSapDataItem() {
    return this.app.state.addNewSapDataItem;
  }

  get newSapDataAttribute() {
    return this.app.state.newSapDataAttribute;
  }

  addNewItem() {
    this.app.state.next({
      addNewSapDataItem: true,
      newSapDataAttribute: new Item(),
    });
  }

  async getInternalItems() {
    this.app.spinner.showSpinner();
    const docs = await getItemsByOmfNumber(
      this.app.customers.expectCurrent,
      this.app.thread.thread["thread.omfNumber"]
    );
    /** the incoming documents from the db are of type InternalItem */
    /** we need to transform the documents into Item type that also contains this editable - flag */
    /** editable - flag is needed since the document can be edited inline - in the table */
    if (docs.length > 0) {
      this.items = [];
      docs.forEach((doc: any) => {
        const newItem = new Item();
        newItem.isEditable = false;
        newItem.part = doc as any;
        if (
          this.items.findIndex(
            (item) => item.part.partNumber === newItem.part.partNumber
          ) === -1
        ) {
          this.items.push(newItem);
        }
      });
      if (this.items) {
        this.itemIds = this.items.map(
          (i: Item) => i.part._id != null && i.part_id
        );
      }
    } else {
      this.items = [];
      this.app.state.next({
        addNewSapDataItem: true,
      });
    }
    this.app.spinner.hideSpinner();
  }

  async getAllInternalItems() {
    (this.allItems as any) = await getDocsByType(
      this.app.customers.expectCurrent,
      "internalItem"
    );
  }

  /** prepare the documents to be saved as InternalItem from Item type */
  async save(item: Item) {
    const doc = {} as InternalItem;

    Object.keys(item.part).forEach((key: string) => {
      doc[key] = item.part[key];
    });
    doc.omfNumber = this.app.thread.thread["thread.omfNumber"];
    try {
      item.isEditable = false;

      await saveItem(this.app.customers.expectCurrent, doc);

      this.newItemSubject.next(true);

      this.app.state.next({
        addNewSapDataItem: false,
      });
      this.hasSuccess = true;
      setTimeout(() => {
        this.hasSuccess = false;
      }, 3000);
      await this.app.thread.getThread(
        this.app.thread.thread[this.app.fieldId.thread._id]
      );

      // Reset allItems variable as when filtering by CPN/MPN on the home page we should always have the updated data
      this.allItems = [];
    } catch (err) {
      this.itemExists = true;
      setTimeout(() => {
        this.itemExists = false;
      }, 3000);
    }
  }

  // delete item with currentItemIndex
  async deleteInternalItem() {
    this.currentItem.part._deleted = true;
    await saveItem(
      this.app.customers.expectCurrent,
      this.currentItem.part as any
    );
    this.newItemSubject.next(true);
    await this.app.thread.getThread(
      this.app.thread.thread[this.app.fieldId.thread._id]
    );

    // Reset allItems variable as when filtering by CPN/MPN on the home page we should always have the updated data
    this.allItems = [];
  }

  async generateInternalItems(docs: InternalItem[]) {
    /** create internalItem docs to be saved into the database - save only the nonexisting docs */
    const threadDoc = model2Doc("thread", this.app.thread.thread);
    const items: string[] = threadDoc.items != null ? threadDoc.items : [];

    const uniqueItems = new Set<string>();
    const existingItems = new Set<string>();

    const docsToSave: Item[] = [];
    docs.forEach((doc) => {
      const newItem = new Item();
      newItem.part = doc;
      if ((newItem.part.entryCheckbox as any) === "true") {
        newItem.part.entryCheckbox = true;
      }
      if ((newItem.part.entryCheckbox as any) === "false") {
        newItem.part.entryCheckbox = false;
      }
      newItem.part.omfNumber = this.app.thread.thread["thread.omfNumber"];
      newItem.isEditable = false;
      newItem.part.type = "internalItem";
      if (items.findIndex((i) => i === doc.partNumber) === -1) {
        docsToSave.push(newItem);
        uniqueItems.add(doc.partNumber);
      } else {
        existingItems.add(doc.partNumber);
      }
    });
    const partNumbers = Array.from(uniqueItems);
    this.app.thread.thread[this.app.fieldId.thread.items] = [
      ...items,
      ...partNumbers,
    ];
    await bulkDocsByType(
      this.app.customers.expectCurrent,
      "internalItem",
      docsToSave.map((doc) => doc.part)
    );

    await this.app.thread.save(this.app.thread.thread);
    this.getInternalItems();
    this.app.state.next({
      addNewSapDataItem: false,
    });
  }
}
