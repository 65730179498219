import { Inject, Injectable } from "@angular/core";
import { Post } from "../../../../shared/models/post";
import { APP_TYPE, AppType } from "../app.type";
import {
  JUMPTOFIRST,
  NEXT,
  PREVIOUS,
  StepperDirection,
  StepperServiceType,
} from "./stepper.service.type";
import { Thread } from "../../../../shared/models/thread";
import { Customer } from "../../../../shared/types/customers";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Injectable()
export class StepperService implements StepperServiceType {
  steps: string[] = [];
  stepIndex = 0;
  statusDescription = "";
  statusResponsible = "";
  numberOfResponsibleLeft = 0;
  nextStatusDisabled = false;
  previousStatusDisabled = false;
  direction: StepperDirection = NEXT;

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  getSteps() {
    this.steps = this.app.field.getOptions(this.app.fieldId.thread.omfStatus);
  }

  getStepIndex(thread: Thread) {
    this.stepIndex = this.steps.indexOf(thread.omfStatus);
  }

  getStatusDescription(thread: Thread) {
    this.statusDescription = this.app.field.getFieldValueAsText(
      this.app.fieldId.thread.omfStatus,
      thread.omfStatus
    );
  }

  getStatusResponsible(thread: Thread) {
    this.statusResponsible = this.app.field.getFieldValueAsText(
      this.app.fieldId.thread.omfStatusResponsible,
      thread.omfStatus
    );
  }

  getNumberOfResponsibleLeft() {
    this.numberOfResponsibleLeft =
      this.app.post.uncompletedTasksByStatus.length > 0
        ? this.app.post.uncompletedTasksByStatus.length
        : 0;
  }

  textWithVariable(text: string, status: string): string {
    return text.replace("$var", status);
  }

  isStepDisabled(status: string, solutions?: Post[]) {
    this.app.spinner.showSpinner();
    const customer = this.app.customers.expectCurrent;
    switch (customer) {
      case Customer.NS:
        if (solutions === undefined) {
          return;
        }
        this.handleSpecialLogicNS(status, solutions);
        break;
      case Customer.MAQUETCARDIO:
        this.handleSpecialLogicMaquetCardio(status);
        break;
      case Customer.KNDS:
        this.handleSpecialLogicKNDS(status);
        break;
      default:
        this.nextStatusDisabled = false;
        this.previousStatusDisabled = false;
    }
    this.app.spinner.hideSpinner();
  }

  async handleSpecialLogicKNDS(status: string) {
    this.nextStatusDisabled = false;
    this.previousStatusDisabled = false;

    let projectResponsibles: string[] = [];
    const taskResponsibles: string[] = [];
    const openTask: string[] = [];

    if (this.app.thread.id != null) {
      this.app.impacts.impacts = await this.app.impacts.getImpacts(
        this.app.thread.id
      );
    }
    projectResponsibles = this.app.impacts.impacts
      .filter((o: any) => o.projectResponsible != null)
      .map((x: any) => x.projectResponsible);
    this.app.post.projectNames = this.app.impacts.impacts
      .filter((o: any) => o.projectNumber != null)
      .map((x: any) => x.projectNumber);
    this.app.post.projectNames = [...new Set(this.app.post.projectNames)];

    switch (status) {
      // when all generated task available in this step are completed -> move next status
      case "Notiz-Fall":
        if (this.app.post.posts.length === 0) {
          this.nextStatusDisabled = true;
          return;
        } else {
          this.app.post.posts.forEach((x: any) => {
            if (
              x.typeOfPost === "task" &&
              !x.taskCompleted &&
              x.snapshot.thread.omfStatus === "Notiz-Fall"
            ) {
              openTask.push(x);
            }

            const impactsWithoutProject = this.app.impacts.impacts.filter(
              (o: any) => StringUtils.isNullOrEmpty(o.projectNumber)
            );

            if (openTask.length > 0 || impactsWithoutProject.length > 0) {
              this.nextStatusDisabled = true;
              return;
            }
          });
        }
        break;
      // when all project responsibles ('Where used') have one task assigned -> move next status
      case "vorerfasst":
        if (projectResponsibles.length === 0) {
          this.nextStatusDisabled = true;
          return;
        } else {
          this.app.post.posts.forEach((x: any) => {
            if (x.typeOfPost === "task") {
              taskResponsibles.push(x.taskResponsible);
            }
          });

          const responsiblesWithOpenTask = projectResponsibles.filter(
            (x) => !taskResponsibles.includes(x)
          );

          if (responsiblesWithOpenTask.length !== 0) {
            this.nextStatusDisabled = true;
          }
        }
        break;
      // when all created tasks are completed and a solution for each project was added -> move next status
      case "In Bearbeitung":
        const solutionCreator: string[] = [];
        this.app.post.posts.forEach((x: any) => {
          if (x.typeOfPost === "task" && !x.taskCompleted) {
            openTask.push(x);
          }

          if (
            x.typeOfPost === "solution" &&
            projectResponsibles.includes(x.user_id)
          ) {
            solutionCreator.push(x.user_id);
          }
        });

        const responsiblesWithoutSolution = projectResponsibles.filter(
          (x) => !solutionCreator.includes(x)
        );

        if (openTask.length !== 0 || responsiblesWithoutSolution.length !== 0) {
          this.nextStatusDisabled = true;
        }
        break;
      // when one solution is marked as final -> move next status
      case "In Lösung":
        let isFinalSolution = false;
        this.app.post.posts.forEach((post: any) => {
          if (post.typeOfPost === "solution" && post.acceptedSolution) {
            isFinalSolution = true;
          }
        });

        if (!isFinalSolution) {
          this.nextStatusDisabled = true;
        }
        break;
      default:
        return;
    }
  }

  handleSpecialLogicNS(status: string, solutions: Post[]) {
    // disable the <Execution> button for NS client if safetyEvaluation is not marked as done
    // disable next/previous step buttons depending on the permissions
    this.nextStatusDisabled = false;
    this.previousStatusDisabled = false;
    const fieldValue = this.app.thread.thread["thread.safetyEvaluation"];

    switch (status) {
      case "allocation":
        // disable if there is not a solution added
        if (solutions.length == 0) {
          this.nextStatusDisabled = true;
        } else {
          // disable next step only if there is a solutiuon written which needs a safety eval + safety eval is not marked as finished
          solutions.forEach((solution) => {
            if (solution.resolveClass == null) {
              return;
            }
            if (
              this.app.post.needEvaluationCheck(solution.resolveClass) &&
              (fieldValue === false || fieldValue == null)
            ) {
              this.nextStatusDisabled = true;
            }
          });
        }
        break;
      case "decision":
        let hasAcceptedSolution = false;
        // disable execution step if there is a solution and it is not marked as final
        solutions.forEach((solution) => {
          if (solution.acceptedSolution == true) {
            hasAcceptedSolution = true;
          }
        });
        if (!hasAcceptedSolution) {
          this.nextStatusDisabled = true;
        }
        break;
      case "handshake":
        if (!this.app.permission.stepper.changeStatusToClosed) {
          this.nextStatusDisabled = true;
        }
        break;
      case "vorerfasst":
        if (this.app.auth.isUser) {
          this.nextStatusDisabled = true;
        }
        break;
      default:
        return;
    }
  }

  handleSpecialLogicMaquetCardio(status: string) {
    /** if there are open tasks the team shouldn't be able to close the case status */
    if (
      status === "In Lösung" &&
      this.app.post.tasks.length > 0 &&
      this.app.tasks.getUncompletedTasks() > 0
    ) {
      this.nextStatusDisabled = true;
    } else {
      this.nextStatusDisabled = false;
    }
  }

  hasChangeStatusPermission(direction: StepperDirection) {
    const isTeam = this.app.auth.isTeam;
    this.direction = direction;

    switch (this.direction) {
      case NEXT:
        return isTeam && this.app.post.uncompletedTasksByStatus.length === 0;
      case PREVIOUS:
        return isTeam && this.app.thread.threadReady;
      case JUMPTOFIRST:
        const closureStatus: boolean =
          this.app.thread.thread[this.app.fieldId.thread.omfStatus] === "120";

        return closureStatus && isTeam;
      default:
        return false;
    }
  }

  async setDueDate() {
    /** set status based on the direction of the workflow  */
    if (this.direction === NEXT) {
      this.app.thread.thread[this.app.fieldId.thread.omfStatus] =
        this.app.stepper.steps[this.app.stepper.stepIndex + 1];
    } else {
      this.app.thread.thread[this.app.fieldId.thread.omfStatus] =
        this.app.stepper.steps[this.app.stepper.stepIndex - 1];
    }
  }
}
