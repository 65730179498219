import { AlertData } from "../../../../shared/models/alert";
import { BehaviorSubject, Observable } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { getThreadsCompressed } from "../api.service";
import { getOpenedCasesFromMpn } from "../api.service";

@Component({
  selector: "app-alerts",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.scss"],
})
export class AlertsComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });
  selectedFilters: { columnName: string; value: string }[] = [];
  loading = true;

  constructor() {}

  public async ngOnInit(): Promise<void> {
    const duration = new Date().getTime() - 3600 * 1000 * 24 * 30 * 3;
    console.log(
      "should bring alerts with issue date later than",
      new Date(duration)
    );

    this.app.type = "alert";
    // reset allAlerts array when changing the view to avoid displaying the old data until the new one is loading
    this.app.alerts.allAlerts = [];

    this.app.alerts.getAlertColumns();
    await this.app.alerts.getAlerts(this.app.mail.tab);
    this.loading = false;
    this.app.alerts.cleanAlerts = this.app.alerts.allAlerts;
    this.app.table.clearSelection();
    // this.app.filterTable.resetAllFilters.next(true);
    // update threadsCompressed in order to set the new added case in seeOpenedCasesModal
    this.app.thread.threadsCompressed = await getThreadsCompressed(
      this.app.customers.expectCurrent,
      "thread"
    );
    /** get the list of cases that contain at least a cpn or a mpn */
    this.app.thread.threads = await getOpenedCasesFromMpn(
      this.app.customers.expectCurrent
    );
    console.log(
      "number of notifications not seen(view)",
      this.app.alerts.allAlerts.filter((a: AlertData) => !a.checkedByOm).length
    );
  }

  async navigateTo(c: string, alert: AlertData) {
    if (c === "manufacturerPartNumber" && alert["manufacturerId"]) {
      if (!alert.checkedByOm) {
        await this.app.alerts.markAsSeeen(alert);
      }
      this.app.manufacturer.navigateToManufacturerDetails(
        alert["manufacturerId"]
      );
    }
    if (c === "partNumber" && alert[c]) {
      if (!alert.checkedByOm) {
        await this.app.alerts.markAsSeeen(alert);
      }
      if (alert.partNumber == null) {
        return;
      }
      this.app.part.navigateToPartDetails(alert.partNumber);
    }
  }
}
