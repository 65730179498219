import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Train } from "../../../../shared/models/train";
import { Subscription } from "rxjs";

@Component({
  selector: "app-trains-list",
  templateUrl: "./trains-list.component.html",
  styleUrls: ["./trains-list.component.scss"],
})
export class TrainsListComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });
  loading = false;
  trains: Train[] = [];
  threadId = "";

  modeSubscription: Subscription = new Subscription();

  currentTrain = {} as Train;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.threadId = params.threadId;
    });
    this.allTrains();
    this.modeSubscription = this.app.train.newTrainSubject.subscribe(
      async () => {
        if (this.threadId !== undefined && this.threadId !== null) {
          this.trains = await this.app.train.getTrains();
        }
      }
    );
  }

  remove(column: string) {
    return column.split(".")[1];
  }

  async allTrains() {
    this.loading = true;
    this.trains = await this.app.train.getTrains();
    this.loading = false;
    return this.trains;
  }

  removeItem(index: number) {
    this.trains.splice(index, 1);
  }

  ngOnDestroy(): void {
    if (this.modeSubscription) {
      this.modeSubscription.unsubscribe();
    }
  }
}
