import { AppType, APP_TYPE } from "../app.type";
import {
  getTrains,
  saveTrain,
  deleteTrain,
  getTrainDetails,
  saveImpact,
  model2Doc,
} from "../api.service";
import { Train } from "../../../../shared/models/train";
import { Docs } from "../../../../shared/components";
import { DocModel } from "../state/state";
import { Inject, Injectable } from "@angular/core";
import { Impact } from "../../../../shared/models/impact";
import { BehaviorSubject } from "rxjs";

// TO DO - uncomment "showSuccess, showError" funnctions once the routing is fixed
@Injectable()
export class TrainService {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  newTrainSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  currentModel: any = {} as Train;
  trains: Train[] = [];
  trainsCompressed: Train[] = [];
  trainId = "";
  train = {} as Train;
  threadId = "";

  async getTrains(): Promise<Train[]> {
    this.trains = await getTrains(this.app.customers.expectCurrent);
    this.app.state.next({ allTrains: this.trains });
    return this.trains;
  }

  async getTrainDetails(trainId: string): Promise<Train> {
    return await getTrainDetails(this.app.customers.expectCurrent, trainId);
  }

  get isNew() {
    const index = this.trains.findIndex((train) => train._id === this.train._id);
    if (index === -1) {
      return true;
    } else {
      return false;
    }
  }

  // getModelClean() {
  //   let model: DocModel = {};
  //   if (this.train._id !== null) {
  //     model = this.app.state.model;
  //   } else {
  //     model = loadModel(
  //       "train",
  //       this.app.docs.getDoc(this.train._id, "train"),
  //       model
  //     );
  //   }
  //   this.app.state.next({ model: model });
  //   return model;
  // }

  // get modelHasChanges() {
  //   this.currentModel = doc2Model("train", this.train);

  //   Object.keys(this.app.model).forEach((key: string) => {
  //     this.currentModel[key] =
  //       this.currentModel[key] === undefined || this.currentModel[key] === ""
  //         ? this.app.model[key]
  //         : this.currentModel[key];
  //   });

  //   if (this.currentModel !== {}) {
  //     return hasChanges(this.currentModel, this.app.model);
  //   } else {
  //     return false;
  //   }
  // }

  async save() {
    // const { model } = this.app;
    const customer = this.app.customers.expectCurrent;
    // const newTrain: Train = {
    //   type: "train",
    //   trainName: model["train.trainName"],
    //   trainResponsible: model["train.trainResponsible"],
    //   update_user: "",
    //   user_id: this.app.state.user != null ? this.app.state.user : "",
    // };

    // // update train function
    // if (model["train._id"]) {
    //   newTrain._id = model["train._id"];
    //   newTrain._rev = model["train._rev"];
    //   newTrain.create_time = model["train.create_time"];
    //   newTrain.update_user =
    //     this.app.state.user != null ? this.app.state.user : "";
    // }
    const newTrain: any = model2Doc("train", this.currentModel);

    // save new train if a train with the same name does not exist (if !model['train._id'] means that we are saving a new doc)

    if (!this.currentModel["train._id"] && this.nameExist(newTrain)) {
      this.app.state.next({
        hasError: true,
        errorText: this.app.text.train.trainNameExist,
      });
      showError(this.app, this.app.text.train.trainNameExist);
    } else {
      const result = await saveTrain(customer, newTrain);
      this.newTrainSubject.next(true);
      if (result !== null) {
        showSuccess(this.app, this.app.text.train.success);
        // this.app.clearModel();
        // this.app.routing.navigateTrainsList(this.threadId);
        this.app.unlockedId = null;
        this.newTrainSubject.next(true);
        // this.train._id = undefined;
      } else {
        showError(this.app, this.app.text.train.error);
      }
    }
  }

  private nameExist(newTrain: Train): boolean {
    const currentTrains = this.app.state.allTrains;
    let exist = false;
    currentTrains.forEach((train) => {
      if (train.trainName === newTrain.trainName) {
        exist = true;
      }
    });
    return exist;
  }

  async delete(train: Train) {
    const customer = this.app.customers.expectCurrent;
    const result = await deleteTrain(customer, train);
    if (result != null) {
      showSuccess(this.app, this.app.text.train.success);
      this.app.clearModel();
      // this.app.routing.navigateBack();
    } else {
      showError(this.app, this.app.text.train.error);
    }
  }

  async addTrainToCase(train: Train) {
    // create impact
    const trainImpact: Impact = {
      type: "impact",
      impactType: "train",
      omfNumber: this.app.thread.thread["thread.omfNumber"],
      omfVehicleName: train.trainName,
      omfCommodityRespName: train.trainResponsible,
    };
    const result = await saveImpact(
      this.app.customers.expectCurrent,
      trainImpact
    );
    // add the case to favourites to the train responsible profile
    this.app.thread.addCaseToFav(
      [train.trainResponsible],
      this.app.thread.thread["thread._id"],
      true
    );
    if (result != null) {
      showSuccess(this.app, this.app.text.train.success);
    } else {
      showError(this.app, this.app.text.train.error);
    }
  }

  async removeTrainFromCase(impactedTrain: Impact) {
    impactedTrain._deleted = true;
    impactedTrain.update_user =
      this.app.state.user != null ? this.app.state.user : "";
    const result = await saveImpact(
      this.app.customers.expectCurrent,
      impactedTrain
    );
    this.app.thread.getThread(this.app.thread.thread["thread._id"]);
    // const result = await saveTrain(this.app.customers.expectCurrent, train);
    if (result != null) {
      showSuccess(this.app, this.app.text.train.success);
    } else {
      showError(this.app, this.app.text.train.error);
    }
  }

  isField(field: string) {
    return field === this.app.fieldId.train.trainName;
  }

  getOptions(field: string) {
    const trains = this.app.state.allTrains;
    const map: Map<string, (model: DocModel) => void> = new Map();
    Object.keys(trains).forEach((id) => {
      const doc: any = trains[Number(id)];
      const model: DocModel = {};
      this.set(doc, model);
      const option = model[field];
      if (option == null) {
        return;
      }
      map.set(option, (m) => this.set(doc, m));
    });
    return map;
  }

  set(doc: Docs["train"], model: DocModel) {
    const { train } = this.app.getModelProxy(model);
    train.trainName = doc.trainName;
    train.trainResponsible = doc.trainResponsible;
  }
}

function showSuccess(app: AppType, text: string) {
  app.state.next({ hasSuccess: true, successText: text });
  setTimeout(() => {
    app.state.next({ hasSuccess: false, successText: "" });
  }, 3000);
}

function showError(app: AppType, text: string) {
  app.state.next({ hasError: true, errorText: text });
  setTimeout(() => {
    app.state.next({ hasError: false, errorText: "" });
  }, 3000);
}
