import {
  Component,
  QueryList,
  ElementRef,
  ViewChildren,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { getApp } from "../app";
import {
  ACTIVE,
  NRND,
  OBSOLETE,
  PARTNUMBER,
  PDN,
  SPECIAL_STATUS,
  WITHOUT_DATA,
} from "./filter-tree.service";
import { Subscription } from "rxjs";
import { FieldTypes } from "../../../../shared/settings/settings";
@Component({
  selector: "app-filter-tree",
  templateUrl: "./filter-tree.component.html",
  styleUrls: ["./filter-tree.component.scss"],
})
export class FilterTreeComponent implements OnInit, OnDestroy {
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef> =
    {} as QueryList<ElementRef>;

  isCollapsed: boolean[] = [true];
  assemblyValue = "";
  obsolescenceStatusOptions: string[] = [
    OBSOLETE,
    PDN,
    NRND,
    ACTIVE,
    WITHOUT_DATA,
  ];
  treeFilterSubscription: Subscription = new Subscription();
  partNumber: string = PARTNUMBER;
  specialStatus: string = SPECIAL_STATUS;
  fieldsOnFilter: string[] = [];
  enableReset = false;
  // assemblies: { cpn: string; isChecked: boolean }[] = [];
  // model: DocModel = {}; -> model was used with the inputs field on tree

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    this.fieldsOnFilter = this.app.field.getAll("part");

    this.app.field.changeFieldSettings(this.app.fieldId.part.partNumber, FieldTypes.typeahead);

    this.treeFilterSubscription =
      this.app.filterTree.searchPartResult.subscribe(async (value) => {
        if (
          this.app.RM.fromRMSearch &&
          Object.keys(this.app.filterTree.filterValues).length === 1
        ) {
          this.enableReset = false;
        } else {
          this.enableReset = value;
        }
      });
  }

  // Uncheck checkboxes when reset filter
  resetFilter() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    // this.model = {};
    this.app.filterTree.searchPartResult.next(false);
    this.app.filterTree.resetFiltersByPage();
  }

  // Check if there is already an assembly selected
  // If true, do not display Customer Part Number in filter tree
  isAssemblySelected(field: string) {
    if (
      field === "part.partNumber" &&
      this.app.filterTree.assemblySelected !== ""
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.treeFilterSubscription) {
      this.treeFilterSubscription.unsubscribe();
    }
  }
}
