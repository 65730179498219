import { Component, Input, OnInit, Output } from "@angular/core";
import { getApp } from "../app";
import { Subject } from "rxjs";

@Component({
  selector: "app-field-options",
  templateUrl: "./field-options.component.html",
  styleUrls: ["./field-options.component.scss"],
})
export class FieldOptionsComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });

  @Input() field = "";
  @Input() model: any = {};
  @Input() searchText = "";
  @Output() selected: Subject<string> = new Subject();

  constructor() {}
  ngOnInit() {
    /** check if the last status is disabled - used for maquetcardio for now */
    if (this.field === "thread.omfStatus" && this.app.unlockedId != null) {
      this.app.stepper.isStepDisabled(
        this.app.thread.thread["thread.omfStatus"]
      );
    }
  }

  isMatch(search: string, option: string) {
    if (option == null) {
      return false;
    }
    if (search == null) {
      return true;
    }
    const cleanSearch = search.trim().toLowerCase();
    if (cleanSearch === "") {
      return true;
    }
    const cleanOption = option.trim().toLowerCase();
    return cleanOption.indexOf(cleanSearch) > -1;
  }

  // checkIfDisabled(i: number) {
  //   if (this.field === this.app.fieldId.thread.omfStatus) {
  //     if (this.app.stepper.nextStatusDisabled) {
  //       return true;
  //     }
  //   } else {
  //     return false;
  //   }
  // }
}
