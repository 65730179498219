import { Component } from "@angular/core";
import { getApp } from "../app";
import {
  CREATED_OPEN_TASKS,
  MY_OPEN_TASK,
  ALL_OPEN_TASKS,
  TasksTab,
} from "./tasks.service";

@Component({
  selector: "app-tasks",
  templateUrl: "./tasks.component.html",
  styleUrls: ["./tasks.component.scss"],
})
export class TasksComponent {
  constructor() {}
  myOpenTasks: TasksTab = MY_OPEN_TASK;
  createdOpenTasks: TasksTab = CREATED_OPEN_TASKS;
  allOpenTasks: TasksTab = ALL_OPEN_TASKS;
  columns: string[] = [];
  selectedFilters: { columnName: string; value: string }[] = [];

  app = getApp((app) => {
    this.app = app;
  });

  showShortDesciption = true;

  async ngOnInit() {
    await this.app.tasks.setTasksTab(MY_OPEN_TASK);
  }

  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption;
  }
}
