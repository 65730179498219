<div class="container-fluid">
  <!-- PCn Generator footer -->
  <div
    *ngIf="app.pcnGenerator.isPcnGenerator"
    class="row dark-blue-bg pcn-footer"
  >
    <!-- Logo -->
    <div class="col">
      <img class="logo m-3 p-1" src="./../assets/amsys-brand-2.png" />
    </div>
    <!-- Info 1 -->
    <div class="col">
      <div class="d-flex p-3">
        <i class="fas fa-map-marker-alt my-1 me-3"></i>
        <span class="contact-info">
          <div>{{ app.text.footer.companyName }}</div>
          <div>{{ app.text.footer.address }}</div>
        </span>
      </div>
    </div>
    <!-- Info 2 -->
    <div class="col">
      <div class="d-flex p-3">
        <i class="fas fa-location-arrow my-1 me-3"></i>
        <div class="contact-info">
          <div>+49 (0) 4103 92 853 60</div>
          <div>info&#64;am-sys.com</div>
        </div>
      </div>
    </div>
    <!-- Social Icons -->
    <div class="col social">
      <div class="d-flex flex-row justify-content-evenly p-3">
        <a target="_blank" [href]="app.text.footer.twitterUrl">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fab fa-twitter fa-stack-1x"></i>
          </span>
        </a>
        <a target="_blank" [href]="app.text.footer.facebookUrl">
          <span class="fa-stack">
            <i class="fab fa-facebook fa-stack-1x"></i>
          </span>
        </a>
        <a target="_blank" [href]="app.text.footer.linkedinUrl">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fab fa-linkedin-in fa-stack-1x"></i>
          </span>
        </a>
        <a target="_blank" [href]="app.text.footer.xingUrl">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fab fa-xing fa-stack-1x"></i>
          </span>
        </a>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- APP Footer -->
    <div
      [ngClass]="{ 'light-blue-bg': app.pcnGenerator.isPcnGenerator }"
      class="app-footer d-flex align-items-center"
    >
      <button class="btn m-1">© {{ year }} AMSYS GmbH</button>

      <a class="btn btn-sm" target="_blank" [href]="app.text.footer.imprintUrl">
        {{ app.text.footer.imprint }}
      </a>
      <a class="btn btn-sm" target="_blank" [href]="app.text.footer.privacyUrl">
        {{ app.text.footer.privacy }}
      </a>
      <a class="btn btn-sm" target="_blank" [href]="app.text.footer.contactUrl">
        {{ app.text.footer.contact }}
      </a>
      <div
        *ngIf="!app.pcnGenerator.isPcnGenerator && app.customer !== 'oc'"
        class="btn-group m-1"
      >
        <div *ngFor="let language of app.languages">
          <button
            *ngIf="app.customer !== 'ns' && language !== 'nl'"
            class="btn btn-sm mt-1"
            [ngClass]="{ selected: language === app.language }"
            (click)="app.language = language; app.users.setLanguage(language)"
          >
            {{ language | uppercase }}
          </button>
          <button
            *ngIf="app.customer === 'ns'"
            class="btn btn-sm mt-1"
            [ngClass]="{ selected: language === app.language }"
            (click)="app.language = language; app.users.setLanguage(language)"
          >
            {{ language | uppercase }}
          </button>
        </div>
      </div>
      <div class="float-end ms-auto">
        <a
          *ngIf="app.customer === 'db'"
          href="mailto:Support-OM.Workflow@deutschebahn.com"
          >Support-OM.Workflow&#64;deutschebahn.com</a
        >
        <span class="badge ms-1">{{ version }}</span>
      </div>
    </div>
  </div>
</div>
