<app-modal
  #deletePostModal
  [modalMessage]="app.text.post.deleteCommentQuestion"
  [modalTitle]="app.text.post.modalTitle"
  (ok)="app.OCPost.deletePost()"
></app-modal>
<app-modal
  #discardChangesModal
  [modalMessage]="app.text.app.discardChanges"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="app.OCPost.discardChanges()"
>
</app-modal>
<div class="row">
  <div class="col-md-8 ps-0">
    <div class="card scroll mb-2 p-3">
      <div class="d-flex">
        <!-- User details -->
        <img
          *ngIf="app.OCPost.selectedPost.avatar != ''"
          class="profile-image my-2"
          target="_blank"
          [src]="app.file.getUrl(app.OCPost.selectedPost.avatar)"
        />
        <img
          *ngIf="app.OCPost.selectedPost.avatar === ''"
          class="profile-image my-2"
          target="_blank"
          [src]="app.url.profile.defaultImage"
        />
        <div class="mx-4 my-2">
          <div class="clear">
            <span
              *ngIf="!app.OCPost.postHasValidUserName(app.OCPost.selectedPost)"
              ><b>{{ app.OCPost.selectedPost.user_id }}</b></span
            >
            <span
              *ngIf="app.OCPost.postHasValidUserName(app.OCPost.selectedPost)"
              ><b
                >{{ app.OCPost.selectedPost.userFirstName }}
                {{ app.OCPost.selectedPost.userLastName }}</b
              ></span
            >
            <span *ngIf="app.OCPost.selectedPost.userBadges != ''">
              <span *ngFor="let badge of app.OCPost.selectedPost.userBadges"
                >&nbsp;
                <i
                  class="fa-solid fa-medal fa-fw"
                  [style.color]="app.OCHome.setBadgeColor(badge)"
                  [ngbTooltip]="app.OCHome.setBadgeTooltip(badge)"
                ></i>
              </span>
            </span>
          </div>
          <div class="credits">
            <i>
              <div class="bottom-line">
                <span>&nbsp;{{ app.OCPost.selectedPost.userRole }}</span
                ><span
                  ><span *ngIf="app.OCPost.selectedPost.userCompany != ''"
                    >&nbsp;&#64;</span
                  ></span
                >
                <span>&nbsp;{{ app.OCPost.selectedPost.userCompany }}</span>
              </div>
              <div>
                <span
                  >Posted on
                  {{
                    app.OCHome.getTimeInfo(app.OCPost.selectedPost.create_time)
                  }}</span
                >
                <span
                  *ngIf="
                    app.OCPost.selectedPost.update_time !=
                    app.OCPost.selectedPost.create_time
                  "
                  >&nbsp;Edited on
                  {{
                    app.OCHome.getTimeInfo(app.OCPost.selectedPost.update_time)
                  }}</span
                >
              </div>
            </i>
          </div>
        </div>

        <!-- Control buttons -->
        <div class="ms-auto">
          <!-- Read mode -->
          <div *ngIf="app.OCPost.selectedPost._id !== app.OCHome.editedId">
            <button
              *ngIf="app.OCHome.hasEditPermission(app.OCPost.selectedPost)"
              class="btn btn-control"
              [title]="app.text.post.edit"
              (click)="
                app.OCHome.editDoc(app.OCPost.selectedPost._id);
                app.OCHome.createCleanModel(app.OCPost.selectedPost)
              "
            >
              <i class="fa fa-edit"></i>
            </button>
            <button
              *ngIf="app.OCHome.hasEditPermission(app.OCPost.selectedPost)"
              class="btn btn-control m-0"
              [title]="app.text.post.delete"
              (click)="deletePostModal.open()"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
          <!-- Edit mode -->
          <div *ngIf="app.OCPost.selectedPost._id === app.OCHome.editedId">
            <button
              class="btn btn-sm btn-control"
              (click)="app.OCPost.updatePost()"
              [title]="app.text.thread.save"
              [disabled]="app.OCPost.modelHasChanges()"
            >
              <i class="fa fa-check save-icon"></i>
            </button>
            <button
              class="btn btn-sm btn-control"
              [title]="app.text.user.closeBtn"
              (click)="discardChangesModal.open()"
            >
              <i class="fas fa-times close-icon"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Read mode -->
      <div *ngIf="app.OCPost.selectedPost._id !== app.OCHome.editedId">
        <div class="d-flex bottom-line pb-2">
          <div class="text pt-2">
            <b>{{ app.OCPost.selectedPost.title }}</b>
          </div>

          <div
            class="credits tag p-1 ms-auto"
            [ngStyle]="
              app.OCPostItem.getTagColor(
                app.fieldId.ocPost.tagCategory,
                app.OCPost.selectedPost.tag
              )
            "
          >
            {{
              app.field.getOptionText(
                app.fieldId.ocPost.tagCategory,
                app.OCPost.selectedPost.tag
              )
            }}
          </div>
        </div>
        <slot>
          <div
            [innerHTML]="
              app.field.highlightLinks(app.OCPost.selectedPost.message)
            "
            class="expanding-div fake-textarea input my-2"
            [ngClass]="{
              readMode: app.OCPost.selectedPost._id !== app.OCHome.editedId
            }"
          ></div>
        </slot>
      </div>
      <!-- Edit mode -->
      <div *ngIf="app.OCPost.selectedPost._id === app.OCHome.editedId">
        <div class="d-flex">
          <input
            [(ngModel)]="app.OCPost.cleanPost.title"
            type="text"
            class="input"
          />
          <div class="dropdown ms-1">
            <button
              class="btn btn-sm btn-tag"
              type="button"
              id="dropdownTags"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              [ngStyle]="
                app.OCPostItem.getTagColor(
                  app.fieldId.ocPost.tagCategory,
                  app.OCPost.cleanPost.tag
                )
              "
            >
              {{
                app.field.getOptionText(
                  app.fieldId.ocPost.tagCategory,
                  app.OCPost.cleanPost.tag
                )
              }}
            </button>
            <ul class="dropdown-menu">
              <li *ngFor="let tag of app.OCHome.tags">
                <a class="dropdown-item pointer" (click)="updateTag(tag)">{{
                  app.field.getOptionText(app.fieldId.ocPost.tagCategory, tag)
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <textarea
          [(ngModel)]="app.OCPost.cleanPost.message"
          class="input my-2"
        ></textarea>
      </div>

      <!-- Comment/Like -->
      <div class="d-flex justify-content-center">
        <div class="p-2">
          <i class="fa-regular fa-comment controls me-2"></i>
          <span class="controls-text"
            >{{ app.OCPost.selectedPost.counterComment }}&nbsp;{{
              app.text.ocPost.comments
            }}</span
          >
        </div>
        <div class="p-2">
          <button
            (click)="app.OCPost.likePost(app.OCPost.selectedPost._id)"
            class="btn btn-sm controls-text"
            [ngClass]="{ liked: app.OCPost.selectedPost.isLikedByLoggedInUser }"
          >
            <i
              *ngIf="!app.OCPost.selectedPost.isLikedByLoggedInUser"
              class="fa-regular fa-thumbs-up controls me-2"
            ></i>
            <i
              *ngIf="app.OCPost.selectedPost.isLikedByLoggedInUser"
              class="fa-solid fa-thumbs-up controls me-2 liked"
            ></i>
            <span>
              {{ app.OCPost.selectedPost.counterLikes }}
            </span>
            {{ app.text.ocPost.likes }}
          </button>
        </div>
      </div>

      <!-- Comments section -->
      <app-oc-comment></app-oc-comment>
    </div>
  </div>

  <div class="col-md-4 ps-0">
    <!-- Post image -->
    <div class="card mb-2">
      <div
        class="d-flex align-items-center justify-content-between card-header"
      >
        <div class="flex-shrink-0">
          <span class="pt-1">{{ app.text.ocPost.postImage }}</span>
        </div>
        <div class="flex-grow-3">
          <div *ngIf="app.OCPost.selectedPost != null">
            <input
              #fileInput
              type="file"
              accept="image/*"
              style="display: none"
              (change)="onUpload()"
            />
            <button
              *ngIf="app.OCHome.hasEditPermission(app.OCPost.selectedPost)"
              class="btn btn-control"
              (click)="fileInput.click()"
              [title]="app.text.thread.uploadImage"
            >
              <i class="fal fa-file-alt"></i>
            </button>
          </div>
        </div>
      </div>
      <app-left-nav
        [greyColored]="true"
        [imageSource]="app.OCPost.externalImage"
        [showBoxes]="false"
        [showImageControlBtn]="
          app.OCHome.hasEditPermission(app.OCPost.selectedPost) &&
          app.OCPost.externalImage != ''
        "
        [docType]="'ocPost'"
        [file]="app.OCPost.getImage()"
      >
      </app-left-nav>
    </div>
    <!-- Attachments -->
    <div class="card mb-2">
      <div
        class="d-flex align-items-center justify-content-between card-header"
      >
        <div class="flex-shrink-0">
          <span class="pt-1"> {{ app.text.attachments.files }}</span>
        </div>
        <div class="flex-grow-3">
          <app-attachments-controls
            *ngIf="
              app.OCHome.hasEditPermission(app.OCPost.selectedPost) &&
              app.OCPost.selectedPost != null
            "
            [id]="app.OCPost.selectedPost._id"
            [isColored]="false"
            type="ocPost"
          ></app-attachments-controls>
        </div>
      </div>
      <div class="mx-2">
        <app-attachments
          *ngIf="app.OCPost.selectedPost != null"
          [id]="app.OCPost.selectedPost._id"
          type="ocPost"
          [editPermission]="
            app.OCHome.hasEditPermission(app.OCPost.selectedPost)
          "
          [files]="app.OCPost.selectedPost._attachments"
          [filesDetails]="app.OCPost.selectedPost.attachmentHistory"
        ></app-attachments>
      </div>
    </div>
  </div>
</div>
